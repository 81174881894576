import { configure, observable, action, runInAction, toJS } from "mobx";
import { toast } from "react-toastify";
import { Get } from "Services/Request";
import { AdressCompany } from "Models/Companies";
import Calendar from "Components/Calendar";
import { IAttendee } from "Models/Attendees";
configure({ enforceActions: "observed" });

export interface ReportBaseData {
  // metrics: {
  registrations: number;
  checkins: number;
  absences: number;
  guests: number;
  draws: number;
  // };
}

interface ResponseReport {
  metrics: ReportBaseData;
}

// interface DataCalendar {
//   calendar: Calendar;
// }
interface Calendar {
  events: Events[];
  date: string;
}

export interface Events {
  _id: string;
  name: string;
  description: string;
  cover: string;
  active: false;
  adress: AdressCompany;
  organizer: Organizer;
  starts_at: string;
  ends_at: string;
  guests: [];
  created_at: string;
  updated_at: string;
  __v: 0;
}
interface Organizer {
  name: string;
  phone: string;
  email: string;
}

interface Filters {
  events_filter: any[];
  cities_filter: any[];
  business_filter: any[];
}
interface RegistrationsByTime {
  registrations: RegistrationDataTime[];
}

interface RegistrationDataTime {
  registrations: any;
  date: string;
}

export interface CompaniesData {
  associated_companies_amount: number;
  not_associated_companies_amount: number;
  total_companies_amount: number;
  associated_companies: CompanyData[];
  not_associated_companies: CompanyData[];
}

export interface CompanyData {
  name: string;
  participants: number;
}

export interface CityData {
  name: string;
  participants: number;
}

interface Gender {
  registrations: RegistationGender[];
}

interface RegistationGender {
  male: number;
  female: number;
  month: string;
}

export interface SelectedFilter {
  name: string;
  value: string;
}

class ReportStore {
  constructor() {
    this.getReportMetrics();
    // this.setGenderReport();
  }
  @observable
  calendar: Calendar[] = [];

  @observable
  registrationTime: RegistrationsByTime = {} as RegistrationsByTime;

  @observable
  registrationByYear: RegistrationsByTime = {} as RegistrationsByTime;

  @observable
  companiesData: CompaniesData = {} as CompaniesData;

  @observable
  citiesData: CityData[] = [];

  @observable
  statesData: CityData[] = [];

  @observable
  reportData: ReportBaseData = {} as ReportBaseData;

  @observable
  reportAttendeeData: IAttendee[] = [];

  @observable
  filters: Filters | null = null;

  @observable
  selectedFilters: SelectedFilter[] = [];

  @observable
  gender: Gender = {} as Gender;

  @action
  setGenderReport(data: Gender) {
    this.gender = data;
  }
  @action
  setReport(data: ReportBaseData) {
    this.reportData = data;
  }

  @action
  addSelectedFilter(data: SelectedFilter) {
    let exist = false;

    this.selectedFilters.map(filter => {
      if (filter.name === data.name) {
        filter.value = data.value;
        exist = true;
      }
    })

    if (!exist)
      this.selectedFilters.push(data);
  }

  @action
  getSelectedFilter(name: string) {
    let foundFilter = null;

    this.selectedFilters.map(filter => {
      if (filter.name === name) {
        foundFilter = filter;
      }
    })

    return foundFilter;
  }

  @action
  resetSelectedFilter() {
    this.selectedFilters = [];
  }

  @action
  getGenderReport = async (month?: any) => {
    try {
      const res = await Get<Gender>(
        `/api/dashboard/registrations-by-gender?${this.getFilterString()}`
      );

      runInAction(() => {
        // @ts-ignore
        this.gender = res.data.data;
      });
      console.log(toJS(this.gender));
    } catch (error) {}
  };
  @action setCalendar = (data: Calendar[]) => {
    this.calendar = data;
  };

  @action
  getCalendar = async (group?: string) => {
    try {
      const response = await Get(`/api/dashboard/calendar?${this.getFilterString()}`, {
        //@ts-ignore
        group_by: group,
      });
      console.log(toJS(response));
      if (response) {
        this.setCalendar(
          // @ts-ignore
          response.data.data.calendar
        );
      }
    } catch (error) {
      toast.error(error);
    }
  };

  @action
  getTimeRegistration = async (month?: any) => {
    try {
      const response = await Get(
        `/api/dashboard/registrations-by-time?${this.getFilterString()}`
      );
      console.log(response);
      runInAction(() => {
        // @ts-ignore
        this.registrationTime = response.data.data;
      });
      // console.log(toJS(this.registrationTime));
    } catch (error) {
      toast.error(error);
    }
  };

  @action
  getYearRegistration = async (month?: any) => {
    try {
      const response = await Get(
        `/api/dashboard/registrations-by-time?by_year=true&${this.getFilterString()}`
      );
      console.log(response);
      runInAction(() => {
        // @ts-ignore
        this.registrationByYear = response.data.data;
      });
    } catch (error) {
      toast.error(error);
    }
  };

  @action
  getCompaniesData = async (month?: any) => {
    try {
      const response = await Get(
        `/api/dashboard/companies-data?${this.getFilterString()}`
      );
      console.log(response);
      runInAction(() => {
        // @ts-ignore
        this.companiesData = response.data.data;
      });
      // console.log(toJS(this.companiesData));
    } catch (error) {
      toast.error(error);
    }
  };

  @action
  getCitiesData = async (month?: any) => {
    try {
      const response = await Get(
        `/api/dashboard/registered-attendees-by-city?${this.getFilterString()}`
      );
      console.log(response);
      runInAction(() => {
        // @ts-ignore
        this.citiesData = response.data.data.cities;
      });
      // console.log(toJS(this.citiesData));
    } catch (error) {
      toast.error(error);
    }
  };

  @action
  getStateData = async () => {
    try {
      const response = await Get(
        `/api/dashboard/registered-attendees-by-state?${this.getFilterString()}`
      );
      console.log("state-------")
      console.log(response);
      runInAction(() => {
        // @ts-ignore
        this.statesData = response.data.data.states;
      });
      // console.log(toJS(this.citiesData));
    } catch (error) {
      toast.error(error);
    }
  };

  @action
  getAttendeesRegistration = async (month?: any) => {
    try {
      const response = await Get(
        `/api/dashboard/registered-attendees?${this.getFilterString()}`
      );
      console.log(response);
      runInAction(() => {
        // @ts-ignore
        this.reportAttendeeData = response.data.data.registrations;
      });
      // console.log(toJS(this.reportAttendeeData));
    } catch (error) {
      toast.error(error);
    }
  };

  getFilterString = () => {
    let filterString = "";

    this.selectedFilters.map((selectedFilter, index) => {
      if (selectedFilter.value && selectedFilter.value.toString().length > 0) {
        if (Array.isArray(selectedFilter.value)) {
          selectedFilter.value.map((value, i) => {
            filterString += `${selectedFilter.name}=${value}`;
            if (i < selectedFilter.value.length - 1) filterString += "&"
          });
        } else {
          filterString += `${selectedFilter.name}=${selectedFilter.value}`;
        }

        if (index < this.selectedFilters.length - 1) filterString += "&"
      }
    });

    console.log(filterString)

    return filterString;
  }

  @action
  getFilters = async () => {
    try {
      const response = await Get(
        `/api/dashboard/filters`
      );
      console.log(response);
      //@ts-ignore
      this.filters = toJS(response.data.data);
    } catch (error) {
      toast.error(error);
    }
  };

  @action
  getReportMetrics = async () => {
    try {
      const response = await Get<ResponseReport>(`/api/dashboard/basic?${this.getFilterString()}`);

      this.setReport({
        registrations: response.data.data.metrics.registrations,
        checkins: response.data.data.metrics.checkins,
        absences: response.data.data.metrics.absences,
        guests: response.data.data.metrics.guests,
        draws: response.data.data.metrics.draws,
      });

      // console.log(toJS(this.reportData));
    } catch (err) {
      console.log(err.response);
      // toast.error(err.response.data.error);
    }
  };
}

export const reportStore = new ReportStore();
