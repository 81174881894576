import { Reducer } from "react";

import { IAction, ActionType, IState } from "../models";

export const reducer: Reducer<IState, IAction> = (state, action) => {
  switch (action.type) {
    case ActionType.SetIsLoading:
      return { ...state, isLoading: action.payload };
    case ActionType.SetEventDraws:
      return { ...state, draws: action.payload };
    case ActionType.SetCurrentDrawId:
      return { ...state, currentDrawId: action.payload };
    case ActionType.SetIsContextMenuOpen:
      return { ...state, isContextMenuOpen: action.payload };
    case ActionType.SetIsDeleteDialogOpen:
      return { ...state, isDeleteDialogOpen: action.payload };
    case ActionType.SetIsReRunDialogOpen:
      return { ...state, isReRunDialogOpen: action.payload };
    case ActionType.SetIsWinnerDialogOpen:
      return { ...state, isWinnerDialogOpen: action.payload };
    case ActionType.SetWinnerName:
      return { ...state, winnerName: action.payload };
    case ActionType.SetIsFetchingDraws:
      return { ...state, isFetchingDraws: action.payload };
    case ActionType.SetIsDeletingDraw:
      return { ...state, isDeletingDraw: action.payload };
    case ActionType.SetAnchorEl:
      return { ...state, anchorEl: action.payload };
    default:
      throw new Error();
  }
};
