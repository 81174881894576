import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useStore } from "Store";

import * as S from "./styles";

// * Material UI and Form related

import {
  PrimaryActionButton,
  SecondaryActionButton,
} from "Components/Styled/Form";

import Grid from "@material-ui/core/Grid";
// @ts-ignore
import { FormikTextField } from "formik-material-fields";
// @ts-ignore
import { FormikSelectField } from "formik-material-fields";
import { Container, TextField } from "@material-ui/core";
import { Formik, Form as FormikForm } from "formik";
import InputMask from "react-input-mask";
import { validationTypeSchema } from "../models";

import { validateCNPJ, validatePhone } from "Utils";
import { toast } from "react-toastify";
import SearchBar from "Components/SearchBar";
import { RegisterQuestionModel } from "Models/RegisterQuestion";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const StringMask = require("string-mask");
interface RouteParams {
  companyId: string;
}

const RegisterQuestionsForm = () => {
  const { registerquestionsStore } = useStore();
  const history = useHistory();
  const [registerQuestions, setRegisterQuestions] = useState<RegisterQuestionModel | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    question_1: "",
    question_2: "",
    question_3: ""
  });
  const [description, setDescription] = useState<string>("");
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

  useEffect(() => {
    fetchRegisterQuestions();
  }, []);

  const fetchRegisterQuestions = useCallback(async () => {
    const resp = await registerquestionsStore.fetchRegisterQuestion();

    if (resp) {
      setRegisterQuestions(resp);

      setInitialValues({
        question_1: resp.question_1,
        question_2: resp.question_2,
        question_3: resp.question_3
      })
      
      setDescription(resp.description);

      const contentBlock = htmlToDraft(resp.description);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [registerquestionsStore]);

  const onEditorChange = async (editorStateProps: EditorState) => {
    setEditorState(editorStateProps);
    setDescription(draftToHtml(convertToRaw(editorStateProps.getCurrentContent())));
  }

  const onSubmit = async (values: any) => {
    try {
      const response = await registerquestionsStore.updateRegisterQuestion(
        registerQuestions!._id,
        { ...values, description }
      );
      console.log(response);
    } catch (error) {}
  };

  return isLoading ? (
    <h1>Carregando</h1>
  ) : (
    <>
      <SearchBar title={"Perguntas para registros de novos participantes"} />
      <Container>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ handleChange, values, errors }) => (
            <>
              <S.FormContainer>
                <S.FormContent>
                  <FormikForm autoComplete="off">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <FormikTextField
                          name="question_1"
                          label="Pergunta 1"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormikTextField
                          name="question_2"
                          label="Pergunta 2"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormikTextField
                          name="question_3"
                          label="Pergunta 3"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Editor
                          editorState={editorState}
                          onEditorStateChange={onEditorChange}
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          localization={{
                            locale: 'pt',
                          }}
                          editorStyle={{
                            backgroundColor: "#ffffff",
                            borderRadius: '2px',
                            border: '1px solid #F1F1F1',
                            height: "300px"
                          }}
                        />
                      </Grid>
                    </Grid>
                    <S.ButtonRow>
                      <PrimaryActionButton type="submit">
                        Atualizar
                      </PrimaryActionButton>
                    </S.ButtonRow>
                  </FormikForm>
                </S.FormContent>
              </S.FormContainer>
            </>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default RegisterQuestionsForm;
