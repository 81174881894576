import Toolbar from "@material-ui/core/Toolbar";
import styled from "styled-components";

import Chip from "@material-ui/core/Chip";

const BaseLabel = styled.div`
  width: min-content;
  margin: 10px 0;
  white-space: nowrap;
  padding: 5px 10px;
  border-radius: 5px;
`;

export const ErrorLabel = styled(BaseLabel)`
  background: #f8d7da;
  color: #721c24;
`;

export const AlertLabel = styled(BaseLabel)`
  background: #ffeeba;
  color: #856403;
`;

export const AlertChip = styled(Chip)`
  && {
    background: #ffeeba;
    color: #856403;
  }
`;

export const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const GenericTableToolbar = styled(Toolbar)`
  && {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #ddd;
  }
`;
