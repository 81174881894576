import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import * as S from "./styles";

import { EventModel, EventBusiness } from "Models/Events";

import {
  GenericFAB,
  GenericTableHead,
  GenericTableRow,
  GenericLeftFAB,
  Generic2FAB,
  GenericLeft2FAB,
} from "Components/Styled/table";
import { GenericTableToolbar } from "Components/Styled/util";

import { Clear as ClearIcon, Search as SearchIcon } from "@material-ui/icons";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import {
  InputAdornment,
  IconButton,
  TableContainer,
  Table,
  TableCell,
  Paper,
  TableBody,
  Input,
  Typography,
  Checkbox,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Chip from "@material-ui/core/Chip";

import { Add } from "@material-ui/icons";

import { useStore } from "Store";
import AddParticipantModal from "./AddParticipantModal";
import { attendeesStore } from "Store/Attendees";

import { paginate, getPagesCount } from "../../utils";
import ModalEditStatus from "./Modal";
import LoadingModal from "Components/LoadingModal";
import ModalEditLimit from "./Modal";

interface RouteParams {
  eventId: string;
}
interface IProps {
  currentEvent: EventModel;
  refetchEvent: Function;
  onClickInSubscriptions?: Function;
}

const Business: React.FC<IProps> = ({ currentEvent, refetchEvent, onClickInSubscriptions }) => {
  const history = useHistory();
  const { eventId } = useParams<RouteParams>();
  const { eventsStore } = useStore();
  const [filter, setFilter] = useState("");
  const [isBetweenStartAndEndDate] = useState(
    moment().isBetween(
      currentEvent.registration_start,
      currentEvent.registration_end
    )
  );
  const [registrationsArr, setRegistrationsArr] = useState(
    currentEvent.businesses
  );
  const [openEdit, setOpenEdit] = useState(false);
  const [currentBus, setCurrentBus] = useState(undefined as undefined | EventBusiness);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [currentAttId, setCurrentAttId] = useState("");
  // const [allSelected, setAllSelected] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    let local: typeof currentEvent.businesses = currentEvent.businesses;
    local = currentEvent.businesses.filter(
      (registration) =>
        registration.business.legal_name
          .toLowerCase()
          .includes(filter.toLowerCase()) ||
        registration.business.trade_name
          .toLowerCase()
          .includes(filter.toLowerCase())
    );
    setRegistrationsArr(local);
  }, [filter, currentEvent]);

  const handleUnlinkFromEvent = async (businessId: string) => {
    try {
      await eventsStore.unlinkBusinessFromEvent({ business_id: businessId, eventId });
      refetchEvent();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleOnEdit = async () => {
    try {
      refetchEvent();
    } catch (error) {
      console.log(error.response);
    }
  }
  interface MyValues {
    name: string;
    company: string;
  }

  const onSubmitParticipant = (values: MyValues) => {};

  const editStatus = (business: EventBusiness) => {
    setCurrentBus(business);
    setOpenEdit(true);
  };

  const getFormattedCNPJ = (cnpj: string) => {
    if (!cnpj) return "";

    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  }

  function handleCheckAllItems(isChecked: boolean) {
		const aux: string[] = [];
    // setAllSelected(isChecked);
		if (!isChecked) {
			setSelectedItems([]);
      setCurrentAttId("");
		} else {
      registrationsArr.map(registration => {
        aux.push(registration.business._id);
      })
			setSelectedItems(aux);
		}
	}

  function updateSelectedItemsArray(itemId: string, checked: boolean) {
		var aux: string[] = selectedItems;

		if (checked) {
			const exists = aux.find(
				(i) => i === itemId
			);

			if (!exists) {
				aux.push(itemId);
			}
		} else {
			aux = aux.filter((i) => {
				return i !== itemId;
			});
		}

    if (checked)
      setCurrentAttId(itemId);
    else {
      setCurrentAttId("0");
    }
    
		setSelectedItems(aux);
	}

  const handleUnlinkBusinessesFromEventInBatch = async () => {
    try {
      await eventsStore.unlinkBusinessFromEventInBatch({ business_ids: selectedItems, eventId });
      refetchEvent();
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <>
      <LoadingModal open={isFetching}>
        <S.LoadingStatusContainer>
          Atualizando empresas...
        </S.LoadingStatusContainer>
      </LoadingModal>
      <>
        {selectedItems && selectedItems.length > 0 ? (
          <>
            <GenericLeft2FAB
              disabled={!currentEvent.active || !isBetweenStartAndEndDate}
              onClick={() => {
                handleUnlinkBusinessesFromEventInBatch();
                handleCheckAllItems(false);
              }}
              variant="extended"
              color="primary"
            >
              Desvincular Empresa(s)
            </GenericLeft2FAB>
          </>
        ) : (
          <GenericFAB
            disabled={!currentEvent.active || !isBetweenStartAndEndDate}
            onClick={() => {
              history.push(`/empresas/${eventId}`)
              // setOpenEdit(true);
            }}
            variant="extended"
            color="primary"
          >
            <Add /> Vincular empresa
          </GenericFAB>
        )}
        {currentEvent.businesses.length > 0 ? (
          <TableContainer component={Paper}>
            <GenericTableToolbar>
              <Typography variant="subtitle1" component="div">
                Empresas liberadas para inscrição de participantes ({registrationsArr.length}) {selectedItems && selectedItems.length > 0 ? " - Selecionado(s): " + selectedItems.length : ""}
              </Typography>
              <Input
                placeholder="Filtro"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    {filter.length > 0 ? (
                      <IconButton
                        aria-label="clear field"
                        onClick={() => setFilter("")}
                      >
                        <ClearIcon />
                      </IconButton>
                    ) : (
                      <SearchIcon />
                    )}
                  </InputAdornment>
                }
              />
            </GenericTableToolbar>
            <Table>
              <GenericTableHead>
                <GenericTableRow>
                  <TableCell>
                    <Checkbox
                      edge="start"
                      onChange={(e, checked) => {
                        handleCheckAllItems(checked);
                      }}
                      tabIndex={-1}
                      disableRipple
                    />
                  </TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>CNPJ</TableCell>
                  <TableCell>Limite de Participantes</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Contato</TableCell>
                  <TableCell></TableCell>
                </GenericTableRow>
              </GenericTableHead>
              <TableBody>
                {paginate({
                  array: registrationsArr,
                  page_number: currentPage,
                  page_size: 25,
                }).map((registration) => (
                  <GenericTableRow key={registration._id}>
                    <TableCell component="th" scope="row">
                      <Checkbox
                        edge="start"
                        checked={selectedItems.indexOf(registration.business._id) !== -1}
                        onChange={(e, checked) => {
                          updateSelectedItemsArray(registration.business._id, checked);
                        }}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          "aria-labelledby": registration.business._id,
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {registration.business.trade_name.toUpperCase()}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {getFormattedCNPJ(registration.business.cnpj)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {registration.user_limit}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {registration.business.profile_type === "associated_company" ? "Empresa associada" : "Empresa não associada"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {`(${registration.business.phone.area_code}) `}
                      {registration.business.phone.number}
                      <WhatsAppIcon onClick={() => {
                        let url = `https://wa.me/${
                          registration.business.phone.area_code +
                            (registration.business.phone.number.split(" ").length > 1 ? registration.business.phone.number.split(" ")[1].replace("-", "") : registration.business.phone.number.replace("-", ""))
                        }`;
                        window.open(url, '_blank', 'noopener,noreferrer');
                      }} style={{marginLeft: "3px"}} />
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      <Chip
                        label="Desvincular"
                        clickable
                        style={{ margin: "5px" }}
                        color="secondary"
                        onClick={() =>
                          handleUnlinkFromEvent(registration.business._id)
                        }
                      />
                      {registration && (
                        <Chip
                          label="Editar Limite"
                          clickable
                          style={{ margin: "5px" }}
                          color="primary"
                          onClick={() => editStatus(registration)}
                        />
                      )}
                      <Chip
                        label="Inscritos"
                        clickable
                        style={{ margin: "5px" }}
                        color="primary"
                        onClick={() => {
                          history.push(`/events/${eventId}?filter=${registration.business.trade_name.toUpperCase()}`);
                          if (onClickInSubscriptions) onClickInSubscriptions();
                        }}
                      />
                    </TableCell>
                  </GenericTableRow>
                ))}
              </TableBody>
            </Table>
            <S.PaginationContainer>
              <Pagination
                page={currentPage}
                count={getPagesCount({
                  itemsCount: registrationsArr.length,
                  pageSize: 25,
                })}
                onChange={(event, value) => setCurrentPage(value)}
                color="secondary"
              />
            </S.PaginationContainer>
          </TableContainer>
        ) : (
          <S.NoBusinessContainer>
            <h3>Nenhuma empresa por aqui!</h3>
          </S.NoBusinessContainer>
        )}
      </>
      
      {currentBus && (
        <ModalEditLimit
          openIt={openEdit}
          setOpen={setOpenEdit}
          eventBusiness={currentBus}
          onEdit={handleOnEdit}
        />
      )}
      
    </>
  );
};

export default Business;
