import styled from "styled-components";

export const NoBusinessContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 98%;
  margin: 10px;
`;

export const LoadingStatusContainer = styled.div`
  font-weight: bold;
  color: #fff;
`;