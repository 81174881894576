import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useObserver } from "mobx-react-lite";
import { autorun } from "mobx";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CalendarToday from "@material-ui/icons/CalendarToday";

import { useStore } from "Store";
import * as S from "./styles";
import Grid from "@material-ui/core/Grid";

// @ts-ignore
import { FormikTextField } from "formik-material-fields";
import Moment from "moment";

import LoadingModal from "Components/LoadingModal";
import { EventModel, EventSponsorModel } from "Models/Events";
import { LoadingStatusContainer } from "Pages/Home/CreateOrEditEvent/components/SecondStep/styles";
import { LandingPageModel } from "Models/LandingPage";

const override = css`
  margin: 0 auto;
  padding: 0;
`;

interface ISection {
  name: string,
  position: number
}

const EventSubscription: React.FC = () => {
  const history = useHistory();
  const { userStore, appStore, eventsStore, subscriptionsStore, eventsponsorsStore, landingpagesStore } = useStore();
  const params = useParams();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [currentEvent, setCurrentEvent] = useState<EventModel>({} as EventModel);
  const [eventSponsors, setEventSponsors] = useState<EventSponsorModel[]>([]);
  const [currentLandingPage, setCurrentLandingPage] = useState<LandingPageModel>({} as LandingPageModel);
  const [bodySections, setBodySections] = useState([] as ISection[]);

  const fetchCurrentEvent = async () => {
    try {
      //@ts-ignore
      const eventName = params.eventName;
      const response = await eventsStore.fetchEventByShortName(eventName);
      //@ts-ignore
      var eventId = response.data.event._id;

      // @ts-ignore
      setCurrentEvent(response.data.event);

      const responseLP = await landingpagesStore.fetchLandingPage(eventId);
      // @ts-ignore
      setCurrentLandingPage(responseLP);

      var sections = [] as ISection[];

      sections.push({
        name: "description_head_position",
        position: responseLP.description_head_position
      });

      sections.push({
        name: "description_title_position",
        position: responseLP.description_title_position
      });

      sections.push({
        name: "description_body_position",
        position: responseLP.description_body_position
      });

      sections.push({
        name: "description_sponsors_position",
        position: responseLP.description_sponsors_position
      });

      sections.push({
        name: "description_button_position",
        position: responseLP.description_button_position
      });

      sections.sort((a, b) => {
        return a.position - b.position;
      })
      
      setBodySections(sections);

      const responseSponsor = await eventsponsorsStore.fetchEventSponsors(eventId);
      //@ts-ignore
      setEventSponsors(responseSponsor);
    } catch (error) {
      console.log(error);
    }

    if (!currentEvent._id || !currentEvent.active) {
      //Error e 404
      console.log("Error on get event");
    }
  };

  useEffect(() => {
    appStore.initializeBaseURL();
    fetchCurrentEvent();
  }, []);

  const onHandleSubscribe = async () => {
    subscriptionsStore.setEventIdForSubscribe(currentEvent._id);
    subscriptionsStore.setEventNameForSubscribe(currentEvent.name);

    if (userStore.isSignedIn && userStore.currentUser) {
      await subscriptionsStore.onSubscribeEvent(currentEvent._id, userStore.currentUser._id);
    } else {
      history.push("/signup");
    }
  };

  const HeadSection = () => {
    return (
      <>
      <img src={appStore.currentBaseURL + "/" + currentEvent?.cover_url} style={{
        alignSelf: "center;", 
        width: "100%",
        paddingLeft: "15%",
        paddingRight: "15%",
        paddingTop: 0
        }}></img>
      </>
    )
  }

  const TitleSection = () => {
    return (
      <>
        <Grid container spacing={2} style={{
          alignSelf: "center;", 
          width: "100%",
          paddingLeft: "15%",
          paddingRight: "15%",
          paddingTop: "20px"
          }}>
            <S.FormTitle style={{color: currentLandingPage.description_text_color}}>
              {currentEvent.name}
            </S.FormTitle>
        </Grid>
        <Grid container spacing={2} style={{
          alignSelf: "center;", 
          width: "100%",
          paddingLeft: "15%",
          paddingRight: "15%",
          paddingTop: "20px",
          paddingBottom: "20px"
          }}>
            <div><CalendarToday style={{color: currentLandingPage.description_text_color, width: "20px", height: "20px"}}/></div>
            <div style={{paddingLeft: "10px", color: currentLandingPage.description_text_color}}>
              {Moment(currentEvent?.starts_at).format(
                "DD [de] MMMM [de] YYYY [às] HH[:]MM"
              )}
            </div>
        </Grid>
      </>
    )
  }

  const BodySection = () => {
    return (
      <div style={{
        backgroundColor: `${currentLandingPage.description_background_secondary_color ? currentLandingPage.description_background_secondary_color : "rgb(245, 247, 248)"}`,
        }}>
          <Grid container spacing={2} style={{
          alignSelf: "center;", 
          width: "100%",
          paddingLeft: "15%",
          paddingRight: "15%",
          paddingTop: "20px",
          paddingBottom: "20px"
          }}>
            <S.FormSubtitle style={{color: currentLandingPage.description_text_color}}>
              Descrição do evento:
            </S.FormSubtitle>
        </Grid>
        <Grid container spacing={2} style={{
          alignSelf: "center;", 
          width: "100%",
          paddingLeft: "15%",
          paddingRight: "15%",
          paddingBottom: "20px"
          }}>
            <div style={{color: currentLandingPage.description_text_color}} dangerouslySetInnerHTML={{ __html: currentLandingPage?.description_body }} />
        </Grid>
      </div>
    );
  }

  const SponsorsSection = () => {
    return (
      <div>
          <Grid container spacing={2} style={{
          alignSelf: "center;", 
          width: "100%",
          paddingLeft: "15%",
          paddingRight: "15%",
          paddingTop: "20px",
          paddingBottom: "20px"
          }}>
            {eventSponsors && eventSponsors.length > 0 && (
              <S.FormSubtitle style={{color: currentLandingPage.description_text_color}}>
                Patrocinadores:
              </S.FormSubtitle>
            )}
        </Grid>
        <Grid container spacing={2} style={{
          alignSelf: "center;", 
          width: "100%",
          paddingLeft: "15%",
          paddingRight: "15%",
          paddingBottom: "20px"
          }}>
            {eventSponsors.map(sponsor => {
              return (
                <img
                  src={appStore.currentBaseURL + "/" + sponsor.cover_url}
                  alt="cover"
                  height="100"
                />
              )
            })}
        </Grid>
      </div>
    )
  }

  const ButtonSection = () => {
    return (
      <div>
          <Grid container spacing={2} style={{
            alignSelf: "center;", 
            width: "100%",
            paddingLeft: "15%",
            paddingRight: "15%"
            }}>
              <S.SignupButton
                disabled={isSubmitting}
                onClick={() => {
                  onHandleSubscribe();
                }}
                style={{
                  background: currentLandingPage.description_button_color,
                  color: currentLandingPage.description_button_text_color ? currentLandingPage.description_button_text_color : "#fff"
                }}
              >
                <S.LoginButtonTitle>
                  {isSubmitting ? (
                    <ClipLoader
                      css={override}
                      size={14}
                      color={currentLandingPage.description_button_text_color ? currentLandingPage.description_button_text_color : "#fff"}
                      loading={true}
                    />
                  ) : (
                    "Registrar-se"
                  )}
                </S.LoginButtonTitle>
              </S.SignupButton>
          </Grid>
        </div>
    )
  }

  return useObserver(() => (
    <S.Container>
      <S.ContentContainer>
        <LoadingModal open={!(currentEvent && currentEvent.name && currentLandingPage)}>
          <LoadingStatusContainer>Carregando evento...</LoadingStatusContainer>
        </LoadingModal>
        {currentLandingPage && currentEvent.name && currentLandingPage && bodySections && (
          <>
            {currentLandingPage?.description_body_html && currentLandingPage?.description_body_html !== "" ? (
              <div dangerouslySetInnerHTML={{ __html: currentLandingPage?.description_body_html }} />
            ) : (
              <div style={currentLandingPage.description_background_image_url ? {
                  backgroundImage: `url(${appStore.currentBaseURL + "/" + currentLandingPage.description_background_image_url})`,
                  backgroundColor: currentLandingPage.description_background_color,
                } : {
                  backgroundColor: currentLandingPage.description_background_color,
                }}>
                {bodySections.map(section => {
                  if (section.name === "description_head_position") {
                    return <HeadSection />;
                  }
                  else if (section.name === "description_title_position") {
                    return <TitleSection />;
                  }
                  else if (section.name === "description_body_position") {
                    return <BodySection />;
                  }
                  else if (section.name === "description_sponsors_position") {
                    return <SponsorsSection />;
                  }
                  else if (section.name === "description_button_position") {
                    return <ButtonSection />;
                  }
                })}
              </div>
            )}
          </>
        )}
        
      </S.ContentContainer>
    </S.Container>
  ));
};

export default EventSubscription;
