import React, { useEffect, useState, useCallback } from "react";
import { useObserver } from "mobx-react-lite";
import clsx from "clsx";

import { useParams } from "react-router-dom";

import EventDrawer from "./components/EventDrawer";

import Participants from "./Participants";
import Badge from "./Badge";
import Page from "./Page";
import Invitation from "./Invitation";
import { EventModel } from "Models/Events";
import * as S from "./styles";
import EventAppBar from "./components/EventAppBar";
import { useStore } from "Store";
import Draw from "./Draw";
import Checkin from "./Checkin";
import { autorun } from "mobx";
import ImportParticipants from "./ImportParticipants";
import Business from "./Business";
import CertificatePage from "./CertificateConfig";
import Certificates from "./Certificate";

interface RouteParams {
  eventId: string;
}

const ShowEvent: React.FC = () => {
  const classes = S.useStyles();
  const { eventsStore } = useStore();
  const { eventId } = useParams<RouteParams>();
  const [currentTitle, setCurrentTitle] = useState<string>("Participantes");
  const [open, setOpen] = useState(true);
  const [currentEvent, setCurrentEvent] = useState<EventModel | null>();

  let drawerItems = [
    { id: 0, title: "Participantes" },
    { id: 1, title: "Crachás" },
    { id: 2, title: "Certificados" },
    { id: 3, title: "Check-ins" },
    { id: 4, title: "Sorteios" },
    { id: 5, title: "Página" },
    { id: 6, title: "Importação" },
    { id: 7, title: "Empresas" },
    { id: 8, title: "Configuração de Certificado" },
  ];

  const fetchCurrentEvent = useCallback(async () => {
    try {
      const response = await eventsStore.fetchEventById(eventId);
      // @ts-ignore
      setCurrentEvent(response.data.event);
    } catch (error) {
      console.log(error);
    }
  }, [eventId, eventsStore]);

  useEffect(() => {
    fetchCurrentEvent();
  }, [fetchCurrentEvent]);

  const handleToggleBar = () => {
    setOpen((e) => !e);
  };

  useEffect(() => {
    eventsStore.setCurrentEventId(eventId);
  }, [eventId, eventsStore]);

  autorun(() => {
    console.log(eventsStore.currentEventSubPageIndex);
  });

  useEffect(() => {
    setCurrentTitle(drawerItems[eventsStore.currentEventSubPageIndex]["title"]);

    if (currentEvent) {
      setTimeout(() => {
        setOpen(false);
      }, 200);
    }
  }, [eventsStore.currentEventSubPageIndex]);

  useEffect(() => {
    setOpen(true);
    
    if (window.matchMedia("(max-width: 500px)").matches) {
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }

  }, [currentEvent]);

  const getPage = (currentEvent: EventModel) => {
    switch (eventsStore.currentEventSubPageIndex) {
      case 0:
        return (
          <Participants
            currentEvent={currentEvent}
            refetchEvent={fetchCurrentEvent}
          />
        );
      case 1:
        return <Badge />;
      case 2:
        return <Certificates />;
      case 3:
        return (
          <Checkin
            currentEvent={currentEvent}
            refetchEvent={fetchCurrentEvent}
          />
        );
      case 4:
        return (
          <Draw currentEvent={currentEvent} refetchEvent={fetchCurrentEvent} />
        );
      case 5:
        return <Page />;
      case 6:
        return <ImportParticipants
                changeEventPage={async () => {
                  await fetchCurrentEvent();
                  eventsStore.setCurrentEventSubPageIndex(0);
                }} />;
      case 7:
        return <Business
                currentEvent={currentEvent}
                refetchEvent={fetchCurrentEvent}
                onClickInSubscriptions={() => {
                  eventsStore.setCurrentEventSubPageIndex(0);
                }} />
      case 8:
        return <CertificatePage />
    }
  };
  return useObserver(() => (
    <>
      {currentEvent ? (
        <>
          <EventAppBar
            currentEvent={currentEvent}
            title={currentTitle}
            open={open}
            handleToggleBar={handleToggleBar}
            classes={classes}
          />
          <EventDrawer
            event={currentEvent}
            title={currentEvent?.name}
            open={open}
            setCurrentPage={eventsStore.setCurrentEventSubPageIndex}
            currentPageIndex={eventsStore.currentEventSubPageIndex}
          />
          <S.MainContent
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
          >
            {currentEvent ? getPage(currentEvent) : <h6>Loading...</h6>}
          </S.MainContent>
        </>
      ) : (
        <h6>Loading...</h6>
      )}
    </>
  ));
};

export default ShowEvent;
