import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useStore } from "Store";

// @ts-ignore
import { estados } from "estados-br";

import { MyValues, IOptions, IResponse } from "../models";

import * as S from "./styles";

// * Material UI and Form related

import {
  PrimaryActionButton,
  SecondaryActionButton,
} from "Components/Styled/Form";

import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// @ts-ignore
import { FormikTextField } from "formik-material-fields";
// @ts-ignore
import { FormikSelectField } from "formik-material-fields";
import { Container, TextField } from "@material-ui/core";
import { Formik, Form as FormikForm } from "formik";
import InputMask from "react-input-mask";
import { validationTypeSchema } from "../models";

import { validateCNPJ, validatePhone } from "Utils";
import { toast } from "react-toastify";
import SearchBar from "Components/SearchBar";
const StringMask = require("string-mask");
interface RouteParams {
  companyId: string;
}

const AttendeeTypeForm = () => {
  const formatter = new StringMask("(00) 00000-0000");
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [ufOptions, setUfOptions] = useState<IOptions[]>([]);
  const { companyId } = useParams<RouteParams>();
  const { attendeesStore } = useStore();
  const history = useHistory();
  const [isFetchingCep, setIsFetchingCep] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<{name: string; value: string}>({
    name: "",
    value: ""
  });

  const onSubmit = async (values: {name: string; value: string}) => {
    try {
      console.log("Submeteu")
      const response = await attendeesStore.registerAttendeeType(
        { ...values },
        () => {
          history.replace("/tipos-participantes")
        }
      );
      console.log(response);
    } catch (error) {}
  };

  return isLoading ? (
    <h1>Carregando</h1>
  ) : (
    <>
      <SearchBar title={"Cadastro de Tipos de Participantes"} />
      <Container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationTypeSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ handleChange, values, setFieldValue, setFieldError, errors }) => (
            <>
              <S.FormContainer>
                <S.FormContent>
                  <FormikForm autoComplete="off">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="name"
                          label="Nome"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="value"
                          label="Chave"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <S.ButtonRow>
                      <SecondaryActionButton
                        onClick={() => history.replace("/tipos-participantes")}
                      >
                        Cancelar
                      </SecondaryActionButton>
                      <PrimaryActionButton type="submit">
                        {companyId ? "Atualizar" : "Cadastrar"}
                      </PrimaryActionButton>
                    </S.ButtonRow>
                  </FormikForm>
                </S.FormContent>
              </S.FormContainer>
            </>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default AttendeeTypeForm;
