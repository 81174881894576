import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { EventModel } from "Models/Events";

// * Material UI
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import IconButton from "@material-ui/core/IconButton";
import SearchBar from "Components/SearchBar";
import logoAdapa from "Assets/Images/adapa.png";

import { Add, MoreVert } from "@material-ui/icons";
import {
  FlexTD,
  GenericFAB,
  GenericTableHead,
  GenericTableRow,
} from "Components/Styled/table";
import { toJS, autorun } from "mobx";
import Moment from "moment";
import { useStore } from "Store";
import * as S from "./styles";
import { useObserver } from "mobx-react-lite";

import EventContextMenu from "./components/EventContextMenu";

import { CustomCircularProgress } from "Components/Styled/Loading";
import styled from "styled-components";

import { NoDataContainer, GenericTableToolbar } from "Components/Styled/util";
import { toast } from "react-toastify";
import { Typography, Card, CardActionArea, CardMedia, CardContent, CardActions, Button, Grid } from "@material-ui/core";
import LoadingModal from "Components/LoadingModal";
import { LoadingStatusContainer } from "Pages/Home/CreateOrEditEvent/components/SecondStep/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    status__active: {
      color: "#2cda2c",
    },
    status__inactive: {
      color: "#e72419",
    },
  })
);

const Subscriptions = () => {
  const history = useHistory();
  const classes = useStyles();
  const { subscriptionsStore, appStore } = useStore();
  const [isCurrentEventActive, setIsCurrentEventActive] = useState<boolean>(
    false
  );
  const [currentEvent, setCurrentEvent] = useState<EventModel | null>(
    {} as EventModel
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentEventId, setCurrentEventId] = useState("");
  const open = Boolean(anchorEl);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [nextEvents, setNextEvents] = useState([] as EventModel[]);

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    eventId: string,
    isActive: boolean
  ) => {
    setIsCurrentEventActive(isActive);
    setCurrentEventId(eventId);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  useEffect(() => {
    fetchEvents();
  }, [subscriptionsStore]);

  useEffect(() => {
    var eventsToShow = [] as EventModel[];
    if (subscriptionsStore.events && subscriptionsStore.events.length > 0 && subscriptionsStore.nextEvents && subscriptionsStore.nextEvents.length > 0) {
      subscriptionsStore.nextEvents.map(nextEvent => {
        let registered = false;
        subscriptionsStore.events.map(event => {
          if (event._id === nextEvent._id) registered = true;
        });
        if (!registered) eventsToShow.push(nextEvent);
      })
    } else if (subscriptionsStore.nextEvents && subscriptionsStore.nextEvents.length > 0) {
      eventsToShow = subscriptionsStore.nextEvents;
    }

    setNextEvents(eventsToShow);
    
  }, [subscriptionsStore.nextEvents, subscriptionsStore.events]);

  const fetchEvents = async () => {
    setLoading(true);
    try {
      await subscriptionsStore.fetchSubscriptionEvents();
      await subscriptionsStore.fetchSubscriptionHistoryEvents();
      await subscriptionsStore.fetchNextEvents();
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  useEffect(
    autorun(() => {
      console.log(toJS(subscriptionsStore.events));
    })
  );

  const openEventPage = (row: EventModel) => {
    if (row.active && Moment(row.starts_at).isSameOrBefore(new Date()) && Moment(row.ends_at).isSameOrAfter(new Date())) {
      if (row.type !== "online") {
        toast.error("Para mais informações, consulte a organização.");
        return;
      }

      history.push(`/evento/${row.short_name}`);
    } else if (row.active && Moment(row.starts_at).isAfter(new Date())) {
      toast.info("Evento não iniciado.");
      return;
    } else {
      toast.error("Evento encerrado");
    }
  }

  const openNewEventPage = (row: EventModel) => {
    if (row.active && Moment(row.registration_start).isSameOrBefore(new Date()) && Moment(row.registration_end).isSameOrAfter(new Date())) {
      if (row.type !== "online") {
        toast.error("Para mais informações, consulte a organização.");
        return;
      }

      history.push(`/inscricao/${row.short_name}`);
    } else {
      toast.error("Inscrições encerradas");
    }
  }

  return useObserver(() => (
    <>
      <SearchBar title={"Minhas Inscrições"} />
      <LoadingModal open={loading}>
        <LoadingStatusContainer>Carregando...</LoadingStatusContainer>
      </LoadingModal>
      {!loading && (
        <S.MainContainer>
          {/* Eventos Futuros para inscrição */}
          {nextEvents.length >= 1 && (
            <Grid container spacing={2} style={{marginBottom: "30px"}}>
            {/* <Grid container spacing={2} style={{marginBottom: "30px"}} direction={"column"}> */}
              {nextEvents.map((row, index) => (
                <Grid item xs={12} md={12}>
                  <Card style={{ maxWidth: "100%", display: "flex" }} onClick={() => openNewEventPage(row)}>
                      {!window.matchMedia("(max-width: 500px)").matches && (
                        <CardMedia
                          component="img"
                          height="140"
                          image={appStore.currentBaseURL + "/" + row.cover_url}
                          alt={row.name}
                          //@ts-ignore
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src=logoAdapa;
                          }}
                        />
                      )}
                      <CardActionArea >
                        {window.matchMedia("(max-width: 500px)").matches && (
                          <CardMedia
                            component="img"
                            height="140"
                            image={appStore.currentBaseURL + "/" + row.cover_url}
                            alt={row.name}
                            //@ts-ignore
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src=logoAdapa;
                            }}
                          />
                        )}
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            {row.name}
                          </Typography>
                          <Typography variant="body2" color="primary">
                            {row.description.substring(0, 155)}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button size="small" color="primary">
                            Inscrever-se
                          </Button>
                        </CardActions>
                      </CardActionArea>
                    </Card>
                </Grid>
              ))}
            </Grid>
          )}
          {/* Eventos Futuros ou ativos */}
          <S.Container>
            {subscriptionsStore.events.length < 1 ? (
              <NoDataContainer>
                <h3>Sem inscrições em eventos aqui!</h3>
              </NoDataContainer>
            ) : (
              <TableContainer component={Paper}>
                <GenericTableToolbar>
                  <Typography variant="subtitle1" component="div">
                    Eventos Ativos
                  </Typography>
                </GenericTableToolbar>
                <Table className={classes.table} aria-label="empresas table">
                  <GenericTableHead>
                    <GenericTableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Data</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Opções</TableCell>
                    </GenericTableRow>
                  </GenericTableHead>
                  <TableBody>
                    {subscriptionsStore.isFetchingEvents ? (
                      <GenericTableRow>
                        <td colSpan={7}>
                          <S.LoadingContainer>
                            <CustomCircularProgress />
                          </S.LoadingContainer>
                        </td>
                      </GenericTableRow>
                    ) : (
                      subscriptionsStore.events?.map((row, index) => (
                        <>
                          <GenericTableRow key={index} clickable>
                            <TableCell
                              onClick={() => openEventPage(row)}
                              component="th"
                              scope="row"
                            >
                              <FlexTD>
                                <span className="title">{row.name}</span>
                                <span>{row.description}</span>
                              </FlexTD>
                            </TableCell>
                            <TableCell
                              onClick={() => openEventPage(row)}
                              className={
                                ((row.active && Moment(row.starts_at).isAfter(new Date())) || (row.active && Moment(row.starts_at).isSameOrBefore(new Date()) && Moment(row.ends_at).isSameOrAfter(new Date())))
                                  ? classes.status__active
                                  : classes.status__inactive
                              }
                            >
                              {row.active && Moment(row.starts_at).isAfter(new Date()) ? "Aguardando Início" : (row.active ? (Moment(row.starts_at).isSameOrBefore(new Date()) && Moment(row.ends_at).isSameOrAfter(new Date()) ? "Acontecendo Agora" : "Encerrado" ) : "Encerrado")}
                            </TableCell>
                            <TableCell
                              onClick={() => openEventPage(row)}
                            >
                              {Moment(row.starts_at).format(
                                "DD [de] MMMM [de] YYYY [às] HH[:]MM"
                              )}
                            </TableCell>
                            <TableCell
                              onClick={() => openEventPage(row)}
                            >
                              {row.paid ? "Participação confirmada" : "Aguardando pagamento"}
                            </TableCell>
                            <TableCell
                              onClick={() => openEventPage(row)}
                            >
                              <div
                                onClick={(e) => handleClick(e, row._id, row.active)}
                              >
                                {
                                row.active && Moment(row.starts_at).isAfter(new Date()) ? 
                                  "Aguardando Início" :
                                  (row.active ? 
                                    (Moment(row.starts_at).isSameOrBefore(new Date()) && Moment(row.ends_at).isSameOrAfter(new Date()) ? 
                                      (row.type == "online" ? "Acessar Evento" : "N/D") 
                                      : "Encerrado" ) : 
                                    "Encerrado")
                                }
                              </div>
                            </TableCell>
                          </GenericTableRow>
                        </>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </S.Container>
          {/* Histórico de Eventos */}
          <S.Container>
            {subscriptionsStore.historyEvents.length >= 1 && (
              <TableContainer component={Paper}>
                <GenericTableToolbar>
                  <Typography variant="subtitle1" component="div">
                    Histórico de Eventos
                  </Typography>
                </GenericTableToolbar>
                <Table className={classes.table} aria-label="empresas table">
                  <GenericTableHead>
                    <GenericTableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Data</TableCell>
                      <TableCell>Status</TableCell>
                    </GenericTableRow>
                  </GenericTableHead>
                  <TableBody>
                    {subscriptionsStore.isFetchingEvents ? (
                      <GenericTableRow>
                        <td colSpan={7}>
                          <S.LoadingContainer>
                            <CustomCircularProgress />
                          </S.LoadingContainer>
                        </td>
                      </GenericTableRow>
                    ) : (
                      subscriptionsStore.historyEvents?.map((row, index) => (
                        <>
                          <GenericTableRow key={index} clickable>
                            <TableCell
                              onClick={() => openEventPage(row)}
                              component="th"
                              scope="row"
                            >
                              <FlexTD>
                                <span className="title">{row.name}</span>
                                <span>{row.description}</span>
                              </FlexTD>
                            </TableCell>
                            <TableCell
                              onClick={() => openEventPage(row)}
                              className={
                                row.active && Moment(row.starts_at).isSameOrBefore(new Date()) && Moment(row.ends_at).isSameOrAfter(new Date())
                                  ? classes.status__active
                                  : classes.status__inactive
                              }
                            >
                              {row.active ? (Moment(row.starts_at).isSameOrBefore(new Date()) && Moment(row.ends_at).isSameOrAfter(new Date()) ? "Acontecendo Agora" : "Encerrado" ) : "Encerrado"}
                            </TableCell>
                            <TableCell
                              onClick={() => openEventPage(row)}
                            >
                              {Moment(row.starts_at).format(
                                "DD [de] MMMM [de] YYYY [às] HH[:]MM"
                              )}
                            </TableCell>
                            <TableCell
                              onClick={() => openEventPage(row)}
                            >
                              {row.paid ? "Participação confirmada" : "Aguardando pagamento"}
                            </TableCell>
                          </GenericTableRow>
                        </>
                      ))
                    )}
                  </TableBody>
                </Table>
                <S.PaginationContainer>
                  <Pagination
                    page={subscriptionsStore.currentPagination.page}
                    count={subscriptionsStore.currentPagination.pages}
                    onChange={(event, value) => subscriptionsStore.fetchSubscriptionEvents(value)}
                    color="secondary"
                  />
                </S.PaginationContainer>
              </TableContainer>
            )}
          </S.Container>
        </S.MainContainer>
      )}
    </>
  ));
};

export default Subscriptions;

const Message = styled.div`
  display: flex;
  color: #00577b;

  margin-top: 25%;
  height: 100%;
  /* left: 50%; */
  font-weight: bold;
  font-size: 20pt;
  text-align: center;
  justify-content: center;
`;
