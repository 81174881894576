import pt from "date-fns/locale/pt";
import Root from "Pages/Root";
import React, { useEffect } from "react";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Route, Router } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import styled from "styled-components";
import "./App.css";
// css do react-toastify
import "react-toastify/dist/ReactToastify.css";
// * Components
import Header from "./Components/Header";
// * Pages
import Signin from "./Pages/Signin";
import ForgotPass from "./Pages/Signin/ForgotPass";
import RecoveryPass from "./Pages/Signin/RecoveryPass";
import { history } from "./Store/History";
import Signup from "Signup";
import EventSubscription from "./Pages/EventSubscription";
import EventPage from "./Pages/EventPage";
import FallbackPage from "Pages/404";

import Url from "Pages/Settings/BaseURL";
import { CssBaseline } from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import { GlobalDebug } from "Utils/GlobalDebug";

function App() {
  useEffect(() => {
    (process.env.NODE_ENV === "production" ||
     process.env.REACT_APP_ENV === "STAGING") &&
    GlobalDebug(false,true);
    
    registerLocale("pt", pt);
    setDefaultLocale("pt");
    toast.configure();
  }, []);

  return (
    <Router history={history}>
      <ToastContainer style={{zIndex: 99999999}} />
      <Route exact path="/settings/url" component={Url} />
      <Route exact path="/forgot-password" component={ForgotPass} />
      <Route exact path="/recovery-password" component={RecoveryPass} />
      <Route exact path="/signin" component={Signin} />
      <Route exact path="/signup" component={Signup} />
      <Route path="/inscricao/:eventName" component={EventSubscription} />
      <Route path="/evento/:eventName" component={EventPage} />
      {/* <Route component={FallbackPage} /> */}
      <Container>
        <Header />
        <Root />
      </Container>
    </Router>
  );
}

const Container = styled.div`
  // position: relative;
  margin-top: 75px;
  @media (max-width: 500px) {
    margin-top: 60px;
  }
  /* height: 100vh; */
`;

export default App;
