import * as Yup from "yup";

import { IUserSignupValues } from "Models/User";

export const SignupSchema = Yup.object().shape({
  first_name: Yup.string().required("Por favor, insira o nome"),
  last_name: Yup.string().required("Por favor, insira o sobrenome"),
  email: Yup.string()
    .email("Por favor, insira um email válido")
    .required("Insira um e-mail no formato exemplo@email.com"),
  password: Yup.string().required("Por favor, insira sua senha"),
  document: Yup.string().required("Por favor, insira seu CPF ou CNPJ"),
  phone_number: Yup.string().required("Por favor, insira seu telefone"),
  city: Yup.string().required("Por favor, insira a cidade"),
  district: Yup.string().required("Por favor, insira o bairro"),
  street: Yup.string().required("Por favor, insira a rua"),
  // business_title: Yup.string().required("Por favor, insira o nome de sua empresa"),
  job_title: Yup.string().required("Por favor, insira o seu cargo"),
  street_number: Yup.string().required(
    "Por favor, insira o número da residência"
  ),
  zipcode: Yup.string().required("O CEP é obrigatório"),
});

export const initialValues: IUserSignupValues = {
  last_name: "",
  first_name: "",
  email: "",
  password: "",
  document: "",
  phone_number: "",
  area_code: "",
  country_code: "55",
  country: "Brasil",
  state: "PA",
  city: "",
  district: "",
  street: "",
  street_number: "0",
  zipcode: "",
  complement: "",
  business_id: "",
  job_title: ""
};
