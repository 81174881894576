import styled from "styled-components";

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const FormContent = styled.div`
  margin: 50px 0 100px 0;
  width: 100%;
  max-width: 800px;
`;

export const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  * {
    margin: 20px 0 0 10px;
  }
`;

const BaseTitle = styled.h1`
  font-size: 24px;

  @media (max-width: 500px) {
    font-size: 12px;
  }
  @media (min-height: 700px) and (max-height: 850px) {
    font-size: 18px;
  }
`;

export const FormTitle = styled(BaseTitle)`
  color: #656565;
  margin: 5px 0;
`;