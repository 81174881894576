import React, { useState } from "react";
import { Registration } from "Models/Events";
// @ts-ignore
import ReactExport from "react-export-excel";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
import { ReportBaseData, CityData, CompaniesData, CompanyData } from "Store/Report";
import { IAttendee } from "Models/Attendees";
const StringMask = require("string-mask");

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

interface IProps {
  dataSet: ReportData;
  downloadButton: any;
  open: boolean;
  handleClose: Function;
}

export interface ReportData {
    generalReport: ReportBaseData[];
    registrations: IAttendee[];
    citiesData: CityData[];
    companiesData: CompaniesData[];
}

const ReportToExcel: React.FC<IProps> = ({
  dataSet,
  downloadButton,
  open,
  handleClose,
}) => {
  const [fileName, setFileName] = useState("");
  const formatter = new StringMask("(00) 00000-0000");

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Exportação de realatório"}
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          variant="outlined"
          onChange={(e) => setFileName(e.target.value)}
          label="Nome do arquivo"
          placeholder="Digite um nome personalizado"
        />
        <DialogContentText id="alert-dialog-description">
          Você pode escolher um nome para o arquivo a ser exportado ou usar o
          nome padrão.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>Cancelar</Button>
        <ExcelFile
          element={downloadButton}
          filename={fileName.length > 0 ? fileName : "relatorios"}
        >
          <ExcelSheet data={dataSet.generalReport} name="Resumos">
            <ExcelColumn
              label="Inscritos em eventos"
              value={(col: ReportBaseData) =>
                `${col.registrations}`
              }
            />
            <ExcelColumn
              label="Checkins realizados"
              value={(col: ReportBaseData) =>
                `${col.checkins}`
              }
            />
            <ExcelColumn
              label="Checkins não realizados"
              value={(col: ReportBaseData) =>
                `${col.absences}`
              }
            />
            <ExcelColumn
              label="Convites para visitantes"
              value={(col: ReportBaseData) =>
                `${col.guests}`
              }
            />
            <ExcelColumn
              label="Sorteios realizados"
              value={(col: ReportBaseData) =>
                `${col.draws}`
              }
            />
          </ExcelSheet>
          <ExcelSheet data={dataSet.companiesData} name="Resumo Empresas">
            <ExcelColumn
              label="Empresas participantes"
              value={(col: CompaniesData) =>
                `${col.total_companies_amount}`
              }
            />
            <ExcelColumn
              label="Empresas associadas"
              value={(col: CompaniesData) =>
                `${col.associated_companies_amount}`
              }
            />
            <ExcelColumn
              label="Empresas não associadas"
              value={(col: CompaniesData) =>
                `${col.not_associated_companies_amount}`
              }
            />
          </ExcelSheet>
          <ExcelSheet data={dataSet.registrations} name="Participantes">
            <ExcelColumn
              label="Name"
              value={(col: IAttendee) =>
                `${col.first_name} ${col.last_name}`
              }
            />
            <ExcelColumn
              label="CPF"
              value={(col: IAttendee) => `${col.cpf ? col.cpf : "N/D"}`}
            />
            <ExcelColumn
              label="Email"
              value={(col: IAttendee) => `${col.email}`}
            />
            <ExcelColumn
              label="Contato"
              value={(col: IAttendee) => `${formatter.apply(
                col.phone.area_code +
                  (col.phone.number.split(" ").length > 1 ? col.phone.number.split(" ")[1].replace("-", "") : col.phone.number.replace("-", ""))
              )}`}
            />
            <ExcelColumn
              label="Gênero"
              value={(col: IAttendee) => `${col.gender === "female" ? "Feminino" : "Masculino"}`}
            />
            <ExcelColumn
              label="Título"
              value={(col: IAttendee) => `${col.job_title ? col.job_title : "N/D"}`}
            />
            <ExcelColumn
              label="Empresa"
              value={(col: IAttendee) => `${col.business ? col.business.name : (col.business_title ? col.business_title : "N/D")}`}
            />
            <ExcelColumn
              label="Cidade"
              value={(col: IAttendee) => `${col.address ? col.address.city : "N/D"}`}
            />
            {/* <ExcelColumn
              label="Presença"
              value={(col: IAttendee) =>
                col.attended ? "Confirmada" : "Não Confirmada"
              }
            /> */}
          </ExcelSheet>
          <ExcelSheet data={dataSet.companiesData[0].associated_companies} name="Participantes por empresa associada">
            <ExcelColumn
              label="Name"
              value={(col: CompanyData) =>
                `${col.name}`
              }
            />
            <ExcelColumn
              label="Participantes"
              value={(col: CompanyData) => `${col.participants}`}
            />
          </ExcelSheet>
          <ExcelSheet data={dataSet.companiesData[0].not_associated_companies} name="Participantes por empresa não associada">
            <ExcelColumn
              label="Name"
              value={(col: CompanyData) =>
                `${col.name}`
              }
            />
            <ExcelColumn
              label="Participantes"
              value={(col: CompanyData) => `${col.participants}`}
            />
          </ExcelSheet>
          <ExcelSheet data={dataSet.citiesData} name="Participantes por cidade">
            <ExcelColumn
              label="Name"
              value={(col: CityData) =>
                `${col.name}`
              }
            />
            <ExcelColumn
              label="Participantes"
              value={(col: CityData) => `${col.participants}`}
            />
          </ExcelSheet>
        </ExcelFile>
      </DialogActions>
    </Dialog>
  );
};

export default ReportToExcel;
