import { configure, observable, action, runInAction, toJS } from "mobx";
import { toast } from "react-toastify";
import {
  EventModel,
  Checkin,
} from "Models/Events";

import { IEventPagination, IDrawPagination } from "Models/Utils";

import { Post, Get, Delete, Put } from "Services/Request";
import { history } from "Store/History";
import { IFormValues } from "Pages/Events/ShowEvent/Page/models/indes";
import { LandingPageModel } from "Models/LandingPage";

configure({ enforceActions: "observed" });

class LandingPagesStore {
  constructor() {
    // this.fetchLandingPage();
  }

  @observable
  landingpage: LandingPageModel | null = null;

  @observable
  isFetchingEvents: boolean = false;

  @action
  fetchLandingPage = async (eventId: string) => {
    this.isFetchingEvents = true;
    try {
      const response = await Get(`/api/events/${eventId}/landingpage`);
      runInAction(() => {
        this.isFetchingEvents = false;
        // @ts-ignore
        this.landingpage = response.data.data.landingpage;
      });

      //@ts-ignore
      return response.data.data.landingpage;
    } catch (error) {
      runInAction(() => {
        this.isFetchingEvents = false;
      });
      console.log(error.response);
    }
  };

  @action
  updateLandingPage = async (eventId: string, values: IFormValues) => {
    try {
      const response = await Put(`/api/events/${eventId}/landingpage`, {
        ...values
      });
      toast.success("Página atualizada com sucesso");
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error.message);
      throw error.response;
    }
  };

  @action
  onUpdateLandingPageBackground = async (eventId: string, cover: any) => {
    try {
      const data = new FormData();
      data.append("cover", cover);
      const response = await Put(`/api/events/${eventId}/landingpage`, data);
      toast.success("Evento atualizado com sucesso");
    } catch (error) {
      toast.error(error.response.data.error.message);
      console.log(error.response);
    }
  };

}

export const landingpagesStore = new LandingPagesStore();
