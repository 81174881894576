import React, { useEffect, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useStore } from "Store";
import { Formik, Form as FormikForm } from "formik";
import InputMask from "react-input-mask";

import NoCompanyDialog from "./components/NoCompanyDialog";

import { Company } from "Models/Companies";
import {
  IFormValues,
  validationSchema,
  IOptions,
  genderOptions,
  IResponse,
} from "./models";

import * as S from "./styles";

// * Material Ui and Form related

import {
  PrimaryActionButton,
  SecondaryActionButton,
} from "Components/Styled/Form";

import Grid from "@material-ui/core/Grid";
// @ts-ignore
import { FormikTextField } from "formik-material-fields";
// @ts-ignore
import { FormikSelectField } from "formik-material-fields";
import { Container, Typography } from "@material-ui/core";
import SimpleFilePicker from "Pages/Home/components/SimpleFilePicker";
import { ErrorLabel } from "Components/Styled/util";
const StringMask = require("string-mask");

const ImportForm = () => {
  const formatter = new StringMask("(00) 00000-0000");
  const [hasNoCompany, setHasNoCompany] = useState(false);
  const [initialValues, setInitialValues] = useState({
    business_id: "",
    event_id: "",
  });
  const [companies, setCompanies] = useState<Company[]>([]);
  const [companiesOptions, setCompaniesOptions] = useState<IOptions[]>([]);
  const { companyStore, attendeesStore } = useStore();
  const history = useHistory();

  const [currentFile, setCurrentFile] = useState<FileList | null>(null);
  const [fileError, setFileError] = useState<boolean>(false);
  const [fileErrorMessage, setFileErrorMessage] = useState("");

  useEffect(() => {
    if (companies.length > 0) {
      const localArr: IOptions[] = [];
      companies.map((company) => {
        return localArr.push({ label: company.legal_name, value: company._id });
      });
      setCompaniesOptions(localArr);
    }
  }, [companies]);

  const getCompaniesData = useCallback(async () => {
    const response = await companyStore.fetchCompanies(1,999);
    // @ts-ignore
    setCompanies(response.businesses);
    // @ts-ignore
    if (response.businesses.length === 0) {
      setHasNoCompany(true);
    }
  }, [companyStore]);

  useEffect(() => {
    getCompaniesData();
  }, [getCompaniesData]);

  const onSubmit = async (values: any) => {
    try {
      await attendeesStore.registerAttendee(values);
      history.replace("/attendees");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      Importação de usuário
      <NoCompanyDialog open={hasNoCompany} />
      <Container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ handleChange, values, errors }) => (
            <>
              <S.FormContainer>
                <S.FormContent>
                  <FormikForm autoComplete="off">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormikSelectField
                          style={{ width: "100%" }}
                          className="form-field"
                          name="event_id"
                          label="Evento"
                          options={genderOptions}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikSelectField
                          style={{ width: "100%" }}
                          className="form-field"
                          name="business_id"
                          label="Empresa"
                          options={companiesOptions}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                    <S.FilePickerWrapper>
                      <SimpleFilePicker
                        onLoadFile={(files: FileList | null) =>
                          setCurrentFile(files)
                        }
                        id="file"
                      />
                      {fileError && <ErrorLabel>{fileErrorMessage}</ErrorLabel>}
                      {currentFile && (
                        <Typography variant="h6" gutterBottom>
                          {currentFile[0].name}
                        </Typography>
                      )}
                    </S.FilePickerWrapper>
                  </Grid>
                    <S.ButtonRow>
                      <SecondaryActionButton
                        onClick={() => history.replace("/attendees")}
                      >
                        Cancelar
                      </SecondaryActionButton>
                      <PrimaryActionButton type="submit">
                        Importar
                      </PrimaryActionButton>
                    </S.ButtonRow>
                  </FormikForm>
                </S.FormContent>
              </S.FormContainer>
            </>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default ImportForm;
