import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

interface IProps {
  open: boolean;
  handleClose: Function;
  onSuccess: Function;
}

const ReRunDrawDialog: React.FC<IProps> = ({
  open,
  handleClose,
  onSuccess,
}) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Você gostaria de refazer o sorteio?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            O sorteio será refeito e um novo vencedor será escolhido.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={() => onSuccess()}
            color="primary"
            autoFocus
          >
            Refazer Sorteio
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReRunDrawDialog;
