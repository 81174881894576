export const paginate = ({
  array,
  page_size,
  page_number,
}: {
  array: any[];
  page_size: number;
  page_number: number;
}) => {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};

export const getPagesCount = ({
  itemsCount,
  pageSize,
}: {
  itemsCount: number;
  pageSize: number;
}) => {
  if (itemsCount <= pageSize) {
    return 1;
  } else {
    return Math.ceil(itemsCount / pageSize);
  }
};
