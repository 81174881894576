import React, { useState, useEffect, useCallback } from "react";
// @ts-ignore
import { FormikSelectField, FormikTextField } from "formik-material-fields";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import * as S from "./styles";
import { useStore } from "Store";
import { IItems, EventModel, Registration, EventSponsorModel } from "Models/Events";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { IAttendee } from "Models/Attendees";
import PageItem from "./components/badgeItem";
import CustomizedCheckbox from "../Checkin/checkbox";

import { ceil } from "lodash";
import { Button, Container, Typography, TextField, Switch } from "@material-ui/core";
import { PrimaryActionButton } from "Components/Styled/Form";
import { IFormValues, validationSchema } from "./models/indes";
import { LandingPageModel } from "Models/LandingPage";
import { Editor } from "react-draft-wysiwyg";
import SimpleFilePicker from "Pages/Home/components/SimpleFilePicker";
import { ErrorLabel } from "Components/Styled/util";
import LoadingModal from "Components/LoadingModal";
import { LoadingStatusContainer } from "Pages/Home/CreateOrEditEvent/components/SecondStep/styles";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { CertificateModel } from "Models/Certificate";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      width: "100%",
    },
    control: {
      padding: theme.spacing(1),
    },
  })
);

interface RouteParams {
  eventId: string;
}
const CertificatePage = () => {
  const classes = useStyles();
  const { eventId } = useParams<RouteParams>();
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [hasChangesPageSettings, setHasChangesPageSettings] = useState<
    boolean
  >(false);
  const [myEvent, setMyEvent] = useState<EventModel>({} as EventModel);
  const [myCertificate, setCertificate] = useState<CertificateModel>({} as CertificateModel);
  const [loading, setLoading] = useState(true);
  const { eventsStore, certificatesStore, appStore } = useStore();
  const formRef = React.useRef();

  //Background
  const [currentBackgroundFile, setCurrentBackgroundFile] = useState<FileList | null>(null);
  const [fileBackgroundError, setFileBackgroundError] = useState<boolean>(false);
  const [fileBackgroundErrorMessage, setFileBackgroundErrorMessage] = useState("");
  const [descriptionBackgroundImage, setDescriptionBackgroundImage] = useState("");

  const [descriptionLocalization, setDescriptionLocalization] = useState("");
  const [descriptionDate, setDescriptionDate] = useState("");
  const [descriptionHours, setDescriptionHours] = useState("");

  useEffect(() => {
    getCertificate();
  }, []);

  const onSubmit = async () => {
    try {
      await certificatesStore.updateCertificate(eventId, {
        description_localization: descriptionLocalization,
        description_date: descriptionDate,
        description_hours: descriptionHours
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitBackgroundImage = async () => {
    try {
      if (currentBackgroundFile) {
        setFileBackgroundError(false);
        await certificatesStore.onUpdateCertificateBackground(eventId, currentBackgroundFile[0]);
        getCertificate();
      } else {
        setFileBackgroundError(true);
        setFileBackgroundErrorMessage("Por favor, escolha uma imagem para o background");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCertificate = useCallback(async () => {
    try {
      const response = await eventsStore.fetchEventById(eventId);
      //@ts-ignore
      var event = response.data.event;
      
      setMyEvent(event);
      
      const certificateResponse = await certificatesStore.fetchCertificate(eventId);
      setCertificate(certificateResponse);
      setDescriptionLocalization(certificateResponse.description_localization);
      setDescriptionDate(certificateResponse.description_date);
      setDescriptionHours(certificateResponse.description_hours);
      setDescriptionBackgroundImage(certificateResponse.background_image_url);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [eventId, eventsStore]);

  return (
    <Grid container className={classes.root} spacing={1}>
      <LoadingModal open={loading}>
        <LoadingStatusContainer>Carregando...</LoadingStatusContainer>
      </LoadingModal>
      <Grid item xs={12} sm={12}>
        <Paper className={classes.paper}>
          <S.Container>
            <S.Title>Configuração de Certificado</S.Title>
          </S.Container>
          <>
            <Container>
              <S.PageFormContainer>
                <S.PageFormContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <EventTitle>Imagem de background:</EventTitle>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {descriptionBackgroundImage && descriptionBackgroundImage != "" && (
                        <img
                          src={appStore.currentBaseURL + "/" + descriptionBackgroundImage}
                          alt="cover"
                          width="300"
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <EventTitle>Alterar imagem de background:</EventTitle>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <S.FilePickerWrapper>
                        <SimpleFilePicker
                          onLoadFile={(files: FileList | null) =>
                            setCurrentBackgroundFile(files)
                          }
                          id="currentBackgroundFile"
                        />
                        {fileBackgroundError && <ErrorLabel>{fileBackgroundErrorMessage}</ErrorLabel>}
                        {currentBackgroundFile && (
                          <Typography variant="h6" gutterBottom>
                            {currentBackgroundFile[0].name}
                          </Typography>
                        )}
                      </S.FilePickerWrapper>
                    </Grid>
                  </Grid>
                  <S.ButtonRow>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!currentBackgroundFile}
                      onClick={() => onSubmitBackgroundImage()}
                    >
                      Enviar
                    </Button>
                  </S.ButtonRow>
                </S.PageFormContent>
              </S.PageFormContainer>
            </Container>

            <Container>
              <S.PageFormContainer>
                <S.PageFormContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <EventTitle>Configurações de valores:</EventTitle>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        label="Localização do evento"
                        fullWidth
                        variant="outlined"
                        value={descriptionLocalization}
                        onChange={target => {
                          setDescriptionLocalization(target.currentTarget.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        label="Data de realização"
                        fullWidth
                        variant="outlined"
                        value={descriptionDate}
                        onChange={target => {
                          setDescriptionDate(target.currentTarget.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        label="Carga horária"
                        fullWidth
                        variant="outlined"
                        value={descriptionHours}
                        onChange={target => {
                          setDescriptionHours(target.currentTarget.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <S.ButtonRow>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={() => onSubmit()}
                    >
                      Salvar
                    </Button>
                  </S.ButtonRow>
                </S.PageFormContent>
              </S.PageFormContainer>
            </Container>
          </>
        </Paper>
      </Grid>
      
    </Grid>
  );
};

export default CertificatePage;

export const FormContainer = styled.div`
  width: 100%;
  margin: 10px;
`;

export const EventContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ContainerItens = styled.div`
  margin-bottom: 3px;
  width: 100%;
`;
export const EventTitle = styled.div`
  font-size: 17px;
  color: black;
  font-weight: bold;
`;

export const PageTitle = styled.div`
  font-size: 17px;
  color: black;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 20px;
`;

export const Text = styled.div`
  font-weight: bold;
  text-align: justify;
  margin-left: 5px;
`;

export const TextContainer = styled.div`
  /* justify-content: center; */
  display: flex;
  margin-left: 20px;
`;
