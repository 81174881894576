import styled from 'styled-components'

export const Container = styled.div`
  padding: 40px;
`

export const Title = styled.h3`
  color: #00577C;
  padding: 0;
  margin: 0;
`

export const Subtitle = styled.h6`
  padding: 0;
  margin: 0 0 20px 0;
  color: #0A345E;
`

export const FilePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface DragItemProps {
  isDragging: boolean;
}
export const DragItem = styled.div<DragItemProps>`
  display: flex;
  justify-content: space-between;

  padding: 10px;
  border: 1px solid #CDD0D1;
  border-radius: 5px;
  margin: 5px;

  color: #0A345E;
  background: ${props => props.isDragging ? '#C2E0E8' : '#FAFEFF'};
`

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
`

export const FormWrapper = styled.div`
  padding-top: 24px;
  .form-field {
    margin: 10px 0;
  }
  .MuiFormControl-root {
    width: 100%;
  }
` 

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const PageFormContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 0px 0px 0px 20px;
`;

export const FormContent = styled.div`
  // margin: 50px 0 100px 0;
  width: 100%;
  max-width: 800px;
`;

export const PageFormContent = styled.div`
  margin: 0px 0 0px 0;
  width: 100%;
`;