import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useObserver } from "mobx-react-lite";
import * as Yup from "yup";

// @ts-ignore
import { useDebounce } from "use-debounce";

import LoadingModal from "Components/LoadingModal";
import { LoadingStatusContainer } from "./styles/index";
import { NoDataContainer } from "Components/Styled/util";

import * as S from "./styles";
import { CustomCircularProgress } from "Components/Styled/Loading";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  createStyles,
  Theme,
  Paper,
  Grid,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Pagination from "@material-ui/lab/Pagination";
import { Add } from "@material-ui/icons";
import {
  Container,
  GenericFAB,
  GenericTableHead,
  FlexTD,
  GenericLeftFAB,
} from "Components/Styled/table";
import { useStore } from "Store";
import { MoreVert } from "@material-ui/icons";
import SearchBar from "Components/SearchBar";
import logo from "../../Assets/Images/pp.jpeg";
import { Formik, Form as FormikForm } from "formik";
// @ts-ignore
import { FormikTextField } from "formik-material-fields";
import { PrimaryActionButton, SecondaryActionButton } from "Components/Styled/Form";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      width: "100%",
    },
    status__active: {
      color: "#2cda2c",
    },
    status__inactive: {
      color: "#e72419",
    },
  })
);
const Contact = () => {
  const history = useHistory();
  const classes = useStyles();
  const { contactStore } = useStore();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const isContextMenuOpen = Boolean(anchorEl);
  const [currentCompanyId, setCurrentCompanyId] = useState<string | null>("");
  const [filter, setFilter] = useState("");
  const [debounced] = useDebounce(filter, 500);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    email: "",
    phone_number: "",
    message: ""
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nome é obrigatória"),
    email: Yup.string()
      .email("Por favor, insira um email válido")
      .required("O e-mail é obrigatório"),
      message: Yup.string().required("O campo de menssagem é obrigatório"),
    phone_number: Yup.string()
      .required("O número telefônico é obrigatório")
  });

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    companyId: string
  ) => {
    setCurrentCompanyId(companyId);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    console.log(debounced);
    contactStore.fetchContact();
  }, [debounced, contactStore]);

  const onSubmit = async (values: any, resetForm: any) => {
    await contactStore.sendEmailForm(values.name, values.email, values.phone_number, values.message);
    history.push("/contacts");
  };

  return useObserver(() => (
    <>
      <LoadingModal open={contactStore.isFetchingContact}>
        <S.LoadingStatusContainer>
          Carregando informações...
        </S.LoadingStatusContainer>
      </LoadingModal>
      <SearchBar title={"Contatos"} />
      <Container style={{flexGrow: 1 }}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <Container>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <S.Title>Formulário de contato</S.Title>
                    <p></p>

                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                      enableReinitialize
                    >
                      {({ handleChange, values, setFieldValue, setFieldError, errors, resetForm }) => (
                        <>
                          {/* <S.FormContainer>
                            <S.FormContent> */}
                              <FormikForm autoComplete="off">
                                <Grid container spacing={1}>
                                  <Grid item xs={12} sm={12}>
                                    <FormikTextField
                                      name="name"
                                      label="Nome"
                                      fullWidth
                                      variant="outlined"
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={12}>
                                    <FormikTextField
                                      name="email"
                                      label="E-mail"
                                      fullWidth
                                      variant="outlined"
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={12}>
                                    <FormikTextField
                                      name="phone_number"
                                      label="Telefone"
                                      fullWidth
                                      variant="outlined"
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={12}>
                                    <FormikTextField
                                      name="message"
                                      label="Menssagem"
                                      fullWidth
                                      variant="outlined"
                                    />
                                  </Grid>
                                </Grid>
                                <S.ButtonRow>
                                  <PrimaryActionButton type="button"
                                    onClick={() => onSubmit(values, resetForm)}
                                    disabled={!!contactStore.isFetchingContact}
                                    >
                                    Enviar
                                  </PrimaryActionButton>
                                </S.ButtonRow>
                              </FormikForm>
                            {/* </S.FormContent>
                          </S.FormContainer> */}
                        </>
                      )}
                    </Formik>
                  </Grid>
                </Grid>
              </Container>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <Container>
                <S.PageFormContainer>
                  <S.PageFormContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <div dangerouslySetInnerHTML={{ __html: contactStore.contact ? contactStore.contact.description : "" }} />
                      </Grid>
                    </Grid>
                  </S.PageFormContent>
                </S.PageFormContainer>
              </Container>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  ));
};

export default Contact;
