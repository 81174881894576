import styled from 'styled-components'
const avatarSize = '2.2rem'
export const Container = styled.div`
  background-color: #fff;
  border-radius: 50%;
  // height: ${avatarSize};
  text-align: center;
  // width: ${avatarSize}; 
  margin-right: 20px;
`
export const InitialsContainer = styled.span`
  font-size: ${`calc(${avatarSize} / 2)`}; /* 50% of parent */
  font-weight: bold;
  line-height: 1;
  position: relative;
  top:  ${`calc(${avatarSize} / 4)`}; /* 25% of parent */
`