import React, { useEffect, useState } from "react";
import { history } from "Store/History";
import { useLocation } from "react-router-dom";
import * as S from "./styles";
import { useStore } from "Store";

import UserDisplayData from "./components/UserDisplayData";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { styled, AppBar } from "@material-ui/core";
import { userHasPermission } from "Utils";

const Header = () => {
  const location = useLocation();
  const { userStore } = useStore();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Navbar collapseOnSelect expand="lg" variant="dark" expanded={open} style={{
        background: "#00577b",
        minHeight: "60px",
        width: "100%",
        marginLeft: 0,
        marginRight: 0,
        top: 0,
        position: "fixed",
        zIndex: 9999,
        // overflow: "hidden",
        display: location.pathname === "/signin" ||
          location.pathname === "/signup" ||
          location.pathname.indexOf("password") != -1 ||
          location.pathname.indexOf("/inscricao/") != -1 ||
          location.pathname.indexOf("/evento/") != -1 ||
          location.pathname === "/settings/url"
            ? "none"
            : "flex",
      }}>
        <Container style={{
          left: 0,
          marginLeft: 0,
          marginRight: 0,
          width: "100%",
          maxWidth: "100%"
        }}>
          <S.LogoContainer />
          <S.NavigationContainer>
            <ArrowBackIosIcon onClick={() => history.goBack()} />
            <ArrowForwardIos onClick={() => history.goForward()} />
          </S.NavigationContainer>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{color: "#ffffff", borderColor: "#ffffff"}} onClick={() => setOpen(open ? false : true)} />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto" style={{
              height: "100%",
              width: "100%",
            }}>
              {userHasPermission(userStore.currentUser?.roles[0], "eventos-inscritos") && (
                <S.Button
                  onClick={() => {
                    history.push("/subscriptions");
                    setOpen(false);
                  }}
                  isActive={
                    location.pathname.includes("/subscriptions")
                  }
                >
                  DASHBOARD
                </S.Button>
              )}
              {userHasPermission(userStore.currentUser?.roles[0], "contacts") && (
                <S.Button
                  onClick={() => {
                    history.push("/contacts");
                    setOpen(false);
                  }}
                  isActive={
                    location.pathname.includes("/contacts")
                  }
                >
                  CONTATOS
                </S.Button>
              )}
              {userHasPermission(userStore.currentUser?.roles[0], "eventos") && (
                <S.Button
                  onClick={() => {
                    history.push("/");
                    setOpen(false);
                  }}
                  isActive={
                    location.pathname === "/" || location.pathname.includes("/events")
                  }
                >
                  EVENTOS
                </S.Button>
              )}
              {userHasPermission(userStore.currentUser?.roles[0], "empresas") && (
                <S.Button
                  onClick={() => {
                    history.push("/empresas");
                    setOpen(false);
                  }}
                  isActive={
                    location.pathname.includes("/empresas") ||
                    location.pathname.includes("/companies")
                  }
                >
                  EMPRESAS
                </S.Button>
              )}
              {userHasPermission(userStore.currentUser?.roles[0], "relatorios") && (
                <S.Button
                  onClick={() => {
                    history.push("/relatorios");
                    setOpen(false);
                  }}
                  isActive={history.location.pathname === "/relatorios"}
                >
                  RELATÓRIOS
                </S.Button>
              )}
              {userHasPermission(userStore.currentUser?.roles[0], "participantes") && (
                <S.Button
                  onClick={() => {
                    history.push("/attendees");
                    setOpen(false);
                  }}
                  isActive={history.location.pathname.includes("/attendees")}
                >
                  {userStore.isCompanyUser() ? "COLABORADORES" : "USUÁRIOS" }
                </S.Button>
              )}
            </Nav>
              <UserDisplayData />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
