import { configure, observable, action, runInAction, toJS } from "mobx";
import { toast } from "react-toastify";
import {
  EventModel,
  Checkin,
} from "Models/Events";

import { IEventPagination, IDrawPagination } from "Models/Utils";

import { Post, Get, Delete, Put } from "Services/Request";
import { history } from "Store/History";

configure({ enforceActions: "observed" });

class SubscriptionsStore {
  constructor() {
    this.fetchSubscriptionEvents();
  }

  @observable
  currentEventSubPageIndex: number = 0;

  @observable
  eventIdForSubscribe: string = "";

  @observable
  eventNameForSubscribe: string = "";

  @observable
  events: EventModel[] = [];

  @observable
  historyEvents: EventModel[] = [];

  @observable
  nextEvents: EventModel[] = [];

  @observable
  currentPagination: IEventPagination = {
    eventsCount: 0,
    page: 1,
    pages: 1,
  };

  @observable
  currentDrawsPagination: IDrawPagination = {
    drawsCount: 0,
    page: 1,
    pages: 1,
  };

  @observable
  isFetchingEvents: boolean = false;

  @action
  setCurrentEventSubPageIndex = (newIndex: number) =>
    (this.currentEventSubPageIndex = newIndex);

  @action
  setEventIdForSubscribe = (id: string) =>
    (this.eventIdForSubscribe = id);

  @action
  setEventNameForSubscribe = (id: string) =>
    (this.eventNameForSubscribe = id);

  @action
  fetchSubscriptionEvents = async (page: number = 1, limit: number = 6, history: boolean = false) => {
    this.isFetchingEvents = true;
    try {
      const response = await Get(`/api/participants/subscriptions/list?page=${page}&limit=${limit}&history=${history}`);
      this.setCurrentPagination({
        // @ts-ignore
        eventsCount: response.data.data.eventsCount,
        // @ts-ignore
        page: response.data.data.page,
        // @ts-ignore
        pages: response.data.data.pages,
      });
      runInAction(() => {
        this.isFetchingEvents = false;
        // @ts-ignore
        this.events = response.data.data.events;
      });
    } catch (error) {
      runInAction(() => {
        this.isFetchingEvents = false;
      });
      console.log(error.response);
    }
  };

  @action
  fetchSubscriptionHistoryEvents = async (page: number = 1, limit: number = 6) => {
    this.isFetchingEvents = true;
    try {
      const response = await Get(`/api/participants/subscriptions/list?page=${page}&limit=${limit}&history=${true}`);
      this.setCurrentPagination({
        // @ts-ignore
        eventsCount: response.data.data.eventsCount,
        // @ts-ignore
        page: response.data.data.page,
        // @ts-ignore
        pages: response.data.data.pages,
      });
      runInAction(() => {
        this.isFetchingEvents = false;
        // @ts-ignore
        this.historyEvents = response.data.data.events;
      });
    } catch (error) {
      runInAction(() => {
        this.isFetchingEvents = false;
      });
      console.log(error.response);
    }
  };

  @action
  fetchNextEvents = async () => {
    this.isFetchingEvents = true;
    try {
      const response = await Get(`/api/participants/subscriptions/next-events`);
      runInAction(() => {
        this.isFetchingEvents = false;
        // @ts-ignore
        this.nextEvents = response.data.data.events;
      });
    } catch (error) {
      runInAction(() => {
        this.isFetchingEvents = false;
      });
      console.log(error.response);
    }
  };

  @action
  onSubscribeEvent = async (eventIdForSubscribe: string, user_id: string) => {
    this.isFetchingEvents = true;
    try {
      const response = await Post(`/api/participants/subscriptions/${eventIdForSubscribe}/create`, {
        user_id: user_id
      });
      runInAction(() => {
        this.isFetchingEvents = false;
        toast.success("Registro efetuado com sucesso");
        history.replace("/subscriptions");
      });
    } catch (error) {
      runInAction(() => {
        this.isFetchingEvents = false;
      });
      toast.error("Registro não efetuado. Verifique o período de inscrição e tente novamente!");
      console.log(error.response);
    }
  };

  @action
  setCurrentPagination = (newPagination: IEventPagination) => {
    this.currentPagination = newPagination;
  };

  @action
  setCurrentDrawsPagination = (newPagination: IDrawPagination) => {
    this.currentDrawsPagination = newPagination;
  };

}

export const subscriptionsStore = new SubscriptionsStore();
