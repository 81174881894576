import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useObserver } from "mobx-react-lite";

// @ts-ignore
import { useDebounce } from "use-debounce";

import LoadingModal from "Components/LoadingModal";
import { LoadingStatusContainer } from "./styles/index";
import { NoDataContainer } from "Components/Styled/util";

import * as S from "./styles";
import { CustomCircularProgress } from "Components/Styled/Loading";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  createStyles,
  Theme,
  Paper,
  Grid,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Pagination from "@material-ui/lab/Pagination";
import { Add } from "@material-ui/icons";
import {
  Container,
  GenericFAB,
  GenericTableHead,
  FlexTD,
  GenericLeftFAB,
} from "Components/Styled/table";
import { useStore } from "Store";
import { MoreVert } from "@material-ui/icons";
import SearchBar from "Components/SearchBar";
import logo from "../../Assets/Images/pp.jpeg";
import { PrimaryActionButton } from "Components/Styled/Form";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
    },
    status__active: {
      color: "#2cda2c",
    },
    status__inactive: {
      color: "#e72419",
    },
  })
);
const ContactForm = () => {
  const history = useHistory();
  const classes = useStyles();
  const { contactStore } = useStore();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const isContextMenuOpen = Boolean(anchorEl);
  const [currentCompanyId, setCurrentCompanyId] = useState<string | null>("");
  const [filter, setFilter] = useState("");
  const [debounced] = useDebounce(filter, 500);
  const [description, setDescription] = useState<string>("");
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
  
  useEffect(() => {
    console.log(debounced);
    contactStore.fetchContact();
  }, [debounced, contactStore]);

  useEffect(() => {
    if (contactStore.contact) {
      setDescription(contactStore.contact ? contactStore.contact.description : "");

      const contentBlock = htmlToDraft(contactStore.contact ? contactStore.contact.description : "");
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [contactStore.contact]);

  const onEditorChange = async (editorStateProps: EditorState) => {
    setEditorState(editorStateProps);
    setDescription(draftToHtml(convertToRaw(editorStateProps.getCurrentContent())));
  }

  const handleSubmit = async () => {
    if (contactStore.contact) {
      await contactStore.updateContact(contactStore.contact._id, description);
      await contactStore.fetchContact();
    }
  }

  return useObserver(() => (
    <>
      <LoadingModal open={contactStore.isFetchingContact}>
        <S.LoadingStatusContainer>
          Atualizando informações...
        </S.LoadingStatusContainer>
      </LoadingModal>
      <SearchBar title={"Editar Contatos"} />
      <Container style={{flexGrow: 1 }}>
        <Grid item xs={12} sm={12}>
          {/* <Paper className={classes.paper}> */}
            <Container>
              <S.PageFormContainer>
                <S.PageFormContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={onEditorChange}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        localization={{
                          locale: 'pt',
                        }}
                        editorStyle={{
                          backgroundColor: "#ffffff",
                          borderRadius: '2px',
                          border: '1px solid #F1F1F1',
                          height: "300px"
                        }}
                      />
                    </Grid>
                    <PrimaryActionButton type="submit"
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={description.length <= 0}
                    >
                      Atualizar
                    </PrimaryActionButton>
                  </Grid>
                </S.PageFormContent>
              </S.PageFormContainer>
            </Container>
          {/* </Paper> */}
        </Grid>
      </Container>
    </>
  ));
};

export default ContactForm;
