import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog';

export const CustomDialog = styled(Dialog)`
  .MuiPaper-root{
      background-color: rgba(0, 0, 0, 0);
      box-shadow: none;
  }

`


export const Container = styled.div`
  
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
`
