import styled from "styled-components";

export const Container = styled.div`
  padding: 40px;

  .mb-02 {
    margin-bottom: 30px;
  }
`;

export const Title = styled.h3`
  color: #00577c;
  padding: 0;
  margin: 0;
`;

export const FormWrapper = styled.div`
  padding-top: 24px;
  .form-field {
    margin: 10px 0;
  }
  .MuiFormControl-root {
    width: 100%;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
`;

export const LoadingStatusContainer = styled.div`
  font-weight: bold;
  color: #fff;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 98%;
  margin: 10px;
`;
