import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useObserver } from "mobx-react-lite";
import { autorun } from "mobx";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { useStore } from "Store";
import * as S from "./../styles";
import Grid from "@material-ui/core/Grid";

// @ts-ignore
import { FormikTextField } from "formik-material-fields";

import LoadingModal from "Components/LoadingModal";

const override = css`
  margin: 0 auto;
  padding: 0;
`;

const ForgotPass: React.FC = () => {
  const history = useHistory();
  const { userStore, appStore, subscriptionsStore } = useStore();

  useEffect(() => {
    appStore.initializeBaseURL();
  }, []);

  useEffect(
    () =>
      autorun(() => {
        if (userStore.isSignedIn) {
          history.push("/");
        }
      }),
    [userStore, userStore.isSignedIn, history]
  );

  const onHandleSubmit = async (values: any, rest: any) => {
    try {
      await userStore.forgotPass(values.email);
    } catch (error) {
      rest.setSubmitting(false);
      console.log(error);
    }
  };

  const ForgotPassSchema = Yup.object().shape({
    username: Yup.string().required("Por favor, insira seu nome de usuário"),
    password: Yup.string().required("Por favor, insira sua senha"),
  });
  const ForgotPassInicialValues = {
    email: "",
  };

  return useObserver(() => (
    <S.Container>
      <LoadingModal open={userStore.isSigninInUnderTheHood}>
        <S.LoadingStatusContainer>
          Gerando email para envio...
        </S.LoadingStatusContainer>
      </LoadingModal>
      <S.LogoContainer />
      <S.ContentContainer>
        <Formik
          onSubmit={onHandleSubmit}
          initialValues={ForgotPassInicialValues}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <Grid container spacing={2}>
                <S.FormContainer>
                  <S.FormSubtitle>
                    Entre com o email do seu usuário. Caso seja um usuário válido, você receberá um email com os dados para recuperação de sua senha.
                  </S.FormSubtitle>
                  <FormikTextField
                    name="email"
                    label="Email do usuário"
                    fullWidth
                    variant="outlined"
                  />
                  <S.LoginButton
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  >
                    <S.LoginButtonTitle>
                      {isSubmitting ? (
                        <ClipLoader
                          css={override}
                          size={14}
                          color={"#fff"}
                          loading={true}
                        />
                      ) : (
                        "Enviar"
                      )}
                    </S.LoginButtonTitle>
                  </S.LoginButton>
                </S.FormContainer>
              </Grid>
            </Form>
          )}
        </Formik>
      </S.ContentContainer>
    </S.Container>
  ));
};

export default ForgotPass;
