import { configure, observable, action, runInAction } from "mobx";
import { Post, Get, Delete, Put } from "Services/Request";
import { Company } from "../../Models/Companies";
import { MyValues } from "Pages/Companies/models";
import { ICompanyPagination } from "Models/Utils";
import { history } from "Store/History";
import { toast } from "react-toastify";

configure({ enforceActions: "observed" });
class CompanyStore {
  constructor() {
    this.fetchCompanies();
  }

  @observable
  companies: Company[] = [];

  @observable
  companiesForSignup: Company[] = [];

  @observable
  companiesForEvent: Company[] = [];

  @observable
  isFetchingCompanies: boolean = false;

  @observable
  isFetchingCompanyToEdit: boolean = false;

  @observable
  isSubmittingCompany: boolean = false;

  @observable
  isDeletingCompany: boolean = false;

  @observable
  currentPagination: ICompanyPagination = {
    companiesCount: 0,
    page: 1,
    pages: 1,
  };

  @action
  setCurrentPagination = (newPagination: ICompanyPagination) => {
    this.currentPagination = newPagination;
  };

  @action
  fetchCompaniesSearch = async (name = "") => {
    await this.fetchCompanies(undefined, undefined, name);
  };

  @action
  fetchCompanies = async (page: number = 1, limit: number = 25, name = "") => {
    this.isFetchingCompanies = true;
    try {
      var uri = `/api/businesses?page=${page}&limit=${limit}`;
      if (name && name.length > 0) uri += `&q=${name}`;
      const response = await Get(uri);
      this.setCurrentPagination({
        // @ts-ignore
        companiesCount: response.data.data.businessesCount,
        // @ts-ignore
        page: response.data.data.page,
        // @ts-ignore
        pages: response.data.data.pages,
      });
      runInAction(() => {
        this.isFetchingCompanies = false;
        // @ts-ignore
        this.companies = response.data.data.businesses;
      });
      return response.data.data;
    } catch (error) {
      throw error.response;
    }
  };

  @action
  fetchEventCompanies = async (page: number = 1, limit: number = 25, name = "") => {
    this.isFetchingCompanies = true;
    try {
      var uri = `/api/businesses/event-list?page=${page}&limit=${limit}`;
      if (name && name.length > 0) uri += `&q=${name}`;
      const response = await Get(uri);
      this.setCurrentPagination({
        // @ts-ignore
        companiesCount: response.data.data.businessesCount,
        // @ts-ignore
        page: response.data.data.page,
        // @ts-ignore
        pages: response.data.data.pages,
      });
      runInAction(() => {
        this.isFetchingCompanies = false;
        // @ts-ignore
        this.companiesForEvent = response.data.data.businesses;
      });
      return response.data.data;
    } catch (error) {
      throw error.response;
    }
  };

  @action
  fetchCompaniesForSignup = async (page: number = 1, limit: number = 25) => {
    this.isFetchingCompanies = true;
    try {
      var uri = `/api/businesses/signup-list?page=${page}&limit=${limit}`;
      const response = await Get(uri);
      this.setCurrentPagination({
        // @ts-ignore
        companiesCount: response.data.data.businessesCount,
        // @ts-ignore
        page: response.data.data.page,
        // @ts-ignore
        pages: response.data.data.pages,
      });
      runInAction(() => {
        this.isFetchingCompanies = false;
        // @ts-ignore
        this.companiesForSignup = response.data.data.businesses;
      });
      return response.data.data;
    } catch (error) {
      throw error.response;
    }
  };

  @action
  onSubmitCSVs = async (csv: any) => {
    this.isSubmittingCompany = true;
    const data = new FormData();
    data.append("businesses", csv);

    try {
      const response = await Put("/api/import/business", data);
      toast.success("Empresas importadas com sucesso!");
      history.replace("/empresas");
      this.fetchCompanies();
    } catch (error) {
      console.log(error.response);
      toast.error("Erro ao processar XLSX, revise os dados ou o modelo do template.");
    }

    runInAction(() => {
      this.isSubmittingCompany = false;
    });

    return;
  };

  @action
  onSubmitCompany = async (
    data: MyValues,
    isUpdate: Boolean = false,
    companyId: string = ""
  ) => {
    this.isSubmittingCompany = true;
    var phone = data.phone_number.replace(/\D/g,'');
    const payload: MyValues = {
      ...data,
      area_code: phone.slice(0, 2),
      phone_number: phone.slice(2, phone.length),
      country: "Brasil",
      country_code: "55",
    };
    try {
      var response: any;
      if (isUpdate) {
        response = await Put(`/api/businesses/${companyId}`, payload);
      } else {
        response = await Post("/api/businesses", payload);
      }
      runInAction(() => {
        this.isSubmittingCompany = false;
      });
      toast.success(
        isUpdate
          ? "Empresa atualizada com sucesso"
          : "Empresa cadastrada com sucesso"
      );
      this.fetchCompanies();
      return response.data.data;
    } catch (error) {
      runInAction(() => {
        this.isSubmittingCompany = false;
      });
      toast.error(error.response.data.error.message);
      throw error;
    }
  };

  @action
  updateProfileImage = async (business_id: string, image: any) => {
    this.isSubmittingCompany = true;

    const data = new FormData();
    data.append("newProfilePicture", image);
    data.append("business_id", business_id);

    try {
      const response = await Post("/api/businesses/profile-picture/update", data);
      toast.success("Imagem anexada com sucesso");
      runInAction(() => {
        this.isSubmittingCompany = false;
      });
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.error.message);
      runInAction(() => {
        this.isSubmittingCompany = false;
      });
      throw error;
    }
  };

  @action
  fetchCompanyById = async (companyId: string): Promise<Company> => {
    this.isFetchingCompanyToEdit = true;
    try {
      const response = await Get(`/api/businesses/${companyId}`);
      runInAction(() => {
        this.isFetchingCompanyToEdit = false;
      });
      // @ts-ignore
      return response.data.data;
    } catch (error) {
      runInAction(() => {
        this.isFetchingCompanyToEdit = false;
      });
      throw error.response;
    }
  };

  @action
  onDeleteCompany = async (companyId: string) => {
    this.isDeletingCompany = true;
    try {
      await Delete(`/api/businesses/${companyId}`);
      runInAction(() => {
        this.isDeletingCompany = false;
      });
      await this.fetchCompanies();
      toast.success("Empresa removida com sucesso");
      // this.setCurrentPagination({
      //   companiesCount: 0,
      //   page: 1,
      //   pages: 1,
      // });
    } catch (error) {
      runInAction(() => {
        this.isDeletingCompany = false;
      });
      toast.error(error.response.data.error.message);
      throw error;
    }
  };

  @action
  deleteCompaniesInBatch = async (companyIds: string[]) => {
    this.isDeletingCompany = true;
    try {
      const response = await Put(
        `/api/businesses/batch/destroy`,
        {
          companyIds: companyIds
        }
      );
      runInAction(() => {
        this.isDeletingCompany = false;
      });
      await this.fetchCompanies();
      toast.success("Empresas removida com sucesso");
      this.setCurrentPagination({
        companiesCount: 0,
        page: 1,
        pages: 1,
      });
    } catch (error) {
      if (error.response.data && error.response.data.error && error.response.data.error.message) {
        toast.error(error.response.data.error.message);
      }
      else {
        toast.error("Erro ao remover empresas, revise as informações e tente novamente");
      }
      runInAction(() => {
        this.isDeletingCompany = false;
      });
    }
  };
}

export const companyStore = new CompanyStore();
