import React, { useState, useEffect } from "react";

import { Search } from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import HelpIcon from "@material-ui/icons/Help";
import UploadIcon from "@material-ui/icons/CloudUpload";

import * as S from "./styles";
import { useLocation } from "react-router-dom";

interface Props {
  title: string;
  onFilter?: Function;
  handleInputAdornmentClick?: Function;
  onUploadCSVClick?: Function
}
const SearchBar: React.FC<Props> = ({
  title,
  onFilter,
  handleInputAdornmentClick,
  onUploadCSVClick
}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let filter = params.get("filter");

  const [inputValue, setInputValue] = useState(filter ? filter : "");

  useEffect(() => {
    if (filter) {
      setInputValue(filter);
    }
  }, [location]);

  useEffect(() => {
    if (onFilter) {
      onFilter(inputValue);
    }
  }, [inputValue]);

  return (
    <S.SearchBar>
      <span className="page-title">{title}</span>
      <div>
        {onUploadCSVClick && (
          <IconButton
            title="Importação de planilha"
            onClick={() => {
              onUploadCSVClick();
            }}>
            <UploadIcon />
          </IconButton>
        )}
        <FormControl style={{padding: "6px"}}>
          {onFilter && (
            <Input
              id="standard-adornment-weight"
              placeholder="Filtro"
              value={inputValue}
              // onChange={onFilter ? (e) => onFilter(e.target.value) : () => {}}
              onChange={onFilter ? (e) => setInputValue(e.target.value) : () => {}}
              endAdornment={
                <InputAdornment position="end">
                  {inputValue.length > 0 ? (
                    <IconButton
                      aria-label="clear field"
                      onClick={() => setInputValue("")}
                    >
                      <ClearIcon />
                    </IconButton>
                  ) : (
                    <Search />
                  )}
                </InputAdornment>
              }
              aria-describedby="standard-weight-helper-text"
              inputProps={{
                "aria-label": "buscar evento",
              }}
            />
          )}
        </FormControl>
      </div>
    </S.SearchBar>
  );
};

export default SearchBar;
