import { configure, observable, action } from "mobx";
import { toast } from "react-toastify";
import axios from "axios";
configure({ enforceActions: "observed" });

class AppStore {
  AppStore() {
    this.initializeBaseURL();
  }

  @observable
  currentBaseURL: string = "https://api-evento.adapanet.com.br";
  // currentBaseURL: string = "http://localhost:3012";

  @action
  initializeBaseURL = () => {
    if (localStorage.getItem("@baseURL")) {
      // @ts-ignore
      this.setCurrentBaseApi(localStorage.getItem("@baseURL"), true);
    }
  };

  @action
  setCurrentBaseApi = (newBaseURL: string, skip: boolean = false) => {
    console.log(newBaseURL);
    localStorage.setItem("@baseURL", newBaseURL);
    this.currentBaseURL = newBaseURL;
    if (!skip) {
      toast.success("Endereço atualizado com sucesso");
    }
  };

  @action
  resetCurrentBaseApi = (skipMessage: boolean = false) => {
    localStorage.setItem("@baseURL", "https://api-evento.adapanet.com.br");
    this.currentBaseURL = "https://api-evento.adapanet.com.br";
    if (!skipMessage) {
      toast.success("Endereço redefinido com sucesso");
    }
  };

  @action
  sync = async () => {
    try {
      if (this.currentBaseURL === "http://localhost:3012") {
        console.log("Você já está sincronizado");
      } else {
        // const res = await axios.get(
        //   `${this.currentBaseURL}/api/sync/collections`
        // );
        // for (var i = 0; i < res.data.data.modelNames.length; i++) {
        //   // await axios.post(
        //   //   `${this.currentBaseURL}/api/sync/${res.data.data.modelNames[i].toLowerCase()}`
        //   // );
        // }
        // console.log("Sincronização realizada com sucesso");
        // // toast.success("Sincronização realizada com sucesso");
        // window.location.reload();
      }
    } catch (error) {
      console.log(error.response);
      console.error("Erro na sincronização, favor verificar servidor nas configurações");
      // toast.error(
      //   "Erro na sincronização, favor verificar servidor nas configurações"
      // );
    }
  };
}

export const appStore = new AppStore();
