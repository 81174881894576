import { EventModel } from "Models/Events";
import { LandingPageModel } from "Models/LandingPage";
import * as Yup from "yup";

export interface IFormValues {
    body?: string;
    body_html?: string;
}

export const validationSchema = Yup.object().shape({
    body: Yup.string().required("Campo é obrigatório"),
});

export interface IOptions {
  value: string;
  label: string;
}

export interface IResponse {
  event: LandingPageModel;
  __proto__: any;
}
