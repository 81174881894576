import { configure, observable, action, runInAction, toJS } from "mobx";
import { toast } from "react-toastify";
import {
  EventModel,
  Checkin,
} from "Models/Events";

import { IEventPagination, IDrawPagination } from "Models/Utils";

import { Post, Get, Delete, Put } from "Services/Request";
import { history } from "Store/History";
import { IFormValues } from "Pages/Events/ShowEvent/CertificateConfig/models/indes";
import { CertificateModel } from "Models/Certificate";

configure({ enforceActions: "observed" });

class CertificatesStore {
  constructor() {
    // this.fetchCertificate();
  }

  @observable
  certificate: CertificateModel | null = null;

  @observable
  isFetchingEvents: boolean = false;

  @action
  fetchCertificate = async (eventId: string) => {
    this.isFetchingEvents = true;
    try {
      const response = await Get(`/api/events/${eventId}/certificate`);
      runInAction(() => {
        this.isFetchingEvents = false;
        // @ts-ignore
        this.certificate = response.data.data.certificate;
      });

      //@ts-ignore
      return response.data.data.certificate;
    } catch (error) {
      runInAction(() => {
        this.isFetchingEvents = false;
      });
      console.log(error.response);
    }
  };

  @action
  updateCertificate = async (eventId: string, values: IFormValues) => {
    try {
      const response = await Put(`/api/events/${eventId}/certificate`, {
        ...values
      });
      toast.success("Certificado atualizado com sucesso");
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error.message);
      throw error.response;
    }
  };

  @action
  onUpdateCertificateBackground = async (eventId: string, cover: any) => {
    try {
      const data = new FormData();
      data.append("background", cover);
      const response = await Put(`/api/events/${eventId}/certificate`, data);
      toast.success("Certificado atualizado com sucesso");
    } catch (error) {
      toast.error(error.response.data.error.message);
      console.log(error.response);
    }
  };

}

export const certificatesStore = new CertificatesStore();
