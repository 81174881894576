import styled from "styled-components";

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const FormContent = styled.div`
  margin: 50px 0 100px 0;
  width: 100%;
  max-width: 800px;
`;

export const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  * {
    margin: 20px 0 0 10px;
  }
`;


export const LoadingStatusContainer = styled.div`
  font-weight: bold;
  color: #fff;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 95%;
  padding: 20px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 98%;
  margin: 10px;
`;

export const Container = styled.div`
  padding: 40px;
`

export const Title = styled.h3`
  color: #00577C;
  padding: 0;
  margin: 0;
`;

export const PageFormContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 0px 0px 0px 20px;
`;

export const PageFormContent = styled.div`
  margin: 0px 0 0px 0;
  width: 100%;
`;