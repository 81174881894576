import styled, { css } from "styled-components";

import Button from "@material-ui/core/Button";

interface IGenericButtonProps {
  aVariant?: string;
}

export const GenericButton = styled(Button)<IGenericButtonProps>`
  && {
    min-width: 100px;
    white-space: nowrap;
    width: min-content;
    border-radius: 38px;
    font-size: 14px;
    background-color: #00577b;
    color: #fff;
    font-weight: bold;
    box-shadow: none;

    &:hover {
      background-color: #00405a;
    }

    ${(props) =>
      props.aVariant === "secondary" &&
      css`
        border: 1px solid #00577b;
        color: #00577b;
        background-color: #fff;
        &:hover {
          background-color: #fff;
        }
      `}
  }
`;

export const PrimaryActionButton = styled.button`
  min-width: 100px;
  background: #00577b;
  font-size: 14px;
  border-radius: 5px;
  color: #fff;
  padding: 16px 32px;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    background: #004764;
  }
`;
export const SecondaryActionButton = styled(PrimaryActionButton)`
  background: none;
  border: 1px solid #00577b;
  color: #00577b;
  &:hover {
    background: none;
  }
`;

export const FormTitle = styled.p`
  padding-top: 20px;
  font-size: 32px;
  font-weight: 700;
`;
