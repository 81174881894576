// * Packages
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useStore } from "Store";
import { autorun, toJS } from "mobx";

// * Components

import LoadingModal from "Components/LoadingModal";

// * Interface and styles
import * as S from "../../styles";

import { ErrorLabel } from "Components/Styled/util";
import { GenericButton } from "Components/Styled/Form";
import SimpleFilePicker from "../../../components/SimpleFilePicker";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import { MenuItem, Select } from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
// @ts-ignore
import { FormikTextField, FormikSelectField } from "formik-material-fields";

// const image2base64 = require('image-to-base64');

interface Props {
  activeStep: number;
  handleNext: Function;
  handleBack: Function;
}
interface RouteParams {
  eventId: string;
}
const FirstStep: React.FC<Props> = ({ activeStep, handleNext, handleBack }) => {
  const { eventId } = useParams<RouteParams>();
  const classes = S.useStyles();
  const { eventsStore } = useStore();
  const [currentFile, setCurrentFile] = useState<FileList | null>(null);
  const [fileError, setFileError] = useState<boolean>(false);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [eventType, setEventType] = useState("regular");
  const [descriptionBody, setDescriptionBody] = useState("");
  const [isLoading] = useState(false);
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
  interface FormValues {
    eventName: string;
    eventDescription: string;
    eventDescriptionBody: string;
    eventType: string;
  }
  const [initialValues, setInitialValues] = useState<FormValues>({
    eventName: eventsStore.currentFirstStep.name || "",
    eventDescription: eventsStore.currentFirstStep.description || "",
    eventDescriptionBody: eventsStore.currentFirstStep.description_body || "",
    eventType: eventsStore.currentFirstStep.type || ""
  });

  useEffect(
    () =>
      autorun(() => {
        setInitialValues({
          eventName: eventsStore.currentFirstStep.name,
          eventDescription: eventsStore.currentFirstStep.description,
          eventDescriptionBody: eventsStore.currentFirstStep.description_body,
          eventType: eventsStore.currentFirstStep.type
        });

        setEventType(eventsStore.currentFirstStep.type);
        setDescriptionBody(eventsStore.currentFirstStep.description_body);

        const contentBlock = htmlToDraft(eventsStore.currentFirstStep.description_body);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }),
    []
  );

  const validationSchema = Yup.object().shape({
    eventName: Yup.string().required("Required"),
    eventDescription: Yup.string().required("Required"),
  });

  const onSubmit = (values: FormValues) => {
    if (eventId) {
      setFileError(false);
      if (currentFile) {
        console.log(currentFile[0]);
        eventsStore.persistFirstStep({
          name: values.eventName,
          description: values.eventDescription,
          description_body: descriptionBody,
          type: eventType,
          cover: currentFile[0],
        });
      } else {
        eventsStore.persistFirstStep({
          name: values.eventName,
          description: values.eventDescription,
          description_body: descriptionBody,
          type: eventType,
          cover: null,
        });
      }

      handleNext();
    } else if (currentFile) {
      setFileError(false);
      console.log(currentFile[0]);
      eventsStore.persistFirstStep({
        name: values.eventName,
        description: values.eventDescription,
        description_body: descriptionBody,
        type: eventType,
        cover: currentFile[0],
      });
      handleNext();
    } else {
      // setFileError(true);
      // setFileErrorMessage("Por favor, escolha uma imagem para o evento");
      setFileError(false);
      eventsStore.persistFirstStep({
        name: values.eventName,
        description: values.eventDescription,
        description_body: descriptionBody,
        type: eventType,
        cover: null,
      });
      handleNext();
    }
  };

  useEffect(() => {
    if (currentFile) {
      setFileError(false);
    }
  }, [currentFile]);

  const handleValidationAndSubmition = async (
    validateForm: Function,
    setTouched: Function,
    values: FormValues
  ) => {
    const res = await validateForm();
    setTouched(res);
    // if (!currentFile) {
    //   setFileError(true)
    // } else {
    setFileError(false);
    if (!Object.keys(res).length) {
      onSubmit(values);
    }
    // }
  };

  const onEditorChange = async (editorStateProps: EditorState) => {
    setEditorState(editorStateProps);
    setDescriptionBody(draftToHtml(convertToRaw(editorStateProps.getCurrentContent())));
  }

  if (activeStep !== 0) {
    return null;
  }

  return (
    <>
      <LoadingModal open={isLoading} />
      <Container className={classes.root} maxWidth="sm">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnBlur={true}
          validationSchema={validationSchema}
        >
          {({
            submitForm,
            isSubmitting,
            handleChange,
            touched,
            values,
            handleBlur,
            errors,
            validateForm,
            setTouched,
          }) => (
            <Form>
              {/* <form className={classes.root}> */}
              <S.FormContainer>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Nome do evento"
                  name="eventName"
                  variant="outlined"
                  value={values.eventName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // @ts-ignore
                  error={errors.eventName && touched.eventName}
                  helperText={
                    errors.eventName && touched.eventName && errors.eventName
                  }
                />
                <TextField
                  fullWidth
                  name="eventDescription"
                  value={values.eventDescription}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // @ts-ignore
                  error={errors.eventDescription && touched.eventDescription}
                  helperText={
                    errors.eventDescription &&
                    touched.eventDescription &&
                    errors.eventDescription
                  }
                  id="outlined-basic"
                  label="Descrição"
                  variant="outlined"
                />
                <FormikSelectField
                  style={{ width: "100%" }}
                  className="form-field"
                  id="eventType"
                  name="eventType"
                  label="Tipo do evento"
                  options={[
                    {label: "Presencial", value: "regular"},
                    {label: "Online", value: "online"},
                  ]}
                  variant="outlined"
                  onChange={((option: any) => {
                    if (option) setEventType(option.target.value);
                  })}
                />
                <p></p>
                <S.FilePickerWrapper>
                  <SimpleFilePicker
                    onLoadFile={(files: FileList | null) =>
                      setCurrentFile(files)
                    }
                    id="file"
                  />
                  {currentFile ? (
                    <Typography variant="h5" gutterBottom>
                      {currentFile[0].name}
                    </Typography>
                  ) : (
                    <Typography variant="subtitle1" gutterBottom>
                      Carregue uma imagem de topo para o seu evento
                    </Typography>
                  )}
                  {fileError && <ErrorLabel>{fileErrorMessage}</ErrorLabel>}
                </S.FilePickerWrapper>
                <Typography variant="h6">
                  Descrição para página de registro do evento:
                </Typography>
                  {/* <textarea
                    value={descriptionBody ? descriptionBody : ""}
                    rows={10}
                    style={{width: "95%"}}
                    onChange={(content: any) => {
                      setDescriptionBody(content.target.value)
                    }}
                  /> */}
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={onEditorChange}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    localization={{
                      locale: 'pt',
                    }}
                    editorStyle={{
                      backgroundColor: "#ffffff",
                      borderRadius: '2px',
                      border: '1px solid #F1F1F1',
                      height: "300px"
                    }}
                  />
              </S.FormContainer>
              <S.ButtonRowWrapper>
                <GenericButton
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    handleValidationAndSubmition(
                      validateForm,
                      setTouched,
                      values
                    )
                  }
                  // type="submit"
                >
                  Próxima etapa
                </GenericButton>
              </S.ButtonRowWrapper>
              {/* </form> */}
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default FirstStep;

{
  /* {fileError && !currentFile && (
    <ErrorLabel>escolha uma foto</ErrorLabel>
  )} */
}
