import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useStore } from "Store";

// @ts-ignore
import { estados } from "estados-br";

import { MyValues, IOptions, IResponse } from "../models";

import * as S from "./styles";

// * Material UI and Form related

import {
  PrimaryActionButton,
  SecondaryActionButton,
} from "Components/Styled/Form";

import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// @ts-ignore
import { FormikTextField } from "formik-material-fields";
// @ts-ignore
import { FormikSelectField } from "formik-material-fields";
import { Container, TextField, Typography } from "@material-ui/core";
import { Formik, Form as FormikForm } from "formik";
import InputMask from "react-input-mask";
import { validationSchema } from "../models";

import { validateCNPJ, validatePhone } from "Utils";
import { toast } from "react-toastify";
import SearchBar from "Components/SearchBar";
import { ErrorLabel } from "Components/Styled/util";
const StringMask = require("string-mask");
interface RouteParams {
  companyId: string;
}

const CompanyForm = () => {
  const { companyId } = useParams<RouteParams>();
  const { companyStore, appStore } = useStore();
  const history = useHistory();
  const [currentUf, setCurrentUf] = useState("PA");
  const [isLoading, setIsLoading] = useState(false);
  const [currentFile, setCurrentFile] = useState<FileList | null>(null);
  const [fileError, setFileError] = useState<boolean>(false);
  const [fileErrorMessage, setFileErrorMessage] = useState("");

  interface FormValues {
    eventName: string;
    eventDescription: string;
    eventDescriptionBody: string;
    eventType: string;
  }

  const [initialValues, setInitialValues] = useState<FormValues>({
    eventName: "",
    eventDescription: "",
    eventDescriptionBody: "",
    eventType: ""
  });

  const onSubmit = async () => {
    try {
      if (currentFile) {
        setFileError(false);
        console.log(currentFile[0]);
        await companyStore.onSubmitCSVs(currentFile[0]);
      } else {
        setFileError(true);
        setFileErrorMessage("Por favor, escolha um arquivo xlsx para prosseguir.");
      }
    } catch (error) {}
  };

  return isLoading ? (
    <h1>Loading</h1>
  ) : (
    <>
      <SearchBar title={"Importação de Empresas (XLSX)"} />
      <Container>
        <>
          <S.FormContainer>
            <S.FormContent>
                  <S.ButtonRow>
                  <Typography variant="h5" gutterBottom>
                    Para importação em lote de empresas, enviar arquivo .xlsx baseado em template pré-definido.
                  </Typography>
                  <>
                    <input
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setCurrentFile(e.target.files)
                      }
                      type="file"
                      id="file"
                      accept=".xlsx"
                    />
                    <label htmlFor="file">Escolha um arquivo</label>
                  </>
                  {currentFile ? (
                    <Typography variant="h5" gutterBottom>
                      {currentFile[0].name}
                    </Typography>
                  ) : (
                    <Typography variant="subtitle1" gutterBottom>
                      Carregue um arquivo XLSX para a lista de empresas. Utilizar o template (<a href={window.location.protocol + "//" + window.location.host + "/templates/template_business.xlsx"} target="_blank">baixar aqui</a>) com formatação em UTF8.
                    </Typography>
                  )}
                  {fileError && <ErrorLabel>{fileErrorMessage}</ErrorLabel>}
                  </S.ButtonRow>
                <S.ButtonRow>
                  <SecondaryActionButton
                    onClick={() => history.replace("/empresas")}
                  >
                    Cancelar
                  </SecondaryActionButton>
                    <PrimaryActionButton 
                      type="submit" 
                      onClick={() => onSubmit()}
                      disabled={!!companyStore.isSubmittingCompany}
                    >
                    Cadastrar
                  </PrimaryActionButton>
                </S.ButtonRow>
            </S.FormContent>
          </S.FormContainer>
        </>
      </Container>
    </>
  );
};

export default CompanyForm;
