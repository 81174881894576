import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import * as S from "./styles";

import { paginate, getPagesCount } from "../../utils";

// * Components
import Pagination from "@material-ui/lab/Pagination";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {
  Clear as ClearIcon,
  Search as SearchIcon,
  Help as HelpIcon,
} from "@material-ui/icons";
import { GenericTableToolbar } from "Components/Styled/util";
import LoadingModal from "Components/LoadingModal";
import { NoDataContainer } from "Components/Styled/util";
import {
  makeStyles,
  createStyles,
  Theme,
  TableContainer,
  Table,
  TableCell,
  Paper,
  TableBody,
  Input,
  Button,
  Tooltip,
  ClickAwayListener,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";

// import Checkbox from "@material-ui/core/Checkbox";
import { GenericTableHead, GenericTableRow } from "Components/Styled/table";
import "./styles/checkbox.css";
import { useStore } from "Store";
import styled from "styled-components";
import { EventModel } from "Models/Events";
import Download from "./Download";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      width: "100%",
    },
    control: {
      padding: theme.spacing(2),
    },
  })
);

interface RouteParams {
  eventId: string;
}
interface IProps {
  currentEvent: EventModel;
  refetchEvent: Function;
}
const Checkin: React.FC<IProps> = ({ currentEvent, refetchEvent }) => {
  const { eventsStore } = useStore();
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [download, setDownload] = useState(false);
  const { active, starts_at, ends_at, _id, checkin_tolerance } = currentEvent;
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isHandlingCheckin, setIsHandlingCheckin] = useState(false);
  const [registrationsArr, setRegistrationsArr] = useState(
    currentEvent.registrations
  );
  const [isBetweenStartAndEndDate] = useState(
    moment().isBetween(
      starts_at,
      ends_at
      //moment(starts_at).add(checkin_tolerance, "ms")
    )
  );

  useEffect(() => {
    let local: typeof currentEvent.registrations = currentEvent.registrations;
    console.log(filter);
    local = currentEvent.registrations.filter(
      (registration) =>
        (registration.user.first_name
          .toLowerCase()
          .includes(filter.toLowerCase())) ||
        (registration.user.last_name
          .toLowerCase()
          .includes(filter.toLowerCase())) ||
        (registration.user.nickname &&
          registration.user.nickname
          .toLowerCase()
          .includes(filter.toLowerCase())) ||
        (registration.user.business &&
          registration.user.business.name &&
          registration.user.business.name
          .toLowerCase()
          .includes(filter.toLowerCase())) ||
        (registration.user.business_title &&
          registration.user.business_title
          .toLowerCase()
          .includes(filter.toLowerCase()))
    );
    setRegistrationsArr(local);
  }, [filter, currentEvent]);

  const handleCheckin = async (attendeeId: string, attended: boolean) => {
    try {
      if (currentEvent) {
        setIsHandlingCheckin(true);
        await eventsStore.onHandleAttendeeCheckin({
          attendeeId,
          eventId: currentEvent?._id,
          checkin: attended,
        });
        setIsHandlingCheckin(false);
        refetchEvent();
      }
    } catch (error) {
      setIsHandlingCheckin(false);
    }
  };

  return (
    <>
      <LoadingModal open={isHandlingCheckin}>
        <S.LoadingStatusContainer>
          Realizando check-in, por favor aguarde...
        </S.LoadingStatusContainer>
      </LoadingModal>
      {currentEvent.registrations.length > 0 ? (
        <TableContainer component={Paper}>
          <GenericTableToolbar>
            <Typography variant="subtitle1" component="div">
              Check-ins
            </Typography>
            <div style={{ display: "flex" }}>
              <Button
                disabled={registrationsArr.length === 0}
                variant="contained"
                color="secondary"
                onClick={() => setDownload(true)}
              >
                Iniciar exportação
              </Button>
              <ClickAwayListener onClickAway={() => setIsTooltipOpen(false)}>
                <div>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setIsTooltipOpen(false)}
                    open={isTooltipOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={"A exportação levará em conta o filtro!"}
                  >
                    <IconButton
                      style={{ marginRight: 20 }}
                      onClick={() => setIsTooltipOpen(true)}
                    >
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </ClickAwayListener>
              <Input
                placeholder="Filtro"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    {filter.length > 0 ? (
                      <IconButton
                        aria-label="clear field"
                        onClick={() => setFilter("")}
                      >
                        <ClearIcon />
                      </IconButton>
                    ) : (
                      <SearchIcon />
                    )}
                  </InputAdornment>
                }
              />
            </div>
          </GenericTableToolbar>
          <Table>
            <GenericTableHead>
              <GenericTableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Empresa</TableCell>
                <TableCell>Data de checkin</TableCell>
                <TableCell>ID</TableCell>
                <TableCell style={{ textAlign: "right" }}>Check-in</TableCell>
              </GenericTableRow>
            </GenericTableHead>
            <TableBody>
              {paginate({
                array: registrationsArr,
                page_number: currentPage,
                page_size: 5,
              }).map((registration) => {
                return (
                  <GenericTableRow key={registration._id}>
                    <TableCell component="th" scope="row">
                      {registration.user.first_name}{" "}
                      {registration.user.last_name}
                      {registration.user.nickname && (
                        " (" + registration.user.nickname + ")"
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {registration.user.business && registration.user.business.name ? registration.user.business.name : (registration.user.business_title ? registration.user.business_title + "*" : "") }
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {registration.attended ? (
                        <span>
                          {moment(registration.checked_in_at.toString()).format(
                            "DD [de] MMMM [de] YYYY [às] HH[:]MM"
                          )}
                        </span>
                      ) : (
                        <span>Presença não confirmada</span>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {registration.user_id}
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      <Switch
                        disabled={
                          !currentEvent.active // || !isBetweenStartAndEndDate
                        }
                        checked={registration.attended}
                        onChange={() =>
                          handleCheckin(
                            registration.user_id,
                            registration.attended
                          )
                        }
                        inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                      />
                    </TableCell>
                  </GenericTableRow>
                );
              })}
            </TableBody>
          </Table>
          <S.PaginationContainer>
            <Pagination
              page={currentPage}
              count={getPagesCount({
                itemsCount: registrationsArr.length,
                pageSize: 5,
              })}
              onChange={(event, value) => setCurrentPage(value)}
              color="secondary"
            />
          </S.PaginationContainer>
          <Download
            handleClose={setDownload}
            open={download}
            downloadButton={
              <Button
                onClick={() => setDownload(false)}
                variant="contained"
                color="secondary"
              >
                Baixar
              </Button>
            }
            dataSet={registrationsArr}
          />
        </TableContainer>
      ) : (
        <NoDataContainer>
          <h3>Nenhum check-in aqui!</h3>
          {!(!currentEvent.active || !isBetweenStartAndEndDate) && (
            <h4>
              Experimente{" "}
              <Link to={`/attendees/${_id}`}>adicionar participantes</Link> ao
              evento
            </h4>
          )}
        </NoDataContainer>
      )}
    </>
  );
};

const CardButton = styled.button`
  display: box;
  width: auto;
  min-width: 200px;
  font-size: 14pt;
  border: #00577b;
  background: white;
  margin-left: 10px;
  padding: 15px;
  color: #00577b;
  border: 0.7px solid #00577b;
  height: 70px;
  border-radius: 20px;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export default Checkin;
