export const tipoDeEntradaOptions = [
  { label: "Todas", value: "default" },
  { label: "VIP", value: "vip" },
  { label: "NORMAL", value: "standard" },
  { label: "EMPRESA ASSOCIADA", value: "associated_company" },
  { label: "EMPRESA NÃO ASSOCIADA", value: "not_associated_company" },
  { label: "EMPRESA PARCEIRA", value: "partner_company" },
  { label: "EMPRESA PATROCINADORA", value: "sponsor_company" },
  { label: "CONVIDADO", value: "guest" },
  { label: "DIRETORIA", value: "director" },
  { label: "PROFISSIONAL LIBERAL", value: "liberal_professional" },
  { label: "ESTUDANTE", value: "student" },
  { label: "PRESTADOR DE SERVIÇO", value: "service_provider" },
];

export const genderOptions = [
  { label: "Todos os gêneros", value: "default" },
  { label: "Feminino", value: "female" },
  { label: "Masculino", value: "male" },
  { label: "Não quero declarar", value: "undefined" },
];
