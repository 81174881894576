import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import * as S from "./styles";

import { EventModel } from "Models/Events";

import {
  GenericFAB,
  GenericTableHead,
  GenericTableRow,
  GenericLeftFAB,
  Generic2FAB
} from "Components/Styled/table";
import { GenericTableToolbar } from "Components/Styled/util";

import { Clear as ClearIcon, Search as SearchIcon, Business } from "@material-ui/icons";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import {
  InputAdornment,
  IconButton,
  TableContainer,
  Table,
  TableCell,
  Paper,
  TableBody,
  Input,
  Typography,
  Checkbox,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Chip from "@material-ui/core/Chip";

import { Add } from "@material-ui/icons";

import { useStore } from "Store";
import AddParticipantModal from "./AddParticipantModal";
import { attendeesStore } from "Store/Attendees";

import { paginate, getPagesCount } from "../../utils";
import ModalEditStatus from "./Modal";
import LoadingModal from "Components/LoadingModal";
import { userStore } from "Store/User";
// @ts-ignore
import { FormikSelectField } from "formik-material-fields";
import { Formik, Form as FormikForm } from "formik";

interface RouteParams {
  eventId: string;
}
interface IProps {
  currentEvent: EventModel;
  refetchEvent: Function;
  filterInitialValue?: string;
}

const Participants: React.FC<IProps> = ({ currentEvent, refetchEvent, filterInitialValue }) => {
  const history = useHistory();
  const location = useLocation();
  const { eventId } = useParams<RouteParams>();
  const { eventsStore, companyStore } = useStore();
  const [filter, setFilter] = useState("");
  const [isBetweenStartAndEndDate] = useState(
    moment().isBetween(
      currentEvent.registration_start,
      currentEvent.registration_end
    )
  );
  const [registrationsArr, setRegistrationsArr] = useState(
    currentEvent.registrations
  );
  const [openEdit, setOpenEdit] = useState(false);
  const [currentAttId, setCurrentAttId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [allSelected, setAllSelected] = useState(false);
  const [paymentFilter, setPaymentFilter] = useState("all");
  const [companyFilter, setCompanyFilter] = useState("all");
  const [companies, setCompanies] = useState([]  as {label: string, value: string}[])

  const params = new URLSearchParams(location.search);
  let filterParam = params.get("filter");

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    companyStore.fetchCompanies(1, 999);
    //companyStore.fetchEventCompanies(1, 999);
  }, []);

  useEffect(() => {
    const opt = [{ label: "Todas Empresas", value: "all" }];
    
    // if (companyStore.companies && companyStore.companies.length == 1) {
      // companyStore.companies.forEach((business) => {
      //   opt.push({
      //     label: business.trade_name.toUpperCase(),
      //     value: business._id,
      //   });
      // });
    // }
    // else if (currentEvent && currentEvent.businesses) {
      currentEvent.businesses.forEach((business) => {
        opt.push({
          label: business.business.trade_name.toUpperCase(),
          value: business.business._id,
        });
      });
    // }

    setCompanies(opt);

}, [companyStore.companies, currentEvent, currentEvent.businesses]);

  useEffect(() => {
    if (filterInitialValue) setFilter(filterInitialValue);
    else if (filterParam) setFilter(filterParam);
  }, [filterInitialValue, filterParam]);

  useEffect(() => {
    let local: typeof currentEvent.registrations = currentEvent.registrations;
    
    local = currentEvent.registrations.filter(
      (registration) =>
        registration.user.first_name
          .toLowerCase()
          .includes(filter.toLowerCase()) ||
        registration.user.last_name
          .toLowerCase()
          .includes(filter.toLowerCase()) ||
        (registration.user.first_name + " " + registration.user.last_name)
          .toLowerCase()
          .includes(filter.toLowerCase()) ||
        registration.user.cpf.includes(filter) ||
        (registration.user.business && 
        registration.user.business.name && 
        registration.user.business.name.toLowerCase().includes(filter.toLowerCase()))
    );

    if (paymentFilter === "confirmed_payments") {
      local = local.filter(registration => {
        return registration.paid
      });
    } else if (paymentFilter === "not_confirmed_payments") {
      local = local.filter(registration => {
        return !registration.paid
      });
    }

    if (companyFilter !== "all") {
      local = local.filter(registration => {
        if (registration.user && registration.user.business)
          return registration.user.business._id === companyFilter;
        
        return false;
      });
    }

    if (userStore.isCompanyUser()) {
      local = local.filter(registration => {
        if (userStore.currentUser && userStore.currentUser.business && registration.user.business)
         return registration.user.business._id == userStore.currentUser.business._id;
        return false;
      });
    }

    setRegistrationsArr(local);
  }, [filter, currentEvent, paymentFilter, companyFilter]);

  const handleUnlinkUserFromEvent = async (attendeeId: string) => {
    try {
      await attendeesStore.unlinkAttendeeFromEvent({ attendeeId, eventId });
      refetchEvent();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleUnlinkUserFromEventInBatch = async () => {
    try {
      await attendeesStore.unlinkAttendeeFromEventInBatch({ attendeeIds: selectedItems, eventId });
      refetchEvent();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleOnEdit = async () => {
    try {
      refetchEvent();
    } catch (error) {
      console.log(error.response);
    }
  }
  interface MyValues {
    name: string;
    company: string;
  }

  const onSubmitParticipant = (values: MyValues) => {};

  const editStatus = (attendeeId: string) => {
    setCurrentAttId(attendeeId);
    setOpenEdit(true);
  };

  const confirmPayment = async (attendeeId: string, paid: boolean) => {
    setIsFetching(true);
    await eventsStore.updatePaidStatus(eventId, attendeeId, paid);
    await refetchEvent();
    setIsFetching(false);
  };

  const confirmPaymentInBatch = async (paid: boolean) => {
    setIsFetching(true);
    await eventsStore.updatePaidStatusInBatch(eventId, selectedItems, paid);
    await refetchEvent();
    setIsFetching(false);
  };

  const getParticipantType = (type: string) => {
    if (type === "vip") {
      return "VIP";
    }
    else if (type === "associated_company") {
      return "Empresa Associada";
    }
    else if (type === "not_associated_company") {
      return "Empresa Não Associada";
    }
    else if (type === "partner_company") {
      return "Empresa Parceira";
    }
    else if (type === "sponsor_company") {
      return "Empresa Patrocinadora";
    }
    else if (type === "guest") {
      return "Convidado";
    }
    else if (type === "director") {
      return "Diretoria";
    }
    else if (type === "liberal_professional") {
      return "Profissional Liberal";
    }
    else if (type === "student") {
      return "Estudante";
    }
    else if (type === "service_provider") {
      return "Prestador De Serviço";
    }
    else if (type === "standard") {
      return "Padrão";
    }
    else if (type === "default") {
      return "Padrão";
    }
    else {
      return type;
    }
  };

  function handleCheckAllItems(isChecked: boolean) {
		const aux: string[] = [];
    const useraux: string[] = [];
    setAllSelected(isChecked);
		if (!isChecked) {
			setSelectedItems([]);
      setSelectedUsers([]);
      setCurrentAttId("");
		} else {
      registrationsArr.map(registration => {
        aux.push(registration._id);
      })
			setSelectedItems(aux);

      registrationsArr.map(registration => {
        useraux.push(registration.user._id);
      })
      setSelectedUsers(useraux);
		}
	}

  function updateSelectedItemsArray(itemId: string, userId: string, checked: Boolean) {
		var array: string[] = selectedItems;
    var userArray: string[] = selectedUsers;

		if (checked) {
			const exists = array.find(
				(i) => i === itemId
			);

			if (!exists) {
				array.push(itemId);
        userArray.push(userId);
			}
		} else {
			array = array.filter((i) => {
				return i !== itemId;
			});

      userArray = userArray.filter((i) => {
				return i !== userId;
			});
		}

    if (checked)
      setCurrentAttId(itemId);
    else {
      setCurrentAttId("0");
    }
    
		setSelectedItems(array);
    setSelectedUsers(userArray);
	}

  return (
    <>
      <LoadingModal open={isFetching}>
        <S.LoadingStatusContainer>
          Atualizando participantes...
        </S.LoadingStatusContainer>
      </LoadingModal>
      {selectedItems && selectedItems.length > 0 ? (
        <>
          {!userStore.isCompanyUser() && (
            <GenericFAB
              disabled={!currentEvent.active || !isBetweenStartAndEndDate}
              onClick={() => {
                confirmPaymentInBatch(true);
                handleCheckAllItems(false);
              }}
              variant="extended"
              color="primary"
            >
              Confirmar Pagamentos
            </GenericFAB>
          )}
          {!userStore.isCompanyUser() && (
            <GenericFAB
              disabled={!currentEvent.active || !isBetweenStartAndEndDate}
              onClick={() => {
                setOpenEdit(true);
              }}
              variant="extended"
              color="primary"
              style={{right: "255px", backgroundColor: "#3f51b5"}}
            >
              Editar Tipos
            </GenericFAB>
          )}
          <Generic2FAB
            disabled={!currentEvent.active || !isBetweenStartAndEndDate}
            onClick={() => {
              handleUnlinkUserFromEventInBatch();
              handleCheckAllItems(false);
            }}
            variant="extended"
            color="primary"
            style={{right: "400px"}}
          >
            Desvincular
          </Generic2FAB>
        </>
      ) : (
        <>
          <Generic2FAB
            disabled={!currentEvent.active || !isBetweenStartAndEndDate}
            onClick={() => {
              history.push(`/attendees/form/event/${eventId}`)
            }}
            variant="extended"
            color="primary"
          >
            <Add /> Novo Cadastro
          </Generic2FAB>
          <GenericFAB
            disabled={!currentEvent.active || !isBetweenStartAndEndDate}
            onClick={() => history.push(`/attendees/${eventId}`)}
            variant="extended"
            color="primary"
          >
            <Add /> Vincular participantes
          </GenericFAB>
        </>
      )}
      {currentEvent.registrations.length > 0 ? (
        <TableContainer component={Paper}>
          <GenericTableToolbar>
            <Typography variant="subtitle1" component="div">
              Inscritos ({currentEvent.registrations.length}) {selectedItems && selectedItems.length > 0 ? " - Selecionado(s): " + selectedItems.length : ""}
            </Typography>
            <div style={{ display: "flex" }}>
              <Formik
                initialValues={{
                  paymentOptions: "all",
                  companyOptions: "all"
                }}
                onSubmit={() => {}}
                enableReinitialize
              >
                {({ handleChange, values, errors }) => (
                  <>
                  <FormikSelectField
                    onChange={(e: any) => {
                      setCompanyFilter(e.target.value);
                      console.log(e.target.value)
                    }}
                    name="companyOptions"
                    label="Empresas"
                    options={companies}
                    style={{marginRight: "20px"}}
                  />
                  <FormikSelectField
                    onChange={(e: any) => {
                      setPaymentFilter(e.target.value);
                    }}
                    name="paymentOptions"
                    label="Tipos de Pagamentos"
                    options={[
                      { label: "Todos os registrados", value: "all" },
                      { label: "Pagamentos confirmados", value: "confirmed_payments" },
                      { label: "Pagamentos não confirmados", value: "not_confirmed_payments" }
                    ]}
                    style={{marginRight: "20px"}}
                  />
                  </>
                )}
              </Formik>
              <Input
                placeholder="Filtro"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    {filter.length > 0 ? (
                      <IconButton
                        aria-label="clear field"
                        onClick={() => setFilter("")}
                      >
                        <ClearIcon />
                      </IconButton>
                    ) : (
                      <SearchIcon />
                    )}
                  </InputAdornment>
                }
              />
          </div>
          </GenericTableToolbar>
          <Table>
            <GenericTableHead>
              <GenericTableRow>
                <TableCell>
                  <Checkbox
                    edge="start"
                    onChange={(e, checked) => {
                      handleCheckAllItems(checked);
                    }}
                    tabIndex={-1}
                    disableRipple
                  />
                </TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Presença</TableCell>
                <TableCell>Empresa</TableCell>
                <TableCell>Cargo</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Contato</TableCell>
                <TableCell></TableCell>
              </GenericTableRow>
            </GenericTableHead>
            <TableBody>
              {paginate({
                array: registrationsArr,
                page_number: currentPage,
                page_size: 25,
              }).map((registration) => (
                <GenericTableRow key={registration._id}>
                  <TableCell component="th" scope="row">
                    <Checkbox
                      edge="start"
                      checked={selectedItems.indexOf(registration._id) !== -1}
                      onChange={(e, checked) => {
                        updateSelectedItemsArray(registration._id, registration.user._id, checked);
                      }}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": registration._id,
                      }}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row" onClick={() => {
                    history.push(`/attendees/form/${registration.user._id}`);
                  }}>
                    {registration.user.first_name}{" "}
                    {registration.user.last_name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {registration.paid ? (registration.attended ? "Confirmada" : "Não confirmada") : "Aguardando pagamento"}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {registration.user.business ? registration.user.business.name : registration.user.business_title + "*"}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {registration.user.job_title}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {getParticipantType(registration.profile_type)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {`(${registration.user.phone.area_code}) `}
                    {registration.user.phone.number}
                    <WhatsAppIcon onClick={() => {
                      let url = `https://wa.me/${
                        registration.user.phone.area_code +
                          (registration.user.phone.number.split(" ").length > 1 ? registration.user.phone.number.split(" ")[1].replace("-", "") : registration.user.phone.number.replace("-", ""))
                      }`;
                      window.open(url, '_blank', 'noopener,noreferrer');
                    }} style={{marginLeft: "3px"}} />
                  </TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    {!userStore.isCompanyUser() && registration.paid && !registration.attended && (
                      <Chip
                        label="Reverter Pagamento"
                        clickable
                        style={{ margin: "5px" }}
                        color="secondary"
                        onClick={() => confirmPayment(registration.user._id, false)}
                      />
                    )}
                    <Chip
                      label="Desvincular"
                      clickable
                      style={{ margin: "5px" }}
                      color="secondary"
                      onClick={() =>
                        handleUnlinkUserFromEvent(registration.user._id)
                      }
                    />
                    {!userStore.isCompanyUser() && registration.paid && (
                      <Chip
                        label="Editar Tipo"
                        clickable
                        style={{ margin: "5px" }}
                        color="primary"
                        onClick={() => editStatus(registration.user._id)}
                      />
                    )}
                    {!userStore.isCompanyUser() && !registration.paid && (
                      <Chip
                        label="Confirmar Pagamento"
                        clickable
                        style={{ margin: "5px" }}
                        color="primary"
                        onClick={() => confirmPayment(registration.user._id, true)}
                      />
                    )}
                  </TableCell>
                </GenericTableRow>
              ))}
            </TableBody>
          </Table>
          <S.PaginationContainer>
            <Pagination
              page={currentPage}
              count={getPagesCount({
                itemsCount: registrationsArr.length,
                pageSize: 25,
              })}
              onChange={(event, value) => setCurrentPage(value)}
              color="secondary"
            />
          </S.PaginationContainer>
        </TableContainer>
      ) : (
        <S.NoParticipantsContainer>
          <h3>Nenhum participante por aqui!</h3>
        </S.NoParticipantsContainer>
      )}
      <AddParticipantModal
        open={isModalOpen}
        handleClose={handleCloseModal}
        onSubmit={onSubmitParticipant}
      />
      <ModalEditStatus
        openIt={openEdit}
        setOpen={setOpenEdit}
        attendeeId={currentAttId}
        attendeeIds={selectedUsers}
        onEdit={handleOnEdit}
      />
    </>
  );
};

export default Participants;
