import * as Yup from "yup";

export interface FormValues {
  city: string;
  zipcode: string;
  street: string;
  street_number: number;
  complement: string;
  district: string;
  state: string;
}
export interface fullValues extends FormValues {
  uf: string;
}

export const validationSchema = Yup.object().shape({
  // city: Yup.string().required("cidade é Required"),
  // zipcode: Yup.string().required("cep é requerido"),
  // street: Yup.string().required("rua é Required"),
  // street_number: Yup.string().required("número é Required"),
  // complement: Yup.string().required("complemento é Required"),
  // state: Yup.string().required("estado é Required"),
  // district: Yup.string().required("estado é Required"),
  checkin_tolerance: Yup.number()
    .required("Insira o tempo de tolerância")
    .min(1, "A tolerância precisa ser maior que 0"),
});
