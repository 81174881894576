import React, { useState, useEffect } from "react";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import Lottie from 'lottie-react-web'
import animation from '../../../../../../Assets/Animations/6058-winning-ballot.json'

interface IProps {
  open: boolean;
  handleClose: Function;
  winnerName: string;
}

const WinnerDialog: React.FC<IProps> = ({ handleClose, open, winnerName }) => {

  const [ready, setReady] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setReady(false);
    }, 500);

    if (open) {
      setTimeout(() => {
        setReady(true);
      }, 4000);
    }
  }, [open, winnerName]);

  return (
    <Dialog
      onClose={() => handleClose()}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      {ready ? (
        <>
          <DialogTitle id="simple-dialog-title">Vencedor do sorteio</DialogTitle>
          <DialogContent>
            <DialogContentText>{winnerName} venceu o sorteio!</DialogContentText>
          </DialogContent>
    
          <DialogActions>
            <Button variant="contained" onClick={() => handleClose()}>
              Fechar
            </Button>
          </DialogActions>
        </>
      ) : (
        <Lottie
          options={{
            animationData: animation
          }}
          style={{marginTop: "30px"}}
        />
      )}
    </Dialog>
  );
};

export default WinnerDialog;
