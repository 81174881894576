import * as Yup from "yup";
import { Company } from "Models/Companies";
export interface IOptions {
  value: string;
  label: string;
}

export interface IResponse {
  business: Company;
  __proto__: any;
}

interface CompanyWithProto extends Company {
  __proto__: any;
}

export interface MyValues {
  trade_name: string;
  legal_name: string;
  cnpj: string;
  email: string;
  country_code: string;
  area_code: string;
  phone_number: string;
  representative: string;
  country: string;
  state: string;
  city: string;
  district: string;
  street: string;
  street_number: string;
  zipcode: string;
  complement?: string;
}

export const initialValues: MyValues = {
  trade_name: "", // nome fantasia
  legal_name: "",
  cnpj: "",
  email: "",
  country_code: "55",
  area_code: "",
  phone_number: "",
  representative: "",
  country: "Brasil",
  state: "PA",
  city: "",
  district: "",
  street: "",
  street_number: "0",
  zipcode: "",
  complement: "",
};

export const resetInitialValues = () => {
  console.log("reseting");
};

export const validationSchema = Yup.object().shape({
  trade_name: Yup.string().required("Nome fantasia é obrigatória"),
  legal_name: Yup.string().required("Razão social é obrigatório"),
  cnpj: Yup.string().required("O CNPJ obrigatório"),
  email: Yup.string()
    .email("Por favor, insira um email válido")
    .required("O e-mail é obrigatório"),
  representative: Yup.string().required("O representante é obrigatório"),
  state: Yup.string().required("O UF é obrigatório"),
  city: Yup.string().required("A cidade é obrigatório"),
  district: Yup.string().required("O bairro é obrigatório"),
  street: Yup.string().required("a cidade é obrigatório"),
  street_number: Yup.string().required("O numero é obrigatório"),
  zipcode: Yup.string().required("O CEP é obrigatório"),
  phone_number: Yup.string()
    .required("O número telefônico é obrigatório")
    // .matches(
    //   /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/,
    //   "Insira um número de telefone válido"
    // ),
});

export const validationTypeSchema = Yup.object().shape({
  name: Yup.string().required("Nome é obrigatório"),
  value: Yup.string().required("Chave é obrigatório"),
});
