import React from "react";
import { Link } from "react-router-dom";

import * as S from "./styles";
import List from "@material-ui/core/List";

import ListItemText from "@material-ui/core/ListItemText";

import PersonIcon from "@material-ui/icons/Person";
import CompanyIcon from "@material-ui/icons/Business";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import CertificateIcon from "@material-ui/icons/Settings";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import CasinoIcon from "@material-ui/icons/Casino";
import PagesIcon from "@material-ui/icons/Pages";
import { EventModel } from "Models/Events";
import UploadIcon from "@material-ui/icons/CloudUpload";
import { useStore } from "Store";

interface Props {
  open: boolean;
  setCurrentPage: Function;
  currentPageIndex: number;
  title: string | undefined;
  event: EventModel | undefined;
}

const EventDrawer: React.FC<Props> = ({
  open,
  setCurrentPage,
  currentPageIndex,
  title,
  event
}) => {
  const { userStore } = useStore();

  return (
    <S.EventDrawer variant="persistent" anchor="left" open={open}>
      <S.EventStatus>
        <h6 className="status-label">Você está em:</h6>
        <h5 className="title">{title}</h5>
        <Link to="/" className="return-button">
          retornar para a lista de eventos
        </Link>
      </S.EventStatus>
      <List>
        <S.EventListItem
          active={currentPageIndex === 0}
          onClick={() => setCurrentPage(0)}
          button
        >
          <PersonIcon className="mr-02" />
          <ListItemText primary="Participantes" />
        </S.EventListItem>
        {!userStore.isCompanyUser() && (
          <S.EventListItem
            active={currentPageIndex === 7}
            onClick={() => setCurrentPage(7)}
            button
          >
            <CompanyIcon className="mr-02" />
            <ListItemText primary="Empresas" />
          </S.EventListItem>
        )}
        {!userStore.isCompanyUser() && (
          <S.EventListItem
            active={currentPageIndex === 1}
            onClick={() => setCurrentPage(1)}
            button
          >
            <AssignmentIndIcon className="mr-02" />
            <ListItemText primary="Crachás" />
          </S.EventListItem>
        )}
        {!userStore.isCompanyUser() && (
          <S.EventListItem
            active={currentPageIndex === 2}
            onClick={() => setCurrentPage(2)}
            button
          >
            <ContactMailIcon className="mr-02" />
            <ListItemText primary="Certificados" />
          </S.EventListItem>
        )}
        {!userStore.isCompanyUser() && (
          <S.EventListItem
            active={currentPageIndex === 3}
            onClick={() => setCurrentPage(3)}
            button
          >
            <VerifiedUserIcon className="mr-02" />
            <ListItemText primary="Checkin" />
          </S.EventListItem>
        )}
        {!userStore.isCompanyUser() && (
          <S.EventListItem
            active={currentPageIndex === 4}
            onClick={() => setCurrentPage(4)}
            button
          >
            <CasinoIcon className="mr-02" />
            <ListItemText primary="Sorteios" />
          </S.EventListItem>
        )}
        {!userStore.isCompanyUser() && (
          <>
            {event && event.type === "online" && (
              <S.EventListItem
                active={currentPageIndex === 5}
                onClick={() => setCurrentPage(5)}
                button
              >
                <PagesIcon className="mr-02" />
                <ListItemText primary="Página" />
              </S.EventListItem>
            )}
          </>
        )}
        <S.EventListItem
          active={currentPageIndex === 6}
          onClick={() => setCurrentPage(6)}
          button
        >
          <UploadIcon className="mr-02" />
          <ListItemText primary="Importação" />
        </S.EventListItem>
        {!userStore.isCompanyUser() && (
          <>
            {event && (
              <S.EventListItem
                active={currentPageIndex === 8}
                onClick={() => setCurrentPage(8)}
                button
              >
                <CertificateIcon className="mr-02" />
                <ListItemText primary="Config. Certificado" />
              </S.EventListItem>
            )}
          </>
        )}
      </List>
    </S.EventDrawer>
  );
};

export default EventDrawer;
