import React, { useState, useEffect, useCallback } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import * as S from "./styles";
import { useStore } from "Store";
import { IItems, EventModel, Registration, EventSponsorModel } from "Models/Events";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { IAttendee } from "Models/Attendees";
import PageItem from "./components/badgeItem";
import CustomizedCheckbox from "../Checkin/checkbox";

import { ceil } from "lodash";
import { Button, Container, Typography, Select, MenuItem, TextField } from "@material-ui/core";
import { PrimaryActionButton, SecondaryActionButton } from "Components/Styled/Form";
import { IFormValues, validationSchema } from "./models/indes";
import { ErrorLabel } from "Components/Styled/util";
import { toast } from "react-toastify";
import { FormikAutocomplete } from "Signup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Formik, Form as FormikForm, Field } from "formik";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      width: "100%",
    },
    control: {
      padding: theme.spacing(1),
    },
  })
);

interface RouteParams {
  eventId: string;
}
const ImportParticipants = ({changeEventPage} : {changeEventPage: Function}) => {
  const classes = useStyles();
  const { eventId } = useParams<RouteParams>();
  const [currentEvent, setCurrentEvent] = useState<EventModel>({} as EventModel);
  const { eventsStore, userStore } = useStore();

  const [currentFile, setCurrentFile] = useState<FileList | null>(null);
  const [fileError, setFileError] = useState<boolean>(false);
  const [fileErrorMessage, setFileErrorMessage] = useState("");

  const [initialValues, setInitialValues] = useState<IFormValues>({
    body: "",
  });
  const [companies, setCompanies] = useState([]  as {label: string, value: string}[])
  const [selectedCompany, setSelectedCompany] = useState("" as string)

  const fetchCurrentEvent = useCallback(async () => {
    try {
      const response = await eventsStore.fetchEventById(eventId);
      // @ts-ignore
      setCurrentEvent(response.data.event);
    } catch (error) {
      console.log(error);
    }
  }, [eventId, eventsStore]);

  useEffect(() => {
    fetchCurrentEvent();
  }, []);

  useEffect(() => {
      const opt = [] as {label: string, value: string}[];
      
      if (currentEvent && currentEvent.businesses) {
        currentEvent.businesses.forEach((business) => {
          if (userStore.isCompanyUser() && userStore.currentUser) {
            if (userStore.currentUser.business._id == business.business._id) {
              opt.push({
                label: business.business.trade_name.toUpperCase(),
                value: business.business._id,
              });
            }
          } else {
            opt.push({
              label: business.business.trade_name.toUpperCase(),
              value: business.business._id,
            });
          }
        });

        setCompanies(opt);
      }

  }, [currentEvent, currentEvent.businesses]);

  const onSubmit = async () => {
    try {
      if (selectedCompany === "default") {
        toast.error("Selecione uma empresa para prosseguir.");
        return;
      }

      if (currentFile) {
        try {
          let response = await eventsStore.onSubmitParticipantsCSVs(currentFile[0], eventId, selectedCompany);
          if (response) {
            setFileError(false);
            changeEventPage();
          }
          else {
            setFileError(true);
            setFileErrorMessage("Por favor, selecione um novo arquivo xlsx para prosseguir.");
          }
        } catch (Exception) {
          setFileError(true);
          setFileErrorMessage("O arquivo original foi modificado. Por favor, selecione um novo arquivo xlsx para prosseguir ou recarregue a página.");
        }
      } else {
        setFileError(true);
        setFileErrorMessage("Por favor, escolha um arquivo xlsx para prosseguir.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid container className={classes.root} spacing={1}>
      <Grid item xs={12} sm={12}>
        <Paper className={classes.paper}>
          <S.Container>
            <S.Title>Importação de Participantes</S.Title>
          </S.Container>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
          {({
            setFieldValue,
            setFieldError,
            values,
            handleChange,
            errors,
          }) => (
            <>
              <S.FormContainer>
                <S.FormContent>
                  <FormikForm autoComplete="off">
                    <Container style={{marginTop: "-30px", marginBottom: "20px"}}>
                        <S.PageFormContainer>
                          <S.PageFormContent>
                                <Typography variant="h5" gutterBottom>
                                  Para importação em lote de participantes de um evento, enviar arquivo .xlsx baseado em template pré-definido.
                                </Typography>
                                <Field
                                  name="business_id"
                                  className="form-field"
                                  component={FormikAutocomplete}
                                  variant="outlined"
                                  label="Empresa"
                                  value={selectedCompany}
                                  options={companies}
                                  //@ts-ignore
                                  onChange={(target => {
                                    if (target && target.value) setSelectedCompany(target.value);
                                  })}
                                />
                                {/* <Select
                                  labelId="companyId"
                                  id="companyId"
                                  name="companyId"
                                  value={selectedCompany}
                                  style={{width: "50%"}}
                                  label="Empresa"
                                  onChange={((option: any) => {
                                    setSelectedCompany(option.target.value);
                                  })}
                                >
                                  {companies && companies.map(company => (
                                    <MenuItem value={company.value}>{company.label}</MenuItem>
                                  ))}
                                </Select> */}
                                <p></p>
                                <>
                                  <input
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      if (e.target && e.target.files) setCurrentFile(e.target.files);
                                    }}
                                    type="file"
                                    id="file"
                                    accept=".xlsx"
                                  />
                                  <label htmlFor="file">Escolha um arquivo</label>
                                </>
                                {currentFile && currentFile.length > 0 ? (
                                  <Typography variant="h5" gutterBottom>
                                    {currentFile[0].name}
                                  </Typography>
                                ) : (
                                  <Typography variant="subtitle1" gutterBottom>
                                    Carregue um arquivo XLSX para a lista de participantes. Utilizar o template (<a href={window.location.protocol + "//" + window.location.host + "/templates/template_event_participants.xlsx"} target="_blank">baixar aqui</a>) com formatação em UTF8.
                                  </Typography>
                                )}
                                {fileError && <ErrorLabel>{fileErrorMessage}</ErrorLabel>}
                                <PrimaryActionButton type="submit" onClick={() => onSubmit()} style={{marginBottom: "30px"}}>
                                  Cadastrar
                                </PrimaryActionButton>
                          </S.PageFormContent>
                        </S.PageFormContainer>
                    </Container>
                  </FormikForm>
                </S.FormContent>
              </S.FormContainer>
            </>
          )}
          </Formik>
        </Paper>
      </Grid>
      
    </Grid>
  );
};

export default ImportParticipants;

export const FormContainer = styled.div`
  width: 100%;
  margin: 10px;
`;

export const EventContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ContainerItens = styled.div`
  margin-bottom: 3px;
  width: 100%;
`;
export const EventTitle = styled.div`
  font-size: 17px;
  color: black;
  font-weight: bold;
`;

export const PageTitle = styled.div`
  font-size: 17px;
  color: black;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 20px;
`;

export const Text = styled.div`
  font-weight: bold;
  text-align: justify;
  margin-left: 5px;
`;

export const TextContainer = styled.div`
  /* justify-content: center; */
  display: flex;
  margin-left: 20px;
`;
