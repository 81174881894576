import React, { useState, useEffect } from "react";
import { toJS } from "mobx";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { GenericButton } from "Components/Styled/Form";
import { useStore } from "Store";

import { EventModel } from "Models/Events";
import LoadingModal from "Components/LoadingModal";

import { LoadingStatusContainer } from "Pages/Home/CreateOrEditEvent/components/SecondStep/styles";

interface IProps {
  open: boolean;
  eventId: string;
  handleClose: Function;
}

const DeleteEventDialog: React.FC<IProps> = ({
  open,
  eventId,
  handleClose
}) => {
  const { eventsStore } = useStore();
  const [currentEvent, setCurrentEvent] = useState<
    null | undefined | EventModel
  >(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log(toJS(eventsStore.events.find(event => event._id === eventId)));
    setCurrentEvent(eventsStore.events.find(event => event._id === eventId));
  }, [eventId, eventsStore.events]);
  const handleDelete = async () => {
    setIsLoading(true);
    await eventsStore.onDeleteEvent(eventId);
    setIsLoading(false);

    handleClose();
  };

  return (
    <Dialog
      open={open}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {"Excluir evento?"}
      </DialogTitle>
      <LoadingModal open={isLoading}>
        <LoadingStatusContainer>
          Buscando os dados de endereço...
        </LoadingStatusContainer>
      </LoadingModal>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Você está prestes a excluir <strong>permanentemente</strong> o evento{" "}
          <strong>{currentEvent?.name}</strong>, deseja continuar?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <GenericButton aVariant="secondary" onClick={() => handleDelete()}>
          Excluir
        </GenericButton>
        <GenericButton variant="contained" onClick={() => handleClose()}>
          Cancelar
        </GenericButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteEventDialog;
