import React, { useEffect } from "react";
import { Switch, useLocation, Redirect, Route } from "react-router-dom";

import Home from "Pages/Home";
import Subscriptions from "Pages/Subscriptions";
import CreateOrEditEvent from "Pages/Home/CreateOrEditEvent";
import { useStore } from "Store";
import Companies from "./Companies";
import CompaniesForm from "./Companies/Form";
import CompaniesImportForm from "./ImportCompanies/Form";
import ShowEvent from "./Events/ShowEvent";
import ProtectedRoute from "Components/ProtectedRoute";
import Report from "./Report";
import ListAttendees from "./Attendees/List/index";
import AttendeesForm from "./Attendees/Form";
import AttendeesImportForm from "./Attendees/ImportForm";
import Pdf from "./Report/utils/pdf";
import ErrorPage from "./Error404";
import DrawForm from "./Events/ShowEvent/Draw/Form";
import AttendeeTypes from "./AttendeeTypes";
import AttendeeTypeForm from "./AttendeeTypes/Form";
import Contacts from "./Contacts";
import MyProfile from "./MyProfile/Form";
import ContactForm from "./Contacts/Form";
import RegisterQuestionsForm from "./RegisterQuestions/Form";

const Root = () => {
  const location = useLocation();
  const { eventsStore } = useStore();
  useEffect(() => {
    if (!location.pathname.includes("/events/form")) {
      eventsStore.resetAllSteps();
    }

    //Check if user has permission to access path
    
  }, [location, eventsStore]);
  return (
    <Switch>
      {/* <ProtectedRoute
        exact
        path="/"
        component={() => <Redirect to="/home" />}
      /> */}
      <ProtectedRoute exact path="/" component={Home} />
      <ProtectedRoute exact path="/subscriptions" component={Subscriptions} />
      <ProtectedRoute exact path="/contacts" component={Contacts} />
      <ProtectedRoute exact path="/contacts/form" component={ContactForm} />
      <ProtectedRoute
        exact
        path="/my-profile"
        component={MyProfile}
      />
      <ProtectedRoute exact path="/events/form" component={CreateOrEditEvent} />
      <ProtectedRoute
        exact
        path="/events/form/:eventId?"
        component={CreateOrEditEvent}
      />
      <ProtectedRoute exact path="/events/:eventId" component={ShowEvent} />
      <ProtectedRoute exact path="/empresas/:eventId?" component={Companies} />
      <ProtectedRoute exact path="/tipos-participantes" component={AttendeeTypes} />
      <ProtectedRoute exact path="/tipos-participantes/form" component={AttendeeTypeForm} />
      <ProtectedRoute exact path="/register-questions" component={RegisterQuestionsForm} />
      <ProtectedRoute
        exact
        path="/companies/form/:companyId?"
        component={CompaniesForm}
      />
      <ProtectedRoute
        exact
        path="/companies/import"
        component={CompaniesImportForm}
      />
      <ProtectedRoute exact path="/relatorios" component={Report} />
      <ProtectedRoute exact path="/relatorios/pdf" component={Pdf} />
      // ! não mudar a ordém da das 2 rotas a seguir !
      <ProtectedRoute
        exact
        path="/attendees/form/:attendeeId?"
        component={AttendeesForm}
      />
      <ProtectedRoute
        exact
        path="/attendees/import/form"
        component={AttendeesImportForm}
      />
      <ProtectedRoute
        exact
        path="/attendees/:eventId?"
        component={ListAttendees}
      />
      <ProtectedRoute
        exact
        path="/attendees/form/event/:eventId?"
        component={AttendeesForm}
      />
      <Route exact path="/home/error" component={ErrorPage} />
      <ProtectedRoute
        exact
        path="/events/:eventId/draw/form/:drawId?"
        component={DrawForm}
      />
    </Switch>
  );
};

export default Root;
