import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useStore } from "Store";

// @ts-ignore
import { estados } from "estados-br";

import { MyValues, IOptions, IResponse } from "../models";

import * as S from "./styles";

import logoAdapa from "Assets/Images/adapa.png";

// * Material UI and Form related

import {
  PrimaryActionButton,
  SecondaryActionButton,
} from "Components/Styled/Form";

import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// @ts-ignore
import { FormikTextField } from "formik-material-fields";
// @ts-ignore
import { FormikSelectField } from "formik-material-fields";
import { Container, TextField, Typography } from "@material-ui/core";
import { Formik, Form as FormikForm } from "formik";
import InputMask from "react-input-mask";
import { validationSchema } from "../models";

import { validateCNPJ, validatePhone } from "Utils";
import { toast } from "react-toastify";
import SearchBar from "Components/SearchBar";
import LoadingModal from "Components/LoadingModal";
const StringMask = require("string-mask");

interface RouteParams {
  companyId: string;
}

const CompanyForm = () => {
  const formatter = new StringMask("(00) 00000-0000");
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [ufOptions, setUfOptions] = useState<IOptions[]>([]);
  const { companyId } = useParams<RouteParams>();
  const { companyStore, appStore } = useStore();
  const history = useHistory();
  const [currentUf, setCurrentUf] = useState("PA");
  const [zipcode, setZipcode] = useState("");
  const [zipcodeError, setZipcodeError] = useState(false);
  const [zipcodeErrorMessage, setZipcodeErrorMessage] = useState("");
  const [isFetchingCep, setIsFetchingCep] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<MyValues>({
    trade_name: "",
    legal_name: "",
    cnpj: "",
    email: "",
    country_code: "55",
    area_code: "",
    phone_number: "",
    representative: "",
    country: "Brasil",
    state: "PA",
    city: "",
    district: "",
    street: "",
    street_number: "0",
    zipcode: "",
    complement: "",
    profile_type: "not_associated_company",
    user_limit: 0
  });
  const [currentFile, setCurrentFile] = useState<FileList | null>(null);
  const [company, setCompany] = useState<any>();
  const [typeOptions, setTypeOptions] = useState<IOptions[]>([
    {
      value: "not_associated_company",
      label: "Empresa não associada"
    },
    {
      value: "associated_company",
      label: "Empresa associada"
    }
  ]);

  const updateInitialValues = useCallback(async () => {
    try {
      const response: any = await companyStore.fetchCompanyById(companyId);
      setCompany(response!.business);
      const {
        business: {
          address: {
            city,
            complement,
            country,
            district,
            state,
            street,
            street_number,
            zipcode,
          },
          _id,
          cnpj,
          email,
          legal_name,
          trade_name,
          representative,
          phone,
          profile_type,
          user_limit
        },
      }: //
      // @ts-ignore
      IResponse = response as IResponse;
      setInitialValues((previousValues: MyValues) => ({
        ...previousValues,
        complement: complement && complement.toUpperCase(),
        country,
        district: district && district.toUpperCase(),
        state,
        street: street && street.toUpperCase(),
        street_number,
        zipcode,
        cnpj: getFormattedCNPJ(cnpj),
        email,
        legal_name: legal_name && legal_name.toUpperCase(),
        trade_name: trade_name && trade_name.toUpperCase(),
        representative,
        city: city && city.toUpperCase(),
        phone_number: formatter.apply(
          phone.area_code + phone.number.replace("-", "")
        ),
        profile_type: profile_type,
        user_limit
      }));
      state && setCurrentUf(state);
      zipcode && setZipcode(zipcode);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [companyId, companyStore]);

  const getFormattedCNPJ = (cnpj: string) => {
    if (!cnpj) return "";

    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  }

  useEffect(() => {
    if (companyId) {
      updateInitialValues();
    } else {
      setIsLoading(false);
    }
  }, [updateInitialValues, companyId]);

  const getAddressDataByCep = async (
    zipcode: string,
    setFieldValue: Function,
    setFieldError: Function
  ) => {
    try {
      setIsFetchingCep(true);
      const response = await axios.get(
        `https://viacep.com.br/ws/${zipcode}/json/`
      );
      if (!response.data.erro) {
        setFieldValue("city", response.data.localidade);
        setFieldValue("district", response.data.bairro);
        setFieldValue("street", response.data.logradouro);
        setFieldValue("state", response.data.uf);
        setCurrentUf(response.data.uf);
        setFieldError("zipcode", null);
        setZipcodeError(false);
        setZipcodeErrorMessage("");
      } else {
        setZipcodeError(true);
        setZipcodeErrorMessage("CEP não encontrado");
        setFieldError("zipcode", "CEP não encontrado");
        setFieldValue("district", "");
        setFieldValue("city", "");
        setFieldValue("street", "");
        setCurrentUf("PA");
      }
      setIsFetchingCep(false);
    } catch (error) {
      setFieldValue("city", "");
      setFieldValue("street", "");
      setCurrentUf("PA");
      setIsFetchingCep(false);
    }
  };

  const handleCepChange = (
    value: string,
    setFieldValue: Function,
    setFieldError: Function
  ) => {
    try {
      if (value.charAt(8) !== "_") {
        const formattedCep = value.replace("-", "");
        getAddressDataByCep(formattedCep, setFieldValue, setFieldError);
      }
      setFieldValue("zipcode", value);
      setZipcode(value);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (values: MyValues) => {
    setIsLoading(true);
    try {
      if (values.phone_number) {
        var valid = validatePhone(values.phone_number);
        if (!valid) {
          toast.error("Telefone inválido, altere e tente novamente.");
          return;
        }
      }
      
      const response = await companyStore.onSubmitCompany(
        { ...values, state: currentUf },
        Boolean(companyId),
        companyId
      );

      if (currentFile && response?.business) {
        await companyStore.updateProfileImage(response.business._id, currentFile[0])
      }

      history.replace("/empresas");
    } catch (error) {}
    setIsLoading(false);
  };

  // useEffect(() => {
  //   setIsLoading(companyStore.isSubmittingCompany)
  //   console.log(companyStore.isSubmittingCompany)
  // }, [companyStore]);

  return isLoading && false ? (
    <LoadingModal open={isLoading}>
      <S.LoadingStatusContainer>
        Carregando...
      </S.LoadingStatusContainer>
    </LoadingModal>
  ) : (
    <>
      <SearchBar title={"Cadastro de Empresa"} />
      <Container>
        <LoadingModal open={companyStore.isSubmittingCompany || isLoading}>
          <S.LoadingStatusContainer>
            Carregando...
          </S.LoadingStatusContainer>
        </LoadingModal>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ handleChange, values, setFieldValue, setFieldError, errors }) => (
            <>
              <S.FormContainer>
                <S.FormContent>
                  <FormikForm autoComplete="off">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="trade_name"
                          label="Nome Fantasia"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="legal_name"
                          label="Razão Social"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {/* <InputMask
                          mask="99.999.999/9999-99"
                          name="cnpj"
                          value={values.cnpj}
                          onChange={handleChange}
                        >
                          {() => ( */}
                            <FormikTextField
                              name="cnpj"
                              label="CNPJ"
                              fullWidth
                              variant="outlined"
                              // validate={validateCNPJ}
                            />
                          {/* )}
                        </InputMask> */}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="email"
                          label="E-mail"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="phone_number"
                          label="Telefone"
                          fullWidth
                          variant="outlined"
                        />
                        {/* <InputMask
                          mask=""
                          name="phone_number"
                          value={values.phone_number}
                          onChange={handleChange}
                        >
                          {() => (
                            <FormikTextField
                              name="phone_number"
                              label="Telefone"
                              fullWidth
                              variant="outlined"
                              validate={validatePhone}
                            />
                          )}
                        </InputMask> */}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="representative"
                          label="Nome do representante da empresa"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputMask
                          mask="99999-999"
                          name="zipcode"
                          value={zipcode}
                          onChange={(e) =>
                            handleCepChange(
                              e.target.value,
                              setFieldValue,
                              setFieldError
                            )
                          }
                        >
                          {() => (
                            <TextField
                              fullWidth
                              label="CEP"
                              variant="outlined"
                              name="zipcode"
                              helperText={errors.zipcode || zipcodeErrorMessage}
                              error={Boolean(errors.zipcode) || zipcodeError}
                            />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="city"
                          label="Cidade"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikSelectField
                          style={{ width: "100%" }}
                          className="form-field"
                          name="state"
                          label="UF"
                          options={estados.map((estado: any) => ({
                            label: estado.sigla,
                            value: estado.sigla,
                          }))}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          fullWidth
                          name="street"
                          label="Rua"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          fullWidth
                          name="street_number"
                          label="Número"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="district"
                          label="Bairro"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="complement"
                          label="Complemento"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikSelectField
                          style={{ width: "100%" }}
                          className="form-field"
                          name="profile_type"
                          label="Tipo"
                          options={typeOptions}
                          variant="outlined"
                        />
                      </Grid>
                      {/* <Grid item xs={12} sm={12}>
                        <FormikTextField
                          name="user_limit"
                          label="Limite de Participantes"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid> */}
                      <Grid item xs={12} sm={12}>
                        <input
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setCurrentFile(e.target.files)
                          }}
                          type="file"
                          id="file"
                          accept="image/*"
                        />
                        <label htmlFor="file" style={{background: "#00577b"}}>Anexar Imagem de Perfil</label>
                        {currentFile && (
                          <Typography variant="h6" gutterBottom>
                            Arquivo: {currentFile[0].name}
                          </Typography>
                        )}
                      </Grid>
                      {companyId && company && company.profile_image_urls && company?.profile_image_urls.x256 && (
                        <Grid item xs={12} sm={12}>
                          <img
                            src={appStore.currentBaseURL + "/" + company?.profile_image_urls?.x256}
                            alt="cover"
                            height="100"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src=logoAdapa;
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                    <S.ButtonRow>
                      {companyId && (
                        <SecondaryActionButton
                          onClick={() => history.push("/attendees?filter=" + company.trade_name)}
                          disabled={!!companyStore.isSubmittingCompany}
                        >
                          Usuários
                        </SecondaryActionButton>
                      )}
                      <SecondaryActionButton
                        onClick={() => history.replace("/empresas")}
                        disabled={!!companyStore.isSubmittingCompany}
                      >
                        Cancelar
                      </SecondaryActionButton>
                      <PrimaryActionButton type="submit"
                        disabled={!!companyStore.isSubmittingCompany}
                        >
                        {companyId ? "Atualizar" : "Cadastrar"}
                      </PrimaryActionButton>
                    </S.ButtonRow>
                  </FormikForm>
                </S.FormContent>
              </S.FormContainer>
            </>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default CompanyForm;
