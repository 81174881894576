import styled from "styled-components";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        margin: "10px 0",
      },
      "& .MuiTypography-root": {
        color: "#8D8D8D",
      },
    },
  })
);

export const FilePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonRowWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  // background: #fff;
  padding: 20px;

  button {
    margin: 0 5px;
  }
`;

export const FormContainer = styled.div`
  margin-bottom: 76px;
`;
