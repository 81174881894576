import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { GenericButton } from "Components/Styled/Form";
import { useStore } from "Store";

import { Company } from "Models/Companies";

interface IProps {
  open: boolean;
  companyId: string | null;
  handleClose: Function;
}

const DeleteCompanyDialog: React.FC<IProps> = ({
  open,
  companyId,
  handleClose
}) => {
  const { companyStore } = useStore();
  const [currentCompany, setCurrentCompany] = useState<
    null | undefined | Company
  >(null);
  useEffect(() => {
    if (companyId) {
      setCurrentCompany(
        companyStore.companies.find(company => company._id === companyId)
      );
    }
  }, [companyId, companyStore.companies]);
  const handleDelete = async () => {
    if (companyId) {
      companyStore.onDeleteCompany(companyId);
    }
    handleClose();
  };
  return (
    <Dialog
      open={open}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {"Excluir empresa?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Você está prestes a excluir <strong>permanentemente</strong> a empresa
          de razão social <strong>{currentCompany?.legal_name}</strong>, deseja
          continuar?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <GenericButton aVariant="secondary" onClick={() => handleDelete()}>
          Excluir
        </GenericButton>
        <GenericButton variant="contained" onClick={() => handleClose()}>
          Cancelar
        </GenericButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCompanyDialog;
