export const validatePhone = (value: any) => {
  // let r = /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/;
  // return !r.test(value);
  var phone = value.replace(/\D/g,'');
  return phone.length >= 9 && phone.length <= 11;
};

export const validateCNPJ = (value: any) => {
  let r = /[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2}/;
  return !r.test(value);
};

export const userHasPermission = (role: string | undefined, page: string) => {
  if (role === "admin" || role === "super_admin") {
    if (page !== "my-profile" && page !== "contacts")
      return true;
    else
      return false;
  }
  //Participants
  else if (page === "eventos-inscritos" || page === "my-profile" || page === "contacts") {
    return true;
  }
  else if (role === "company_admin") {
    if (page === "participantes" || page === "eventos")
      return true;
  }

  return false;
};