import * as Yup from "yup";

import { IAttendee } from "Models/Attendees";

export interface IFormValues {
  first_name: string;
  last_name: string;
  gender: string;
  cpf: string;
  business_id: string;
  job_title: string;
  country_code: string;
  area_code: string;
  phone_number: string;
  email: string;
  state?: string;
  city?: string;
  district?: string;
  street?: string;
  zipcode?: string;
  complement?: string;
}

export interface IFormTypeValues {
  name: string;
  value: string;
}

export const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("Nome é obrigatório"),
  last_name: Yup.string().required("Sobrenome é obrigatório"),
  gender: Yup.string().required("Gênero é obrigatório"),
  cpf: Yup.string().required("CPF é obrigatório"),
  business_id: Yup.string().required("Empresa é obrigatório"),
  job_title: Yup.string().required("Cargo é obrigatório"),
  country_code: Yup.string().required("DDI é obrigatório"),
  area_code: Yup.string().required("DDD é obrigatório"),
  phone_number: Yup.string().required("Telefone é obrigatório"),
  email: Yup.string()
    .email("Insira um email no formato fulano@email.com")
    .required("E-mail é obrigatório"),
});

export interface IOptions {
  value: string;
  label: string;
}
export const genderOptions: IOptions[] = [
  { label: "Feminino", value: "female" },
  { label: "Masculino", value: "male" },
  { label: "Não quero declarar", value: "undefined" },
];

export interface IResponse {
  user: IAttendee;
  __proto__: any;
}
