// * Packages
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { useStore } from "Store";
// * Models
import { validationSchema } from "./model";

// * Components
import LoadingModal from "Components/LoadingModal";

// * Form related
import { Formik, Form } from "formik";
// @ts-ignore
import { FormikTextField } from "formik-material-fields";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "styled-components";
import InputMask from "react-input-mask";
// @ts-ignore
import { estados } from "estados-br";
import * as S from "../../styles";
import { ErrorLabel } from "Components/Styled/util";
import { GenericButton } from "Components/Styled/Form";
import TextField from "@material-ui/core/TextField";

import "moment/locale/pt";
import { DateTimePicker } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import "./styles/index.css";
import { LoadingStatusContainer } from "./styles";
import { SecondStepType, EventModel } from "Models/Events";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        margin: "10px 0",
        // margin: theme.spacing(1),
      },
      "& .MuiTypography-root": {
        color: "#8D8D8D",
      },
    },
    button: {
      marginRight: theme.spacing(1),
    },
    formControl: {
      margin: "10px 0",
      minWidth: "100%",
    },
  })
);
type IInitialState = {
  startDate: Date | null;
  endDate: Date | null;
  focusedInput: any;
};

interface Props {
  activeStep: number;
  handleNext: Function;
  handleBack: Function;
  currentEvent?: EventModel;
}
const SecondStep: React.FC<Props> = ({
  activeStep,
  handleBack,
  handleNext,
  currentEvent,
}) => {
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth] = React.useState(0);
  const formRef = useRef();
  const classes = useStyles();
  const { eventsStore } = useStore();
  const [dateError, setDateError] = useState<boolean>(false);
  const [dateErrorMessage, setDateErrorMessage] = useState<string>("");
  const [currentUf, setCurrentUf] = useState("PA");
  // useEffect(() => {
  //   setLabelWidth(inputLabel.current!.offsetWidth);
  // }, []);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [registration_start, setregistration_start] = useState(new Date());
  const [registration_end, setregistration_end] = useState(new Date());
  useEffect(() => {
    moment.locale("pt");
    if (currentEvent?.address) {
      setZipcode(currentEvent.address.zipcode);
    }
    if (currentEvent?.starts_at) {
      setStartDate(currentEvent?.starts_at);
    }
    if (currentEvent?.ends_at) {
      setEndDate(currentEvent?.ends_at);
    }
    if (currentEvent?.registration_start) {
      setregistration_start(currentEvent?.registration_start);
    }
    if (currentEvent?.registration_end) {
      setregistration_end(currentEvent?.registration_end);
    }
    if (currentEvent?.address) {
      setCurrentUf(currentEvent.address.state);
    }
  }, [currentEvent]);
  const [zipcode, setZipcode] = useState("");
  const [zipcodeError, setZipcodeError] = useState(false);
  const [zipcodeErrorMessage, setZipcodeErrorMessage] = useState("");
  const [isFetchingCep, setIsFetchingCep] = useState(false);

  useEffect(() => {
    if (moment(endDate).isBefore(startDate)) {
      setDateError(true);
      setDateErrorMessage("A data inicial não pode ser após a final");
    } else {
      setDateError(false);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    console.log(formRef.current);
    // @ts-ignore
    console.log(formRef.current?.values);
  }, [formRef]);

  const handlePersist = (values: SecondStepType) => {
    console.log(currentUf);
    eventsStore.persistSecondStep({
      country: "Brasil",
      state: currentUf,
      zipcode: values.zipcode,
      city: values.city,
      street_number: values.street_number,
      street: values.street,
      complement: values.complement,
      district: values.district,
      starts_at: startDate,
      ends_at: endDate,
      registration_start,
      registration_end,
      checkin_tolerance: values.checkin_tolerance,
    });
  };
  const onHandleBack = (values: SecondStepType) => {
    handlePersist(values);
    handleBack();
  };

  const initialValues: SecondStepType = {
    country: "Brasil",
    state: eventsStore.currentSecondStep.state || currentUf,
    city: eventsStore.currentSecondStep.city || "",
    district: eventsStore.currentSecondStep.district || "",
    street: eventsStore.currentSecondStep.street || "",
    street_number: eventsStore.currentSecondStep.street_number || "1",
    complement: eventsStore.currentSecondStep.complement || "",
    zipcode: eventsStore.currentSecondStep.zipcode || "",
    starts_at: eventsStore.currentSecondStep.starts_at || Date.now(),
    ends_at: eventsStore.currentSecondStep.ends_at || Date.now(),
    registration_start:
      eventsStore.currentSecondStep.registration_start || Date.now(),
    registration_end:
      eventsStore.currentSecondStep.registration_end || Date.now(),
    checkin_tolerance: eventsStore.currentSecondStep.checkin_tolerance || 1,
  };

  const onSubmit = (values: SecondStepType) => {
    if (startDate && endDate) {
      setDateError(false);
      handlePersist(values);
      handleNext();
    } else {
      setDateError(true);
    }
  };

  const handleValidationAndSubmition = async (
    validateForm: Function,
    setTouched: Function,
    values: SecondStepType
  ) => {
    const res = await validateForm();
    setTouched(res);
    if (moment(endDate).isBefore(startDate)) {
      setDateError(true);
      setDateErrorMessage("A data inicial não pode ser após a final");
    } else {
      setDateError(false);
      if (!Object.keys(res).length) {
        onSubmit(values);
      }
    }
  };
  const handleCepChange = (
    value: string,
    setFieldValue: Function,
    setFieldError: Function
  ) => {
    try {
      if (value.charAt(8) !== "_") {
        const formattedCep = value.replace("-", "");
        getAddressDataByCep(formattedCep, setFieldValue, setFieldError);
      }
      setFieldValue("zipcode", value);
      setZipcode(value);
    } catch (error) {
      console.log("teste");
    }
  };

  const getAddressDataByCep = async (
    zipcode: string,
    setFieldValue: Function,
    setFieldError: Function
  ) => {
    try {
      setIsFetchingCep(true);
      const response = await axios.get(
        `https://viacep.com.br/ws/${zipcode}/json/`
      );
      console.log(response.data);
      if (!response.data.erro) {
        setFieldValue("city", response.data.localidade);
        setFieldValue("district", response.data.bairro);
        setFieldValue("street", response.data.logradouro);
        setCurrentUf(response.data.uf);
        setFieldError("zipcode", null);
        setZipcodeError(false);
        setZipcodeErrorMessage("");
      } else {
        setZipcodeError(true);
        setZipcodeErrorMessage("CEP não encontrado");
        setFieldError("zipcode", "CEP não encontrado");
        setFieldValue("district", "");
        setFieldValue("city", "");
        setFieldValue("street", "");
        setCurrentUf("PA");
      }
      setIsFetchingCep(false);
    } catch (error) {
      console.log("erro!");
      setFieldValue("city", "");
      setFieldValue("street", "");
      setCurrentUf("PA");
      setIsFetchingCep(false);
      console.log(error);
    }
  };

  if (activeStep !== 1) {
    return null;
  }
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <LoadingModal open={isFetchingCep}>
        <LoadingStatusContainer>
          Buscando os dados de endereço...
        </LoadingStatusContainer>
      </LoadingModal>
      <Container className={classes.root} maxWidth="sm">
        <Formik
          // @ts-ignore
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnBlur={true}
          validationSchema={validationSchema}
        >
          {({
            handleChange,
            values,
            setTouched,
            validateForm,
            setFieldValue,
            errors,
            setFieldError,
            touched,
          }) => (
            <>
              <Form>
                <S.FormContainer>
                  <InputMask
                    mask="99999-999"
                    name="zipcode"
                    value={zipcode}
                    onChange={(e) =>
                      handleCepChange(
                        e.target.value,
                        setFieldValue,
                        setFieldError
                      )
                    }
                  >
                    {() => (
                      <TextField
                        fullWidth
                        label="CEP"
                        variant="outlined"
                        name="zipcode"
                        helperText={errors.zipcode || zipcodeErrorMessage}
                        // @ts-ignore
                        error={Boolean(errors.zipcode) || zipcodeError}
                      />
                    )}
                  </InputMask>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={10}>
                      <FormikTextField
                        fullWidth
                        label="Cidade"
                        variant="outlined"
                        name="city"
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel
                          ref={inputLabel}
                          id="demo-simple-select-outlined-label"
                        >
                          UF
                        </InputLabel>
                        <Select
                          name="state"
                          label="UF"
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={currentUf}
                          onChange={(e: any) => setCurrentUf(e.target.value)}
                          labelWidth={labelWidth}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {/* 
                        // @ts-ignore                       */}
                          {estados.map((estado) => (
                            <MenuItem key={estado.sigla} value={estado.sigla}>
                              {estado.sigla}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormikTextField
                        fullWidth
                        name="district"
                        label="Bairro"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={10}>
                      <FormikTextField
                        fullWidth
                        name="street"
                        label="Rua"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <FormikTextField
                        fullWidth
                        name="street_number"
                        label="Número"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <FormikTextField
                    fullWidth
                    name="complement"
                    label="Complemento"
                    variant="outlined"
                  />
                  <ThemeProvider
                    theme={{
                      reactDatepicker: {
                        datepickerZIndex: 9999,
                      },
                    }}
                  >
                    <>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                          Período do Evento:
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {/* 
                        // @ts-ignore                       */}
                          <DateTimePicker
                            className="fullwidth-field"
                            label="Data inicial"
                            inputVariant="outlined"
                            format="DD/MM/YYYY HH:mm"
                            ampm={false}
                            // disablePast
                            value={startDate}
                            // @ts-ignore
                            onChange={setStartDate}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {/* 
                        // @ts-ignore                       */}
                          <DateTimePicker
                            className="fullwidth-field"
                            label="Data final"
                            inputVariant="outlined"
                            format="DD/MM/YYYY HH:mm"
                            ampm={false}
                            // disablePast
                            value={endDate}
                            // @ts-ignore
                            onChange={setEndDate}
                          />
                        </Grid>
                      </Grid>
                      {dateError && (
                        <ErrorLabel>
                          <span>{dateErrorMessage}</span>
                        </ErrorLabel>
                      )}
                    </>
                  </ThemeProvider>
                  <ThemeProvider
                    theme={{
                      reactDatepicker: {
                        datepickerZIndex: 9999,
                      },
                    }}
                  >
                    <>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                          Período de Inscrição:
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {/* 
                        // @ts-ignore                       */}
                          <DateTimePicker
                            className="fullwidth-field"
                            label="Inicio cadastro"
                            inputVariant="outlined"
                            format="DD/MM/YYYY HH:mm"
                            ampm={false}
                            // disablePast
                            value={registration_start}
                            // @ts-ignore
                            onChange={setregistration_start}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {/* 
                        // @ts-ignore                       */}
                          <DateTimePicker
                            className="fullwidth-field"
                            label="Fim cadastro"
                            inputVariant="outlined"
                            format="DD/MM/YYYY HH:mm"
                            ampm={false}
                            // disablePast
                            value={registration_end}
                            // @ts-ignore
                            onChange={setregistration_end}
                          />
                        </Grid>
                      </Grid>
                      {dateError && (
                        <ErrorLabel>
                          <span>{dateErrorMessage}</span>
                        </ErrorLabel>
                      )}
                    </>
                  </ThemeProvider>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormikTextField
                        fullWidth
                        type="number"
                        name="checkin_tolerance"
                        label="Tempo de tolerância de checkin em minutos"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </S.FormContainer>
                <S.ButtonRowWrapper>
                  <GenericButton
                    aVariant="secondary"
                    onClick={() => onHandleBack(values)}
                  >
                    Voltar
                  </GenericButton>
                  <GenericButton
                    onClick={() =>
                      handleValidationAndSubmition(
                        validateForm,
                        setTouched,
                        values
                      )
                    }
                    // type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Próxima etapa
                  </GenericButton>
                </S.ButtonRowWrapper>
              </Form>
            </>
          )}
        </Formik>
      </Container>
    </MuiPickersUtilsProvider>
  );
};
interface ShowDateProps {
  value?: string;
  onClick?: Function;
}

export default SecondStep;
