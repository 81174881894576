import React, { useEffect, useState, useCallback } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useStore } from "Store";
import { useObserver } from "mobx-react-lite";
import IconButton from "@material-ui/core/IconButton";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUserOutlined";

// @ts-ignore
import { useDebounce } from "use-debounce";

import AttendeeContextMenu from "./components/AttendeeContextMenu";
import DeleteAttendeeDialog from "./components/DeleteAttendeeDialog";

import { NoDataContainer } from "Components/Styled/util";
import SearchBar from "Components/SearchBar";

// Custom Styles
import * as S from "./styles";
import { CustomCircularProgress } from "Components/Styled/Loading";
import LoadingModal from "Components/LoadingModal";

import { ListBaseContainer } from "Components/Styled/List";
import { PaginationContainer, GenericLeftFAB, GenericLeft2FAB } from "Components/Styled/table";

// * Material Ui

import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
  Checkbox,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { GenericFAB, GenericTableHead } from "Components/Styled/table";
import Chip from "@material-ui/core/Chip";
import { MoreVert, Add } from "@material-ui/icons";
import { IAttendee } from "Models/Attendees";
import AdminDialog from "./components/AdminDialog";
import CompanyAdminDialog from "./components/CompanyAdminDialog";
import { toast } from "react-toastify";
import { EventModel } from "Models/Events";
const StringMask = require("string-mask");
interface RouteParams {
  eventId: string;
}

const ListAttendees: React.FC = () => {
  const formatter = new StringMask("(00) 00000-0000");
  const { eventId } = useParams<RouteParams>();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isAdminDialogOpen, setIsAdminDialogOpen] = useState(false);
  const [isCompanyAdminDialogOpen, setIsCompanyAdminDialogOpen] = useState(false);
  const [isUserAdmin, setIsAdmin] = useState(false);
  const [isUserCompanyAdmin, setIsCompanyAdmin] = useState(false);
  const [currentAttendeeId, setCurrentAttendeeId] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isContextMenuOpen = Boolean(anchorEl);
  const history = useHistory();
  const { attendeesStore, eventsStore, userStore } = useStore();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [currentAttId, setCurrentAttId] = useState("");
  const [allSelected, setAllSelected] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<EventModel | null>();

  const params = new URLSearchParams(location.search);
  let filterParam = params.get("filter");
  const [filter, setFilter] = useState(filterParam ? filterParam : "");
  const [debounced] = useDebounce(filter, 500);

  const fetchEventAttendees = useCallback(async () => {
    try {
      const response = await eventsStore.fetchEventById(eventId);
      const attendees: IAttendee[] = [];
      // @ts-ignore
      response.data.event.registrations.map((registration: any) =>
        attendees.push(registration.user)
      );
      // @ts-ignore
      attendeesStore.setCurrentEventAttendees(attendees);
    } catch (error) {
      console.log(error);
    }
  }, [eventId, attendeesStore, eventsStore]);

  const fetchCurrentEvent = useCallback(async () => {
    try {
      const response = await eventsStore.fetchEventById(eventId);
      // @ts-ignore
      setCurrentEvent(response.data.event);
    } catch (error) {
      console.log(error);
    }
  }, [eventId, eventsStore]);

  useEffect(() => {
    if (eventId) {
      attendeesStore.resetCurrentEventAttendees();
      fetchEventAttendees();
      fetchCurrentEvent();
    } else {
      attendeesStore.resetCurrentEventAttendees();
    }
  }, [eventId, attendeesStore, fetchEventAttendees]);

  useEffect(() => {
    let name = "";

    if (filter) {
      name = filter;
      setFilter(filter);
    }
    
    attendeesStore.fetchAttendees({
      limit: limit,
      page: page + 1,
      name: name,
      eventId: eventId ? eventId : "",
    });
  }, [attendeesStore]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    attendeeId: string
  ) => {
    setCurrentAttendeeId(attendeeId);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setIsAdminDialogOpen(false);
    setIsCompanyAdminDialogOpen(false);
    setAnchorEl(null);
    handleCheckAllItems(false);
  };
  const handleEditAttendee = () => {
    history.push(`/attendees/form/${currentAttendeeId}`);
  };

  const handleAdmin = (isAdmin: boolean) => {
    setIsAdminDialogOpen(true);
    setIsAdmin(isAdmin);
  };

  const handleCompanyAdmin = (isAdmin: boolean) => {
    setIsCompanyAdminDialogOpen(true);
    setIsCompanyAdmin(isAdmin);
  };

  const handleLinkUserToEvent = async (attendeeId?: string, attendeeIds?: string[]) => {
    try {
      setLoading(true);
      if (attendeeId) {
        const response = await attendeesStore.linkAttendeeToEvent({
          user_id: attendeeId,
          eventId,
        });
      } else if (attendeeIds) {
        const response = await attendeesStore.linkAttendeeInBatchToEvent({
          user_ids: attendeeIds,
          eventId,
        });
      }
      fetchEventAttendees();
      history.push(`/events/${eventId}`);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error);
      setLoading(false);
    }
  };
  const handleFilterAttendees = (value: string) => {
    setFilter(value);
  };

  useEffect(() => {
    attendeesStore.fetchAttendees({
      limit: limit,
      page: page + 1,
      name: debounced,
      eventId: eventId ? eventId : "",
    });
  }, [debounced]);

  const isAdmin = (role: string | undefined) => {
    if (role === "admin" || role === "super_admin") {
      return true;
    }

    return false;
  };

  const isCompanyAdmin = (role: string | undefined) => {
    if (role === "company_admin") {
      return true;
    }

    return false;
  };

  function updateSelectedItemsArray(itemId: string, checked: Boolean) {
		var array: string[] = selectedItems;

		if (checked) {
			const exists = array.find(
				(i) => i === itemId
			);

			if (!exists) {
				array.push(itemId);
			}
		} else {
			array = array.filter((i) => {
				return i !== itemId;
			});
		}

    if (checked)
      setCurrentAttId(itemId);
    else {
      setCurrentAttId("0");
    }
    
		setSelectedItems(array);
	}

  function handleCheckAllItems(isChecked: boolean) {
		const aux: string[] = [];
    setAllSelected(isChecked);
		if (!isChecked) {
			setSelectedItems([]);
      setCurrentAttId("");
		} else {
      attendeesStore.users.map(user => {
        aux.push(user._id);
      })
			setSelectedItems(aux);
		}
	}

  return useObserver(() => (
    <>
      <LoadingModal open={attendeesStore.isDelettingAttendee}>
        <S.LoadingStatusContainer>
          Removendo participante...
        </S.LoadingStatusContainer>
      </LoadingModal>
      <LoadingModal open={attendeesStore.isChangingUser || loading}>
        <S.LoadingStatusContainer>
          Atualizando usuários...
        </S.LoadingStatusContainer>
      </LoadingModal>
      <SearchBar title={(userStore.isCompanyUser() ? "Colaboradores" : "Usuários") + ` (${(attendeesStore.usersPagination.attendeesCount)})` + (eventId && currentEvent ? " | Evento: " + currentEvent.name : "")} onFilter={handleFilterAttendees} />
      <ListBaseContainer>
        {attendeesStore.users ? (
          <TableContainer component={Paper}>
            <Table>
              <GenericTableHead>
                <TableRow>
                  <TableCell>
                      <Checkbox
                        edge="start"
                        onChange={(e, checked) => {
                          handleCheckAllItems(checked);
                        }}
                        tabIndex={-1}
                        disableRipple
                      />
                  </TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Último Evento</TableCell>
                  <TableCell>Empresa</TableCell>
                  <TableCell>Cargo</TableCell>
                  <TableCell>Contato</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </GenericTableHead>
              <TableBody>
                {attendeesStore.isFetchingAttendees ? (
                  <TableRow>
                    <td colSpan={4}>
                      <S.LoadingContainer>
                        <CustomCircularProgress />
                      </S.LoadingContainer>
                    </td>
                  </TableRow>
                ) : (
                  attendeesStore.users.map((attendee) => (
                    <TableRow key={attendee._id}>
                      <TableCell component="th" scope="row">
                        <Checkbox
                          edge="start"
                          checked={selectedItems.indexOf(attendee._id) !== -1}
                          onChange={(e, checked) => {
                            updateSelectedItemsArray(attendee._id, checked);
                          }}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            "aria-labelledby": attendee._id,
                          }}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {attendee.first_name} {attendee.last_name} {isAdmin(attendee.roles[0]) ? (<VerifiedUserIcon></VerifiedUserIcon>) : ""}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {
                          attendee.registrations && attendee.registrations.length > 0 ? attendee.registrations[attendee.registrations.length - 1].event.name : "N/D"
                        }
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {attendee.business ? attendee.business.name : (attendee.business_title ? attendee.business_title + "*" : "N/D")} {isCompanyAdmin(attendee.roles[0]) ? (<VerifiedUserIcon></VerifiedUserIcon>) : ""}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {attendee.job_title}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {formatter.apply(
                          attendee.phone.area_code +
                            (attendee.phone.number.split(" ").length > 1 ? attendee.phone.number.split(" ")[1].replace("-", "") : attendee.phone.number.replace("-", ""))
                        )}
                        <WhatsAppIcon onClick={() => {
                          let url = `https://wa.me/${
                            attendee.phone.area_code +
                              (attendee.phone.number.split(" ").length > 1 ? attendee.phone.number.split(" ")[1].replace("-", "") : attendee.phone.number.replace("-", ""))
                          }`;
                          window.open(url, '_blank', 'noopener,noreferrer');
                        }} style={{marginLeft: "3px"}} />
                      </TableCell>
                      <TableCell
                        style={{ textAlign: "right" }}
                        component="th"
                        scope="row"
                      >
                        {eventId ? (
                          !attendeesStore.currentEventAttendeesIds.includes(
                            attendee._id
                          ) ? (
                            <Chip
                              label="Vincular"
                              clickable
                              color="primary"
                              onClick={() =>
                                handleLinkUserToEvent(attendee._id)
                              }
                            />
                          ) : (
                            <Chip label="Já vinculado" disabled />
                          )
                        ) : null}
                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={(e) => { 
                            setIsCompanyAdmin(isCompanyAdmin(attendee.roles[0]));
                            setIsAdmin(isAdmin(attendee.roles[0]));
                            handleClick(e, attendee._id);
                          }}
                        >
                          <MoreVert color="secondary" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
            <PaginationContainer>
              <TablePagination
                component="div"
                page={page}
                count={attendeesStore.usersPagination.pages}
                rowsPerPage={limit}
                labelRowsPerPage="Quantidade"
                nextIconButtonProps={{disabled: page >= attendeesStore.usersPagination.pages - 1}}
                onPageChange={(event, value) => {
                  setPage(value);

                  attendeesStore.fetchAttendees({
                    page: value + 1,
                    limit: limit,
                    name: debounced || "",
                    eventId: eventId ? eventId : "",
                  })
                }}
                onChangeRowsPerPage={(event) => {
                  setLimit(+event.target.value);
                  
                  attendeesStore.fetchAttendees({
                    page: page + 1,
                    limit: +event.target.value,
                    name: debounced || "",
                    eventId: eventId ? eventId : "",
                  })
                }}
                color="secondary"
              />
            </PaginationContainer>
          </TableContainer>
        ) : (
          <LoadingModal open={true}>
        <S.LoadingStatusContainer>
          Buscando participantes...
        </S.LoadingStatusContainer>
      </LoadingModal>
        )}
      </ListBaseContainer>
      {/* <GenericLeftFAB
        variant="extended"
        color="primary"
        onClick={() => history.push("/attendees/import/form")}
      >
        <Add /> Importar lista de participantes
      </GenericLeftFAB> */}
      {selectedItems && selectedItems.length > 0 ? (
        <>
          {eventId ? (
            <GenericLeftFAB
              variant="extended"
              color="primary"
              onClick={() => {
                handleLinkUserToEvent(undefined, selectedItems);
              }}
            >
              Vincular Usuário(s)
            </GenericLeftFAB>
          ) : (
            <GenericLeft2FAB
              variant="extended"
              color="secondary"
              onClick={() => {
                setDeleteDialogOpen(true);
              }}
            >
              Excluir Usuário(s)
            </GenericLeft2FAB>
          )}
        </>
      ) : (
        <GenericLeftFAB
          variant="extended"
          color="primary"
          onClick={() => history.push("/attendees/form")}
        >
          <Add /> Novo Usuário
        </GenericLeftFAB>
      )}
      <AttendeeContextMenu
        open={isContextMenuOpen}
        isAdmin={isUserAdmin}
        isCompanyAdmin={isUserCompanyAdmin}
        handleClose={handleClose}
        handleDelete={setDeleteDialogOpen}
        handleEdit={handleEditAttendee}
        anchorEl={anchorEl}
        handleAdmin={handleAdmin}
        handleCompanyAdmin={handleCompanyAdmin}
      />
      <DeleteAttendeeDialog
        open={isDeleteDialogOpen}
        attendeeId={currentAttendeeId}
        attendeeIds={selectedItems}
        handleClose={handleCloseDeleteDialog}
      />
      <AdminDialog
        open={isAdminDialogOpen}
        attendeeId={currentAttendeeId}
        isAdmin={isUserAdmin}
        handleClose={handleCloseDeleteDialog}
      />
      <CompanyAdminDialog
        open={isCompanyAdminDialogOpen}
        attendeeId={currentAttendeeId}
        isAdmin={isUserCompanyAdmin}
        handleClose={handleCloseDeleteDialog}
      />
    </>
  ));
};

export default ListAttendees;
