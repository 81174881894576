import { IFormValues, IFormTypeValues } from "./../../Pages/Attendees/Form/models/index";
import { IAttendee, IAttendeeType } from "./../../Models/Attendees/index";
import {
  configure,
  observable,
  action,
  runInAction,
  computed,
  toJS,
} from "mobx";
import { Post, Get, Delete, Put } from "Services/Request";
import { IAttendeePagination } from "Models/Utils";
import { toast } from "react-toastify";

configure({ enforceActions: "observed" });

class AttendeesStore {
  @observable
  users: IAttendee[] = [];

  @observable
  attendeeTypes: IAttendeeType[] = [];

  @observable
  userFilter: string = "";

  @observable
  currentEventAttendeesIds: string[] = [];

  @computed
  get attendeesNotInCurrentEvent(): IAttendee[] {
    console.log(toJS(this.currentEventAttendeesIds));
    return this.users
      .filter(
        (attendee) => !this.currentEventAttendeesIds.includes(attendee._id)
      )
      .filter(
        (attendee) =>
          attendee.last_name
            .toLowerCase()
            .includes(this.userFilter.toLowerCase()) ||
          attendee.first_name
            .toLowerCase()
            .includes(this.userFilter.toLowerCase())
      );
  }

  @observable
  isFetchingAttendeeTypes: boolean = false;

  @observable
  isFetchingAttendees: boolean = false;

  @observable
  isFetchingAttendeeData: boolean = false;

  @observable
  isSubmittingAttendee: boolean = false;

  @observable
  isSubmittingAttendeeTyppe: boolean = false;

  @observable
  isDelettingAttendee: boolean = false;

  @observable
  isChangingUser: boolean = false;

  @observable
  usersPagination: IAttendeePagination = {
    attendeesCount: 0,
    page: 1,
    pages: 1,
  };

  @observable
  attendeeTypesPagination: IAttendeePagination = {
    attendeesCount: 0,
    page: 1,
    pages: 1,
  };
  
  @observable
  last_limit: number = 6;

  @action
  setAttendeeFilter = (value: string) => (this.userFilter = value);

  @action
  setAttendeesPagination = (newPagination: IAttendeePagination) => {
    this.usersPagination = newPagination;
  };

  @action
  setAttendeeTypesPagination = (newPagination: IAttendeePagination) => {
    this.attendeeTypesPagination = newPagination;
  };

  @action
  fetchAttendees = async ({
    page = 1,
    limit = 6,
    name = "",
    eventId,
  }: {
    page?: number;
    limit?: number;
    name?: string;
    eventId?: string;
  }) => {
    this.isFetchingAttendees = true;
    this.last_limit = limit;
    try {
      const eventIdFormatted = eventId ? `&event_id=${eventId}` : "";
      const response = await Get(
        `/api/participants?page=${page}&limit=${limit}&q=${name}${eventIdFormatted}`
      );
      runInAction(() => {
        this.setAttendeesPagination({
          // @ts-ignore
          attendeesCount: response.data.data.usersCount,
          // @ts-ignore
          page: response.data.data.page,
          // @ts-ignore
          pages: response.data.data.pages,
        });
        // @ts-ignore
        this.users = response.data.data.users;
        this.isFetchingAttendees = false;
      });
    } catch (error) {
      runInAction(() => {
        this.isFetchingAttendees = false;
      });
      console.log(error);
    }
  };

  @action
  fetchAttendeeTypes = async ({
    page = 1,
    limit = 6
  }: {
    page?: number;
    limit?: number;
  }) => {
    this.isFetchingAttendeeTypes = true;
    this.last_limit = limit;
    try {
      const response = await Get(
        `/api/events/attende-types?page=${page}&limit=${limit}`
      );
      runInAction(() => {
        // this.setAttendeesPagination({
        //   // @ts-ignore
        //   attendeesCount: response.data.data.usersCount,
        //   // @ts-ignore
        //   page: response.data.data.page,
        //   // @ts-ignore
        //   pages: response.data.data.pages,
        // });
        // @ts-ignore
        this.attendeeTypes = response.data.data.attendeetype;
        this.isFetchingAttendeeTypes = false;
      });
    } catch (error) {
      runInAction(() => {
        this.isFetchingAttendeeTypes = false;
      });
      console.log(error);
    }
  };

  @action
  setCurrentEventAttendees = (attendees: IAttendee[]) => {
    attendees.map((attendee) =>
      this.currentEventAttendeesIds.push(attendee._id)
    );
    console.log(toJS(this.currentEventAttendeesIds));
  };

  @action
  resetCurrentEventAttendees = () => {
    this.currentEventAttendeesIds = [];
  };

  @action
  fetchAttendeeById = async (attendeeId: string) => {
    this.isFetchingAttendeeData = true;
    try {
      const response = await Get(`/api/participants/${attendeeId}`);
      return response.data.data;
    } catch (error) {}
  };

  @action
  registerAttendee = async (values: IFormValues) => {
    this.isSubmittingAttendee = true;
    try {
      var phone = values.phone_number.replace(/\D/g,'');

      const response = await Post("/api/participants", {
        ...values,
        area_code: phone.slice(0, 2),
        phone_number: phone.slice(2, phone.length)
      });
      toast.success("Participante cadastrado com sucesso");
      runInAction(() => {
        this.isSubmittingAttendee = false;
      });
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.error.message);
      runInAction(() => {
        this.isSubmittingAttendee = false;
      });
      throw error;
    }
  };

  @action
  updateAttendeeProfileImage = async (user_id: string, image: any) => {
    this.isSubmittingAttendee = true;

    const data = new FormData();
    data.append("newProfilePicture", image);
    data.append("user_id", user_id);

    try {
      const response = await Post("/api/users/profile-picture", data);
      toast.success("Imagem anexada com sucesso");
      runInAction(() => {
        this.isSubmittingAttendee = false;
      });
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.error.message);
      runInAction(() => {
        this.isSubmittingAttendee = false;
      });
      throw error;
    }
  };

  @action
  registerAttendeeType = async (values: IFormTypeValues, callback: Function) => {
    this.isSubmittingAttendee = true;
    try {
      const response = await Post("/api/events/attende-types/", {
        ...values
      });
      toast.success("Tipo de participante cadastrado com sucesso");
      runInAction(() => {
        this.isSubmittingAttendee = false;
        callback && callback();
      });
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.error.message);
      runInAction(() => {
        this.isSubmittingAttendee = false;
      });
      throw error;
    }
  };

  @action
  updateAttendee = async (attendeeId: string, values: IFormValues) => {
    try {
      var phone = values.phone_number.replace(/\D/g,'');
      
      const response = await Put(`/api/participants/${attendeeId}`, {
        ...values,
        area_code: phone.slice(0, 2),
        phone_number: phone.slice(2, phone.length),
      });
      toast.success("Participante atualizado com sucesso");
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error.message);
      throw error.response;
    }
  };

  @action
  deleteAttendee = async (attendeeId: string) => {
    this.isDelettingAttendee = true;

    try {
      await Delete(`/api/participants/${attendeeId}`);
      toast.success("Usuário removido com sucesso");
      runInAction(() => {
        this.isDelettingAttendee = false;
      });
      await this.fetchAttendees({limit: this.last_limit});
      this.setAttendeesPagination({
        attendeesCount: 0,
        page: 1,
        pages: 1,
      });
    } catch (error) {
      toast.error(error.response.data.error.message);
      runInAction(() => {
        this.isDelettingAttendee = false;
      });
    }
  };

  @action
  deleteAttendeesInBatch = async (attendeeIds: string[]) => {
    this.isDelettingAttendee = true;
    try {
      const response = await Put(
        `/api/participants/batch/destroy`,
        {
          userIds: attendeeIds
        }
      );
      toast.success("Usuários removidos com sucesso");
      runInAction(() => {
        this.isDelettingAttendee = false;
      });
      await this.fetchAttendees({limit: this.last_limit});
      this.setAttendeesPagination({
        attendeesCount: 0,
        page: 1,
        pages: 1,
      });
    } catch (error) {
      toast.error(error.response.data.error.message);
      runInAction(() => {
        this.isDelettingAttendee = false;
      });
    }
  };

  @action
  toggleAdmin = async (attendeeId: string, is_admin: boolean) => {
    this.isChangingUser = true;

    try {
      await Put(`/api/users/${attendeeId}/toggle`, {
        is_admin,
      });
      toast.success("Usuário alterado com sucesso");
      runInAction(() => {
        this.isChangingUser = false;
      });
      await this.fetchAttendees({limit: this.last_limit});
      this.setAttendeesPagination({
        attendeesCount: 0,
        page: 1,
        pages: 1,
      });
    } catch (error) {
      toast.error(error.response.data.error.message);
      runInAction(() => {
        this.isDelettingAttendee = false;
      });
      throw error.response;
    }
  };

  @action
  toggleCompanyAdmin = async (attendeeId: string, is_admin: boolean) => {
    this.isChangingUser = true;

    try {
      await Put(`/api/users/${attendeeId}/company/toggle`, {
        is_admin,
      });
      toast.success("Usuário alterado com sucesso");
      runInAction(() => {
        this.isChangingUser = false;
      });
      await this.fetchAttendees({limit: this.last_limit});
      this.setAttendeesPagination({
        attendeesCount: 0,
        page: 1,
        pages: 1,
      });
    } catch (error) {
      toast.error(error.response.data.error.message);
      runInAction(() => {
        this.isDelettingAttendee = false;
      });
      throw error.response;
    }
  };

  @action
  linkAttendeeToEvent = async ({
    user_id,
    eventId,
  }: {
    user_id: string;
    eventId: string;
  }) => {
    try {
      const response = await Post(`/api/events/${eventId}/attendees`, {
        user_id,
      });
      toast.success("Participante vinculado com sucesso");
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error.message);
      throw error.response;
    }
  };

  @action
  linkAttendeeInBatchToEvent = async ({
    user_ids,
    eventId,
  }: {
    user_ids: string[];
    eventId: string;
  }) => {
    try {
      const response = await Post(`/api/events/${eventId}/attendees`, {
        user_ids,
      });
      toast.success("Participantes vinculados com sucesso");
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error.message);
      throw error.response;
    }
  };
  
  @action
  unlinkAttendeeFromEvent = async ({
    attendeeId,
    eventId,
  }: {
    attendeeId: string;
    eventId: string;
  }) => {
    try {
      const response = await Delete(
        `/api/events/${eventId}/attendees/${attendeeId}`
      );
      toast.success("Participante desvinculado com sucesso");
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error.message);
      throw error.response;
    }
  };

  @action
  unlinkAttendeeFromEventInBatch = async ({
    attendeeIds,
    eventId,
  }: {
    attendeeIds: string[];
    eventId: string;
  }) => {
    try {
      const response = await Put(
        `/api/events/${eventId}/attendees/subscriptions/remove`,
        {
          attendeeIds: attendeeIds
        }
      );
      toast.success("Participantes desvinculado com sucesso");
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error.message);
      throw error.response;
    }
  };
}

export const attendeesStore = new AttendeesStore();
