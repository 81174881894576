import React, { useState, useEffect, useCallback } from "react";
// @ts-ignore
import { FormikSelectField, FormikTextField } from "formik-material-fields";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import * as S from "./styles";
import { useStore } from "Store";
import { Formik, Field } from "formik";
import { IItems, EventModel, Registration } from "Models/Events";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { IAttendee } from "Models/Attendees";
import BadgeItem from "./components/badgeItem";
import CustomizedCheckbox from "../Checkin/checkbox";

import { ceil } from "lodash";
// @ts-ignore

import PdfBadge from "./components/pdfTeste";
import { string } from "yup";
import Companies from "Pages/Companies";
import { FormikAutocomplete } from "Signup";
import { Checkbox, TableContainer, Table, TableRow, TableCell, TableBody } from "@material-ui/core";
import { GenericTableHead } from "Components/Styled/table";
import PdfCertificate from "./components/Pdf";
import { CertificateModel } from "Models/Certificate";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      width: "100%",
    },
    control: {
      padding: theme.spacing(2),
    },
  })
);

interface RouteParams {
  eventId: string;
}
const Certificates = () => {
  const classes = useStyles();
  const { eventId } = useParams<RouteParams>();
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [hasChangesBadgeSettings, setHasChangesBadgeSettings] = useState<
    boolean
  >(false);
  const [myEvent, setMyEvent] = useState<EventModel>({} as EventModel);
  const [myCertificate, setCertificate] = useState<CertificateModel>({} as CertificateModel);
  const [renders, setRenders] = useState(0);
  const { eventsStore, certificatesStore, appStore } = useStore();
  const [attendeeArray, setAttendeeArray] = useState([] as Registration[]);
  const [attendee, setAttendee] = useState([] as IAttendee[]);
  // const [attendee, setAttendee] = useState([] as {}[]);
  const formRef = React.useRef();
  const [myItems, setMyItems] = useState<IItems[]>([] as IItems[]);
  const [printAll, setPrintAll] = useState(false);
  const [currentAttendee, setCurrentAttendee] = useState({} as Registration);
  const [currentAttendeeId, setCurrentAttendeeId] = useState("");
  const [attendees, setAttendees] = useState([]  as {label: string, value: string}[])

  const [twoColumns, setTwoColumns] = useState(true);
  const [show, setShow] = useState(false);
  const [showCompany, setShowCompany] = useState(false);
  const [edit, setEdit] = useState(false);
  const [job, setJob] = useState("");
  const [name, setName] = useState("");
  const [businessName, setBusiness] = useState("");
  const [companies, setCompanies] = useState([]  as {label: string, value: string}[])
  const [companyAttendees, setCompanyAttendees] = useState([] as Registration[]);
  const [attendeesInfo, setAttendeesInfo] = useState([] as Registration[]);
  const [currentCompanyId, setCurrentCompanyId] = useState("");
  const [config, setConfig] = useState(false);
  const [slots, setSlots] = useState(false);
  const [offsetX, setOffsetX] = useState(4);
  const [offsetY, setOffsetY] = useState(14);
  const [offsetRow, setOffsetRow] = useState(34);
  const [currentSlot, setCurrentSlot] = useState("");

  const originalSlotMatrix = [
    [true, true],
    [true, true],
    [true, true],
    [true, true],
    [true, true],
    [true, true],
    [true, true],
    [true, true],
  ];

  const [slotMatrix, setSlotMatrix] = useState(originalSlotMatrix);

  useEffect(() => {
    getEvent();
    // RegistrationsEvent();
  }, []);

  interface IMyValues {
    name: string;
    business: string;
    job_title: string;
  }

  const onGenerateBadge = (values: IMyValues) => {
    console.log(values);
  };

  const getEvent = useCallback(async () => {
    try {
      const response = await eventsStore.fetchEventById(eventId);
      //@ts-ignore
      setAttendeeArray(response.data.event.registrations);
      //@ts-ignore
      setMyEvent(response.data.event);
      // RegistrationsEvent();

      const certificateResponse = await certificatesStore.fetchCertificate(eventId);
      setCertificate(certificateResponse);

    } catch (error) {
      console.log(error);
    }
  }, [eventId, eventsStore]);

  useEffect(() => {
    //Set companies
    let tempCompanies: any[] = [];

    attendeeArray.map(attendee => {
      if (attendee.user.business) {
        let exists = false;
        tempCompanies.map(company => {
          if (company.value === attendee.user.business._id) {
            exists = true;
          }
        })

        if (!exists) {
          tempCompanies.push({label: attendee.user.business.name, value: attendee.user.business._id});
        }
      }
    });

    setCompanies(tempCompanies);

    let tempAttendees: any[] = [];
    let attendeesInfoArray: Registration[] = [];

    attendeeArray.map(function (item) {
      if (item.paid) {
        attendeesInfoArray.push(item);

        tempAttendees.push({
          label: item.user.first_name + " " + item.user.last_name,
          value: item.user._id,
        });
      }
    });

    setAttendees(tempAttendees);
    setAttendeesInfo(attendeesInfoArray);

  }, [attendeeArray]);

  const setPrint = () => {
    setPrintAll(!printAll);
    setEdit(false);
    setName("");
    setJob("");
    setBusiness("");
  };
  
  const infoAttendee = (id: string) => {
    const a = attendeesInfo.filter((item) => {
      return item.user._id === id;
    });
    setCurrentAttendee(a[0]);
    setShow(true);
    setShowCompany(false);

    return a;
  };

  const infoCompanies = (id: string) => {
    const a = attendeeArray.filter((item) => {
      return item.user.business && item.user.business._id === id;
    });
    setCompanyAttendees(a);
    setShow(false);
    setShowCompany(true);

    return a;
  };

  const getAttendeeName = (user: IAttendee) => {
    if (!user) return "";
    if (user.nickname) return user.nickname.toUpperCase();
    else {
      let first_name = user.first_name.split(" ")[0];
      let last_name_array = user.last_name.split(" ");
      let last_name = last_name_array[last_name_array.length - 1];

      return (first_name + " " + last_name).toUpperCase();
    }
  };

  const getProfileTypeName = (type: string) => {
    if (type === "vip") {
      return "VIP";
    }
    else if (type === "associated_company") {
      return "Empresa Associada";
    }
    else if (type === "not_associated_company") {
      return "Empresa Não Associada";
    }
    else if (type === "partner_company") {
      return "Empresa Parceira";
    }
    else if (type === "sponsor_company") {
      return "Empresa Patrocinadora";
    }
    else if (type === "guest") {
      return "Convidado";
    }
    else if (type === "director") {
      return "Diretoria";
    }
    else if (type === "liberal_professional") {
      return "Profissional Liberal";
    }
    else if (type === "student") {
      return "Estudante";
    }
    else if (type === "service_provider") {
      return "Prestador De Serviço";
    }
    else if (type === "standard") {
      return "Participante";
    }
    else if (type === "default") {
      return "Participante";
    }
    else {
      return type;
    }
  }

  const getAttendeeProfileType = (user: IAttendee) => {
    let profile_type = "";

    if (user.profile_type) { 
      profile_type = user.profile_type;
    }
    else {
      const a = attendeeArray.filter((item) => {
        return item.user._id === user._id;
      });

      if (a && a.length > 0)
        profile_type = (a[0].profile_type);
      else
        profile_type = "N/D";
    }

    return getProfileTypeName(profile_type).toUpperCase();
  };

  const initialValues = {
    name: edit
      ? getAttendeeName(currentAttendee.user)
      : "",
    business: edit ? (currentAttendee.user.business && currentAttendee.user.business.name ? currentAttendee.user.business.name.toUpperCase() : (currentAttendee.user.business_title ? currentAttendee.user.business_title.toUpperCase() : "N/D")) : "",
    job_title: edit ? currentAttendee.user.job_title : "",
  };
  const maxBadgesPerPage = 5;
  const qtdPages = ceil(attendeesInfo.length / maxBadgesPerPage);

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} sm={12}>
        <Paper className={classes.paper}>
          <S.Container>
            <S.Title>Gerar Certificado</S.Title>

            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={onGenerateBadge}
              // @ts-ignore
              innerRef={formRef}
            >
              {({ isValid, handleSubmit, values, handleChange, errors }) => (
                <S.FormWrapper>
                  <FormControl>
                    <FormContainer>
                      <EventTitle>{myEvent.name}</EventTitle>
                    </FormContainer>
                  </FormControl>
                  {!edit && (
                    <FormControl>
                      <FormContainer>
                        <Field
                          name="attendee-name"
                          className="form-field"
                          component={FormikAutocomplete}
                          variant="outlined"
                          label={printAll ? "Todos Participantes" : "Participante"}
                          value={currentAttendeeId}
                          options={attendees}
                          //@ts-ignore
                          onChange={(target => {
                            if (target && target.value) {
                              setCurrentAttendeeId(target.value);
                              infoAttendee(target.value);
                            }
                          })}
                        />
                      </FormContainer>
                    </FormControl>
                  )}

                  <EventContainer>
                    <div style={{ marginTop: "18px" }}>
                      {currentAttendee && currentAttendee.user && myCertificate && (
                        <PdfCertificate
                          data={{
                            event: myEvent.name,
                            background_url: appStore && myCertificate.background_image_url ? appStore.currentBaseURL + "/" + myCertificate.background_image_url : "",
                            participant: 
                              name !== ""
                                ? name
                                : getAttendeeName(currentAttendee.user),
                            localization: myCertificate.description_localization,
                            day: myCertificate.description_date,
                            hours: myCertificate.description_hours
                          }}
                        />
                      )}
                    </div>
                  </EventContainer>
                </S.FormWrapper>
              )}
            </Formik>
          </S.Container>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Certificates;

export const FormContainer = styled.div`
  width: 100%;
  margin: 10px;
`;

export const EventContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ContainerItens = styled.div`
  margin-bottom: 3px;
  width: 100%;
`;
export const EventTitle = styled.div`
  font-size: 17px;
  color: black;
  font-weight: bold;
`;

export const BadgeTitle = styled.div`
  font-size: 17px;
  color: black;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 20px;
`;

export const Text = styled.div`
  font-weight: bold;
  text-align: justify;
  margin-left: 5px;
`;

export const TextContainer = styled.div`
  /* justify-content: center; */
  display: flex;
  margin-left: 20px;
`;
