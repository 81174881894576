import { configure, observable, action, runInAction, toJS } from "mobx";
import { toast } from "react-toastify";
import {
  EventModel,
  Checkin,
} from "Models/Events";

import { IEventPagination, IDrawPagination } from "Models/Utils";

import { Post, Get, Delete, Put } from "Services/Request";
import { history } from "Store/History";
import { RegisterQuestionModel } from "Models/RegisterQuestion";

configure({ enforceActions: "observed" });

interface IFormValues {
    question_1: string;
    question_2: string;
    question_3: string;
}

class RegisterQuestionsStore {
  constructor() {
    // this.fetchRegisterQuestion();
  }

  @observable
  registerquestion: RegisterQuestionModel | null = null;

  @observable
  isFetching: boolean = false;

  @action
  fetchRegisterQuestion = async () => {
    this.isFetching = true;
    try {
      const response = await Get(`/api/registerquestions`);
      runInAction(() => {
        this.isFetching = false;
        // @ts-ignore
        this.registerquestion = response.data.data.register_question;
      });

      //@ts-ignore
      return response.data.data.register_question;
    } catch (error) {
      runInAction(() => {
        this.isFetching = false;
      });
      console.log(error.response);
    }
  };

  @action
  updateRegisterQuestion = async (registerQuestionId: string, values: IFormValues) => {
    try {
      const response = await Put(`/api/registerquestions/${registerQuestionId}`, {
        ...values
      });
      toast.success("Questões atualizadas com sucesso");
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error.message);
      throw error.response;
    }
  };

}

export const registerquestionsStore = new RegisterQuestionsStore();
