import React from "react";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useStore } from "Store";

interface IProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  isAdmin: boolean;
  isCompanyAdmin: boolean;
  handleClose: Function;
  handleEdit: Function;
  handleDelete: Function;
  handleAdmin: Function;
  handleCompanyAdmin: Function;
}

const AttendeeContextMenu: React.FC<IProps> = ({
  anchorEl,
  open,
  isAdmin,
  isCompanyAdmin,
  handleClose,
  handleDelete,
  handleEdit,
  handleAdmin,
  handleCompanyAdmin
}) => {

  const { userStore } = useStore();

  return (
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={() => handleClose()}
    >
      {!isAdmin && (<MenuItem onClick={() => handleDelete(true)}>Excluir</MenuItem>)}
      <MenuItem onClick={() => handleEdit(true)}>Editar</MenuItem>
      { !userStore.isCompanyUser() && (
        <>
          {isAdmin ? (
              <MenuItem onClick={() => handleAdmin(false)}>Remover Admin</MenuItem>
            ) : ( isCompanyAdmin ? (
              <MenuItem onClick={() => handleCompanyAdmin(false)}>Remover Admin de Empresa</MenuItem>
            ) : (
              <>
                <MenuItem onClick={() => handleAdmin(true)}>Tornar Admin</MenuItem>
                <MenuItem onClick={() => handleCompanyAdmin(true)}>Tornar Admin de Empresa</MenuItem>
              </>
            ))
          }
        </>
      )}
    </Menu>
  );
};

export default AttendeeContextMenu;
