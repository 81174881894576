import { configure, observable, action, runInAction, toJS } from "mobx";
import { toast } from "react-toastify";

import { Post, Get, Delete, Put } from "Services/Request";
import { ContactModel } from "Models/Companies";

configure({ enforceActions: "observed" });

class ContactStore {
  constructor() {
    // this.fetchContact();
  }

  @observable
  contact: ContactModel | null = null;

  @observable
  isFetchingContact: boolean = false;

  @action
  fetchContact = async () => {
    this.isFetchingContact = true;
    try {
      const response = await Get(`/api/contacts`);
      runInAction(() => {
        this.isFetchingContact = false;
        // @ts-ignore
        this.contact = response.data.data.contact;
      });

      //@ts-ignore
      return response.data.data.contact;
    } catch (error) {
      runInAction(() => {
        this.isFetchingContact = false;
      });
      console.log(error.response);
    }
  };

  @action
  updateContact = async (contactId: string, description: string) => {
    this.isFetchingContact = true;
    try {
      const response = await Put(`/api/contacts/${contactId}`, {
        description: description
      });
      runInAction(() => {
        this.isFetchingContact = false;
      });
      toast.success("Contato editado com sucesso");
      console.log(response.data);
    } catch (error) {
      runInAction(() => {
        this.isFetchingContact = false;
      });
      if (error.response && error.response.data)
        toast.error(error.response.data.error.message);
      console.log(error);
    }
  };

  @action
  sendEmailForm = async (name: string, email: string, phone_number: string, message: string) => {
    this.isFetchingContact = true;
    try {
      const response = await Put(`/api/contacts/form/send-email`, {
        name, 
        email, 
        phone_number, 
        message
      });
      runInAction(() => {
        this.isFetchingContact = false;
      });
      toast.success("Formulário submetido com sucesso!");
      console.log(response.data);
    } catch (error) {
      runInAction(() => {
        this.isFetchingContact = false;
      });
      if (error.response && error.response.data)
        toast.error(error.response.data.error.message);
      console.log(error);
    }
  };

}

export const contactStore = new ContactStore();
