import React, { useState, useEffect, useCallback } from "react";
// @ts-ignore
import { FormikSelectField, FormikTextField } from "formik-material-fields";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import * as S from "./styles";
import { useStore } from "Store";
import { IItems, EventModel, Registration, EventSponsorModel } from "Models/Events";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { IAttendee } from "Models/Attendees";
import PageItem from "./components/badgeItem";
import CustomizedCheckbox from "../Checkin/checkbox";

import { ceil } from "lodash";
import { Button, Container, Typography, TextField, Switch } from "@material-ui/core";
import { PrimaryActionButton } from "Components/Styled/Form";
import { IFormValues, validationSchema } from "./models/indes";
import { LandingPageModel } from "Models/LandingPage";
import { Editor } from "react-draft-wysiwyg";
import SimpleFilePicker from "Pages/Home/components/SimpleFilePicker";
import { ErrorLabel } from "Components/Styled/util";
import LoadingModal from "Components/LoadingModal";
import { LoadingStatusContainer } from "Pages/Home/CreateOrEditEvent/components/SecondStep/styles";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      width: "100%",
    },
    control: {
      padding: theme.spacing(1),
    },
  })
);

interface RouteParams {
  eventId: string;
}
const Page = () => {
  const classes = useStyles();
  const { eventId } = useParams<RouteParams>();
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [hasChangesPageSettings, setHasChangesPageSettings] = useState<
    boolean
  >(false);
  const [myEvent, setMyEvent] = useState<EventModel>({} as EventModel);
  const [eventSponsors, setEventSponsors] = useState<EventSponsorModel[]>([]);
  const [myLandingPage, setLandingPage] = useState<LandingPageModel>({} as LandingPageModel);
  const [body, setBody] = useState("");
  const [registerBody, setRegisterBody] = useState("");
  const [loading, setLoading] = useState(true);
  const [bodyHTML, setBodyHTML] = useState("");
  const [descriptionBodyHTML, setDescriptionBodyHTML] = useState("");
  const { eventsStore, landingpagesStore, appStore, eventsponsorsStore } = useStore();
  const formRef = React.useRef();

  const [registerLink, setRegisterLink] = useState("");
  const [landingPageLink, setLandingPageLink] = useState("");

  //Sponsors
  const [currentFile, setCurrentFile] = useState<FileList | null>(null);
  const [fileError, setFileError] = useState<boolean>(false);
  const [fileErrorMessage, setFileErrorMessage] = useState("");

  //Background
  const [currentBackgroundFile, setCurrentBackgroundFile] = useState<FileList | null>(null);
  const [fileBackgroundError, setFileBackgroundError] = useState<boolean>(false);
  const [fileBackgroundErrorMessage, setFileBackgroundErrorMessage] = useState("");

  //Event Image
  const [currentEventFile, setCurrentEventFile] = useState<FileList | null>(null);
  const [fileEventError, setFileEventError] = useState<boolean>(false);
  const [fileEventErrorMessage, setFileEventErrorMessage] = useState("");

  const [initialValues, setInitialValues] = useState<IFormValues>({
    body: "",
  });

  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
  const [editorRegisterState, setEditorRegisterState] = useState<EditorState>(EditorState.createEmpty());

  const [descriptionHeadPosition, setDescriptionHeadPosition] = useState(0);
  const [descriptionTitlePosition, setDescriptionTitlePosition] = useState(0);
  const [descriptionBodyPosition, setDescriptionBodyPosition] = useState(0);
  const [descriptionSponsorsPosition, setDescriptionSponsorsPosition] = useState(0);
  const [descriptionButtomPosition, setDescriptionButtomPosition] = useState(0);
  const [descriptionBackgroundColor, setDescriptionBackgroundColor] = useState("");
  const [descriptionBackgroundSecondaryColor, setDescriptionBackgroundSecondaryColor] = useState("");
  const [descriptionTextColor, setDescriptionTextColor] = useState("");
  const [descriptionButtonColor, setDescriptionButtonColor] = useState("");
  const [descriptionButtonTextColor, setDescriptionButtonTextColor] = useState("");
  const [descriptionRegisterBackgroundColor, setDescriptionRegisterBackgroundColor] = useState("");
  const [descriptionBackgroundImage, setDescriptionBackgroundImage] = useState("");

  const [htmlMode, setHtmlMode] = useState(false);

  useEffect(() => {
    getLandingPage();
    getSponsors();
    // RegistrationsEvent();
  }, []);

  const onSubmitRegisterPage = async () => {
    try {
      await eventsStore.onUpdateDescriptionBodyEvent(eventId, registerBody);
      await landingpagesStore.updateLandingPage(eventId, {
        description_body: registerBody,
        description_head_position: descriptionHeadPosition,
        description_title_position: descriptionTitlePosition,
        description_body_position: descriptionBodyPosition,
        description_sponsors_position: descriptionSponsorsPosition,
        description_button_position: descriptionButtomPosition,
        description_background_color: descriptionBackgroundColor,
        description_background_secondary_color: descriptionBackgroundSecondaryColor,
        description_text_color: descriptionTextColor,
        description_button_color: descriptionButtonColor,
        description_button_text_color: descriptionButtonTextColor,
        description_register_background_color: descriptionRegisterBackgroundColor
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async () => {
    try {
      await landingpagesStore.updateLandingPage(eventId, {
        body
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitHTML = async () => {
    try {
      await landingpagesStore.updateLandingPage(eventId, {
        body_html: bodyHTML
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitDescriptionHTML = async () => {
    try {
      await landingpagesStore.updateLandingPage(eventId, {
        description_body_html: descriptionBodyHTML
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitSponsor = async () => {
    try {
      if (currentFile) {
        setFileError(false);
        await eventsponsorsStore.onSubmitEventSponsor(eventId, currentFile[0]);
        getSponsors();
      } else {
        setFileError(true);
        setFileErrorMessage("Por favor, escolha uma imagem para o patrocinador");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDeleteSponsor = async (sponsorId: string) => {
    try {
      await eventsponsorsStore.onDeleteEventSponsor(eventId, sponsorId);
      getSponsors();
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitEventImage = async () => {
    try {
      if (currentEventFile) {
        setFileEventError(false);
        await eventsStore.onUpdateEventImage(eventId, currentEventFile[0]);
        getLandingPage();
      } else {
        setFileEventError(true);
        setFileEventErrorMessage("Por favor, escolha uma imagem para o evento");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitBackgroundImage = async () => {
    try {
      if (currentBackgroundFile) {
        setFileBackgroundError(false);
        await landingpagesStore.onUpdateLandingPageBackground(eventId, currentBackgroundFile[0]);
        getLandingPage();
      } else {
        setFileBackgroundError(true);
        setFileBackgroundErrorMessage("Por favor, escolha uma imagem para o background");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLandingPage = useCallback(async () => {
    try {
      const response = await eventsStore.fetchEventById(eventId);
      //@ts-ignore
      var event = response.data.event;
      
      setMyEvent(event);
      
      const landingPageResponse = await landingpagesStore.fetchLandingPage(eventId);
      setLandingPage(landingPageResponse);
      setBodyHTML(landingPageResponse.body_html);
      setDescriptionBodyHTML(landingPageResponse.description_body_html);
      setDescriptionHeadPosition(landingPageResponse.description_head_position);
      setDescriptionTitlePosition(landingPageResponse.description_title_position);
      setDescriptionBodyPosition(landingPageResponse.description_body_position);
      setDescriptionSponsorsPosition(landingPageResponse.description_sponsors_position);
      setDescriptionButtomPosition(landingPageResponse.description_button_position);
      setDescriptionBackgroundColor(landingPageResponse.description_background_color);
      setDescriptionBackgroundSecondaryColor(landingPageResponse.description_background_secondary_color);
      setDescriptionTextColor(landingPageResponse.description_text_color);
      setDescriptionButtonColor(landingPageResponse.description_button_color);
      setDescriptionButtonTextColor(landingPageResponse.description_button_text_color);
      setDescriptionRegisterBackgroundColor(landingPageResponse.description_register_background_color);
      setDescriptionBackgroundImage(landingPageResponse.description_background_image_url);

      //Page editor
      setBody(landingPageResponse.body);
      const contentBlock = htmlToDraft(landingPageResponse.body);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);

      //Register page editor
      setRegisterBody(event.description_body);
      const contentBlockRegister = htmlToDraft(event.description_body);
      const contentStateRegister = ContentState.createFromBlockArray(contentBlockRegister.contentBlocks);
      const editorStateRegister = EditorState.createWithContent(contentStateRegister);
      setEditorRegisterState(editorStateRegister);

      setRegisterLink(`${window.location.host}/inscricao/${event.short_name}`);
      setLandingPageLink(`${window.location.host}/evento/${event.short_name}`);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [eventId, eventsStore]);

  const getSponsors = useCallback(async () => {
    try {
      const response = await eventsponsorsStore.fetchEventSponsors(eventId);
      //@ts-ignore
      setEventSponsors(response);
    } catch (error) {
      console.log(error);
    }
  }, [eventId, eventsStore]);

  const onEditorChange = async (editorStateProps: EditorState) => {
    setEditorState(editorStateProps);
    setBody(draftToHtml(convertToRaw(editorStateProps.getCurrentContent())));
  }

  const onEditorRegisterChange = async (editorStateProps: EditorState) => {
    setEditorRegisterState(editorStateProps);
    setRegisterBody(draftToHtml(convertToRaw(editorStateProps.getCurrentContent())));
  }

  return (
    <Grid container className={classes.root} spacing={1}>
      <LoadingModal open={loading}>
        <LoadingStatusContainer>Carregando...</LoadingStatusContainer>
      </LoadingModal>
      <Grid item xs={12} sm={12}>
        <Paper className={classes.paper}>
          <S.Container>
            <S.Title>Página do evento</S.Title>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                Modo HTML <Switch
                  checked={htmlMode}
                  onChange={() => {
                    setHtmlMode(!htmlMode);
                  }}
                  inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                />
              </Grid>
            </Grid>
            
          </S.Container>

          <Container>
            <S.PageFormContainer>
              <S.PageFormContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    Link para registro: <a href={`http://${registerLink}`} target="_blank">{`http://${registerLink}`}</a>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    Link para evento: <a href={`http://${landingPageLink}`} target="_blank">{`http://${landingPageLink}`}</a>
                  </Grid>
                  <Grid item xs={12} sm={12}>

                  </Grid>
                </Grid>
              </S.PageFormContent>
            </S.PageFormContainer>
          </Container>

          {htmlMode ? (
            <>
              <Container style={{marginTop: "30px"}}>
                <S.PageFormContainer>
                  <S.PageFormContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        OBS: Para desativar o modo HTML, salvar os campos abaixo sem nenhuma informação.
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <EventTitle>Corpo do site de registro HTML (OBS: Ao utilizar, desativa o template padrão):</EventTitle>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <textarea
                          value={descriptionBodyHTML}
                          rows={10}
                          style={{width: "95%"}}
                          onChange={(content: any) => {
                            setDescriptionBodyHTML(content.target.value)
                          }}
                        />
                      </Grid>
                    </Grid>
                    <S.ButtonRow>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={() => onSubmitDescriptionHTML()}
                        style={{marginBottom: "20px"}}
                      >
                        Salvar
                      </Button>
                    </S.ButtonRow>
                  </S.PageFormContent>
                </S.PageFormContainer>
              </Container>

              <Container style={{marginTop: "30px"}}>
                <S.PageFormContainer>
                  <S.PageFormContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <EventTitle>Corpo do site HTML (OBS: Ao utilizar, desativa o template padrão):</EventTitle>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <textarea
                          value={bodyHTML}
                          rows={10}
                          style={{width: "95%"}}
                          onChange={(content: any) => {
                            setBodyHTML(content.target.value)
                          }}
                        />
                      </Grid>
                    </Grid>
                    <S.ButtonRow>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={() => onSubmitHTML()}
                        style={{marginBottom: "20px"}}
                      >
                        Salvar
                      </Button>
                    </S.ButtonRow>
                  </S.PageFormContent>
                </S.PageFormContainer>
              </Container>
            </>
          ) : (
            <>
              <Container>
                <S.PageFormContainer>
                  <S.PageFormContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <EventTitle>Imagem de topo do evento:</EventTitle>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <img
                          src={appStore.currentBaseURL + "/" + myEvent.cover_url}
                          alt="cover"
                          width="300"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <EventTitle>Alterar imagem de topo do evento:</EventTitle>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <S.FilePickerWrapper>
                          <SimpleFilePicker
                            onLoadFile={(files: FileList | null) =>
                              setCurrentEventFile(files)
                            }
                            id="currentEventFile"
                          />
                          {fileEventError && <ErrorLabel>{fileEventErrorMessage}</ErrorLabel>}
                          {currentEventFile && (
                            <Typography variant="h6" gutterBottom>
                              {currentEventFile[0].name}
                            </Typography>
                          )}
                        </S.FilePickerWrapper>
                      </Grid>
                    </Grid>
                    <S.ButtonRow>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!currentEventFile}
                        onClick={() => onSubmitEventImage()}
                      >
                        Enviar
                      </Button>
                    </S.ButtonRow>
                  </S.PageFormContent>
                </S.PageFormContainer>
              </Container>

              <Container>
                <S.PageFormContainer>
                  <S.PageFormContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <EventTitle>Corpo do site de registro:</EventTitle>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Editor
                          editorState={editorRegisterState}
                          onEditorStateChange={onEditorRegisterChange}
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          localization={{
                            locale: 'pt',
                          }}
                          editorStyle={{
                            backgroundColor: "#ffffff",
                            borderRadius: '2px',
                            border: '1px solid #F1F1F1',
                            height: "300px"
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <EventTitle>Posição dos elementos da página:</EventTitle>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          label="Posição do banner"
                          fullWidth
                          variant="outlined"
                          value={descriptionHeadPosition}
                          type="number"
                          onChange={target => {
                            setDescriptionHeadPosition(+target.currentTarget.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          label="Posição do título"
                          fullWidth
                          variant="outlined"
                          value={descriptionTitlePosition}
                          type="number"
                          onChange={target => {
                            setDescriptionTitlePosition(+target.currentTarget.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          label="Posição do corpo"
                          fullWidth
                          variant="outlined"
                          value={descriptionBodyPosition}
                          type="number"
                          onChange={target => {
                            setDescriptionBodyPosition(+target.currentTarget.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          label="Posição dos patrocinadores"
                          fullWidth
                          variant="outlined"
                          value={descriptionSponsorsPosition}
                          type="number"
                          onChange={target => {
                            setDescriptionSponsorsPosition(+target.currentTarget.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          label="Posição do botão de registro"
                          fullWidth
                          variant="outlined"
                          value={descriptionButtomPosition}
                          type="number"
                          onChange={target => {
                            setDescriptionButtomPosition(+target.currentTarget.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <EventTitle>Cores dos elementos da página:</EventTitle>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          label="Background da página"
                          fullWidth
                          variant="outlined"
                          value={descriptionBackgroundColor}
                          onChange={target => {
                            setDescriptionBackgroundColor(target.currentTarget.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          label="Background secundário da página"
                          fullWidth
                          variant="outlined"
                          value={descriptionBackgroundSecondaryColor}
                          onChange={target => {
                            setDescriptionBackgroundSecondaryColor(target.currentTarget.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          label="Texto da página"
                          fullWidth
                          variant="outlined"
                          value={descriptionTextColor}
                          onChange={target => {
                            setDescriptionTextColor(target.currentTarget.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          label="Botão de registrar"
                          fullWidth
                          variant="outlined"
                          value={descriptionButtonColor}
                          onChange={target => {
                            setDescriptionButtonColor(target.currentTarget.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          label="Texto do botão de registrar"
                          fullWidth
                          variant="outlined"
                          value={descriptionButtonTextColor}
                          onChange={target => {
                            setDescriptionButtonTextColor(target.currentTarget.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          label="Background da tela de registro"
                          fullWidth
                          variant="outlined"
                          value={descriptionRegisterBackgroundColor}
                          onChange={target => {
                            setDescriptionRegisterBackgroundColor(target.currentTarget.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <S.ButtonRow>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={() => onSubmitRegisterPage()}
                      >
                        Salvar
                      </Button>
                    </S.ButtonRow>
                  </S.PageFormContent>
                </S.PageFormContainer>
              </Container>

              <Container>
                <S.PageFormContainer>
                  <S.PageFormContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <EventTitle>Imagem para background:</EventTitle>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        {descriptionBackgroundImage && descriptionBackgroundImage != "" && (
                          <img
                            src={appStore.currentBaseURL + "/" + descriptionBackgroundImage}
                            alt="cover"
                            width="300"
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <EventTitle>Adicionar imagem de um background:</EventTitle>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <S.FilePickerWrapper>
                          <SimpleFilePicker
                            onLoadFile={(files: FileList | null) =>
                              setCurrentBackgroundFile(files)
                            }
                            id="currentBackgroundFile"
                          />
                          {fileBackgroundError && <ErrorLabel>{fileBackgroundErrorMessage}</ErrorLabel>}
                          {currentBackgroundFile && (
                            <Typography variant="h6" gutterBottom>
                              {currentBackgroundFile[0].name}
                            </Typography>
                          )}
                        </S.FilePickerWrapper>
                      </Grid>
                    </Grid>
                    <S.ButtonRow>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!currentBackgroundFile}
                        onClick={() => onSubmitBackgroundImage()}
                      >
                        Enviar
                      </Button>
                    </S.ButtonRow>
                  </S.PageFormContent>
                </S.PageFormContainer>
              </Container>
              
              <Container>
                <S.PageFormContainer>
                  <S.PageFormContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <EventTitle>Corpo do site:</EventTitle>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        {/* <textarea
                          value={body}
                          rows={10}
                          style={{width: "95%"}}
                          onChange={(content: any) => {
                            setBody(content.target.value)
                          }}
                        /> */}
                        <Editor
                          editorState={editorState}
                          onEditorStateChange={onEditorChange}
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          localization={{
                            locale: 'pt',
                          }}
                          editorStyle={{
                            backgroundColor: "#ffffff",
                            borderRadius: '2px',
                            border: '1px solid #F1F1F1',
                            height: "300px"
                          }}
                        />
                      </Grid>
                    </Grid>
                    <S.ButtonRow>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={() => onSubmit()}
                      >
                        Salvar
                      </Button>
                    </S.ButtonRow>
                  </S.PageFormContent>
                </S.PageFormContainer>
              </Container>

              <Container>
                <S.PageFormContainer>
                  <S.PageFormContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <EventTitle>Patrocinadores:</EventTitle>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        {eventSponsors.map(sponsor => {
                          return (
                            <div style={{paddingTop: "5px"}}>
                              <img
                                src={appStore.currentBaseURL + "/" + sponsor.cover_url}
                                alt="cover"
                                height="100"
                              />
                              <Button
                                variant="contained"
                                color="secondary"
                                type="submit"
                                onClick={() => onDeleteSponsor(sponsor._id)}
                                style={{marginLeft: "20px"}}
                              >
                                Excluir
                              </Button>
                            </div>
                          )
                        })}
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <EventTitle>Adicionar imagem de um patrocinador:</EventTitle>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <S.FilePickerWrapper>
                          <SimpleFilePicker
                            onLoadFile={(files: FileList | null) =>
                              setCurrentFile(files)
                            }
                            id="currentFile"
                          />
                          {fileError && <ErrorLabel>{fileErrorMessage}</ErrorLabel>}
                          {currentFile && (
                            <Typography variant="h6" gutterBottom>
                              {currentFile[0].name}
                            </Typography>
                          )}
                        </S.FilePickerWrapper>
                      </Grid>
                    </Grid>
                    <S.ButtonRow>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!currentFile}
                        onClick={() => onSubmitSponsor()}
                      >
                        Enviar
                      </Button>
                    </S.ButtonRow>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} style={{paddingBottom: "20px"}}>
                        
                      </Grid>
                    </Grid>
                  </S.PageFormContent>
                </S.PageFormContainer>
              </Container>
            </>
          )}
        </Paper>
      </Grid>
      
    </Grid>
  );
};

export default Page;

export const FormContainer = styled.div`
  width: 100%;
  margin: 10px;
`;

export const EventContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ContainerItens = styled.div`
  margin-bottom: 3px;
  width: 100%;
`;
export const EventTitle = styled.div`
  font-size: 17px;
  color: black;
  font-weight: bold;
`;

export const PageTitle = styled.div`
  font-size: 17px;
  color: black;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 20px;
`;

export const Text = styled.div`
  font-weight: bold;
  text-align: justify;
  margin-left: 5px;
`;

export const TextContainer = styled.div`
  /* justify-content: center; */
  display: flex;
  margin-left: 20px;
`;
