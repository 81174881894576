import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { Response } from "../../Models/Services";
import { appStore } from "Store/App";

// const api = axios.create({
// });
export const api = axios.create({
  baseURL: appStore.currentBaseURL,
  headers: {
    "Content-Type": "application/json"
  },
});

api.interceptors.request.use((config) => {
  config.baseURL = appStore.currentBaseURL;
  if (!config.headers.Authorization) {
    const token = localStorage.getItem("@token");
    
    if (token) {
      config.headers.Authorization = token ? `JWT ${token}` : "";
    }
  }
  return config;
});

export function Get<T>(url: string, config?: AxiosRequestConfig) {
  return api.get<any, AxiosResponse<Response<T>>>(url, config);
}

export function Post<T>(url: string, data: any, config?: AxiosRequestConfig) {
  return api.post<any, AxiosResponse<Response<T>>>(url, data, config);
}

export function Put<T>(url: string, data: any, config?: AxiosRequestConfig) {
  return api.put<any, AxiosResponse<Response<T>>>(url, data, config);
}

export function Delete<T>(url: string, config?: AxiosRequestConfig) {
  return api.delete<any, AxiosResponse<Response<T>>>(url, config);
}
