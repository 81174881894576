import styled from "styled-components";

export const LoadingStatusContainer = styled.div`
  font-weight: bold;
  color: #fff;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 95%;
  padding: 20px;
`;

export const NoParticipantsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
