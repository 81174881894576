import React, { useState, useEffect, useCallback } from "react";
import * as Yup from "yup";
import { Formik, Form as FormikForm } from "formik";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import _ from "underscore";

import * as S from "./styles";

import Grid from "@material-ui/core/Grid";
// @ts-ignore
import { FormikTextField } from "formik-material-fields";
import { Container, Typography } from "@material-ui/core";

import {
  PrimaryActionButton,
  SecondaryActionButton,
  FormTitle,
} from "Components/Styled/Form";
import { useStore } from "Store";
import { IAttendee } from "Models/Attendees";
import { ISorteioFilter, Registration } from "Models/Events";

import FilterSetupDialog from "./components/FilterSetupDialog";
import { Company } from "Models/Companies";
import { IOptions } from "Pages/Attendees/Form/models";
import DrawAttendeesList from "./components/DrawAttendeesList";
import { AlertLabel } from "Components/Styled/util";
import LoadingModal from "Components/LoadingModal";

interface RouteParams {
  eventId: string;
  drawId?: string;
}

interface IFormValues {
  prize: string;
  prize_description: string;
}

interface IMyValues {
  name: string;
  gender: string;
  company: string;
  type: string;
}

export const validationSchema = Yup.object().shape({
  prize: Yup.string().required("Prêmio é obrigatório"),
  prize_description: Yup.string().required("Descrição é obrigatório"),
});

const DrawForm = () => {
  const [hasInitialized, setHasInitialized] = useState(false);
  const history = useHistory();
  const { eventsStore, companyStore } = useStore();
  const { eventId, drawId } = useParams<RouteParams>();
  const [isUpdate] = useState(Boolean(drawId));
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [registrations, setRegistrations] = useState<Registration[]>([]);
  const [attendees, setAttendees] = useState<IAttendee[]>([]);
  const [attendeesIds, setAttendeesIds] = useState<string[]>([]);
  const [drawWinnersId, setDrawWinnersId] = useState<string[]>([]);
  const [hasUpdatedAttendees, setHasUpdatedAttendees] = useState(false);
  const [companiesOpt, setCompaniesOpt] = useState<IOptions[]>([
    { label: "Todas as Empresas", value: "default" },
  ]);
  const [isFetchingCompanies, setIsFetchingCompanies] = useState(false);
  const [filterAPI, setFilterAPI] = useState([{ placeholder: "bla bla bla" }]);
  const [filterSetup, setFilterSetup] = useState<ISorteioFilter>({});
  const [initialValues, setInitialValues] = useState<IFormValues>({
    prize: "",
    prize_description: "",
  });
  const [isFetchingDrawToEdit, setIsFetchingDrawToEdit] = useState(false);

  const fetchCompanies = useCallback(async () => {
    setIsFetchingCompanies(true);
    try {
      const response = await companyStore.fetchCompanies(1,999);
      const opt: any[] = [];
      // @ts-ignore
      response.businesses.forEach((business) => {
        opt.push({
          label: business.trade_name.toUpperCase(),
          value: business._id,
        });
      });
      setCompaniesOpt(opt);
      setIsFetchingCompanies(false);
    } catch (error) {
      setIsFetchingCompanies(false);
      console.log(error);
    }
  }, [companyStore]);

  const fetchEvent = useCallback(
    async (eventId: string) => {
      try {
        const response = await eventsStore.fetchEventById(eventId);
        // @ts-ignore
        setRegistrations(response.data.event.registrations);

        //Get Draws
        const res = await eventsStore.fetchDrawsByEventId({
          eventId,
          page: 1,
          limit: 999
        }) as any;

        const winners: string[] = [];

        if (res && res.data && res.data.draws) {
          res.data.draws.map((draw: any) => {
            if (draw.winner) {
              winners.push(draw.winner.user_id);
            }
          });
        }

        setDrawWinnersId(winners);
        
      } catch (error) {
        console.log(error);
      }
    },
    [eventsStore]
  );

  const fetchDrawToEdit = async ({
    drawId,
    eventId,
  }: {
    drawId: string;
    eventId: string;
  }) => {
    setIsFetchingDrawToEdit(true);
    try {
      const res = await eventsStore.fetchDrawById({
        drawId,
        eventId,
      });
      setInitialValues({
        // @ts-ignore
        prize: res.data.draw.prize,
        // @ts-ignore
        prize_description: res.data.draw.prize_description,
      });
      // @ts-ignore
      setFilterSetup(res.data.draw.filter[0]);
      if (isUpdate && !hasInitialized) {
        const aux: any = {
          // @ts-ignore
          name: res.data.draw.filter[0].name
            ? // @ts-ignore
              // @ts-ignore
              res.data.draw.filter[0].name
            : "",
          // @ts-ignore
          gender: res.data.draw.filter[0].gender
            ? // @ts-ignore
              // @ts-ignore
              res.data.draw.filter[0].gender
            : "default",
          // @ts-ignore
          type: res.data.draw.filter[0].type
            ? // @ts-ignore
              // @ts-ignore
              res.data.draw.filter[0].type
            : "default",
          // @ts-ignore
          company: res.data.draw.filter[0].company
            ? // @ts-ignore
              // @ts-ignore
              res.data.draw.filter[0].company
            : "default",
        };
        // @ts-ignore
        // if (res.data.draw.filter[0].name) {
        //   // @ts-ignore
        //   aux.name = res.data.draw.filter[0].name;
        // }
        // // @ts-ignore
        // if (res.data.draw.filter[0].gender) {
        //   // @ts-ignore
        //   aux.gender = res.data.draw.filter[0].gender;
        // }
        // // @ts-ignore
        // if (res.data.draw.filter[0].company) {
        //   // @ts-ignore
        //   aux.company = res.data.draw.filter[0].company;
        // }
        // @ts-ignore
        // if (res.data.draw.filter[0].type) {
        //   // @ts-ignore
        //   aux.type = res.data.draw.filter[0].type;
        // }
        handleChangeFilterSetup(aux);
        console.log(aux);
      }
      // setAttendees(
      //   attendees.filter(
      //     // @ts-ignore
      //     (attendee) => res.data.draw.competitors.includes(attendee._id)
      //   )
      // );
      setIsFetchingDrawToEdit(false);
    } catch (error) {
      setIsFetchingDrawToEdit(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    fetchEvent(eventId);
  }, [eventId, fetchEvent]);

  useEffect(() => {
    if (attendees.length > 0 && !hasUpdatedAttendees) {
      if (drawId) {
        fetchDrawToEdit({ drawId, eventId });
      }
      setHasUpdatedAttendees(true);
    }
  }, [attendees]);

  useEffect(() => {
    if (registrations) {
      let filtered_registrations = registrations.filter((registration) => {
        return registration.checked_in_at;
      });

      if (drawWinnersId) {
        filtered_registrations = filtered_registrations.filter((registration) => {
          let notWinner = true;

          drawWinnersId.map(winnerId => {
            if (winnerId == registration.user_id) notWinner = false;
          });

          return notWinner;
        });
      }
  
      setAttendees(filtered_registrations.map((registration) => registration.user));

    }
  }, [registrations, filterSetup, drawWinnersId]);

  const resetAttendees = () => {
    let filtered_registrations = registrations.filter((registration) => {
      return registration.checked_in_at;
    });

    if (drawWinnersId) {
      filtered_registrations = filtered_registrations.filter((registration) => {
        let notWinner = true;

        drawWinnersId.map(winnerId => {
          if (winnerId == registration.user_id) notWinner = false;
        });

        return notWinner;
      });
    }

    setAttendees(filtered_registrations.map((registration) => registration.user));
    setAttendeesIds(
      filtered_registrations.map((registration) => registration.user._id)
    );
    
    resetFilterSetup();
  };

  const resetFilterSetup = () => {
    setFilterSetup({});
    setFilterAPI([{ placeholder: "" }]);
  };

  const handleChangeFilterSetup = (values: any) => {
    console.log("valores")
    console.log(values)

    const query = values;

    for (let i in query) {
      if (query[i] === "default" || query[i] === "") {
        delete query[i];
      }
    }

    let types = query["types"];
    let companies = query["companies"];

    if (query["types"]) {
      delete query["types"];
    }

    if (query["companies"]) {
      delete query["companies"];
    }

    if (query["company_autocomplete"]) {
      delete query["company_autocomplete"];
    }

    if (Object.keys(query).length > 0 || (types && types.length > 0) || (companies && companies.length > 0)) {
      setFilterAPI([query]);
      const filterKeys = Object.keys(query);
      setFilterSetup({
        name: query.name ? query.name : null,
        gender: query.gender ? query.gender : null,
        // type: query.type ? query.type : null,
        // company: query.company ? query.company : null,
      });
      const dados: any[] = [];
      registrations.forEach((registration) => {
        dados.push({
          name:
            registration.user.first_name +
            " " +
            registration.user.last_name,
          gender: registration.user.gender ? registration.user.gender : "male",
          type: registration.profile_type,
          company: registration.user.business ? registration.user.business._id : null,
          id: registration.user._id,
        });
      });

      const query2: any[] = [];
      
      for (let key in values) {
        if (key !== "default" && key !== "") {
          query2.push({ [key]: values[key] });
        }
      }
      
      let filtered = dados.filter((item) => {
        return query2
          .map((query: any) => {
            const keys = Object.keys(query);
            if (keys[0] === "name") {
              return item[keys[0]]
                .toLowerCase()
                .includes(query[keys[0]].toLowerCase());
            } else {
              return item[keys[0]] === query[keys[0]];
            }
          })
          .every((item) => item === true);
      });

      if (types) {
        filtered = filtered.filter((item) => {
          let match = false;
          types.map((type: string) => {
            if (type === item.type) match = true;
          })
          return match;
        });
      }

      if (companies) {
        console.log(companies[0]);
        console.log(companies[0] == "default");
        if (companies.length == 0 && companies[0] == "default") console.log("continua");
        else {
          filtered = filtered.filter((item) => {
            let match = false;
            companies.map((companyId: string) => {
              if (companyId === item.company) match = true;
            })
            return match;
          });
        }
      }
      
      setAttendeesIds(filtered.map((f) => f.id));
      // if (filterKeys.length === 0 ) {
      //   resetAttendees();
      // }
    } else {
      setFilterAPI([{ placeholder: "" }]);
      resetAttendees();
      setFilterSetup({
        name: query.name ? query.name : null,
        gender: query.gender ? query.gender : null,
        type: query.type ? query.type : null,
        company: query.company ? query.company : null,
      });
    }
    setIsDialogOpen(false);
  };

  useEffect(() => {
    setAttendees((prev) =>
      prev.filter((item) => attendeesIds.includes(item._id))
    );
  }, [attendeesIds]);

  useEffect(() => {
    if (attendees.length > 0 && !isUpdate && !hasInitialized) {
      resetAttendees();
      setHasInitialized(true);
    }
  }, [attendees]);

  const onSubmit = async (values: IFormValues) => {

    const filteredRegistrations = registrations.filter((registration) =>
      attendeesIds.includes(registration.user._id)
    );

    const data = {
      competitors: filteredRegistrations.map((attendee) => attendee._id),
      eventId,
      prize: values.prize,
      prize_description: values.prize_description,
    };
    try {
      // filterAPI?.placeholder = "";
      if (isUpdate && drawId) {
        await eventsStore.handleUpdateDraw({
          ...data,
          drawId,
          filter: filterAPI,
        });
      } else {
        await eventsStore.handleSubmitDraw({ ...data, filter: filterAPI });
      }
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Container>
        <FormTitle>{isUpdate ? "Atualizar sorteio" : "Novo sorteio"}</FormTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ handleChange }) => (
            <>
              <S.FormContainer>
                <S.FormContent>
                  <FormikForm>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="prize"
                          label="Prêmio"
                          placeholder="Insira aqui o prêmio do evento"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="prize_description"
                          label="Descrição"
                          placeholder="Insira aqui a descrição do prêmio do evento"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <S.ButtonRow>
                      <SecondaryActionButton
                        type="button"
                        onClick={() => resetAttendees()}
                      >
                        Restaurar filtro
                      </SecondaryActionButton>
                      <SecondaryActionButton
                        type="button"
                        onClick={() => setIsDialogOpen(true)}
                      >
                        Editar filtro
                      </SecondaryActionButton>
                      <PrimaryActionButton
                        // disabled={attendees.length <= 1}
                        type="submit"
                      >
                        {isUpdate ? "Atualizar sorteio" : "Criar sorteio"}
                      </PrimaryActionButton>
                    </S.ButtonRow>
                  </FormikForm>
                </S.FormContent>
              </S.FormContainer>
            </>
          )}
        </Formik>
        <DrawAttendeesList attendees={attendees} />
      </Container>
      <FilterSetupDialog
        companyOptions={companiesOpt}
        open={isDialogOpen}
        filterSetup={filterSetup}
        handleClose={() => setIsDialogOpen(false)}
        onSubmit={handleChangeFilterSetup}
      />
      <LoadingModal open={isFetchingCompanies || isFetchingDrawToEdit}>
        <S.LoadingStatusContainer>
          Carregando formulário
        </S.LoadingStatusContainer>
      </LoadingModal>
    </>
  );
};

export default DrawForm;
