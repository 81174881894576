import React, { useState, useEffect, useCallback } from "react";
import { Line, Bar, Doughnut } from "react-chartjs-2";

interface Props {
  title: string;
  data1: any[] | any | string[];
  data2?: any[] | any | string[];
  label1: string;
  label2?: string;
  label: any[] | any;
  type?: string;
  color1?: string;
  color2?: string;
}

const Graphic = (props: Props) => {
  const [chartData, setChartData] = useState({});
  console.log(props);
  const chart = useCallback(() => {
    setChartData({
      labels: props.label,
      datasets: props.data2
        ? [
            {
              label: props.label1,
              data: props.data1,
              backgroundColor: "#188C90",
              borderWidth: 4,
              borderColor: "#188C90",
              fill: "none",
            },

            {
              label: props.label2,
              data: props.data2,
              backgroundColor: "#0D468F",
              borderWidth: 4,
              display: "flex",
              fill: "none",
              borderColor: "#0D468F",
            },
          ]
        : [
            {
              label: props.label1,
              data: props.data1,
              backgroundColor: "#188C90",
              borderWidth: 4,
              borderColor: "#188C90",
              fill: "none",
            },
          ],
    });
  }, [props]);

  useEffect(() => {
    chart();
  }, [chart]);

  return (
    <div>
      <div style={{ height: "auto", width: "auto    " }}>
        <h3>{props.title}</h3>
        {props.type === "Bar" ? (
          <Bar data={chartData} options={{ responsive: true, scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        } }} />
        ) : props.type === "Line" ? (
          <Line data={chartData} options={{ responsive: true, scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        } }} />
        ) : (
          <Doughnut data={chartData} options={{ responsive: true }} />
        )}
      </div>
    </div>
  );
};

export const GraphicDoughnut = (props: Props) => {
  const data = {
    labels: [props.label1, props.label2],
    datasets: [
      {
        data: [props.data1, props.data2],
        backgroundColor: [props.color1, props.color2],
        hoverBackgroundColor: ["#00577b", "#168669"],
      },
    ],
  };

  return (
    <div>
      <div style={{ height: "auto", width: "400px" }}>
        <h3 style={{ color: "#00577b" }}>{props.title}</h3>

        <Doughnut data={data} options={{ responsive: true }} />
      </div>
    </div>
  );
};

export default Graphic;
