import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { useStore } from "Store";
import clsx from "clsx";
import Moment from "moment";

import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import TextField from "@material-ui/core/TextField";

import * as S from "./styles";

import IconButton from "@material-ui/core/IconButton";

import "./styles/index.css";
import { EventModel } from "Models/Events";

import ChipAlert from "Components/ChipAlert";
import { AppBar } from "@material-ui/core";

interface Props {
  classes: any;
  open: boolean;
  handleToggleBar: Function;
  title: string;
  currentEvent: EventModel;
}

const EventAppBar: React.FC<Props> = ({
  classes,
  open,
  handleToggleBar,
  title,
  currentEvent,
}) => {
  const { active, starts_at, ends_at, checkin_tolerance } = currentEvent;
  const [isBetweenStartAndEndDate] = useState(
    Moment().isBetween(starts_at, ends_at)
  );
  const [canCheckIn] = useState(
    Moment().isBetween(
      starts_at,
      Moment(starts_at).add(checkin_tolerance, "ms")
    )
  );
  const { eventsStore } = useStore();

  const getInactiveMessage = () => {
    if (!active && (!isBetweenStartAndEndDate || !canCheckIn)) {
      return "Evento inativo ou fora do horário de check-in / cadastro";
    } else if (!active) {
      return "Evento inativo";
    } else {
      return "Evento fora do horário";
    }
  };

  useEffect(() => {
    console.log(isBetweenStartAndEndDate);
  }, [isBetweenStartAndEndDate]);

  return useObserver(() => (
    <S.EventsAppBar
      // position="fixed"
      style={{
        marginLeft: open ? 210 : 0,
        width: open ? `calc(100% - 210px)` : `100%`,
        backgroundColor: "#ddd",
        color: "#000",
      }}
    >
      <Toolbar>
        <S.ContentWrapper>
          <div className="start">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => handleToggleBar()}
              edge="start"
              className={clsx(classes.menuButton)}
            >
              {open ? <ChevronLeftIcon /> : <MenuIcon />}
            </IconButton>
            <Typography variant="h6" noWrap>
              {(window.matchMedia("(max-width: 500px)").matches) ? title : title + " | " + currentEvent.name}
            </Typography>
            {(!active || !isBetweenStartAndEndDate) && (
              <ChipAlert
                title={getInactiveMessage()}
                helperText="Enquanto um evento estiver inativo e/ou fora de andamento (ou check-in), funções como realizar check-in poderão estar desabilitadas"
              />
            )}
          </div>
        </S.ContentWrapper>
      </Toolbar>
    </S.EventsAppBar>
  ));
};

export default EventAppBar;
