import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useObserver } from "mobx-react-lite";
import { autorun } from "mobx";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { useStore } from "Store";
import * as S from "./styles";
import Grid from "@material-ui/core/Grid";

// @ts-ignore
import { FormikTextField } from "formik-material-fields";

import LoadingModal from "Components/LoadingModal";

const override = css`
  margin: 0 auto;
  padding: 0;
`;

const Signin: React.FC = () => {
  const history = useHistory();
  const { userStore, appStore, subscriptionsStore } = useStore();

  useEffect(() => {
    appStore.initializeBaseURL();
  }, []);

  useEffect(
    () =>
      autorun(() => {
        if (userStore.isSignedIn) {
          history.push("/");
        }
      }),
    [userStore, userStore.isSignedIn, history]
  );

  const onHandleSubmit = async (values: any, rest: any) => {
    try {
      const response = await userStore.signin(values.username, values.password);
      if (response) {
        //Registra no evento caso esteja vindo de um
        if (userStore.isSignedIn && userStore.currentUser && subscriptionsStore.eventIdForSubscribe && subscriptionsStore.eventIdForSubscribe !== "") {
          await subscriptionsStore.onSubscribeEvent(subscriptionsStore.eventIdForSubscribe, userStore.currentUser._id);
        }

        rest.setSubmitting(false);

        history.replace("/");
      }
    } catch (error) {
      rest.setSubmitting(false);
      console.log(error);
    }
  };

  const SigninSchema = Yup.object().shape({
    username: Yup.string().required("Por favor, insira seu nome de usuário"),
    password: Yup.string().required("Por favor, insira sua senha"),
  });
  const SigninInicialValues = {
    username: "",
    password: "",
  };

  return useObserver(() => (
    <S.Container>
      <LoadingModal open={userStore.isSigninInUnderTheHood}>
        <S.LoadingStatusContainer>
          Tentando login automático...
        </S.LoadingStatusContainer>
      </LoadingModal>
      <S.LogoContainer />
      <S.ContentContainer>
        <Formik
          onSubmit={onHandleSubmit}
          initialValues={SigninInicialValues}
          validateOnBlur={true}
          validationSchema={SigninSchema}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <Grid container spacing={2} style={{display: "block"}}>
                <S.FormContainer>
                  <S.FormSubtitle>
                    Entre com o seu usuário e senha cadastrados no sistema
                  </S.FormSubtitle>
                  <FormikTextField
                    name="username"
                    label="Nome de usuário"
                    fullWidth
                    variant="outlined"
                  />
                  <FormikTextField
                    name="password"
                    label="Senha"
                    type="password"
                    fullWidth
                    variant="outlined"
                  />
                  <S.LoginButton
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  >
                    <S.LoginButtonTitle>
                      {isSubmitting ? (
                        <ClipLoader
                          css={override}
                          size={14}
                          color={"#fff"}
                          loading={true}
                        />
                      ) : (
                        "Login"
                      )}
                    </S.LoginButtonTitle>
                  </S.LoginButton>

                  {/* <S.SignupButton
                    disabled={false}
                    onClick={() => history.push("/signup")}
                  >
                    <S.LoginButtonTitle>Cadastre-se</S.LoginButtonTitle>
                  </S.SignupButton> */}
                  {/* <S.SettingsButton
                    disabled={false}
                    onClick={() => history.push("/settings/url")}
                  >
                    <S.LoginButtonTitle>Alterar servidor</S.LoginButtonTitle>
                  </S.SettingsButton> */}
                  <S.ForgotTitle
                   onClick={() => {
                    history.push("/forgot-password")
                   }}>
                    Esqueci minha senha...
                  </S.ForgotTitle>
                </S.FormContainer>
              </Grid>
            </Form>
          )}
        </Formik>
      </S.ContentContainer>
    </S.Container>
  ));
};

export default Signin;
