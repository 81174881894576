import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Dialog,
  Fade,
  Container,
  Button,
  DialogActions,
} from "@material-ui/core";

import { Formik, Form } from "formik";
import { initialValues } from "Pages/Companies/models";
import { validationSchema } from "Pages/Attendees/Form/models";

import { GenericButton } from "Components/Styled/Form";
//@ts-ignore
import { FormikSelectField } from "formik-material-fields";
import { useParams } from "react-router-dom";
import { useStore } from "Store";
import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: "10px 0",
    minWidth: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    "& .MuiTextField-root": {
      margin: "10px 0",
    },
    backgroundColor: theme.palette.background.paper,

    height: "auto",
    maxWidth: "800px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

interface Props {
  openIt: boolean;
  setOpen: Function;
  attendeeId: string;
  attendeeIds: string[];
  onEdit: Function;
}
interface MyValuesHere {
  status: string;
}
interface RouteParams {
  eventId: string;
}

const ModalEditStatus = ({ openIt, setOpen, attendeeId, attendeeIds, onEdit }: Props) => {
  const [stateStatus, setstateStatus] = useState("");
  const { eventId } = useParams<RouteParams>();
  const { eventsStore } = useStore();
  const classes = useStyles();
  const [isFetching, setIsFetching] = useState(false);
  const { attendeesStore } = useStore();
  const [status, setStatus] = useState<{label: string, value: string}[]>([]);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (values: MyValuesHere) => {
    setIsFetching(true);
    if (attendeeIds && attendeeIds.length > 0) {
      for (let atId of attendeeIds) {
        await eventsStore.updateStatus(eventId, atId, values.status);
      }
    } else {
      await eventsStore.updateStatus(eventId, attendeeId, values.status);
    }
    await onEdit();
    setIsFetching(false);
    setOpen(false);
  };

  const initialValues: MyValuesHere = {
    status: "", // nome fantasia
  };

  useEffect(() => {
    attendeesStore.fetchAttendeeTypes({page: 1, limit: 999});
  }, [attendeesStore]);

  useEffect(() => {
    if (attendeesStore.attendeeTypes && attendeesStore.attendeeTypes.length > 0) {
      var newStatus: {label: string, value: string}[] = [];

      attendeesStore.attendeeTypes.map(type => {
        newStatus.push({label: type.name, value: type.value});
      })

      setStatus(newStatus);
    }
  }, [attendeesStore.attendeeTypes]);

  // const status = [
  //   { label: initialValues.status, value: "" },
  //   { label: "VIP", value: "vip" },
  //   { label: "NORMAL", value: "standard" },
  //   { label: "EMPRESA ASSOCIADA", value: "associated_company" },
  //   { label: "EMPRESA NÃO ASSOCIADA", value: "not_associated_company" },
  //   { label: "EMPRESA PARCEIRA", value: "partner_company" },
  //   { label: "EMPRESA PATROCINADORA", value: "sponsor_company" },
  //   { label: "CONVIDADO", value: "guest" },
  //   { label: "DIRETORIA", value: "director" },
  //   { label: "PROFISSIONAL LIBERAL", value: "liberal_professional" },
  //   { label: "ESTUDANTE", value: "student" },
  //   { label: "PRESTADOR DE SERVIÇO", value: "service_provider" },
  // ];
  return (
    <Dialog fullWidth maxWidth="sm" open={openIt}>
      <Fade in={openIt}>
        <div className={classes.paper}>
          <h2 style={{ color: "#00577C" }}>Tipo do Participante</h2>

          <Container>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values }) => (
                <>
                  <FormContainer
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Form autoComplete="off">
                      <FormContainer>
                        <FormikSelectField
                          style={{ width: "100%" }}
                          value={values.status}
                          onChange={(e: any) => setstateStatus(e.target.value)}
                          className="form-field"
                          name="status"
                          label="Tipo"
                          options={status}
                          variant="outlined"
                        />
                      </FormContainer>
                      <DialogActions>
                        <Button
                          onClick={handleClose}
                          variant="contained"
                          color="secondary"
                          disabled={isFetching}
                        >
                          Cancelar
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={isFetching}
                          onClick={() => onSubmit(values)}
                        >
                          Atualizar
                        </Button>
                      </DialogActions>
                    </Form>
                  </FormContainer>
                </>
              )}
            </Formik>
          </Container>
        </div>
      </Fade>
    </Dialog>
  );
};

export default ModalEditStatus;

const FormContainer = styled.div`
  margin-bottom: 20px;
`;
