import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  width: 100%;
`

export const UserNameAndRoleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  
  color: #fff;

  .username, .role {
    margin: 0;
    padding: 0;
  }
  .username {
    font-size: 18px;
    font-weight: bold;
  }
  .role {
    margin-top: -5px;
    font-size: 14px;
  }
  @media (max-width: 500px) {
    display: none;
  }
`

interface IStatusRowProps {
  online: boolean;
}
export const StatusRow = styled.div<IStatusRowProps> `
  display: flex;
  align-items: center;

  margin: 0 20px;

  color: ${props => props.online ? '#2CDA2C' : '#721c24'}
`