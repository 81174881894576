import React, { useState } from "react";

import * as S from "./style";

import {
  CssBaseline,
  Container,
  Paper,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import { useStore } from "Store";
import { history } from "Store/History";
import { useObserver } from "mobx-react-lite";
const BaseURL = () => {
  const { appStore } = useStore();
  const [baseURL, setBaseURL] = useState("");
  const handleUpdateBaseURL = () => {
    setBaseURL("");
    appStore.setCurrentBaseApi(baseURL);
  };
  const handleResetBaseURL = () => {
    appStore.resetCurrentBaseApi();
  };
  const handleSync = () => {
    appStore.sync();
  };

  return useObserver(() => (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        <Paper style={{ marginTop: 50, boxSizing: "border-box" }}>
          <S.Container>
            <h4 style={{ margin: 0 }}>
              URL atual: (endereço que será levando em conta na hora de
              sincronizar)
            </h4>
            <h3>{appStore.currentBaseURL}</h3>
            <TextField
              value={baseURL}
              onChange={(e) => setBaseURL(e.target.value)}
              fullWidth
              variant="outlined"
              label="Novo endereço"
              placeholder="http://example.com:9999"
            />

            <Button onClick={() => history.replace("/")} variant="contained">
              Retornar ao início
            </Button>
            <Button onClick={handleResetBaseURL} variant="contained">
              Restaurar valor padrão
            </Button>
            <Button onClick={handleSync} variant="contained">
              Sincronizar
            </Button>
            <Button
              onClick={handleUpdateBaseURL}
              color="secondary"
              variant="contained"
            >
              Alterar endereço do servidor
            </Button>
          </S.Container>
        </Paper>
      </Container>
    </React.Fragment>
  ));
};

export default BaseURL;
