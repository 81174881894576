import React, { useEffect, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useStore } from "Store";
import { Formik, Form as FormikForm, Field } from "formik";
import InputMask from "react-input-mask";

import NoCompanyDialog from "./components/NoCompanyDialog";
import axios from "axios";

import { Company } from "Models/Companies";
import {
  IFormValues,
  validationSchema,
  IOptions,
  genderOptions,
  IResponse,
} from "./models";

import logoAdapa from "Assets/Images/adapa.png";

import * as S from "./styles";

// * Material Ui and Form related

// @ts-ignore
import { estados } from "estados-br";

import {
  PrimaryActionButton,
  SecondaryActionButton,
} from "Components/Styled/Form";

import Grid from "@material-ui/core/Grid";
// @ts-ignore
import { FormikTextField } from "formik-material-fields";
// @ts-ignore
import { FormikSelectField } from "formik-material-fields";
import { Container, Typography } from "@material-ui/core";
import { toast } from "react-toastify";
import { validatePhone } from "Utils";
import SearchBar from "Components/SearchBar";
import LoadingModal from "Components/LoadingModal";
import { LoadingStatusContainer } from "Pages/Home/CreateOrEditEvent/components/SecondStep/styles";
import { FormikAutocomplete } from "Signup";
import { IBusiness } from "Models/Attendees";
import { EventModel } from "Models/Events";
const StringMask = require("string-mask");
interface RouteParams {
  attendeeId: string;
  eventId: string;
}

const Form = () => {
  const formatter = new StringMask("(00) 00000-0000");
  const [hasNoCompany, setHasNoCompany] = useState(false);
  const { attendeeId, eventId } = useParams<RouteParams>();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<IFormValues>({
    first_name: "",
    last_name: "",
    nickname: "",
    gender: "female",
    cpf: "",
    business_id: "",
    job_title: "",
    country_code: "55",
    area_code: "00",
    phone_number: "",
    email: "",
    state: "PA",
    city: "",
    district: "",
    street: "",
    zipcode: "",
    complement: ""
  });
  const [currentUf, setCurrentUf] = useState("PA");
  const [zipcode, setZipcode] = useState("");
  const [zipcodeError, setZipcodeError] = useState(false);
  const [zipcodeErrorMessage, setZipcodeErrorMessage] = useState("");
  const [isFetchingCep, setIsFetchingCep] = useState(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [companiesOptions, setCompaniesOptions] = useState<IOptions[]>([]);
  const { companyStore, attendeesStore, appStore, eventsStore } = useStore();
  const history = useHistory();
  const [currentFile, setCurrentFile] = useState<FileList | null>(null);
  const [user, setUser] = useState<any>();
  const [selectedCompany, setSelectedCompany] = useState("" as string);
  const [respBusiness, setRespBusiness] = useState<IBusiness | null>(null);
  const [currentEvent, setCurrentEvent] = useState<EventModel | null>();

  const updateInitialValues = useCallback(async () => {
    try {
      const response: any = await attendeesStore.fetchAttendeeById(attendeeId);
      setUser(response!.user);
      const {
        user: {
          first_name,
          last_name,
          nickname,
          business,
          cpf,
          email,
          gender,
          job_title,
          phone,
          address
        },
      }: IResponse = response as IResponse;
      
      const phone_splited = phone.number.split(" ");
      const phone_number = formatter.apply(
        phone.area_code + (phone_splited.length > 1 ? phone_splited[1].replace("-", "") : phone_splited[0].replace("-", ""))
      );

      setInitialValues((previousValues: IFormValues) => ({
        ...previousValues,
        first_name,
        last_name,
        nickname,
        gender,
        cpf: getFormattedCPF(cpf),
        business_id: business ? business._id : "",
        email,
        job_title,
        phone_number: phone_number,
        street_number: address && address.street_number ? address.street_number : "",
        state: address && address.state ? address.state : "",
        city: address && address.city ? address.city.toUpperCase() : "",
        district: address && address.district ? address.district.toUpperCase() : "",
        street: address && address.street ? address.street.toUpperCase() : "",
        zipcode: address && address.zipcode ? address.zipcode : "",
        complement: address && address.complement ? address.complement.toUpperCase() : ""
      }));

      setRespBusiness(business);

      if (address && address.zipcode)
        setZipcode(address.zipcode);
      
    } catch (error) {
      console.log(error);
    }
  }, [attendeeId, attendeesStore]);

  useEffect(() => {
    if (attendeeId) {
      updateInitialValues();
    }
  }, [updateInitialValues, attendeeId]);

  const fetchCurrentEvent = useCallback(async () => {
    try {
      const response = await eventsStore.fetchEventById(eventId);
      // @ts-ignore
      setCurrentEvent(response.data.event);
    } catch (error) {
      console.log(error);
    }
  }, [eventId, eventsStore]);

  useEffect(() => {
    if (eventId) {
      fetchCurrentEvent();
    }
  }, [eventId]);

  const getFormattedCPF = (cpf: string) => {
    if (!cpf) return "";

    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  useEffect(() => {
    if (companies.length > 0) {
      const localArr: IOptions[] = [];
      companies.map((company) => {
        return localArr.push({ label: company.legal_name, value: company._id });
      });
      setCompaniesOptions(localArr);

      if (respBusiness) {
        setSelectedCompany(respBusiness._id);
      }
    }
  }, [companies]);

  const getCompaniesData = useCallback(async () => {
    const response = await companyStore.fetchCompanies(1,999);
    // @ts-ignore
    setCompanies(response.businesses);
    // @ts-ignore
    if (response.businesses.length === 0) {
      setHasNoCompany(true);
    }
  }, [companyStore]);

  useEffect(() => {
    getCompaniesData();
  }, [getCompaniesData]);

  const getAddressDataByCep = async (
    zipcode: string,
    setFieldValue: Function,
    setFieldError: Function
  ) => {
    try {
      setIsFetchingCep(true);
      const response = await axios.get(
        `https://viacep.com.br/ws/${zipcode}/json/`
      );
      if (!response.data.erro) {
        setFieldValue("city", response.data.localidade);
        setFieldValue("district", response.data.bairro);
        setFieldValue("street", response.data.logradouro);
        setFieldValue("state", response.data.uf);
        setCurrentUf(response.data.uf);
        setFieldError("zipcode", null);
        setZipcodeError(false);
        setZipcodeErrorMessage("");
      } else {
        setZipcodeError(true);
        setZipcodeErrorMessage("CEP não encontrado");
        setFieldError("zipcode", "CEP não encontrado");
        // setFieldValue("district", "");
        // setFieldValue("city", "");
        // setFieldValue("street", "");
        // setCurrentUf("PA");
      }
      setIsFetchingCep(false);
    } catch (error) {
      // setFieldValue("city", "");
      // setFieldValue("street", "");
      // setCurrentUf("PA");
      setIsFetchingCep(false);
    }
  };

  const handleCepChange = (
    value: string,
    setFieldValue: Function,
    setFieldError: Function
  ) => {
    try {
      if (value.charAt(8) !== "_") {
        const formattedCep = value.replace("-", "");
        getAddressDataByCep(formattedCep, setFieldValue, setFieldError);
      }
      setFieldValue("zipcode", value);
      setZipcode(value);
    } catch (error) {}
  };

  const onSubmit = async (values: IFormValues) => {
    try {
      if (values.phone_number) {
        var valid = validatePhone(values.phone_number);
        if (!valid) {
          toast.error("Telefone inválido, altere e tente novamente.");
          return;
        }
      }

      if (!selectedCompany) {
        toast.error("Selecione uma empresa para prosseguir.");
        return;
      }

      if (selectedCompany && selectedCompany !== "") values.business_id = selectedCompany;

      setLoading(true);
      let userId = attendeeId;
      let user: any = undefined;

      if (attendeeId) {
        await attendeesStore.updateAttendee(attendeeId, values);
      } else {
        const response: any = await attendeesStore.registerAttendee(values);
        setUser(response!.user);
        userId = response!.user._id;
        user = response!.user;
      }

      if (currentFile) {
        await attendeesStore.updateAttendeeProfileImage(userId, currentFile[0]);
      }

      setLoading(false);

      if (user && eventId) {
        await attendeesStore.linkAttendeeToEvent({
          user_id: userId,
          eventId,
        });

        history.push(`/events/${eventId}`);
        
      } else {
        history.replace("/attendees");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const validateCpf = (cpf: string) => {
    let r = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    return !r.test(cpf);
  };

  return (
    <>
      <SearchBar title={"Cadastro de Participante" + (eventId && currentEvent ? " | Evento: " + currentEvent.name : "")} />
      <LoadingModal open={loading}>
        <LoadingStatusContainer>Carregando...</LoadingStatusContainer>
      </LoadingModal>
      <NoCompanyDialog open={hasNoCompany} />
      <Container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({
            setFieldValue,
            setFieldError,
            values,
            handleChange,
            errors,
          }) => (
            <>
              <S.FormContainer>
                <S.FormContent>
                  <FormikForm autoComplete="off">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="first_name"
                          label="Nome"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="last_name"
                          label="Sobrenome"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormikTextField
                          name="nickname"
                          label="Nome para Crachá"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikSelectField
                          style={{ width: "100%" }}
                          className="form-field"
                          name="gender"
                          label="Gênero"
                          options={genderOptions}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {/* <InputMask
                          mask="999.999.999-99"
                          name="cpf"
                          value={values.cpf}
                          onChange={handleChange}
                        > */}
                          {/* {() => ( */}
                            <FormikTextField
                              name="cpf"
                              label="CPF"
                              fullWidth
                              variant="outlined"
                              // validate={validateCpf}
                            />
                          {/*} )} 
                        {/* </InputMask> */}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {/* {user ? (
                          <FormikSelectField
                            style={{ width: "100%" }}
                            className="form-field"
                            name="business_id"
                            label="Empresa"
                            options={companiesOptions}
                            variant="outlined"
                          />
                        ) : ( */}
                          <Field
                            name="business_id_search"
                            className="form-field"
                            component={FormikAutocomplete}
                            variant="outlined"
                            label="Empresa"
                            value={selectedCompany}
                            options={companiesOptions}
                            //@ts-ignore
                            onChange={(target => {
                              console.log("Selected: " + target.value);
                              if (target && target.value) setSelectedCompany(target.value);
                            })}
                          />
                        {/* )} */}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="job_title"
                          label="Cargo"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="phone_number"
                          label="Telefone"
                          fullWidth
                          variant="outlined"
                        />
                        {/* <InputMask
                          mask="(99) 99999-9999"
                          name="phone_number"
                          value={values.phone_number}
                          onChange={handleChange}
                        >
                          {() => (
                            <FormikTextField
                              name="phone_number"
                              label="Telefone"
                              fullWidth
                              variant="outlined"
                              validate={validatePhone}
                            />
                          )}
                        </InputMask> */}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="email"
                          label="Email"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {/* <InputMask
                          // mask="99999-999"
                          name="zipcode"
                          // value={zipcode}
                          onChange={(e) => {
                            // handleCepChange(
                            //   e.target.value,
                            //   setFieldValue,
                            //   setFieldError
                            // )
                          }}
                        >
                          {() => ( */}
                            <FormikTextField
                              fullWidth
                              label="CEP"
                              variant="outlined"
                              name="zipcode"
                              helperText={
                                zipcodeErrorMessage
                              }
                              error={
                                zipcodeError
                              }
                              onChange={(e: any) => {
                                handleCepChange(
                                  e.target.value,
                                  setFieldValue,
                                  setFieldError
                                )
                              }}
                            />
                          {/* )}
                        </InputMask> */}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikSelectField
                          style={{ width: "100%" }}
                          className="form-field"
                          name="state"
                          value={currentUf}
                          label="UF"
                          options={estados.map((estado: any) => ({
                            label: estado.sigla,
                            value: estado.sigla,
                          }))}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={10}>
                        <FormikTextField
                          name="street"
                          label="Rua"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <FormikTextField
                          name="street_number"
                          label="Número"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikTextField
                          name="complement"
                          label="Complemento"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="district"
                          label="Bairro"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="city"
                          label="Cidade"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      
                      {attendeeId && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <FormikTextField
                              name="password"
                              label="Nova Senha"
                              fullWidth
                              type="password"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormikTextField
                              name="confirm_password"
                              label="Confirmar Nova Senha"
                              fullWidth
                              type="password"
                              variant="outlined"
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} sm={12}>
                        <input
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setCurrentFile(e.target.files)
                          }}
                          type="file"
                          id="file"
                          accept="image/*"
                        />
                        <label htmlFor="file" style={{background: "#00577b"}}>Anexar Imagem de Perfil</label>
                        {currentFile && (
                          <Typography variant="h6" gutterBottom>
                            Arquivo: {currentFile[0].name}
                          </Typography>
                        )}
                      </Grid>
                      {attendeeId && user && user.profile_image_urls && user.profile_image_urls.x256 && (
                        <Grid item xs={12} sm={12}>
                          <img
                            src={appStore.currentBaseURL + "/" + user?.profile_image_urls?.x256}
                            alt="cover"
                            height="100"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src=logoAdapa;
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                    <S.ButtonRow>
                      <SecondaryActionButton
                        onClick={() => history.replace("/attendees")}
                      >
                        Cancelar
                      </SecondaryActionButton>
                      <PrimaryActionButton type="submit"
                        disabled={!!attendeesStore.isSubmittingAttendee}>
                        {attendeesStore.isSubmittingAttendee ? "Carregando..." : (attendeeId ? "Atualizar" : "Cadastrar")}
                      </PrimaryActionButton>
                    </S.ButtonRow>

                    {user && user.register_questions && user.register_questions.length >= 1 && (
                      <Grid container spacing={2} style={{marginTop: "15px"}}>
                        <Grid item xs={12} sm={12}>
                          <S.FormTitle>
                            Perguntas de registro
                          </S.FormTitle>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          {user.register_questions[0].question_1}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          R: {user.register_questions[0].answer_1 ? "Sim" : "Não"}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          {user.register_questions[0].question_2}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          R: {user.register_questions[0].answer_2 ? "Sim" : "Não"}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          {user.register_questions[0].question_3}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          R: {user.register_questions[0].answer_3 ? "Sim" : "Não"}
                        </Grid>
                      </Grid>
                    )}
                  </FormikForm>
                </S.FormContent>
              </S.FormContainer>
            </>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default Form;
