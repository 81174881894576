import React from "react";
import { GenericButton } from "Components/Styled/Form";
import jsPDF from "jspdf";
import QRCode from "qrcode";

import { IAttendee } from "Models/Attendees";
import { Registration } from "Models/Events";

interface Data {
  title?: string;
  header?: string;
  functionClose?: Function;
  renderFunction?: Function;
  userArray?: Registration[];
  participant?: string;
  description?: string;
  participant_type?: string;
  date?: string;
  all?: boolean;
  registrations?: number;
  image?: string;
  code?: string;
  event?: string;
  offsetX?: number;
  offsetY?: number;
  offsetRow?: number;
  slotMatrix?: boolean[][];
}

interface Props {
  data: Data;
  twoColumns: boolean;
  printerType: "etiquetasA4" | "etiquetasContinua";
}

const getProfileTypeName = (type: string) => {
  const types: { [key: string]: string } = {
    vip: "VIP",
    associated_company: "Empresa Associada",
    not_associated_company: "Empresa Não Associada",
    partner_company: "Empresa Parceira",
    sponsor_company: "Empresa Patrocinadora",
    guest: "Convidado",
    director: "Diretoria",
    liberal_professional: "Profissional Liberal",
    student: "Estudante",
    service_provider: "Prestador De Serviço",
    standard: "Participante",
    default: "Participante",
  };
  return types[type] || type;
};

const PdfBadge = (props: Props) => {
  const getAttendeeName = (user: IAttendee) => {
    if (user.nickname) return user.nickname.toUpperCase();
    else {
      let first_name = user.first_name.split(" ")[0];
      let last_name_array = user.last_name.split(" ");
      let last_name = last_name_array[last_name_array.length - 1];

      return (first_name + " " + last_name).toUpperCase();
    }
  };

  const generatePDF = async () => {
    try {
      if (props.printerType === "etiquetasA4") {
        // Código para etiquetas A4
        const docAll = new jsPDF("p", "mm", "a4");

        let offsetX = props.data.offsetX ? +props.data.offsetX : 4;
        let offsetY = props.data.offsetY ? +props.data.offsetY : 9;
        let slotMatrix = props.data.slotMatrix;
        const pageMaxItems = 8;
        let countRows = 0;
        let count = 0;
        var docWidth = docAll.internal.pageSize.getWidth();
        var column2Start = docWidth / 2;
        let columns = 1;
        let rowSpace = props.data.offsetRow ? +props.data.offsetRow : 34;

        if (props.twoColumns) columns = 2;

        if (props.data.all) {
          if (props.data.userArray) {
            var itemIndex = 0;

            for (let i = 0; i < props.data.userArray.length; i = i + columns) {
              if (countRows >= pageMaxItems) {
                docAll.addPage();
                countRows = 0;
              }

              count = countRows;

              docAll.setFontSize(10);

              if (columns == 1) {
                let item = props.data.userArray[i];

                const qrCodeBase = await QRCode.toDataURL(item._id);

                // Item
                docAll.text(
                  `${getAttendeeName(item.user)}`,
                  offsetX + 30,
                  offsetY + 11 + count * rowSpace
                );
                docAll.text(
                  `${
                    item.user.business && item.user.business.name
                      ? item.user.business.name.toUpperCase()
                      : item.user.business_title
                      ? item.user.business_title.toUpperCase()
                      : "N/D"
                  }`,
                  offsetX + 30,
                  offsetY + 15 + count * rowSpace
                );
                docAll.text(
                  `${
                    item.user.job_title
                      ? item.user.job_title.toUpperCase()
                      : "N/D"
                  }`,
                  offsetX + 30,
                  offsetY + 19 + count * rowSpace
                );
                docAll.text(
                  `${
                    item.profile_type
                      ? getProfileTypeName(item.profile_type).toUpperCase()
                      : "N/D"
                  }`,
                  offsetX + 30,
                  offsetY + 23 + count * rowSpace
                );
                docAll.addImage(
                  qrCodeBase,
                  "PNG",
                  offsetX + 10,
                  offsetY + 5 + count * rowSpace,
                  20,
                  21
                );
              } else {
                var ignoreSlot1 = false;
                var ignoreSlot2 = false;

                if (slotMatrix) {
                  if (slotMatrix[countRows][0] == false) ignoreSlot1 = true;
                  if (slotMatrix[countRows][1] == false) ignoreSlot2 = true;
                }

                if (!ignoreSlot1 && itemIndex < props.data.userArray.length) {
                  let item = props.data.userArray[itemIndex];

                  const qrCodeBase = await QRCode.toDataURL(item._id);

                  // Item
                  docAll.text(
                    `${getAttendeeName(item.user)}`,
                    offsetX + 30,
                    offsetY + 11 + count * rowSpace
                  );
                  docAll.text(
                    `${
                      item.user.business && item.user.business.name
                        ? item.user.business.name.toUpperCase()
                        : item.user.business_title
                        ? item.user.business_title.toUpperCase()
                        : "N/D"
                    }`,
                    offsetX + 30,
                    offsetY + 15 + count * rowSpace
                  );
                  docAll.text(
                    `${
                      item.user.job_title
                        ? item.user.job_title.toUpperCase()
                        : "N/D"
                    }`,
                    offsetX + 30,
                    offsetY + 19 + count * rowSpace
                  );
                  docAll.text(
                    `${
                      item.profile_type
                        ? getProfileTypeName(item.profile_type).toUpperCase()
                        : "N/D"
                    }`,
                    offsetX + 30,
                    offsetY + 23 + count * rowSpace
                  );
                  docAll.addImage(
                    qrCodeBase,
                    "PNG",
                    offsetX + 10,
                    offsetY + 5 + count * rowSpace,
                    20,
                    21
                  );

                  itemIndex++;
                }

                // Item 2
                if (
                  !ignoreSlot2 &&
                  itemIndex < props.data.userArray.length
                ) {
                  let item2 = props.data.userArray[itemIndex];

                  const qrCodeBase = await QRCode.toDataURL(item2._id);

                  docAll.text(
                    `${getAttendeeName(item2.user)}`,
                    offsetX + column2Start + 30,
                    offsetY + 11 + count * rowSpace
                  );
                  docAll.text(
                    `${
                      item2.user.business && item2.user.business.name
                        ? item2.user.business.name.toUpperCase()
                        : item2.user.business_title
                        ? item2.user.business_title.toUpperCase()
                        : "N/D"
                    }`,
                    offsetX + column2Start + 30,
                    offsetY + 15 + count * rowSpace
                  );
                  docAll.text(
                    `${
                      item2.user.job_title
                        ? item2.user.job_title.toUpperCase()
                        : "N/D"
                    }`,
                    offsetX + column2Start + 30,
                    offsetY + 19 + count * rowSpace
                  );
                  docAll.text(
                    `${
                      item2.profile_type
                        ? getProfileTypeName(item2.profile_type).toUpperCase()
                        : "N/D"
                    }`,
                    offsetX + column2Start + 30,
                    offsetY + 23 + count * rowSpace
                  );
                  docAll.addImage(
                    qrCodeBase,
                    "PNG",
                    offsetX + column2Start + 10,
                    offsetY + 5 + count * rowSpace,
                    20,
                    21
                  );

                  itemIndex++;
                }

                if (ignoreSlot1) i--;
                if (ignoreSlot2) i--;
              }

              countRows++;
            }
          }

          docAll.save("etiquetas.pdf");
        } else {
          // Para um único participante no modo A4
          const doc = new jsPDF();

          const qrCodeBase = await QRCode.toDataURL(props.data.code!);

          let offsetX = props.data.offsetX ? +props.data.offsetX : 4;
          let offsetY = props.data.offsetY ? +props.data.offsetY : 9;

          doc.setFontSize(10);

          doc.text(
            `${props.data.participant}`,
            offsetX + 30,
            offsetY + 11
          );
          doc.text(
            `${props.data.header}`,
            offsetX + 30,
            offsetY + 15
          );
          doc.text(
            `${props.data.description}`,
            offsetX + 30,
            offsetY + 19
          );
          doc.text(
            `${props.data.participant_type}`,
            offsetX + 30,
            offsetY + 23
          );
          doc.addImage(
            qrCodeBase,
            "PNG",
            offsetX + 10,
            offsetY + 5,
            20,
            21
          );

          doc.save("etiquetas.pdf");
        }
      } else if (props.printerType === "etiquetasContinua") {
        // Ajustes para etiquetas contínuas

        const labelWidth = 100; // Largura desejada da etiqueta em mm
        const labelHeight = 40; // Altura desejada da etiqueta em mm

        // Criar o documento com orientação 'portrait'
        const doc = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: [labelWidth, labelHeight],
        });

        if (props.data.all) {
          if (props.data.userArray) {
            for (let i = 0; i < props.data.userArray.length; i++) {
              if (i > 0) doc.addPage([labelWidth, labelHeight], "portrait");

              let item = props.data.userArray[i];
              const qrCodeBase = await QRCode.toDataURL(item._id);
              doc.setFontSize(9);

              // Define posições
              const textX = 26; // Posição X para o texto
              const textYStart = 30; // Posição Y para o texto (ajustado para não sobrepor o QR Code)
              const lineWidth = 5; // Espaço entre linhas

              // Adicionar texto rotacionado com o ângulo adequado
              doc.text(getAttendeeName(item.user), 
                textX, 
                textYStart, 
                {
                angle: -90,
                }
              );

              doc.text(
                item.user.business && item.user.business.name
                  ? item.user.business.name.toUpperCase()
                  : item.user.business_title
                  ? item.user.business_title.toUpperCase()
                  : "N/D",
                textX - lineWidth,
                textYStart,
                {
                  angle: -90,
                }
              );
              doc.text(
                item.user.job_title
                  ? item.user.job_title.toUpperCase()
                  : "N/D",
                textX - 2 * lineWidth,
                textYStart,
                {
                  angle: -90,
                }
              );
              doc.text(
                item.profile_type
                  ? getProfileTypeName(item.profile_type).toUpperCase()
                  : "N/D",
                textX - 3 * lineWidth,
                textYStart,
                {
                  angle: -90,
                }
              );

              // Adicionar QR Code com rotação
              doc.addImage(
                qrCodeBase,
                "PNG",
                7, // Posição X do QR Code
                -18, // Posição Y do QR Code
                24, // Largura do QR Code
                24, // Altura do QR Code
                undefined,
                undefined,
                -90
              );
            }
          }
          doc.save("etiquetas_continuas.pdf");
        } else {
          // Para um único participante no modo contínuo
          const qrCodeBase = await QRCode.toDataURL(props.data.code!);
          doc.setFontSize(9);

          // Define posições
          const textX = 26; // Posição X para o texto
          const textYStart = 30; // Posição Y para o texto (ajustado para não sobrepor o QR Code)
          const lineWidth = 5; // Espaço entre linhas

          doc.text(
            props.data.participant || "", 
            textX, 
            textYStart, {
            angle: -90,
          });
          doc.text(props.data.header || "", 
            textX - lineWidth, 
            textYStart, {
            angle: -90,
          });
          doc.text(
            props.data.description || "",
            textX - 2 * lineWidth,
            textYStart,
            {
              angle: -90,
            }
          );
          doc.text(
            props.data.participant_type || "",
            textX - 3 * lineWidth,
            textYStart,
            {
              angle: -90,
            }
          );

          // Adicionar QR Code
          doc.addImage(
            qrCodeBase,
            "PNG",
            7, // Posição X do QR Code
            -18, // Posição Y do QR Code
            24, // Largura do QR Code
            24, // Altura do QR Code
            undefined,
            undefined,
            -90
          );

          doc.save("etiqueta.pdf");
        }
      }
    } catch (error) {
      console.error("Erro ao gerar o PDF:", error);
      // Opcional: Exibir uma mensagem de erro para o usuário
    }
  };

  return (
    <>
      <GenericButton
        onClick={generatePDF}
        style={{
          width: "max-content",
          height: "auto",
          padding: "10px",
          float: "right",
        }}
      >
        GERAR
      </GenericButton>
      {props.data.all ? (
        <>
          {props.data.userArray?.map((item) => (
            <img
              key={item.user_id}
              id={`itf-${item.user_id}`}
              style={{ display: "none" }}
            />
          ))}
        </>
      ) : (
        <img id="itf" style={{ display: "none" }} />
      )}
    </>
  );
};

export default PdfBadge;