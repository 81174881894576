import styled from 'styled-components'

export const SearchBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  padding: 10px 20px;
  width: 100%;

  background: #E3E3E3;

  .page-title {
    font-size: 24px;
    font-weight: bold;
    color: #00577C;
  }
`