// src/Pages/Report/utils/pdf.tsx

import React, { useEffect } from "react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable"; // Importar autoTable diretamente
import { useStore } from "Store";
import moment from "moment";
//@ts-ignore
import { Base64 } from "js-base64";
import logoAdapa from "Assets/Images/adapa.png";
import { DialogActions, Button } from "@material-ui/core";

interface Props {
  setClose: Function;
}

const ReportToPDF = ({ setClose }: Props) => {
  const { reportStore } = useStore();

  useEffect(() => {
    reportStore.getReportMetrics();
    reportStore.getGenderReport(12);
    reportStore.getTimeRegistration(12);
  }, [reportStore]);

  const exportPDF = () => {
    try {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 ou A4
      const orientation = "portrait"; // portrait ou landscape
      const marginLeft = 40; // Ajustado para 40 conforme uso no texto
      const doc = new jsPDF(orientation, unit, size);

      // Converter a imagem para Base64 corretamente (opcional, dependendo do uso)
      const imgData = "data:image/png;base64," + Base64.encode(String(logoAdapa));

      // Carregar a imagem
      const img = new Image();
      img.src = logoAdapa;

      img.onload = () => {
        doc.addImage(img, "PNG", 40, 50, 100, 80); // Tipo corrigido para "PNG"

        doc.setFontSize(14);
        doc.setFont("helvetica", "bold"); // Substituído setFontType por setFont
        doc.text(`RELATÓRIO`, 250, 150);

        doc.setFontSize(12);
        doc.setFont("helvetica", "normal"); // Substituído setFontType por setFont
        doc.text(`${moment(new Date()).format("DD/MM/YYYY")}`, 470, 180);

        doc.setFontSize(12);
        doc.setFont("helvetica", "normal"); // Substituído setFontType por setFont
        doc.text(
          `Inscritos em eventos: ${reportStore.reportData.registrations}`,
          marginLeft,
          200
        );
        doc.text(
          `Checkins realizados: ${reportStore.reportData.checkins}`,
          marginLeft,
          220
        );
        doc.text(
          `Convites para visitantes: ${reportStore.reportData.guests}`,
          marginLeft,
          240
        );
        doc.text(
          `Sorteios realizados: ${reportStore.reportData.draws}`,
          marginLeft,
          260
        );

        // Títulos das Tabelas
        const titleGender = "Inscrições por Gênero";
        const titleRegistration = "Inscrições por mês";
        const titleAttendees = "Participantes";

        // Definir posições Y para os títulos e tabelas
        let currentY = 280;

        // Tabela de Gênero
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text(titleGender, marginLeft, currentY);
        currentY += 20;
        autoTable(doc, {
          head: [["Mês", "Mulheres", "Homens"]],
          body: reportStore.gender.registrations.map((gender) => [
            gender.month,
            gender.female,
            gender.male,
          ]),
          theme: "grid",
          styles: {
            halign: "center",
            fillColor: [60, 149, 185],
            textColor: "#000000",
          },
          columnStyles: {
            0: { halign: "center", fillColor: "#ffffff" },
            1: { halign: "center", fillColor: "#ffffff" },
            2: { halign: "center", fillColor: "#ffffff" },
          },
          startY: currentY,
        });
        // Atualizar a posição Y após a tabela
        currentY = (doc as any).lastAutoTable.finalY + 10;

        // Tabela de Inscrições por Mês
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text(titleRegistration, marginLeft, currentY);
        currentY += 20;
        autoTable(doc, {
          head: [["Mês", "Quantidade de inscritos"]],
          body: reportStore.registrationTime.registrations.map(
            (registration) => [registration.date, registration.registrations]
          ),
          theme: "grid",
          styles: { halign: "center", textColor: "#000000" },
          startY: currentY,
        });
        currentY = (doc as any).lastAutoTable.finalY + 10;

        // Tabela de Participantes
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text(titleAttendees, marginLeft, currentY);
        currentY += 20;
        autoTable(doc, {
          head: [["Mês", "Quantidade de inscritos"]], // Supondo que seja a mesma estrutura
          body: reportStore.registrationTime.registrations.map(
            (registration) => [registration.date, registration.registrations]
          ),
          theme: "grid",
          styles: { halign: "center", textColor: "#000000" },
          startY: currentY,
        });

        doc.save("relatorio.pdf");
        setClose(false);
      };

      img.onerror = () => {
        console.error("Erro ao carregar a imagem do logo.");

        doc.setFontSize(14);
        doc.setFont("helvetica", "bold"); // Substituído setFontType por setFont
        doc.text(`RELATÓRIO`, 250, 150);

        doc.setFontSize(12);
        doc.setFont("helvetica", "normal"); // Substituído setFontType por setFont
        doc.text(`${moment(new Date()).format("DD/MM/YYYY")}`, 470, 180);

        doc.setFontSize(12);
        doc.setFont("helvetica", "normal"); // Substituído setFontType por setFont
        doc.text(
          `Inscritos em eventos: ${reportStore.reportData.registrations}`,
          marginLeft,
          200
        );
        doc.text(
          `Checkins realizados: ${reportStore.reportData.checkins}`,
          marginLeft,
          220
        );
        doc.text(
          `Convites para visitantes: ${reportStore.reportData.guests}`,
          marginLeft,
          240
        );
        doc.text(
          `Sorteios realizados: ${reportStore.reportData.draws}`,
          marginLeft,
          260
        );

        // Títulos das Tabelas
        const titleGender = "Inscrições por Gênero";
        const titleRegistration = "Inscrições por mês";
        const titleAttendees = "Participantes";

        // Definir posições Y para os títulos e tabelas
        let currentY = 280;

        // Tabela de Gênero
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text(titleGender, marginLeft, currentY);
        currentY += 20;
        autoTable(doc, {
          head: [["Mês", "Mulheres", "Homens"]],
          body: reportStore.gender.registrations.map((gender) => [
            gender.month,
            gender.female,
            gender.male,
          ]),
          theme: "grid",
          styles: {
            halign: "center",
            fillColor: [60, 149, 185],
            textColor: "#000000",
          },
          columnStyles: {
            0: { halign: "center", fillColor: "#ffffff" },
            1: { halign: "center", fillColor: "#ffffff" },
            2: { halign: "center", fillColor: "#ffffff" },
          },
          startY: currentY,
        });
        currentY = (doc as any).lastAutoTable.finalY + 10;

        // Tabela de Inscrições por Mês
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text(titleRegistration, marginLeft, currentY);
        currentY += 20;
        autoTable(doc, {
          head: [["Mês", "Quantidade de inscritos"]],
          body: reportStore.registrationTime.registrations.map(
            (registration) => [registration.date, registration.registrations]
          ),
          theme: "grid",
          styles: { halign: "center", textColor: "#000000" },
          startY: currentY,
        });
        currentY = (doc as any).lastAutoTable.finalY + 10;

        // Tabela de Participantes
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text(titleAttendees, marginLeft, currentY);
        currentY += 20;
        autoTable(doc, {
          head: [["Mês", "Quantidade de inscritos"]], // Supondo que seja a mesma estrutura
          body: reportStore.registrationTime.registrations.map(
            (registration) => [registration.date, registration.registrations]
          ),
          theme: "grid",
          styles: { halign: "center", textColor: "#000000" },
          startY: currentY,
        });

        doc.save("relatorio.pdf");
        setClose(false);
      };
    } catch (error) {
      console.error("Erro ao gerar o PDF:", error);
      // Opcional: Exibir uma mensagem de erro para o usuário
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            onClick={() => setClose(false)}
            color="secondary"
            style={{
              width: "150px",
              padding: "10px",
              margin: "10px",
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => exportPDF()}
            style={{
              width: "150px",
              padding: "10px",
              margin: "10px",
            }}
          >
            Gerar relatório
          </Button>
        </DialogActions>
      </div>
    </div>
  );
};

export default ReportToPDF;