export enum ActionType {
  SetIsLoading = "SetIsLoading",
  SetEventDraws = "SetEventDraws",
  SetCurrentDrawId = "SetCurrentDrawId",
  SetAnchorEl = "SetAnchorEl",
  SetIsContextMenuOpen = "SetIsContextMenuOpen",
  SetIsDeleteDialogOpen = "SetIsDeleteDialogOpen",
  SetIsReRunDialogOpen = "SetIsReRunDialogOpen",
  SetIsWinnerDialogOpen = "SetIsWinnerDialogOpen",
  SetWinnerName = "SetWinnerName",
  SetIsFetchingDraws = "SetIsFetchingDraws",
  SetIsDeletingDraw = "SetIsDeletingDraw",
}

export interface IDraw {
  prize: string;
  prize_description: string;
  competitors: any[];
  winner?: any;
  drawn_at?: any;
  _id: string;
  event: string;
  created_at: Date;
  updated_at: Date;
  __v: number;
}

export interface IState {
  isLoading: boolean;
  currentDrawId: string;
  draws: IDraw[];
  anchorEl: null | HTMLElement;
  isContextMenuOpen: boolean;
  isDeleteDialogOpen: boolean;
  isReRunDialogOpen: boolean;
  isWinnerDialogOpen: boolean;
  isFetchingDraws: boolean;
  isDeletingDraw: boolean;
  winnerName: string;
}

export interface IAction {
  type: ActionType;
  payload: any;
}

export const initialState: IState = {
  currentDrawId: "",
  isLoading: false,
  draws: [],
  anchorEl: null,
  isContextMenuOpen: false,
  isDeleteDialogOpen: false,
  isReRunDialogOpen: false,
  isWinnerDialogOpen: false,
  isFetchingDraws: false,
  isDeletingDraw: false,
  winnerName: "",
};
