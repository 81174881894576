import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useObserver } from "mobx-react-lite";

// @ts-ignore
import { useDebounce } from "use-debounce";

import CompanyContextMenu from "./components/CompanyContextMenu";
import DeleteCompanyDialog from "./components/DeleteCompanyDialog";
import LoadingModal from "Components/LoadingModal";
import { LoadingStatusContainer } from "./styles/index";
import { NoDataContainer } from "Components/Styled/util";

import * as S from "./styles";
import { CustomCircularProgress } from "Components/Styled/Loading";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  createStyles,
  Theme,
  Paper,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Pagination from "@material-ui/lab/Pagination";
import { Add } from "@material-ui/icons";
import {
  Container,
  GenericFAB,
  GenericTableHead,
  FlexTD,
  GenericLeftFAB,
} from "Components/Styled/table";
import { useStore } from "Store";
import { MoreVert } from "@material-ui/icons";
import SearchBar from "Components/SearchBar";
import logo from "../../Assets/Images/pp.jpeg";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    status__active: {
      color: "#2cda2c",
    },
    status__inactive: {
      color: "#e72419",
    },
  })
);
const AttendeeTypes = () => {
  const history = useHistory();
  const classes = useStyles();
  const { attendeesStore } = useStore();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const isContextMenuOpen = Boolean(anchorEl);
  const [currentCompanyId, setCurrentCompanyId] = useState<string | null>("");
  const [filter, setFilter] = useState("");
  const [debounced] = useDebounce(filter, 500);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    companyId: string
  ) => {
    setCurrentCompanyId(companyId);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    console.log(debounced);
    attendeesStore.fetchAttendeeTypes({page: page + 1, limit});
  }, [debounced, attendeesStore]);

  useEffect(() => {
    attendeesStore.fetchAttendeeTypes({page: page + 1, limit: limit});
  }, [attendeesStore]);

  return useObserver(() => (
    <>
      <SearchBar title={"Tipos de Participantes"} />
      <Container style={{ marginBottom: "60px" }}>
        <GenericLeftFAB
          className={classes.fab}
          variant="extended"
          color="primary"
          onClick={() => history.push("/tipos-participantes/form")}
        >
          <Add /> Novo Tipo
        </GenericLeftFAB>
        {attendeesStore.attendeeTypes.length > 0 ? (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="attendees table">
              <GenericTableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Valor</TableCell>

                  <TableCell></TableCell>
                </TableRow>
              </GenericTableHead>
              <TableBody>
                {attendeesStore.isFetchingAttendeeTypes ? (
                  <TableRow>
                    <td colSpan={4}>
                      <S.LoadingContainer>
                        <CustomCircularProgress />
                      </S.LoadingContainer>
                    </td>
                  </TableRow>
                ) : (
                  attendeesStore.attendeeTypes.map((row) => (
                    <TableRow key={row.value}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ width: "66px" }}
                      >
                        {/* <img src={logo} alt="logo" width="64px"></img> */}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <FlexTD>
                          <span className="title">{row.name}</span>
                        </FlexTD>
                      </TableCell>
                      <TableCell>{row.value}</TableCell>
                      <TableCell style={{ textAlign: "right" }}>
                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={(e) => handleClick(e, row._id)}
                        >
                          <MoreVert color="secondary" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <NoDataContainer>
            <h3>Sem tipos aqui!</h3>
          </NoDataContainer>
        )}
      </Container>
      <CompanyContextMenu
        open={isContextMenuOpen}
        handleClose={handleClose}
        handleDelete={setDeleteDialogOpen}
        anchorEl={anchorEl}
      />
      <DeleteCompanyDialog
        open={isDeleteDialogOpen}
        companyId={currentCompanyId}
        handleClose={handleCloseDeleteDialog}
      />
    </>
  ));
};

export default AttendeeTypes;
