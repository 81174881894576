import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Route, Redirect } from "react-router-dom";
import { useStore } from "Store";
import LoadingModal from "Components/LoadingModal";
import * as S from "./styles";

const ProtectedRoute = ({ component: Component, ...rest }: any) => {
  const [loading, setLoading] = useState(true);
  // const [hasChecked, setHasChecked] = useState(false);
  // const [isSignedIn, setIsSignedIn] = useState(false);
  // const decode = () => {
  //   try {
  //     if (localStorage.getItem("@token")) {
  //       // @ts-ignore
  //       const data = jwtDecode(localStorage.getItem("@token"));
  //       setIsSignedIn(true);
  //       console.log(data);
  //     } else {
  //       setIsSignedIn(false);
  //     }
  //   } catch (error) {
  //     localStorage.removeItem("@token");
  //     setIsSignedIn(false);
  //     console.log(error);
  //   }
  // };
  const history = useHistory();
  const { userStore } = useStore();
  const goToSignin = () => history.replace("/signin");

  useEffect(() => {
    verifyUserToken();
  }, []);

  const verifyUserToken = async () => {
    if (!userStore.isSignedIn) {
      await userStore.getUserDataByToken();
    } 
    setLoading(false);
  }

  const routeComponent = (props: any) => {
    if (loading) {
      return (
      <LoadingModal open={true}>
        <S.LoadingStatusContainer>
          Carregando...
        </S.LoadingStatusContainer>
      </LoadingModal>
      );
    } else {
      return userStore.isSignedIn ? React.createElement(Component, props) : goToSignin();
    }
  }
    
  return <Route {...rest} render={routeComponent} />;
};

export default ProtectedRoute;
