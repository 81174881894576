import styled from "styled-components";
import { TableHead, Fab } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 98%;
  margin: 10px;
`;

export const TableTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
`;

export const Container = styled.div`
  /* height: 100%; */
  background: #f7f7f7;
  padding: 15px 30px;
`;

export const GenericFAB = styled(Fab)`
  && {
    z-index: 999;
    background-color: #00577b;
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
  && &:hover {
    background-color: #00577b;
  }
`;

export const Generic2FAB = styled(Fab)`
  && {
    z-index: 999;
    background-color: #f50057;
    position: fixed;
    bottom: 20px;
    right: 280px;
  }
  && &:hover {
    background-color: #00577b;
  }
`;

export const GenericLeftFAB = styled(Fab)`
  && {
    z-index: 999;
    background-color: #00577b;
    position: fixed;
    bottom: 20px;
    left: 20px;
    right: auto;
  }
  && &:hover {
    background-color: #00577b;
  }
`;

export const GenericLeft2FAB = styled(Fab)`
  && {
    z-index: 999;
    background-color: #f50057;
    position: fixed;
    bottom: 20px;
    left: 20px;
    right: auto;
  }
  && &:hover {
    background-color: #f50057;
  }
`;

export const GenericTableHead = styled(TableHead)`
  && {
    background-color: #dddddd;
  }
`;
export const GenericTable = styled.table`
  td {
    border-color: #fff;
  }
  width: 100%;
  thead > tr {
    background: #e3e3e3;
  }
  tr {
    text-align: left;

    background: #fff;
  }
  tbody td {
    vertical-align: top;
    padding-top: 10px;
  }
  .ativo {
    color: #2cda2c;
  }
  .inativo {
    color: #e72419;
  }
`;

interface GenericTableProps {
  clickable?: boolean;
}
export const GenericTableRow = styled(TableRow)<GenericTableProps>`
  && {
    cursor: ${(props) => (props.clickable ? "pointer" : "auto")};
  }
`;

export const FlexTD = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;

  color: #6c6c6c;

  .title {
    font-weight: bold;
  }
`;

export const EventWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .title {
    font-size: 18px;
    font-weight: bold;
    color: #6c6c6c;
  }
  .subtitle {
    margin-top: 10px;

    font-weight: 200;
    color: #acacac;
  }
`;
