import React, { useState, useEffect } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { GenericButton } from "Components/Styled/Form";
import { useStore } from "Store";

import { IAttendee } from "Models/Attendees";
import { IDraw } from "../../models";

interface IProps {
  open: boolean;
  handleDelete: Function;
  handleClose: Function;
  itemName: string | undefined;
}

const DeleteDrawDialog: React.FC<IProps> = ({
  open,
  handleDelete,
  handleClose,
  itemName,
}) => {
  return (
    <Dialog
      open={open}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {"Exclusão de sorteio"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Tem certeza que deseja excluir "{itemName}"
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <GenericButton aVariant="secondary" onClick={() => handleDelete()}>
          Excluir
        </GenericButton>
        <GenericButton variant="contained" onClick={() => handleClose()}>
          Cancelar
        </GenericButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDrawDialog;
