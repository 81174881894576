import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";

import FirstStep from "./components/FirstStep";
import SecondStep from "./components/SecondStep";
import ThirdStep from "./components/ThirdStep";

import Container from "@material-ui/core/Container";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import * as S from "./styles";
import { useStore } from "Store";
import { EventModel } from "Models/Events";
import SearchBar from "Components/SearchBar";
import LoadingModal from "Components/LoadingModal";
import { LoadingStatusContainer } from "./components/SecondStep/styles";

interface IRouteParams {
  eventId?: string;
}

function getSteps() {
  return ["Informações básicas", "Local e data", "Dados do organizador"];
}

const CreateOrEditEvent = () => {
  const { eventId } = useParams<IRouteParams>();
  const { eventsStore } = useStore();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [currentEvent, setCurrentEvent] = useState({} as EventModel);
  const [skipped, setSkipped] = useState(new Set<number>());
  const steps = getSteps();
  const [loading, setLoading] = useState(false);

  const fetchEventAttendees = useCallback(
    async (eventId: string) => {
      try {
        const response = await eventsStore.fetchEventById(eventId);
        // @ts-ignore
        const event: EventModel = response.data.event as EventModel;
        const {
          cover,
          description,
          description_body,
          name,
          type,
          registration_start,
          registration_end,
          checkin_tolerance,
          address: {
            city,
            complement,
            country,
            district,
            state,
            street,
            street_number,
            zipcode,
          },
          organizer: { email, phone },
          starts_at,
          ends_at,
        } = event;
        setCurrentEvent(event);
        eventsStore.persistFirstStep({ cover: "1", description, description_body, name, type });
        eventsStore.persistSecondStep({
          city,
          complement,
          zipcode,
          street_number: street_number.toString(),
          street,
          state,
          district,
          starts_at,
          country,
          ends_at,
          registration_end,
          registration_start,
          checkin_tolerance: Number(checkin_tolerance) / 60000,
        });
        eventsStore.persistThirdStep({
          email,
          name: event.organizer.name,
          phone,
        });
      } catch (error) {
        console.log(error);
      }
    },
    [eventId, eventsStore]
  );

  useEffect(() => {
    if (eventId) {
      fetchEventAttendees(eventId);
    }
  }, [eventId]);

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (eventId) {
        await eventsStore.onUpdateEvent(currentEvent._id, currentEvent.active);
      } else {
        await eventsStore.onSubmitEvent();
      }
      setLoading(false);
    }
    catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };
  return (
    <>
      <SearchBar title={"Cadastro de Evento"} />
      <LoadingModal open={loading}>
        <LoadingStatusContainer>Carregando...</LoadingStatusContainer>
      </LoadingModal>
      <Container style={{ marginTop: "0px" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: { optional?: React.ReactNode } = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <S.FormContainer>
          <FirstStep
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
          />
          <SecondStep
            currentEvent={currentEvent}
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
          />
          <ThirdStep
            handleSubmit={handleSubmit}
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
          />
        </S.FormContainer>
      </Container>
    </>
  );
};

export default CreateOrEditEvent;
