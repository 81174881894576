import React from "react";
import { useHistory } from "react-router-dom";

import { IProps } from "./models";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const NoCompanyDialog: React.FC<IProps> = ({ open }) => {
  const history = useHistory();

  const handleClose = () => {
    history.push("/companies/form");
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Nenhuma empresa encontrada"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Pelo menos uma empresa é necessária para realizar o cadastro de um
            participante no sistema.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ir para cadastro de empresas
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NoCompanyDialog;
