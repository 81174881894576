import styled from "styled-components";

import Fab from '@material-ui/core/Fab';
import TableHead from '@material-ui/core/TableHead';

export const Container = styled.div`
  /* height: 100%; */
  background: #f7f7f7;
  padding: 15px 30px;
  margin-bottom: 60px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 98%;
  margin: 10px;
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const EventsFAB = styled(Fab)`
  && {
    background-color: #00577b;
  }
  && &:hover {
    background-color: #00577b;
  }
`
export const EventosTableHead = styled(TableHead)`
  && {
    background-color: #DDDDDD;
  }
`
export const EventsTable = styled.table`
  td {
    border-color: #fff;
  }
  width: 100%;
  thead > tr {
    background: #e3e3e3;
  }
  tr {
    text-align: left;

    background: #fff;
  }
  tbody td {
    vertical-align: top;
    padding-top: 10px;
  }
  .ativo {
    color: #2cda2c;
  }
  .inativo {
    color: #e72419;
  }
`;

export const FlexTD = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;

  color: #6c6c6c;

  .title {
    font-weight: bold;
  }
`;

export const EventWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .title {
    font-size: 18px;
    font-weight: bold;
    color: #6c6c6c;
  }
  .subtitle {
    margin-top: 10px;

    font-weight: 200;
    color: #acacac;
  }
`;
