import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useObserver } from "mobx-react-lite";
import { autorun } from "mobx";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { useStore } from "Store";
import * as S from "./styles";
import Grid from "@material-ui/core/Grid";

// @ts-ignore
import { FormikTextField } from "formik-material-fields";

import LoadingModal from "Components/LoadingModal";
import { EventModel, EventSponsorModel } from "Models/Events";
import { LandingPageModel } from "Models/LandingPage";
import Moment from "moment";
import CalendarToday from "@material-ui/icons/CalendarToday";
import { LoadingStatusContainer } from "Pages/Home/CreateOrEditEvent/components/SecondStep/styles";

const override = css`
  margin: 0 auto;
  padding: 0;
`;

const EventPage: React.FC = () => {
  const history = useHistory();
  const { userStore, appStore, landingpagesStore, eventsStore, eventsponsorsStore } = useStore();
  const params = useParams();

  const [currentEvent, setCurrentEvent] = useState<EventModel>({} as EventModel);
  const [currentLandingPage, setCurrentLandingPage] = useState<LandingPageModel>({} as LandingPageModel);
  const [eventSponsors, setEventSponsors] = useState<EventSponsorModel[]>([]);

  const fetchCurrentEvent = async () => {
    try {
      if (!userStore.isSignedIn) {
        await userStore.getUserDataByToken();
        
        if (!userStore.isSignedIn) {
          console.log("Nao logado")
          return history.replace("/signin");
        } 
        
      }

      //@ts-ignore
      const eventName = params.eventName;
      const responseEvent = await eventsStore.fetchEventByShortName(eventName);
      //@ts-ignore
      var eventId = responseEvent.data.event._id;
      
      const responseLP = await landingpagesStore.fetchLandingPage(eventId);
      // @ts-ignore
      setCurrentLandingPage(responseLP);
      
      // @ts-ignore
      setCurrentEvent(responseEvent.data.event);

      const responseSponsor = await eventsponsorsStore.fetchEventSponsors(eventId);
      //@ts-ignore
      setEventSponsors(responseSponsor);
    } catch (error) {
      console.log(error);
    }

    if (!currentEvent._id || !currentEvent.active || !currentLandingPage) {
      //Error e 404
    }
  };

  useEffect(() => {
    appStore.initializeBaseURL();
    fetchCurrentEvent();
  }, []);

  const EventPage = () => {
    return (
      <>
      <img src={appStore.currentBaseURL + "/" + currentEvent?.cover_url} style={{
        alignSelf: "center;", 
        width: "100%",
        paddingLeft: "100px",
        paddingRight: "100px",
        paddingTop: 0
        }}></img>
      </>
    )
  }

  return useObserver(() => (
    <>
      <LoadingModal open={!(currentEvent && currentEvent.name)}>
        <LoadingStatusContainer>Carregando evento...</LoadingStatusContainer>
      </LoadingModal>
      {currentEvent && currentEvent.name && (
        <>
          {currentLandingPage?.body_html && currentLandingPage?.body_html !== "" ? (
            <div dangerouslySetInnerHTML={{ __html: currentLandingPage?.body_html }} />
          ) : (
            <S.Container>
              <S.ContentContainer>
                <EventPage />
                <Grid container spacing={2} style={{
                  alignSelf: "center;", 
                  width: "100%",
                  paddingLeft: "120px",
                  paddingRight: "120px",
                  paddingTop: "20px"
                  }}>
                    <S.FormTitle>
                      {currentEvent.name}
                    </S.FormTitle>
                </Grid>
                <Grid container spacing={2} style={{
                  alignSelf: "center;", 
                  width: "100%",
                  paddingLeft: "120px",
                  paddingRight: "120px",
                  paddingTop: "20px",
                  paddingBottom: "20px"
                  }}>
                    <div><CalendarToday style={{color: "#168669", width: "20px", height: "20px"}}/></div>
                    <div style={{paddingLeft: "10px"}}>
                      {Moment(currentEvent?.starts_at).format(
                        "DD [de] MMMM [de] YYYY [às] HH[:]MM"
                      )}
                    </div>
                </Grid>
                
                <div style={{
                  backgroundColor: "rgb(245, 247, 248)",
                  }}>
                    <Grid container spacing={2} style={{
                    alignSelf: "center;", 
                    width: "100%",
                    paddingLeft: "120px",
                    paddingRight: "120px",
                    paddingTop: "20px",
                    paddingBottom: "20px"
                    }}>
                      <S.FormSubtitle>
                        Descrição do evento:
                      </S.FormSubtitle>
                  </Grid>
                  <Grid container spacing={2} style={{
                    alignSelf: "center;", 
                    width: "100%",
                    paddingLeft: "120px",
                    paddingRight: "120px",
                    paddingBottom: "20px"
                    }}>
                      <div dangerouslySetInnerHTML={{ __html: currentLandingPage?.body }} />
                  </Grid>
                </div>

                <div style={{
                  backgroundColor: "rgb(255, 255, 255)",
                  }}>
                    <Grid container spacing={2} style={{
                    alignSelf: "center;", 
                    width: "100%",
                    paddingLeft: "120px",
                    paddingRight: "120px",
                    paddingTop: "20px",
                    paddingBottom: "20px"
                    }}>
                      {eventSponsors && eventSponsors.length > 0 && (
                        <S.FormSubtitle>
                          Patrocinadores:
                        </S.FormSubtitle>
                      )}
                  </Grid>
                  <Grid container spacing={2} style={{
                    alignSelf: "center;", 
                    width: "100%",
                    paddingLeft: "120px",
                    paddingRight: "120px",
                    paddingBottom: "20px"
                    }}>
                      {eventSponsors.map(sponsor => {
                        return (
                          <img
                            src={appStore.currentBaseURL + "/" + sponsor.cover_url}
                            alt="cover"
                            height="100"
                          />
                        )
                      })}
                  </Grid>
                </div>
              </S.ContentContainer>
            </S.Container>
          )}
        </>
      )}
    </>
  ));
};

export default EventPage;
