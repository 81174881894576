import React from "react";
import styled from "styled-components";
import error from "../../Assets/Images/error404.png";

const ErrorPage = () => {
  return (
    <Container>
      <div style={{ textAlign: "center", marginTop: "200px" }}>
        <img src={error} width={"600px"} />
        <Description>
          {" "}
          Ops! Não encontramos a página
          <br /> que você está procurando!
        </Description>
      </div>
    </Container>
  );
};

export default ErrorPage;

const Container = styled.div`
  display: "flex";
  justify-content: "center";
  align-content: "center";
`;

const Description = styled.div`
  color: #00577b;
  font-size: 40px;
`;
