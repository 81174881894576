import Container from "@material-ui/core/Container";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { GenericButton } from "Components/Styled/Form";
import { Form, Formik } from "formik";
// @ts-ignore
import { FormikTextField } from "formik-material-fields";
import { toJS } from "mobx";
import React from "react";
import InputMask from "react-input-mask";
import { useStore } from "Store";
import { history } from "Store/History";
import * as Yup from "yup";
import * as S from "../../styles";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        margin: "10px 0",
      },
    },
    formControl: {
      margin: "10px 0",
      minWidth: "100%",
    },
  })
);
interface Props {
  activeStep: number;
  handleSubmit: Function;
  handleNext: Function;
  handleBack: Function;
}
const ThirdStep: React.FC<Props> = ({
  activeStep,
  handleBack,
  handleSubmit,
}) => {
  const classes = useStyles();
  const { eventsStore } = useStore();
  interface Values {
    name: string;
    email: string;
    phone: string;
  }
  // const validationSchema = Yup.object().shape({
  //   name: Yup.string().required("Nome é obrigatório"),
  //   phone: Yup.string()
  //     .required("Telefone é obrigatório")
  //     .min(10, "Insíra um número válido"),
  //   email: Yup.string()
  //     .email("por favor insira um email válido")
  //     .required("email é´obrigatório"),
  // });
  const initialValues: Values = {
    name: eventsStore.currentThirdStep.name || "",
    email: eventsStore.currentThirdStep.email || "",
    phone: eventsStore.currentThirdStep.phone || "",
  };
  const handlePersist = (values: Values) => {
    eventsStore.persistThirdStep({
      email: values.email,
      name: values.name,
      phone: values.phone,
    });
  };
  const onHandleBack = (values: Values) => {
    handlePersist(values);
    handleBack();
  };
  const onSubmit = (values: Values) => {
    handlePersist(values);
    handleSubmit();
  };
  const validatePhone = (value: any) => {
    if (!value || (value && value.length == 0)) return true;

    var phone = value.replace(/\D/g,'');
    return phone.length >= 9 && phone.length <= 11;
    // console.log(value);
    // let r = /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/;
    // console.log(r.test(value));
    // return !r.test(value);
  };
  if (activeStep !== 2) {
    return null;
  }
  return (
    <>
      <Container className={classes.root} maxWidth="sm">
        <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isValid, handleSubmit, values, handleChange }) => (
            <>
              <Form autoComplete="off">
                <S.FormContainer>
                  <FormikTextField
                    name="name"
                    label="Nome do organizador"
                    fullWidth
                    variant="outlined"
                  />
                  <FormikTextField
                    name="phone"
                    label="Telefone do organizador"
                    fullWidth
                    variant="outlined"
                  />
                  {/* <InputMask
                    mask={`(99) 99999-9999`}
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                  >
                    {() => (
                      <FormikTextField
                        name="phone"
                        label="Telefone do organizador"
                        fullWidth
                        variant="outlined"
                        validate={validatePhone}
                      />
                    )} 
                    </InputMask> */}
                  <FormikTextField
                    name="email"
                    label="E-mail do organizador"
                    fullWidth
                    variant="outlined"
                  />
                </S.FormContainer>
                <S.ButtonRowWrapper>
                  <GenericButton
                    aVariant="secondary"
                    onClick={() => onHandleBack(values)}
                  >
                    Voltar
                  </GenericButton>
                  <GenericButton
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Concluir cadastro
                  </GenericButton>
                </S.ButtonRowWrapper>
              </Form>
            </>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default ThirdStep;
