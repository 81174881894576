import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";

export const EventsAppBar = styled(AppBar)`
  && {
    background-color: "#E3E3E3" !important;
    background: "#E3E3E3" !important;
  }
  color: "#00577C";
  .MuiAppBar-root,
  .MuiAppBar-colorPrimary {
    background-color: "#E3E3E3" !important;
    background: "#E3E3E3" !important;
  }
  margin-top: 75px;

  @media (max-width: 500px) {
    margin-top: 60px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  .start {
    display: flex;
    align-items: center;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
