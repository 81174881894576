import React, { useEffect, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useStore } from "Store";
import { Formik, Form as FormikForm } from "formik";
import InputMask from "react-input-mask";

import NoCompanyDialog from "./components/NoCompanyDialog";

import { Company } from "Models/Companies";
import {
  IFormValues,
  validationSchema,
  IOptions,
  genderOptions,
  IResponse,
} from "./models";

import * as S from "./styles";

// @ts-ignore
import { estados } from "estados-br";

// * Material Ui and Form related

import {
  PrimaryActionButton,
  SecondaryActionButton,
} from "Components/Styled/Form";

import Grid from "@material-ui/core/Grid";
// @ts-ignore
import { FormikTextField } from "formik-material-fields";
// @ts-ignore
import { FormikSelectField } from "formik-material-fields";
import { Container } from "@material-ui/core";
import { toast } from "react-toastify";
import { validatePhone } from "Utils";
import SearchBar from "Components/SearchBar";
import LoadingModal from "Components/LoadingModal";
import { LoadingStatusContainer } from "Pages/Companies/styles";
import Axios from "axios";
const StringMask = require("string-mask");

const Form = () => {
  const formatter = new StringMask("(00) 00000-0000");
  const [loading, setLoading] = useState(false);
  const [attendeeId, setAttendeeId] = useState<string>("");
  const [initialValues, setInitialValues] = useState<IFormValues>({
    first_name: "",
    last_name: "",
    gender: "female",
    cpf: "",
    business_id: "",
    job_title: "",
    country_code: "55",
    area_code: "00",
    phone_number: "",
    email: "",
    state: "PA",
    city: "",
    district: "",
    street: "",
    zipcode: "",
    complement: ""
  });
  const [companies, setCompanies] = useState<Company[]>([]);
  const [companiesOptions, setCompaniesOptions] = useState<IOptions[]>([]);
  const { companyStore, attendeesStore, userStore } = useStore();
  const history = useHistory();
  const [currentUf, setCurrentUf] = useState("PA");
  const [zipcode, setZipcode] = useState("");
  const [zipcodeError, setZipcodeError] = useState(false);
  const [zipcodeErrorMessage, setZipcodeErrorMessage] = useState("");
  const [isFetchingCep, setIsFetchingCep] = useState(false);

  const updateInitialValues = useCallback(async () => {
    try {
      const response = await attendeesStore.fetchAttendeeById(attendeeId);
      console.log(response)
      const {
        user: {
          first_name,
          last_name,
          business,
          cpf,
          email,
          gender,
          job_title,
          phone,
          address
        },
      }: IResponse = response as IResponse;
      
      const phone_splited = phone.number.split(" ");
      const phone_number = formatter.apply(
        phone.area_code + (phone_splited.length > 1 ? phone_splited[1].replace("-", "") : phone_splited[0].replace("-", ""))
      );

      setInitialValues((previousValues: IFormValues) => ({
        ...previousValues,
        first_name,
        last_name,
        gender,
        cpf: getFormattedCPF(cpf),
        business_id: business ? business._id : "",
        email,
        job_title,
        phone_number: phone_number,
        street_number: address && address.street_number ? address.street_number : "",
        state: address && address.state ? address.state : "",
        city: address && address.city ? address.city.toUpperCase() : "",
        district: address && address.district ? address.district.toUpperCase() : "",
        street: address && address.street ? address.street.toUpperCase() : "",
        zipcode: address && address.zipcode ? address.zipcode : "",
        complement: address && address.complement ? address.complement.toUpperCase() : ""
      }));
    } catch (error) {
      console.log(error);
    }
  }, [attendeeId, attendeesStore, userStore]);

  useEffect(() => {
    setAttendeeId(userStore.currentUser!._id);
    updateInitialValues();
  }, [updateInitialValues, attendeeId, userStore]);

  const getFormattedCPF = (cpf: string) => {
    if (!cpf) return "";

    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  useEffect(() => {
    if (companies.length > 0) {
      const localArr: IOptions[] = [];
      companies.map((company) => {
        return localArr.push({ label: company.legal_name, value: company._id });
      });
      setCompaniesOptions(localArr);
    }
  }, [companies]);

  const getCompaniesData = useCallback(async () => {
    const response = await companyStore.fetchCompanies(1,999);
    // @ts-ignore
    setCompanies(response.businesses);
  }, [companyStore]);

  useEffect(() => {
    getCompaniesData();
  }, [getCompaniesData]);

  const getAddressDataByCep = async (
    zipcode: string,
    setFieldValue: Function,
    setFieldError: Function
  ) => {
    try {
      setIsFetchingCep(true);
      const response = await Axios.get(
        `https://viacep.com.br/ws/${zipcode}/json/`
      );
      if (!response.data.erro) {
        setFieldValue("city", response.data.localidade);
        setFieldValue("district", response.data.bairro);
        setFieldValue("street", response.data.logradouro);
        setFieldValue("state", response.data.uf);
        setCurrentUf(response.data.uf);
        setFieldError("zipcode", null);
        setZipcodeError(false);
        setZipcodeErrorMessage("");
      } else {
        setZipcodeError(true);
        setZipcodeErrorMessage("CEP não encontrado");
        setFieldError("zipcode", "CEP não encontrado");
      }
      setIsFetchingCep(false);
    } catch (error) {
      setIsFetchingCep(false);
    }
  };

  const handleCepChange = (
    value: string,
    setFieldValue: Function,
    setFieldError: Function
  ) => {
    try {
      if (value.charAt(8) !== "_") {
        const formattedCep = value.replace("-", "");
        getAddressDataByCep(formattedCep, setFieldValue, setFieldError);
      }
      setFieldValue("zipcode", value);
      setZipcode(value);
    } catch (error) {}
  };

  const onSubmit = async (values: IFormValues) => {
    try {
      if (!attendeeId || attendeeId === "") {
        toast.error("Erro ao carregar perfil.");
        return;
      }

      if (values.phone_number) {
        var valid = validatePhone(values.phone_number);
        if (!valid) {
          toast.error("Telefone inválido, altere e tente novamente.");
          return;
        }
      } else {
        toast.error("Para continuar é necessário um número de telefone válido.");
        return;
      }

      setLoading(true);
      await attendeesStore.updateAttendee(attendeeId, values);
      setLoading(false);

      history.replace("/");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <SearchBar title={"Meu Perfil"} />
      <LoadingModal open={loading}>
        <LoadingStatusContainer>Atualizando...</LoadingStatusContainer>
      </LoadingModal>
      <Container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ setFieldValue, setFieldError, handleChange, values, errors }) => (
            <>
              <S.FormContainer>
                <S.FormContent>
                  <FormikForm autoComplete="off">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="first_name"
                          label="Nome"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="last_name"
                          label="Sobrenome"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikSelectField
                          style={{ width: "100%" }}
                          className="form-field"
                          name="gender"
                          label="Gênero"
                          options={genderOptions}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {/* <InputMask
                          mask="999.999.999-99"
                          name="cpf"
                          value={values.cpf}
                          onChange={handleChange}
                        > */}
                          {/* {() => ( */}
                            <FormikTextField
                              disabled={true}
                              name="cpf"
                              label="CPF"
                              fullWidth
                              variant="outlined"
                              // validate={validateCpf}
                            />
                          {/*} )} 
                        {/* </InputMask> */}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikSelectField
                          style={{ width: "100%" }}
                          className="form-field"
                          name="business_id"
                          label="Empresa"
                          options={companiesOptions}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="job_title"
                          label="Cargo"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="phone_number"
                          label="Telefone"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          disabled={true}
                          name="email"
                          label="Email"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        {/* <InputMask
                          // mask="99999-999"
                          name="zipcode"
                          // value={zipcode}
                          onChange={(e) => {
                            // handleCepChange(
                            //   e.target.value,
                            //   setFieldValue,
                            //   setFieldError
                            // )
                          }}
                        >
                          {() => ( */}
                            <FormikTextField
                              fullWidth
                              label="CEP"
                              variant="outlined"
                              name="zipcode"
                              helperText={
                                zipcodeErrorMessage
                              }
                              error={
                                zipcodeError
                              }
                              onChange={(e: any) => {
                                handleCepChange(
                                  e.target.value,
                                  setFieldValue,
                                  setFieldError
                                )
                              }}
                            />
                          {/* )}
                        </InputMask> */}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikSelectField
                          style={{ width: "100%" }}
                          className="form-field"
                          name="state"
                          value={currentUf}
                          label="UF"
                          options={estados.map((estado: any) => ({
                            label: estado.sigla,
                            value: estado.sigla,
                          }))}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={10}>
                        <FormikTextField
                          name="street"
                          label="Rua"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <FormikTextField
                          name="street_number"
                          label="Número"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikTextField
                          name="complement"
                          label="Complemento"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="district"
                          label="Bairro"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="city"
                          label="Cidade"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="password"
                          label="Nova Senha"
                          fullWidth
                          type="password"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikTextField
                          name="confirm_password"
                          label="Confirmar Nova Senha"
                          fullWidth
                          type="password"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <S.ButtonRow>
                      <PrimaryActionButton type="submit"
                        disabled={!!attendeesStore.isSubmittingAttendee}>
                        {attendeesStore.isSubmittingAttendee ? "Carregando..." : "Atualizar"}
                      </PrimaryActionButton>
                    </S.ButtonRow>
                  </FormikForm>
                </S.FormContent>
              </S.FormContainer>
            </>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default Form;
