import { configure, observable, action, runInAction, toJS } from "mobx";
import { toast } from "react-toastify";
import {
  EventModel,
  FirstStep,
  SecondStep,
  ThirdStep,
  IBadge,
  IInvitation,
  IItems,
  ISorteioFilter,
  Checkin,
  Guests,
} from "Models/Events";

import { IEventPagination, IDrawPagination } from "Models/Utils";

import { Post, Get, Delete, Put } from "Services/Request";
import { history } from "Store/History";
import { IBusiness } from "Models/Attendees";

configure({ enforceActions: "observed" });

const checkinTeste: Checkin = {
  id: "83283623",
  date: new Date(),
  participantName: "Fulano de Tal",
  presence: true,
  code: "832837632",
};

class EventsStore {
  constructor() {
    this.fetchEvents();
  }

  @observable
  currentEventSubPageIndex: number = 0;

  @observable
  events: EventModel[] = [];

  @observable
  event: EventModel = {} as EventModel;

  @observable
  chekinUser: Checkin[] = [checkinTeste];

  @observable
  invitations: IInvitation[] = [];

  @observable
  myItems: IItems[] = [
    { id: "0", content: "Nome do evento" },
    { id: "1", content: "Nome do Participante" },
    { id: "2", content: "Empresa" },
    { id: "3", content: "Data do evento" },
  ];

  @observable
  currentPagination: IEventPagination = {
    eventsCount: 0,
    page: 1,
    pages: 1,
  };

  @observable
  currentDrawsPagination: IDrawPagination = {
    drawsCount: 0,
    page: 1,
    pages: 1,
  };

  @observable
  badges: IBadge[] = [
    {
      items: this.myItems,
      isDefault: false,
      eventId: "2d014eac-d4ba-48f6-b00f-edc25c711d67",
    },
  ];

  @observable
  currentEventId: string = "";

  @observable
  currentFirstStep: FirstStep = {} as FirstStep;

  @observable
  currentSecondStep: SecondStep = {} as SecondStep;

  @observable
  currentThirdStep: ThirdStep = {} as ThirdStep;

  @observable
  currentFilterSetup: ISorteioFilter = {
    name: null,
    gender: null,
    company: null,
    type: null,
  };

  @observable
  currentInvitation: Guests = {} as Guests;

  @observable
  editInvitation: Guests = {} as Guests;

  @observable
  isFetchingEvents: boolean = false;

  @observable
  currentEventBusinessesIds: string[] = [];

  @action
  setCurrentEventBusinesses = (businesses: IBusiness[]) => {
    businesses.map((business) =>
      this.currentEventBusinessesIds.push(business._id)
    );
    console.log(toJS(this.currentEventBusinessesIds));
  };

  @action
  resetCurrentEventBusinesses = () => {
    this.currentEventBusinessesIds = [];
  };

  @action
  setCurrentEventSubPageIndex = (newIndex: number) =>
    (this.currentEventSubPageIndex = newIndex);

  @action
  setCurrentFilterSetup(newFilterSetup: ISorteioFilter) {
    this.currentFilterSetup = newFilterSetup;
  }

  @action
  setEditInvitation(invitation: Guests) {
    this.editInvitation = invitation;
  }
  @action
  setChekin(chekinUser: Checkin[]) {
    this.chekinUser = chekinUser;
  }

  @action
  setCurrentEventId(eventId: string) {
    this.currentEventId = eventId;
  }

  @action
  addInvitation(invitationToAdd: IInvitation) {
    this.invitations.push(invitationToAdd);
  }
  @action
  setCurrentInvitation(data: Guests) {
    this.currentInvitation = data;
  }

  @action
  updateBadge(badgeToUpdate: IBadge) {
    let localBadges = this.badges.filter(
      (badge) => badge.eventId !== badgeToUpdate.eventId
    );
    localBadges.push(badgeToUpdate);
    this.badges = localBadges;
  }

  @action
  persistFirstStep(data: FirstStep) {
    this.currentFirstStep = data;
  }

  @action
  persistSecondStep(data: SecondStep) {
    this.currentSecondStep = data;
  }

  @action
  persistThirdStep(data: ThirdStep) {
    this.currentThirdStep = data;
  }

  @action
  resetAllSteps = () => {
    this.currentFirstStep = {} as FirstStep;
    this.currentSecondStep = {} as SecondStep;
    this.currentThirdStep = {} as ThirdStep;
  };

  @action
  onSubmitParticipantsCSVs = async (csv: any, event_id: string, business_id: string) => {
    const data = new FormData();
    data.append("eventUsers", csv);
    data.append("event_id", event_id);
    data.append("business_id", business_id);

    try {
      const response = await Put("/api/import/users/events", data);
      toast.success("Participantes importados com sucesso!");
      await this.fetchOnlyEvent(event_id);
      return true;
    } catch (error) {
      console.log(error.response);
      toast.error(error.response.data.error.message);
      // toast.error("Erro ao processar XLSX, revise os dados ou o modelo do template.");
    }

    return false;
  };

  @action
  fetchEventsSearch = async (name = "") => {
    await this.fetchEvents(undefined, undefined, name);
  };

  @action
  fetchEvents = async (page: number = 1, limit: number = 6, name: string = "") => {
    this.isFetchingEvents = true;
    try {
      var uri = `/api/events?page=${page}&limit=${limit}`;
      if (name && name.length > 0) uri += `&name=${name}`;
      const response = await Get(uri);
      this.setCurrentPagination({
        // @ts-ignore
        eventsCount: response.data.data.eventsCount,
        // @ts-ignore
        page: response.data.data.page,
        // @ts-ignore
        pages: response.data.data.pages,
      });
      runInAction(() => {
        this.isFetchingEvents = false;
        // @ts-ignore
        this.events = response.data.data.events;
      });
    } catch (error) {
      runInAction(() => {
        this.isFetchingEvents = false;
      });
      console.log(error.response);
    }
  };

  @action
  fetchOnlyEvent = async (id: string | undefined) => {
    this.isFetchingEvents = true;
    try {
      const response = await Get(`/api/events/${id}`);

      runInAction(() => {
        this.isFetchingEvents = false;
        // @ts-ignore
        this.event = response.data.data.event;
        console.log(toJS(this.event));
      });
    } catch (error) {
      runInAction(() => {
        this.isFetchingEvents = false;
      });
      console.log(error.response);
    }
  };

  @action
  fetchEventById = async (eventId: string) => {
    try {
      const response = await Get(`/api/events/${eventId}`);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  @action
  fetchEventByShortName = async (name: string) => {
    try {
      const response = await Get(`/api/events/read?short_name=${name}`);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  @action
  setCurrentPagination = (newPagination: IEventPagination) => {
    this.currentPagination = newPagination;
  };

  @action
  setCurrentDrawsPagination = (newPagination: IDrawPagination) => {
    this.currentDrawsPagination = newPagination;
  };

  @action
  onDeleteEvent = async (eventId: string) => {
    try {
      await Delete(`/api/events/${eventId}`);
      await this.fetchEvents();
      toast.success("Evento removido com sucesso");
      this.setCurrentPagination({
        eventsCount: 0,
        page: 1,
        pages: 1,
      });
    } catch (error) {
      toast.error(error.response.data.error.message);
    }
  };

  @action
  updateStatus = async (eventId: string, attendeeId: string, data: string) => {
    try {
      const response = await Put(
        `/api/events/${eventId}/attendees/${attendeeId}`,
        {
          profile_type: data,
        }
      );
      if (response) {
        await this.fetchOnlyEvent(eventId);

        // console.log(toJS(this.editInvitation));
        toast.success(`Status atualizado com sucesso`);
      }
    } catch (error) {
      toast.error(error.response.data.error.message);
    }
  };

  @action
  updatePaidStatus = async (eventId: string, attendeeId: string, paid: boolean) => {
    try {
      const response = await Put(
        `/api/events/${eventId}/attendees/${attendeeId}`,
        {
          paid: paid,
        }
      );
      if (response) {
        await this.fetchOnlyEvent(eventId);

        // console.log(toJS(this.editInvitation));
        toast.success(`Pagamento atualizado com sucesso`);
      }
    } catch (error) {
      toast.error(error.response.data.error.message);
    }
  };

  @action
  updatePaidStatusInBatch = async (eventId: string, attendeeIds: string[], paid: boolean) => {
    try {
      const response = await Put(
        `/api/events/${eventId}/attendees/subscriptions/paid`,
        {
          paid: paid,
          attendeeIds: attendeeIds
        }
      );
      if (response) {
        await this.fetchOnlyEvent(eventId);

        // console.log(toJS(this.editInvitation));
        toast.success(`Pagamentos atualizado com sucesso`);
      }
    } catch (error) {
      toast.error(error.response.data.error.message);
    }
  };

  @action
  onSubmitInvited = async (data: Guests, eventId: string) => {
    try {
      const response = await Post(`/api/events/${eventId}/guests`, {
        name: data.name,
        business: data.business,
        job_title: data.job_title,
        email: data.email,
        phone_number: data.phone_number
      });
      if (response) {
        toast.success("Convite gerado com sucesso");
        await this.fetchOnlyEvent(eventId);
        this.setCurrentInvitation(
          this.event.guests[this.event.guests.length - 1]
        );
        console.log(this.currentInvitation);
      }
      console.log(response.data);
      console.log(this.currentInvitation);
    } catch (error) {
      toast.error(error.response.data.error.message);
    }
  };

  @action
  onDeleteInvitation = async (eventId: string, guestId: string) => {
    try {
      await Delete(`/api/events/${eventId}/guests/${guestId}`);
      this.fetchOnlyEvent(eventId);
      toast.success("Convite removido com sucesso");
    } catch (error) {
      toast.error(error.response.data.error.message);
    }
  };

  @action
  onUpdateInvitation = async (
    eventId: string,
    guestId: string,
    data: Guests
  ) => {
    try {
      const response = await Put(`/api/events/${eventId}/guests/${guestId}`, {
        name: data.name,
        business: data.business,
        job_title: data.job_title,
        email: data.email,
        phone_number: data.phone_number
      });
      if (response) {
        await this.fetchOnlyEvent(eventId);
        const invitation = this.event.guests.filter(
          (guest) => guest._id === guestId
        );
        runInAction(() => {
          this.currentInvitation = invitation[0];
        });
        this.setCurrentInvitation(invitation[0]);
        // console.log(toJS(this.editInvitation));
        toast.success(`Convite atualizado com sucesso!`);

        await this.fetchOnlyEvent(eventId);
      }
    } catch (error) {
      toast.error(error.response.data.error.message);
    }
  };

  @action
  onSubmitEvent = async () => {
    const data = new FormData();
    data.append("cover", this.currentFirstStep.cover);
    data.append("description", this.currentFirstStep.description);
    data.append("description_body", this.currentFirstStep.description_body);
    data.append("name", this.currentFirstStep.name);
    data.append("type", this.currentFirstStep.type);

    data.append("country", this.currentSecondStep.country);
    data.append("state", this.currentSecondStep.state);
    data.append("city", this.currentSecondStep.city);
    data.append("district", this.currentSecondStep.district);
    data.append("street", this.currentSecondStep.street);
    data.append("street_number", this.currentSecondStep.street_number);
    data.append("complement", this.currentSecondStep.complement);
    data.append("zipcode", this.currentSecondStep.zipcode);
    data.append("starts_at", this.currentSecondStep.starts_at.toString());
    data.append(
      "registration_start",
      this.currentSecondStep.registration_start.toString()
    );
    data.append(
      "registration_end",
      this.currentSecondStep.registration_end.toString()
    );
    data.append(
      "checkin_tolerance",
      (Number(this.currentSecondStep.checkin_tolerance) * 60000).toString()
    );
    data.append("ends_at", this.currentSecondStep.ends_at.toString());

    data.append("organizer_name", this.currentThirdStep.name);
    data.append("organizer_phone", this.currentThirdStep.phone);
    data.append("organizer_email", this.currentThirdStep.email);

    try {
      // const response = await Post(
      //   "/api/events",
      //   {
      //     organizer_name: this.currentThirdStep.name,
      //     organizer_phone: this.currentThirdStep.phone,
      //     organizer_email: this.currentThirdStep.email,
      //     ...this.currentFirstStep,
      //     ...this.currentSecondStep,
      //     checkin_tolerance:
      //       Number(this.currentSecondStep.checkin_tolerance) * 60000,
      //   },
      //   { headers: { "Content-Type": "multipart/form-data" } }
      // );
      const response = await Post("/api/events", data);
      toast.success("Evento cadastrado com sucesso");
      history.replace("/");
      this.fetchEvents();
      console.log(response.data);
    } catch (error) {
      toast.error(error.response.data.error.message);
      console.log(error.response);
    }
  };

  @action
  onUpdateEvent = async (eventId: string, active: boolean) => {
    try {
      const data = new FormData();
      data.append("cover", this.currentFirstStep.cover);
      data.append("description", this.currentFirstStep.description);
      data.append("description_body", this.currentFirstStep.description_body);
      data.append("name", this.currentFirstStep.name);
      data.append("type", this.currentFirstStep.type);
      
      data.append("country", this.currentSecondStep.country);
      data.append("state", this.currentSecondStep.state);
      data.append("city", this.currentSecondStep.city);
      data.append("district", this.currentSecondStep.district);
      data.append("street", this.currentSecondStep.street);
      data.append("street_number", this.currentSecondStep.street_number);
      data.append("complement", this.currentSecondStep.complement);
      data.append("zipcode", this.currentSecondStep.zipcode);
      data.append("starts_at", this.currentSecondStep.starts_at.toString());
      data.append(
        "registration_start",
        this.currentSecondStep.registration_start.toString()
      );
      data.append(
        "registration_end",
        this.currentSecondStep.registration_end.toString()
      );
      data.append(
        "checkin_tolerance",
        (Number(this.currentSecondStep.checkin_tolerance) * 60000).toString()
      );
      data.append("ends_at", this.currentSecondStep.ends_at.toString());

      data.append("organizer_name", this.currentThirdStep.name);
      data.append("organizer_phone", this.currentThirdStep.phone);
      data.append("organizer_email", this.currentThirdStep.email);
      const response = await Put(`/api/events/${eventId}`, data);
      toast.success("Evento atualizado com sucesso");
      history.replace("/");
      this.fetchEvents();
      console.log(response.data);
    } catch (error) {
      toast.error(error.response.data.error.message);
      console.log(error.response);
    }
  };

  @action
  onUpdateEventImage = async (eventId: string, cover: any) => {
    try {
      const data = new FormData();
      data.append("cover", cover);
      const response = await Put(`/api/events/${eventId}`, data);
      toast.success("Evento atualizado com sucesso");
    } catch (error) {
      toast.error(error.response.data.error.message);
      console.log(error.response);
    }
  };

  @action
  onUpdateDescriptionBodyEvent = async (eventId: string, description_body: string) => {
    try {
      const data = new FormData();
      data.append("description_body", description_body);

      const response = await Put(`/api/events/${eventId}`, data);
      toast.success("Evento atualizado com sucesso");
      this.fetchEvents();
      console.log(response.data);
    } catch (error) {
      toast.error(error.response.data.error.message);
      console.log(error.response);
    }
  };

  @action
  onHandleActivateEvent = async ({
    eventId,
    active,
  }: {
    eventId: string;
    active: boolean;
  }) => {
    try {
      await Put(`/api/events/${eventId}`, {
        active: !active,
      });
      toast.success(`Evento ${!active ? "ativado" : "desativado"} com sucesso`);
      this.fetchEvents();
    } catch (error) {
      toast.error(error.response.data.error.message);
    }
  };

  @action
  onHandleAttendeeCheckin = async ({
    attendeeId,
    eventId,
    checkin,
  }: {
    attendeeId: string;
    eventId: string;
    checkin: boolean;
  }) => {
    try {
      await Put(`/api/events/${eventId}/attendees/${attendeeId}/checkin`, {
        checkin: !checkin,
      });
      toast.success(
        `Check-in ${checkin ? "desfeito" : "realizado"} com sucesso`
      );
    } catch (error) {
      toast.error(error.response.data.error.message);
      throw error.response;
    }
  };

  @action
  fetchDrawsByEventId = async ({
    eventId,
    page = 1,
    limit = 6,
  }: {
    eventId: string;
    page?: number;
    limit?: number;
  }) => {
    try {
      console.log(page);
      const res = await Get(
        `/api/events/${eventId}/draws?page=${page}&limit=${limit}`
      );
      this.setCurrentDrawsPagination({
        // @ts-ignore
        drawsCount: res.data.data.drawsCount,
        // @ts-ignore
        page: res.data.data.page,
        // @ts-ignore
        pages: res.data.data.pages,
      });
      return res.data;
    } catch (error) {
      throw error.response;
    }
  };

  @action
  fetchDrawById = async ({
    drawId,
    eventId,
    run = false,
  }: {
    drawId: string;
    eventId: string;
    run?: boolean;
  }) => {
    try {
      const res = await Get(
        `/api/events/${eventId}/draws/${drawId}?run=${run}`
      );
      return res.data;
    } catch (error) {
      throw error.response;
    }
  };

  @action
  handleSubmitDraw = async ({
    eventId,
    competitors,
    prize,
    prize_description,
    filter,
  }: {
    eventId: string;
    competitors: string[];
    prize: string;
    prize_description: string;
    filter: any;
  }) => {
    try {
      const res = await Post(`/api/events/${eventId}/draws`, {
        competitors,
        prize,
        prize_description,
        filter,
      });
      console.log(res);
      toast.success("Sorteio cadastrado com sucesso");
      return res.data;
    } catch (error) {
      toast.error(error.response.data.error.message);
      throw error.response;
    }
  };

  @action
  handleUpdateDraw = async ({
    eventId,
    drawId,
    competitors,
    prize,
    prize_description,
    filter,
  }: {
    eventId: string;
    drawId: string;
    competitors: string[];
    prize: string;
    prize_description: string;
    filter: any;
  }) => {
    try {
      const res = await Put(`/api/events/${eventId}/draws/${drawId}`, {
        competitors,
        prize,
        prize_description,
        filter,
      });
      toast.success("Sorteio atualizado com sucesso");
      return res.data;
    } catch (error) {
      toast.error(error.response.data.error.message);
      throw error.response;
    }
  };
  @action
  handleDeleteDraw = async ({
    eventId,
    drawId,
  }: {
    eventId: string;
    drawId: string;
  }) => {
    try {
      await Delete(`api/events/${eventId}/draws/${drawId}`);
      toast.success("Sorteio removido com sucesso");
    } catch (error) {
      toast.error(error.response.data.error.message);
      throw error.response;
    }
  };

  @action
  handleRunDraw = async ({
    eventId,
    drawId,
  }: {
    eventId: string;
    drawId: string;
  }) => {
    try {
      const response = await Get(
        `/api/events/${eventId}/draws/${drawId}?run=true`
      );
      toast.success("Sorteio realizado com sucesso!");
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error.message);
      throw error.response;
    }
  };

  @action
  linkBusinessToEvent = async ({
    business_id,
    eventId,
    user_limit = 0
  }: {
    business_id: string;
    eventId: string;
    user_limit: number;
  }) => {
    try {
      const response = await Post(`/api/events/${eventId}/businesses`, {
        business_id,
        user_limit
      });
      toast.success("Empresa vinculada com sucesso");
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error.message);
      throw error.response;
    }
  };

  @action
  linkBusinesInPackToEvent = async ({
    business_ids,
    eventId
  }: {
    business_ids: string[];
    eventId: string;
  }) => {
    try {
      const response = await Post(`/api/events/${eventId}/businesses`, {
        business_ids
      });
      toast.success("Empresas vinculadas com sucesso");
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error.message);
      throw error.response;
    }
  };

  @action
  unlinkBusinessFromEvent = async ({
    business_id,
    eventId,
  }: {
    business_id: string;
    eventId: string;
  }) => {
    try {
      const response = await Delete(
        `/api/events/${eventId}/businesses/${business_id}`
      );
      toast.success("Empresa desvinculada com sucesso");
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error.message);
      throw error.response;
    }
  };

  @action
  unlinkBusinessFromEventInBatch = async ({
    business_ids,
    eventId,
  }: {
    business_ids: string[];
    eventId: string;
  }) => {
    try {
      const response = await Put(
        `/api/events/${eventId}/businesses/subscription/remove`,
        {
          businessIds: business_ids
        }
      );
      toast.success("Empresas desvinculada com sucesso");
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error.message);
      throw error.response;
    }
  };

  @action
  updateBusinessFromEvent = async ({
    business_id,
    eventId,
    user_limit
  }: {
    business_id: string;
    eventId: string;
    user_limit: number;
  }) => {
    try {
      const response = await Put(
        `/api/events/${eventId}/businesses/${business_id}`,
        {
          user_limit: user_limit,
        }
      );
      toast.success("Empresa atualizada com sucesso");
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error.message);
      throw error.response;
    }
  };
}

export const eventsStore = new EventsStore();
