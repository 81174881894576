import React, { useEffect, useState } from "react";
import {
  // createStyles,
  // makeStyles,
  // Theme,
  Container, Button, Grid, TableContainer, Typography, Table, TableCell, TableBody, Paper,
} from "@material-ui/core";
import styled from "styled-components";
import checkins from "../../Assets/Icons/checkins2.svg";
import checkouts from "../../Assets/Icons/xicon.svg";
import inscritos from "../../Assets/Icons/inscritos2.svg";
import convites from "../../Assets/Icons/convite2.svg";
import dado from "../../Assets/Icons/dado2.svg";
import Graphic, { GraphicDoughnut } from "./Graphic";
import Calendar from "Components/Calendar";
import { useObserver } from "mobx-react-lite";
import { toJS } from "mobx";
// import Calendar from "./Calendar";
import { useStore } from "Store";
import SelectMonth from "./utils/select";
import { GenericButton, PrimaryActionButton } from "Components/Styled/Form";
import ModalToPdf from "./utils/modal";
import ReportToExcel, { ReportData } from "./utils/excel";
import { ReportBaseData } from "Store/Report";
import SelectFilter from "./utils/select-filter";
import moment from "moment";
import { GenericTableToolbar } from "Components/Styled/util";
import { GenericTableHead, GenericTableRow, GenericLeft2FAB, GenericFAB } from "Components/Styled/table";
import { Field, Formik } from "formik";
import CustomSelect from "Pages/Events/ShowEvent/Draw/Form/components/FilterSetupDialog/CustomSelect";

const labels = [
  "jan",
  "fev",
  "mar",
  "abr",
  "mai",
  "jun",
  "jul",
  "ago",
  "set",
  "out",
  "nov",
  "dez",
];

interface IFilterOption {
  label: string,
  value: string
}

const Report = () => {
  const { reportStore } = useStore();
  const [monthsQuantity, setMonthsQuantity] = useState(12);
  const [monthsEvents, setMonthsEvents] = useState(12);
  const [open, setOpen] = useState(false);

  const [filterEvent, setFilterEvent] = useState<IFilterOption[]>([]);
  const [filterCompany, setFilterCompany] = useState<IFilterOption[]>([]);
  const [filterCity, setFilterCity] = useState<IFilterOption[]>([]);

  useEffect(() => {
    reportStore.getFilters().then(async () => {
      fetchReports();
    })
    // console.log(toJS(reportStore.registrationTime));
  }, []);

  const fetchReports = async () => {
    reportStore.getReportMetrics();
    reportStore.getAttendeesRegistration();
    reportStore.getGenderReport();
    reportStore.getTimeRegistration();
    reportStore.getYearRegistration();
    reportStore.getCompaniesData();
    reportStore.getCitiesData();
    reportStore.getStateData();
  }

  useEffect(() => {
    if (reportStore.filters) {
      const companyFilter = reportStore.filters.business_filter.map(company => {
        return {label: company.label, value: company.value} as IFilterOption;
      });

      setFilterCompany(companyFilter);

      const eventFilter = reportStore.filters.events_filter.map(event => {
        return {label: event.label, value: event.value} as IFilterOption;
      });

      setFilterEvent(eventFilter);

      const cityFilter = reportStore.filters.cities_filter.map(city => {
        return {label: city.label, value: city.value} as IFilterOption;
      });

      setFilterCity(cityFilter);
    }
  }, [reportStore.filters]);

  const a = reportStore.gender.registrations;
  // console.log(toJS(a));

  // Cria Array de 12 posições
  const data_man = [...Array(12).fill(0)];
  const data_wom = [...Array(12).fill(0)];
  const month = [...Array(12).fill(0)];
  const monthName = [...Array(12).fill(0)];
  const quantityEvent = [...Array(12).fill(0)];
  const dados = { data_man, data_wom, month, monthName };
  const Male = useObserver(
    () =>
      reportStore.gender.registrations &&
      reportStore.gender.registrations.map((registration) => {
        const month = registration.month.split("-")[0].toString();

        return (
          //@ts-ignore
          (dados.data_man = data_man[Number(month) - 1] = registration.male)
        );
      })
  );

  const Female = useObserver(
    () =>
      reportStore.gender.registrations &&
      reportStore.gender.registrations.map((registration) => {
        const month = registration.month.split("-")[0].toString();

        return (
          //@ts-ignore
          (dados.data_wom = data_wom[Number(month) - 1] = registration.female)
        );
      })
  );

  const Undefined = useObserver(
    () =>
      reportStore.gender.registrations &&
      reportStore.gender.registrations.map((registration) => {
        const month = registration.month.split("-")[0].toString();

        return (
          //@ts-ignore
          (dados.data_wom = data_wom[Number(month) - 1] != registration.female && dados.data_wom != registration.male)
        );
      })
  );


  const Month = useObserver(
    () =>
      reportStore.gender.registrations &&
      reportStore.gender.registrations.map((registration) => {
        return (
          //@ts-ignore
          (dados.data_man = month[Number(month) - 1] = registration.month
            .split("-")[0]
            .toString())
        );
      })
  );
  
  const RegistrationsEvent = useObserver(
    () =>
      reportStore.registrationTime.registrations &&
      reportStore.registrationTime.registrations.map((registration) => {
        // console.log(registration);
        return (quantityEvent[Number(month) - 1] = registration.registrations);
      })
  );

  const RegistrationsEventByYear = useObserver(
    () =>
      reportStore.registrationByYear.registrations &&
      reportStore.registrationByYear.registrations.map((registration) => {
        return registration.registrations;
      })
  );

  const Years = useObserver(
    () =>
      reportStore.registrationByYear.registrations &&
      reportStore.registrationByYear.registrations.map((registration) => {
        return registration.date;
      })
  );

  const onSubmit = (values: any) => {
    console.log("Valores");
    console.log(values);

    reportStore.addSelectedFilter({
      name: "start_date",
      value: values.date
    })

    reportStore.addSelectedFilter({
      name: "cities",
      value: values.cities
    })

    reportStore.addSelectedFilter({
      name: "companies",
      value: values.companies
    })

    reportStore.addSelectedFilter({
      name: "events",
      value: values.events
    })

    fetchReports();

  }

  return useObserver(() => (
    <>
      <Container style={{ marginTop: "70px" }}>
        {open && reportStore.reportData && reportStore.reportAttendeeData && (
          <ReportToExcel
            handleClose={() => setOpen(false)}
            open={open}
            downloadButton={
              <Button
                onClick={() => setOpen(false)}
                variant="contained"
                color="secondary"
              >
                Baixar
              </Button>
            }
            dataSet={{
              generalReport: [toJS(reportStore.reportData)],
              registrations: toJS(reportStore.reportAttendeeData),
              citiesData: toJS(reportStore.citiesData),
              companiesData: [toJS(reportStore.companiesData)]
            }}
          />
        )}

        <Title>Relatórios</Title>
        <Formik
          initialValues={{

          }}
          onSubmit={onSubmit}
        >
          {({submitForm}) => {
            return (
              <>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={3}>
                  <Field
                    className="custom-select"
                    name="date"
                    options={[
                      {label: "Hoje", value: moment().format("YYYY-MM-DD")},
                      {label: "Desde ontem", value: moment().subtract(1, "day").format("YYYY-MM-DD")},
                      {label: "1 Semana", value: moment().subtract(1, "week").format("YYYY-MM-DD")},
                      {label: "1 mês", value: moment().subtract(1, "month").format("YYYY-MM-DD")},
                      {label: "3 meses", value: moment().subtract(3, "month").format("YYYY-MM-DD")},
                      {label: "6 meses", value: moment().subtract(6, "month").format("YYYY-MM-DD")},
                      {label: "1 ano", value: moment().subtract(1, "year").format("YYYY-MM-DD")},
                      {label: "2 anos", value: moment().subtract(2, "year").format("YYYY-MM-DD")},
                    ]}
                    component={CustomSelect}
                    placeholder="Selecionar Período"
                    isMulti={false}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  {filterEvent && filterEvent.length > 0 && (
                    <Field
                      className="custom-select"
                      name="events"
                      options={filterEvent}
                      component={CustomSelect}
                      placeholder="Selecionar Eventos"
                      isMulti={true}
                    />
                  )}
                </Grid>

                <Grid item xs={12} sm={3}>
                  {filterCity && filterCity.length > 0 && (
                    <Field
                      className="custom-select"
                      name="cities"
                      options={filterCity}
                      component={CustomSelect}
                      placeholder="Selecionar Cidades"
                      isMulti={true}
                    />
                  )}
                </Grid>

                <Grid item xs={12} sm={3}>
                  {filterCompany && filterCompany.length > 0 && (
                    <Field
                      className="custom-select"
                      name="companies"
                      options={filterCompany}
                      component={CustomSelect}
                      placeholder="Selecionar Empresas"
                      isMulti={true}
                    />
                  )}
                </Grid>
              </Grid>
              <GenericLeft2FAB
                onClick={() => {
                  setOpen(true);
                }}
                variant="extended"
                color="primary"
              >
                Gerar Relatório
              </GenericLeft2FAB>
              <GenericFAB
                variant="extended"
                color="primary"
                onClick={() => {
                  submitForm();
                }}
              >
                Buscar
              </GenericFAB>
              </>
            );
        }}
        </Formik>
        
        <ContainerCard>
          <Card>
            <img src={inscritos} alt="inscritos" />

            <Quantity>{toJS(reportStore.reportData.registrations)}</Quantity>
            <Description>Inscritos em eventos</Description>
          </Card>
          <Card>
            {" "}
            <img src={checkins} alt="checkins" />
            <Quantity>{toJS(reportStore.reportData.checkins)}</Quantity>
            <Description>Checkins realizados</Description>
          </Card>
          <Card>
            {" "}
            <img src={checkouts} alt="ausentes" />
            <Quantity>{toJS(reportStore.reportData.absences)}</Quantity>
            <Description>Checkins não realizados</Description>
          </Card>
          <Card>
            {" "}
            <img src={convites} alt="convites" />
            <Quantity>{toJS(reportStore.reportData.guests)}</Quantity>
            <Description>Convites para visitantes</Description>
          </Card>
        </ContainerCard>
        
        <ContainerCard>
          <GraphicStyle>
            <Graphic
              label={Month}
              data1={Male}
              label1={"Homens"}
              data2={Female}
              label2={"Mulheres"}
              title={"Inscritos por gênero mensalmente"}
              type={"Bar"}
            />
          </GraphicStyle>

          <GraphicStyle>
            {/* <SelectMonth
              value={monthsQuantity || 3}
              label={"Visão mensal"}
              onChange={(e: any) => {
                setMonthsEvents(e.target.value);
              }}
            /> */}
            <Graphic
              label={Month}
              data1={RegistrationsEvent}
              label1={"Inscritos por mês"}
              title={"Comparativo de inscrição em eventos por mês"}
              type={"Line"}
            />
          </GraphicStyle>

          <GraphicStyle>
            <Graphic
              label={Years}
              data1={RegistrationsEventByYear}
              label1={"Inscritos por ano"}
              title={"Comparativo de inscrição em eventos por ano"}
              type={"Line"}
            />
          </GraphicStyle>

        </ContainerCard>

        <ContainerCard>
          <Card>
            <img src={inscritos} alt="inscritos" />

            <Quantity>{toJS(reportStore.companiesData.total_companies_amount)}</Quantity>
            <Description>Empresas Participantes</Description>
          </Card>
          <Card>
            {" "}
            <img src={inscritos} alt="checkins" />
            <Quantity>{toJS(reportStore.companiesData.associated_companies_amount)}</Quantity>
            <Description>Empresas Associadas</Description>
          </Card>
          <Card>
            {" "}
            <img src={inscritos} alt="convites" />
            <Quantity>{toJS(reportStore.companiesData.not_associated_companies_amount)}</Quantity>
            <Description>Empresas Não Associadas</Description>
          </Card>
          <Card>
            {" "}
            <img src={dado} alt="dado" />
            <Quantity>{toJS(reportStore.reportData.draws)}</Quantity>
            <Description>Sorteios realizados</Description>
          </Card>
        </ContainerCard>
        
        <ContainerCard>
          <TableCard>
            <TableContainer component={Paper}>
              <GenericTableToolbar>
                <Typography variant="subtitle1" component="div" style={{fontWeight: "bold"}}>
                  Participantes por empresa associada
                </Typography>
              </GenericTableToolbar>
              <Table>
                <GenericTableHead>
                  <GenericTableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Participantes</TableCell>
                  </GenericTableRow>
                </GenericTableHead>
                <TableBody>
                  {reportStore.companiesData.associated_companies && reportStore.companiesData.associated_companies.map((associated_company) => (
                    <GenericTableRow key={associated_company.name}>
                      <TableCell component="th" scope="row">
                        {associated_company.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {associated_company.participants}
                      </TableCell>
                    </GenericTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TableCard>
          <TableCard>
            <TableContainer component={Paper}>
              <GenericTableToolbar>
                <Typography variant="subtitle1" component="div" style={{fontWeight: "bold"}}>
                  Participantes por empresa não associada
                </Typography>
              </GenericTableToolbar>
              <Table>
                <GenericTableHead>
                  <GenericTableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Participantes</TableCell>
                  </GenericTableRow>
                </GenericTableHead>
                <TableBody>
                  {reportStore.companiesData.not_associated_companies && reportStore.companiesData.not_associated_companies.map((not_associated_company) => (
                    <GenericTableRow key={not_associated_company.name}>
                      <TableCell component="th" scope="row">
                        {not_associated_company.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {not_associated_company.participants}
                      </TableCell>
                    </GenericTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TableCard>
        </ContainerCard>

        <ContainerCard>
          <TableCard>
            <TableContainer component={Paper}>
              <GenericTableToolbar>
                <Typography variant="subtitle1" component="div" style={{fontWeight: "bold"}}>
                  Participantes por cidade
                </Typography>
              </GenericTableToolbar>
              <Table>
                <GenericTableHead>
                  <GenericTableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Participantes</TableCell>
                  </GenericTableRow>
                </GenericTableHead>
                <TableBody>
                  {reportStore.citiesData && reportStore.citiesData.map((city) => (
                    <GenericTableRow key={city.name}>
                      <TableCell component="th" scope="row">
                        {city.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {city.participants}
                      </TableCell>
                    </GenericTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TableCard>

          <TableCard>
            <TableContainer component={Paper}>
              <GenericTableToolbar>
                <Typography variant="subtitle1" component="div" style={{fontWeight: "bold"}}>
                  Participantes por estado
                </Typography>
              </GenericTableToolbar>
              <Table>
                <GenericTableHead>
                  <GenericTableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Participantes</TableCell>
                  </GenericTableRow>
                </GenericTableHead>
                <TableBody>
                  {reportStore.statesData && reportStore.statesData.map((state) => (
                    <GenericTableRow key={state.name}>
                      <TableCell component="th" scope="row">
                        {state.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {state.participants}
                      </TableCell>
                    </GenericTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TableCard>
          
          <ContainerCircularGraphic>
            <GraphicDoughnut
              label={["Participantes"]}
              data1={toJS(reportStore.reportData.checkins)}
              label1={"Checkin efetuado"}
              data2={toJS(reportStore.reportData.absences)}
              label2={"Sem Checkin"}
              title={"Participantes"}
              type={"Doughnut"}
              color1={"#a9a4db"}
              color2={"#cbc5fe"}
            />
          </ContainerCircularGraphic>

        </ContainerCard>
      </Container>
      <div style={{
            padding: "10px",
            zIndex: 4,
            marginTop: "20px",
            marginRight: "3%",
          }}>
            -
          </div>
      {/* <GenericButton
        variant="contained"
        style={{
          float: "left",
          width: "200px",
          height: "max-content",
          padding: "10px",
          zIndex: 4,
          marginTop: "1%",
          marginLeft: "3%",
        }}
        onClick={() => setOpen(true)}
      >
        Gerar relatório
      </GenericButton>
      <GenericButton
        variant="contained"
        style={{
          float: "right",
          width: "200px",
          height: "max-content",
          padding: "10px",
          zIndex: 4,
          marginTop: "1%",
          marginRight: "3%",
        }}
        onClick={() => fetchReports()}
      >
        Buscar
      </GenericButton> */}
    </>
  ));
};

export default Report;

const Title = styled.h1`
  color: #00577b;
`;

const Card = styled.div`
  padding: 20px;
  width: 25%;
  background: white;
  margin: 10px;
  color: #00577b;
  font-family: Arial, Helvetica, sans-serif;
`;

const TableCard = styled.div`
  padding: 20px;
  width: 50%;
  background: white;
  margin: 10px;
  color: #00577b;
  font-family: Arial, Helvetica, sans-serif;
`;

const ContainerCard = styled.div`
  width: 100%;
  display: flex;
`;

const ContainerCalendar = styled.div`
  margin-top: 10px;
  width: 70%;
  display: flex;
  background: white;
  padding: 20px;
`;

const Quantity = styled.h2`
  margin-top: 10px;
  color: black !important;
  margin-bottom: -10px;
  margin-left: 5px;
`;
const Description = styled.p`
  color: black !important;
  margin-left: 5px;
`;
const GraphicStyle = styled.div`
  padding: 20px;
  width: 50%;
  background: white;
  margin: 10px;
  color: #00577b;
  font-family: Arial, Helvetica, sans-serif;
`;

const ContainerCircularGraphic = styled.div`
  margin-top: 10px;
  width: 96%;
  height: auto;
  display: flex;
  background: white;
  padding: 20px;
  margin-left: 20px;
`;
