import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import * as S from "./styles";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useStore } from "Store";
import { IconButton } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { userHasPermission } from "Utils";

interface IProps {
  initials: string | undefined;
}

const CircleInitialsAvatar: React.FC<IProps> = ({ initials }) => {
  const history = useHistory();
  const { userStore, appStore } = useStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChangeBaseUrl = () => {
    history.push("/settings/url");
    handleClose();
  };

  const handleChangeBaseUrlAttendeeTypes = () => {
    history.push("/tipos-participantes");
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSignOut = () => {
    userStore.signout();
    handleClose();
  };
  return (
    // @ts-ignore
    <div style={{ cursor: "pointer" }}>
      <S.Container>
        {/* <S.InitialsContainer onClick={handleClick}>
          {initials}
        </S.InitialsContainer> */}
        <IconButton
          title="Configurações"
          onClick={(event) => {
            handleClick(event);
          }}>
          <SettingsIcon style={{color: "rgb(0, 87, 123)", fontSize: "25px"}} />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          style={{
            zIndex: 999999
          }}
        >
          {/* <MenuItem onClick={() => handleChangeBaseUrl()}>
            Alterar servidor
          </MenuItem> */}
          {userHasPermission(userStore.currentUser?.roles[0], "my-profile") && (
            <MenuItem onClick={() => {
                history.push("/my-profile");
                handleClose();
              }}>
              Meu Perfil
            </MenuItem>
          )}
          {userHasPermission(userStore.currentUser?.roles[0],"tipos-participantes") && (
            <MenuItem onClick={() => {
              handleChangeBaseUrlAttendeeTypes()
            }}>
              Tipos de Participantes
            </MenuItem>
          )}
          {userHasPermission(userStore.currentUser?.roles[0],"register-questions") && (
            <MenuItem onClick={() => {
              history.push("/register-questions");
              handleClose();
            }}>
              Perguntas para novos registros
            </MenuItem>
          )}
          {userHasPermission(userStore.currentUser?.roles[0],"contact-form") && (
            <MenuItem onClick={() => {
              history.push("/contacts/form");
              handleClose();
            }}>
              Editar Contatos
            </MenuItem>
          )}
          {/* <MenuItem onClick={() => appStore.sync()}>Sincronizar</MenuItem> */}
          <MenuItem onClick={() => handleSignOut()}>Logout</MenuItem>
        </Menu>
      </S.Container>
    </div>
  );
};

export default CircleInitialsAvatar;
