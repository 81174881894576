import styled from "styled-components";

export const Container = styled.div`
  margin-top: 20px;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  button {
    margin-top: 20px;
  }
`;
