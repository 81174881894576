import React from 'react'

import { CustomCircularProgress } from 'Components/Styled/Loading'

import * as S from './styles'

interface IProps {
  open: boolean;
}

const LoadingModal: React.FC<IProps> = ({ open, children }) => {
  return (
    <S.Container>
      <S.CustomDialog open={open}>
        <S.IconContainer className="container">
          <CustomCircularProgress />
          {children}
        </S.IconContainer>
      </S.CustomDialog>
    </S.Container>
  )
}

export default LoadingModal
