import React, { useState, useEffect } from "react";

import { Form, Formik, Field } from "formik";
// @ts-ignore
import { FormikSelectField } from "formik-material-fields";
// @ts-ignore
import { FormikTextField } from "formik-material-fields";
import * as S from "./styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { genderOptions } from "./mock";
import { useStore } from "Store";
import { ISorteioFilter } from "Models/Events";
import { IOptions } from "Pages/Attendees/Form/models";
import { FormikAutocomplete } from "Signup";
import CustomSelect from "./CustomSelect";
import { Grid } from "@material-ui/core";

interface IProps {
  filterSetup: ISorteioFilter;
  open: boolean;
  handleClose: Function;
  onSubmit: Function;
  companyOptions: IOptions[];
}
interface IMyValues {
  name: string;
  gender: string;
  company: string;
  type: string;
}

const FilterSetupDialog: React.FC<IProps> = ({
  open,
  handleClose,
  onSubmit,
  filterSetup,
  companyOptions,
}) => {
  const { eventsStore, attendeesStore } = useStore();

  const initialValues: IMyValues = {
    name: filterSetup.name || "",
    gender: filterSetup.gender || "default",
    company: filterSetup.company || "default",
    type: filterSetup.type || "default",
  };

  const [selectedCompany, setSelectedCompany] = useState(filterSetup.company || "default" as string);
  const [tipoDeEntradaOptions, setTipoDeEntradaOptions] = useState<{label: string, value: string}[]>([]);

  useEffect(() => {
    attendeesStore.fetchAttendeeTypes({page: 1, limit: 999});
  }, [attendeesStore]);

  useEffect(() => {
    if (attendeesStore.attendeeTypes && attendeesStore.attendeeTypes.length > 0) {
      var newStatus: {label: string, value: string}[] = [];

      // newStatus.push({label: "Todos", value: "default"});

      attendeesStore.attendeeTypes.map(type => {
        newStatus.push({label: type.name, value: type.value});
      })

      setTipoDeEntradaOptions(newStatus);
    }
  }, [attendeesStore.attendeeTypes]);

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={() => handleClose()}>
      <DialogTitle id="form-dialog-title">Configuração de filtro</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          // @ts-ignore
          onSubmit={onSubmit}
        >
          {({setFieldValue}) => (
            <>
              <Form>
                <S.FormWrapper>
                  {/* <FormikSelectField
                    name="type"
                    label="Tipo de entrada"
                    options={tipoDeEntradaOptions}
                    variant="outlined"
                  /> */}
                  <Grid>
                    {tipoDeEntradaOptions && tipoDeEntradaOptions.length > 0 && (
                      <Field
                        className="custom-select"
                        name="types"
                        options={tipoDeEntradaOptions}
                        component={CustomSelect}
                        placeholder="Tipos de entrada"
                        isMulti={true}
                      />
                    )}
                  </Grid>
                  <Grid style={{marginTop: "5px"}}>
                    <Field
                      className="custom-select"
                      name="gender"
                      options={genderOptions}
                      component={CustomSelect}
                      placeholder="Gênero"
                      isMulti={false}
                    />
                  </Grid>
                  {/* <FormikSelectField
                    name="gender"
                    label="Gênero"
                    options={genderOptions}
                    variant="outlined"
                  /> */}
                  {/* <FormikSelectField
                    name="company"
                    label="Empresa"
                    options={companyOptions}
                    variant="outlined"
                  /> */}
                  <Grid style={{marginTop: "5px"}}>
                    <Field
                        className="custom-select"
                        name="companies"
                        options={companyOptions}
                        component={CustomSelect}
                        placeholder="Empresas"
                        isMulti={true}
                      />
                  </Grid>
                  {/* <Field
                    name="company_autocomplete"
                    className="form-field"
                    component={FormikAutocomplete}
                    variant="outlined"
                    label="Empresa"
                    value={selectedCompany}
                    options={companyOptions}
                    //@ts-ignore
                    onChange={(target => {
                      if (target && target.value) {
                        setSelectedCompany(target.value);
                        setFieldValue('company', target.value)
                      }
                    })}
                  /> */}
                  {/* <FormikTextField
                    name="name"
                    label="Nome"
                    variant="outlined"
                  /> */}
                  <DialogActions>
                    <Button
                      onClick={() => handleClose()}
                      variant="contained"
                      color="secondary"
                    >
                      Cancelar
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                      Salvar filtro
                    </Button>
                  </DialogActions>
                </S.FormWrapper>
              </Form>
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default FilterSetupDialog;
