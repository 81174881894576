import React, { useState } from 'react'

import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import IconButton from "@material-ui/core/IconButton";

import { AlertChip } from "Components/Styled/util";


interface IProps {
  title: string;
  helperText: string;
}

const ChipAlert: React.FC<IProps> = ({ title, helperText }) => {
  const [tipOpen, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <AlertChip
        style={{ marginLeft: 20 }}
        color="secondary"
        label={
          <div style={{ display: "flex", alignItems: "center" }}>
            {title}
          </div>
        }
      />
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true
            }}
            onClose={handleTooltipClose}
            open={tipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={helperText}
          >
            <IconButton onClick={handleTooltipOpen}>
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  )
}

export default ChipAlert
