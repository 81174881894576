import React, { useState } from "react";
import * as S from "./styles";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import CircleInitialsContainer from "Components/CircleInitialsAvatar";
import { useStore } from "Store";
import SettingsIcon from "@material-ui/icons/Settings";

const UserDisplayData = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { userStore } = useStore();

  const getRoleName = (role: string | undefined) => {
    if (role === "participant") {
      return "Participante";
    } else if (!role) {
      return "";
    } else if (role === "company_admin") {
      return "Admin. Empresa";
    } else {
      return "Administrador";
    }
  };

  return (
    <S.Container aria-controls="simple-menu" aria-haspopup="true">
      {/* <S.StatusRow online={true}>
        <FiberManualRecordIcon />
        Online
      </S.StatusRow> */}
      <S.UserNameAndRoleContainer>
        {/* 
    // @ts-ignore */}
        <div className="username" onClick={handleClick}>
          {userStore.currentUser ? (
            <>
              {userStore.currentUser.first_name.split(" ")[0]}{" "}
              {userStore.currentUser.last_name}
            </>
          ) : (
            <>
            </>
          )}
          
        </div>
        <div className="role">{getRoleName(userStore.currentUser?.roles[0])}</div>
      </S.UserNameAndRoleContainer>
      <CircleInitialsContainer initials={userStore.currentUserInitials} />
    </S.Container>
  );
};

export default UserDisplayData;
