import React from "react";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

interface IProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: Function;
  handleEdit: Function;
  handleDelete: Function;
}

const DrawContextMenu: React.FC<IProps> = ({
  anchorEl,
  open,
  handleClose,
  handleDelete,
  handleEdit,
}) => {
  return (
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={() => handleClose()}
    >
      <MenuItem onClick={() => handleDelete(true)}>Excluir</MenuItem>
      <MenuItem onClick={() => handleEdit(true)}>Editar</MenuItem>
    </Menu>
  );
};

export default DrawContextMenu;
