import React from "react";
import { Paper } from "@material-ui/core";

import BarcodeGenerator from "./barcode";
import { BadgeTitle, TextContainer, EventContainer } from "..";

import styled from "styled-components";

import { IAttendee } from "Models/Attendees";
import { Registration } from "Models/Events";

interface Data {
  title?: string;
  header?: string;
  functionClose?: Function;
  renderFunction?: Function;
  userArray?: Registration[];
  participant?: string;
  description?: string;
  date?: string;
  all?: boolean;
  registrations?: number;
  image?: string;
  code?: string;
  event?: string;
}
interface Props {
  data: Data;
}

const BadgeItem = (props: Props) => {
  const getAttendeeName = (user: IAttendee) => {
    if (user.nickname) return user.nickname.toUpperCase();
    else {
      let first_name = user.first_name.split(" ")[0];
      let last_name_array = user.last_name.split(" ");
      let last_name = last_name_array[last_name_array.length - 1];

      return (first_name + " " + last_name).toUpperCase();
    }
  };

  return (
    <div>
      <Paper
        style={{
          background: "#ffffff !important",

          marginBottom: "20px",
        }}
      >
        {!props.data.all ? (
          <div
            style={{
              background: "#ffffff ",
              padding: "20px",
              border: "1px solid",
            }}
          >
            <BadgeTitle>{props.data.title}</BadgeTitle>

            <TextContainer>
              Nome:
              <TextDiv>{props.data.participant}</TextDiv>
            </TextContainer>

            <TextContainer>
              Empresa:
              <TextDiv>{props.data.header}</TextDiv>
            </TextContainer>
            <TextContainer>
              Cargo:
              <TextDiv>{props.data.description}</TextDiv>
            </TextContainer>

            {/* <EventContainer>
              {BarcodeGenerator(props.data.code!)}
            </EventContainer> */}
          </div>
        ) : (
          props.data.userArray!.map((item) => (
            <div
              style={{
                background: "#ffffff ",
                borderBottom: "1px solid",
                padding: "5px",
              }}
            >
              <BadgeTitle>{props.data.title}</BadgeTitle>

              <TextContainer>
                Nome:
                <TextDiv>
                  {getAttendeeName(item.user)}
                </TextDiv>
              </TextContainer>

              <TextContainer>
                Empresa:
                <TextDiv>{item.user.business && item.user.business.name ? item.user.business.name.toUpperCase() : (item.user.business_title ? item.user.business_title.toUpperCase() : "N/D")}</TextDiv>
              </TextContainer>
              <TextContainer>
                Cargo:
                <TextDiv>{item.user.job_title ? item.user.job_title.toUpperCase() : "N/D"}</TextDiv>
              </TextContainer>

              {/* <EventContainer>{BarcodeGenerator(item._id!)}</EventContainer> */}
            </div>
          ))
        )}
      </Paper>
    </div>
  );
};

export default BadgeItem;

export const TextDiv = styled.div`
  font-weight: bold;
  text-align: justify;
  margin-left: 5px;
`;
