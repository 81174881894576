import styled from "styled-components";
const LOGO_BRANCA = require("../../../Assets/Images/LOGO_BRANCA.svg");

export const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  height: 60px;
  background: #00577b;
  width: 100vw;

  @media (max-width: 500px) {
    height: 98px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 500px) {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
`;

export const ButtonRow = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;

  @media (max-width: 500px) {
    height: 38px;
    justify-content: space-around;
  }
`;

interface ButtonProps {
  isActive: boolean;
}
export const Button = styled.div<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 4px);

  padding: 0 20px;

  cursor: pointer;
  font-weight: bold;
  color: #fff;
  border-bottom: ${(props) => (props.isActive ? "2px solid #fff" : "none")};
  // border-bottom-width: thin;

  @media (max-width: 500px) {
    height: 38px;
  }
`;
export const NavigationContainer = styled.div`
  padding: 0 1rem;
  display: flex;
  align-items: center;
  color: #fff;

  * {
    cursor: pointer;
  }
`;

export const LogoContainer = styled.div`
  height: 0;
  width: 60px;
  height: 60px;

  background: url(${LOGO_BRANCA}) no-repeat;
  background-position: center center;
  background-size: 90%;

  @media (max-width: 1400px) {
    width: 50px;
    height: 50px;
  }

  @media (max-height: 650px) {
    width: 40px;
    height: 40px;
  }
`;