import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { EventModel } from "Models/Events";

// * Material UI
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import IconButton from "@material-ui/core/IconButton";

import { Add, MoreVert } from "@material-ui/icons";
import {
  FlexTD,
  GenericFAB,
  GenericTableHead,
  GenericTableRow,
  GenericLeftFAB,
} from "Components/Styled/table";
import { toJS, autorun } from "mobx";
import Moment from "moment";
import { useStore } from "Store";
import SearchBar from "Components/SearchBar";
import * as S from "./styles";
import { useObserver } from "mobx-react-lite";

import EventContextMenu from "./components/EventContextMenu";
import DeleteEventDialog from "./components/DeleteEventDialog";

import { CustomCircularProgress } from "Components/Styled/Loading";
import styled from "styled-components";

import { NoDataContainer } from "Components/Styled/util";
// @ts-ignore
import { useDebounce } from "use-debounce";
import logoAdapa from "Assets/Images/adapa.png";
import { TablePagination } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    status__active: {
      color: "#2cda2c",
    },
    status__inactive: {
      color: "#e72419",
    },
  })
);

const Home = () => {
  const history = useHistory();
  const classes = useStyles();
  const { eventsStore, userStore, appStore } = useStore();
  const [isCurrentEventActive, setIsCurrentEventActive] = useState<boolean>(
    false
  );
  const [currentEvent, setCurrentEvent] = useState<EventModel | null>(
    {} as EventModel
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentEventId, setCurrentEventId] = useState("");
  const open = Boolean(anchorEl);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [debounced] = useDebounce(filter, 500);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    eventId: string,
    isActive: boolean
  ) => {
    setIsCurrentEventActive(isActive);
    setCurrentEventId(eventId);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    eventsStore.fetchEvents(page + 1, limit);
  }, [eventsStore]);

  if (userStore && userStore.currentUser && (userStore.currentUser.roles[0] === "participant")) {
    history.replace("/subscriptions")
  }

  useEffect(
    autorun(() => {
      console.log(toJS(eventsStore.events));
    })
  );

  const handleEditEvent = () => {
    history.push(`/events/form/${currentEventId}`);
  };

  const handleActiveEvent = async () => {
    try {
      await eventsStore.onHandleActivateEvent({
        active: isCurrentEventActive,
        eventId: currentEventId,
      });
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilterEvents = (value: string) => {
    setFilter(value);
  };

  useEffect(() => {
    console.log(debounced);
    eventsStore.fetchEvents(page + 1, limit, debounced);
  }, [debounced, eventsStore]);

  return useObserver(() => (
    <>
      <SearchBar title={"Eventos" + ` (${(eventsStore.currentPagination.eventsCount)})`} onFilter={handleFilterEvents} />
      <S.Container>
        <GenericLeftFAB
          onClick={() => history.push("/events/form")}
          className={classes.fab}
          variant="extended"
          color="primary"
        >
          <Add /> Novo evento
        </GenericLeftFAB>

        {eventsStore.events.length < 1 ? (
          <NoDataContainer>
            <h3>Sem eventos aqui!</h3>
          </NoDataContainer>
        ) : (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="empresas table">
              <GenericTableHead>
                <GenericTableRow>
                  <TableCell>Imagem</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Administrador</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Opções</TableCell>
                </GenericTableRow>
              </GenericTableHead>
              <TableBody>
                {eventsStore.isFetchingEvents ? (
                  <GenericTableRow>
                    <td colSpan={7}>
                      <S.LoadingContainer>
                        <CustomCircularProgress />
                      </S.LoadingContainer>
                    </td>
                  </GenericTableRow>
                ) : (
                  eventsStore.events?.map((row, index) => (
                    <>
                      <GenericTableRow key={index} clickable>
                        <TableCell
                          onClick={() => history.push(`/events/${row._id}`)}
                          component="th"
                          scope="row"
                        >
                          <img
                            src={appStore.currentBaseURL + "/" + row.cover_url}
                            alt="cover"
                            width="100"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src=logoAdapa;
                            }}
                          />
                        </TableCell>
                        <TableCell
                          onClick={() => history.push(`/events/${row._id}`)}
                          component="th"
                          scope="row"
                        >
                          <FlexTD>
                            <span className="title">{row.name}</span>
                            <span>{row.description}</span>
                          </FlexTD>
                        </TableCell>
                        <TableCell
                          onClick={() => history.push(`/events/${row._id}`)}
                        >
                          {row.organizer.name}
                        </TableCell>
                        <TableCell
                          onClick={() => history.push(`/events/${row._id}`)}
                        >
                          {row.type === "online" ? "Online" : "Presencial"}
                        </TableCell>
                        <TableCell
                          onClick={() => history.push(`/events/${row._id}`)}
                          className={
                            row.active
                              ? classes.status__active
                              : classes.status__inactive
                          }
                        >
                          {row.active ? "Ativo" : "Inativo"}
                        </TableCell>
                        <TableCell
                          onClick={() => history.push(`/events/${row._id}`)}
                        >
                          {Moment(row.starts_at).format(
                            "DD [de] MMMM [de] YYYY [às] HH[:]MM"
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e) => handleClick(e, row._id, row.active)}
                          >
                            <MoreVert color="secondary" />
                          </IconButton>
                        </TableCell>
                      </GenericTableRow>
                      <EventContextMenu
                        active={isCurrentEventActive}
                        open={open}
                        handleActive={handleActiveEvent}
                        handleClose={handleClose}
                        handleDelete={setDeleteDialogOpen}
                        handleEdit={handleEditEvent}
                        anchorEl={anchorEl}
                      />
                    </>
                  ))
                )}
              </TableBody>
            </Table>
            <S.PaginationContainer>
              <TablePagination
                component="div"
                page={page}
                count={eventsStore.currentPagination.pages}
                rowsPerPage={limit}
                labelRowsPerPage="Quantidade"
                nextIconButtonProps={{disabled: page >= eventsStore.currentPagination.pages - 1}}
                onPageChange={(event, value) => {
                  setPage(value);

                  eventsStore.fetchEvents(
                    value + 1,
                    limit,
                    debounced || ""
                  )
                }}
                onChangeRowsPerPage={(event) => {
                  setLimit(+event.target.value);
                  
                  eventsStore.fetchEvents(
                    page + 1,
                    +event.target.value,
                    debounced || ""
                  )
                }}
                color="secondary"
              />
            </S.PaginationContainer>
          </TableContainer>
        )}
      </S.Container>

      <DeleteEventDialog
        open={isDeleteDialogOpen}
        eventId={currentEventId}
        handleClose={handleCloseDeleteDialog}
      />
    </>
  ));
};

export default Home;

const Message = styled.div`
  display: flex;
  color: #00577b;

  margin-top: 25%;
  height: 100%;
  /* left: 50%; */
  font-weight: bold;
  font-size: 20pt;
  text-align: center;
  justify-content: center;
`;
