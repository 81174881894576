import styled from "styled-components";

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const FormContent = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

export const ButtonRow = styled.div`
  position: fixed;
  bottom: 0;
  right: 10%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;

  * {
    margin: 20px 0 0 10px;
  }
`;

export const LoadingStatusContainer = styled.div`
  font-weight: bold;
  color: #fff;
`;
