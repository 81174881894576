import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Dialog,
  Fade,
  Container,
  Button,
  DialogActions,
} from "@material-ui/core";

import { Formik, Form } from "formik";
import { initialValues } from "Pages/Companies/models";
import { validationSchema } from "Pages/Attendees/Form/models";

import { GenericButton } from "Components/Styled/Form";
//@ts-ignore
import { FormikSelectField } from "formik-material-fields";
import { useParams } from "react-router-dom";
import { useStore } from "Store";
import styled from "styled-components";

// @ts-ignore
import { FormikTextField } from "formik-material-fields";

import { EventBusiness } from "Models/Events";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: "10px 0",
    minWidth: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    "& .MuiTextField-root": {
      margin: "10px 0",
    },
    backgroundColor: theme.palette.background.paper,

    height: "auto",
    maxWidth: "800px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

interface Props {
  openIt: boolean;
  setOpen: Function;
  eventBusiness: EventBusiness;
  onEdit: Function;
}
interface MyValuesHere {
  user_limit: number;
}
interface RouteParams {
  eventId: string;
}

const ModalEditLimit = ({ openIt, setOpen, eventBusiness, onEdit }: Props) => {
  const [stateStatus, setstateStatus] = useState("");
  const { eventId } = useParams<RouteParams>();
  const { eventsStore } = useStore();
  const classes = useStyles();
  const [isFetching, setIsFetching] = useState(false);
  const { attendeesStore } = useStore();
  const [status, setStatus] = useState<{label: string, value: string}[]>([]);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (values: MyValuesHere) => {
    setIsFetching(true);
    try {
      await eventsStore.updateBusinessFromEvent({business_id: eventBusiness.business_id, eventId, user_limit: values.user_limit});
      await onEdit();
    } catch (ex) {
      console.error(ex);
    }
    setIsFetching(false);
    setOpen(false);
  };

  const initialValues: MyValuesHere = {
    user_limit: eventBusiness.user_limit,
  };

  useEffect(() => {
    attendeesStore.fetchAttendeeTypes({page: 1, limit: 999});
  }, [attendeesStore]);

  useEffect(() => {
    if (attendeesStore.attendeeTypes && attendeesStore.attendeeTypes.length > 0) {
      var newStatus: {label: string, value: string}[] = [];

      attendeesStore.attendeeTypes.map(type => {
        newStatus.push({label: type.name, value: type.value});
      })

      setStatus(newStatus);
    }
  }, [attendeesStore.attendeeTypes]);

  return (
    <Dialog fullWidth maxWidth="sm" open={openIt}>
      <Fade in={openIt}>
        <div className={classes.paper}>
          <h2 style={{ color: "#00577C" }}>Limite de Participantes</h2>

          <Container>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values }) => (
                <>
                  <FormContainer
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Form autoComplete="off">
                      <FormContainer>
                        <FormikTextField
                          name="user_limit"
                          label="Novo Limite"
                          fullWidth
                          variant="outlined"
                        />
                      </FormContainer>
                      <DialogActions>
                        <Button
                          onClick={handleClose}
                          variant="contained"
                          color="secondary"
                          disabled={isFetching}
                        >
                          Cancelar
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={isFetching}
                          onClick={() => onSubmit(values)}
                        >
                          Atualizar
                        </Button>
                      </DialogActions>
                    </Form>
                  </FormContainer>
                </>
              )}
            </Formik>
          </Container>
        </div>
      </Fade>
    </Dialog>
  );
};

export default ModalEditLimit;

const FormContainer = styled.div`
  margin-bottom: 20px;
`;
