import React, { useState } from "react";
import { Registration } from "Models/Events";
// @ts-ignore
import ReactExport from "react-export-excel";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
const StringMask = require("string-mask");

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

interface IProps {
  dataSet: Registration[];
  downloadButton: any;
  open: boolean;
  handleClose: Function;
}

const Download: React.FC<IProps> = ({
  dataSet,
  downloadButton,
  open,
  handleClose,
}) => {
  const [fileName, setFileName] = useState("");
  const formatter = new StringMask("(00) 00000-0000");

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Exportação de check-in"}
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          variant="outlined"
          onChange={(e) => setFileName(e.target.value)}
          label="Nome do arquivo"
          placeholder="Digite um nome personalizado"
        />
        <DialogContentText id="alert-dialog-description">
          Você pode escolher um nome para o arquivo a ser exportado ou usar o
          nome padrão.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>Cancelar</Button>
        <ExcelFile
          element={downloadButton}
          filename={fileName.length > 0 ? fileName : "Check-ins"}
        >
          <ExcelSheet data={dataSet} name="Check-ins">
            <ExcelColumn
              label="Name"
              value={(col: Registration) =>
                `${col.user.first_name} ${col.user.last_name}`
              }
            />
            <ExcelColumn
              label="CPF"
              value={(col: Registration) => `${col.user.cpf}`}
            />
            <ExcelColumn
              label="Empresa"
              value={(col: Registration) => `${col.user.business && col.user.business.name ? col.user.business.name : (col.user.business_title ? col.user.business_title + "*" : "") }`}
            />
            <ExcelColumn
              label="Email"
              value={(col: Registration) => `${col.user.email}`}
            />
            <ExcelColumn
              label="Contato"
              value={(col: Registration) => `${formatter.apply(
                col.user.phone.area_code +
                  (col.user.phone.number.split(" ").length > 1 ? col.user.phone.number.split(" ")[1].replace("-", "") : col.user.phone.number.replace("-", ""))
              )}`}
            />
            <ExcelColumn
              label="Presença"
              value={(col: Registration) =>
                col.paid ? (col.attended ? "Confirmada" : "Não Confirmada") : "Aguardando pagamento"
              }
            />
          </ExcelSheet>
        </ExcelFile>
      </DialogActions>
    </Dialog>
  );
};

export default Download;
