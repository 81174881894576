import React, { useState, useEffect, useCallback } from "react";
// @ts-ignore
import { FormikSelectField, FormikTextField } from "formik-material-fields";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import * as S from "./styles";
import { useStore } from "Store";
import { Formik, Field } from "formik";
import { IItems, EventModel, Registration } from "Models/Events";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { IAttendee } from "Models/Attendees";
import BadgeItem from "./components/badgeItem";
import CustomizedCheckbox from "../Checkin/checkbox";

import { ceil } from "lodash";
// @ts-ignore

import PdfBadge from "./components/pdfTeste";
import { string } from "yup";
import Companies from "Pages/Companies";
import { FormikAutocomplete } from "Signup";
import { Checkbox, TableContainer, Table, TableRow, TableCell, TableBody } from "@material-ui/core";
import { GenericTableHead } from "Components/Styled/table";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      width: "100%",
    },
    control: {
      padding: theme.spacing(2),
    },
  })
);

interface RouteParams {
  eventId: string;
}
const Badge = () => {
  const classes = useStyles();
  const { eventId } = useParams<RouteParams>();
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [hasChangesBadgeSettings, setHasChangesBadgeSettings] = useState<
    boolean
  >(false);
  const [myEvent, setMyEvent] = useState<EventModel>({} as EventModel);
  const [renders, setRenders] = useState(0);
  const { eventsStore } = useStore();
  const [attendeeArray, setAttendeeArray] = useState([] as Registration[]);
  const [attendee, setAttendee] = useState([] as IAttendee[]);
  // const [attendee, setAttendee] = useState([] as {}[]);
  const formRef = React.useRef();
  const [myItems, setMyItems] = useState<IItems[]>([] as IItems[]);
  const [printAll, setPrintAll] = useState(false);
  const [currentAttendee, setCurrentAttendee] = useState({} as Registration);
  const [currentAttendeeId, setCurrentAttendeeId] = useState("");
  const [attendees, setAttendees] = useState([]  as {label: string, value: string}[])

  const [twoColumns, setTwoColumns] = useState(true);
  const [show, setShow] = useState(false);
  const [showCompany, setShowCompany] = useState(false);
  const [edit, setEdit] = useState(false);
  const [job, setJob] = useState("");
  const [name, setName] = useState("");
  const [businessName, setBusiness] = useState("");
  const [companies, setCompanies] = useState([]  as {label: string, value: string}[])
  const [companyAttendees, setCompanyAttendees] = useState([] as Registration[]);
  const [attendeesInfo, setAttendeesInfo] = useState([] as Registration[]);
  const [currentCompanyId, setCurrentCompanyId] = useState("");
  const [config, setConfig] = useState(false);
  const [zebra, setZebra] = useState(false);
  const [slots, setSlots] = useState(false);
  const [offsetX, setOffsetX] = useState(4);
  const [offsetY, setOffsetY] = useState(14);
  const [offsetRow, setOffsetRow] = useState(34);
  const [currentSlot, setCurrentSlot] = useState("");

  const originalSlotMatrix = [
    [true, true],
    [true, true],
    [true, true],
    [true, true],
    [true, true],
    [true, true],
    [true, true],
    [true, true],
  ];

  const [slotMatrix, setSlotMatrix] = useState(originalSlotMatrix);

  useEffect(() => {
    getEvent();
    // RegistrationsEvent();
  }, []);

  const reorder = (list: IItems[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  interface IMyValues {
    name: string;
    business: string;
    job_title: string;
  }

  const onGenerateBadge = (values: IMyValues) => {
    console.log(values);
  };

  const getEvent = useCallback(async () => {
    try {
      const response = await eventsStore.fetchEventById(eventId);
      //@ts-ignore
      setAttendeeArray(response.data.event.registrations);
      //@ts-ignore
      setMyEvent(response.data.event);
      // RegistrationsEvent();
    } catch (error) {
      console.log(error);
    }
  }, [eventId, eventsStore]);

  useEffect(() => {
    //Set companies
    let tempCompanies: any[] = [];

    attendeeArray.map(attendee => {
      if (attendee.user.business) {
        let exists = false;
        tempCompanies.map(company => {
          if (company.value === attendee.user.business._id) {
            exists = true;
          }
        })

        if (!exists) {
          tempCompanies.push({label: attendee.user.business.name, value: attendee.user.business._id});
        }
      }
    });

    setCompanies(tempCompanies);

    let tempAttendees: any[] = [];
    let attendeesInfoArray: Registration[] = [];

    attendeeArray.map(function (item) {
      if (item.paid) {
        attendeesInfoArray.push(item);

        tempAttendees.push({
          label: item.user.first_name + " " + item.user.last_name,
          value: item.user._id,
        });
      }
    });

    setAttendees(tempAttendees);
    setAttendeesInfo(attendeesInfoArray);

  }, [attendeeArray]);

  const setPrint = () => {
    setPrintAll(!printAll);
    setEdit(false);
    setName("");
    setJob("");
    setBusiness("");
  };
  
  const infoAttendee = (id: string) => {
    const a = attendeesInfo.filter((item) => {
      return item.user._id === id;
    });
    setCurrentAttendee(a[0]);
    setShow(true);
    setShowCompany(false);

    return a;
  };

  const infoCompanies = (id: string) => {
    const a = attendeeArray.filter((item) => {
      return item.user.business && item.user.business._id === id;
    });
    setCompanyAttendees(a);
    setShow(false);
    setShowCompany(true);

    return a;
  };

  const getAttendeeName = (user: IAttendee) => {
    if (user.nickname) return user.nickname.toUpperCase();
    else {
      let first_name = user.first_name.split(" ")[0];
      let last_name_array = user.last_name.split(" ");
      let last_name = last_name_array[last_name_array.length - 1];

      return (first_name + " " + last_name).toUpperCase();
    }
  };

  const getProfileTypeName = (type: string) => {
    if (type === "vip") {
      return "VIP";
    }
    else if (type === "associated_company") {
      return "Empresa Associada";
    }
    else if (type === "not_associated_company") {
      return "Empresa Não Associada";
    }
    else if (type === "partner_company") {
      return "Empresa Parceira";
    }
    else if (type === "sponsor_company") {
      return "Empresa Patrocinadora";
    }
    else if (type === "guest") {
      return "Convidado";
    }
    else if (type === "director") {
      return "Diretoria";
    }
    else if (type === "liberal_professional") {
      return "Profissional Liberal";
    }
    else if (type === "student") {
      return "Estudante";
    }
    else if (type === "service_provider") {
      return "Prestador De Serviço";
    }
    else if (type === "standard") {
      return "Participante";
    }
    else if (type === "default") {
      return "Participante";
    }
    else {
      return type;
    }
  }

  const getAttendeeProfileType = (user: IAttendee) => {
    let profile_type = "";

    if (user.profile_type) { 
      profile_type = user.profile_type;
    }
    else {
      const a = attendeeArray.filter((item) => {
        return item.user._id === user._id;
      });

      if (a && a.length > 0)
        profile_type = (a[0].profile_type);
      else
        profile_type = "N/D";
    }

    return getProfileTypeName(profile_type).toUpperCase();
  };

  const initialValues = {
    name: edit
      ? getAttendeeName(currentAttendee.user)
      : "",
    business: edit ? (currentAttendee.user.business && currentAttendee.user.business.name ? currentAttendee.user.business.name.toUpperCase() : (currentAttendee.user.business_title ? currentAttendee.user.business_title.toUpperCase() : "N/D")) : "",
    job_title: edit ? currentAttendee.user.job_title : "",
  };
  const maxBadgesPerPage = 5;
  const qtdPages = ceil(attendeesInfo.length / maxBadgesPerPage);

  function updateSlotMatrix(positionX: number, positionY: number, checked: boolean) {
    var newSlotMatrix = slotMatrix;

    newSlotMatrix[positionX][positionY] = checked;

    setSlotMatrix(newSlotMatrix);
    setCurrentSlot(positionX + "_" + positionY + "_" + checked);
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} sm={6}>
        <Paper className={classes.paper}>
          <S.Container>
            <S.Title>Gerar crachá</S.Title>

            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={onGenerateBadge}
              // @ts-ignore
              innerRef={formRef}
            >
              {({ isValid, handleSubmit, values, handleChange, errors }) => (
                <S.FormWrapper>
                  <FormControl>
                    <FormContainer>
                      <EventTitle>{myEvent.name}</EventTitle>
                    </FormContainer>
                  </FormControl>
                  {!edit && (
                    <FormControl>
                      <FormContainer>
                        {/* <FormikSelectField
                          onChange={(e: any) => infoAttendee(e.target.value)}
                          className="form-field"
                          name="name"
                          label={printAll ? "Todos Participantes" : "Participante"}
                          options={attendees}
                          variant="outlined"
                        /> */}
                        <Field
                          name="attendee-name"
                          className="form-field"
                          component={FormikAutocomplete}
                          variant="outlined"
                          label={printAll ? "Todos Participantes" : "Participante"}
                          value={currentAttendeeId}
                          options={attendees}
                          //@ts-ignore
                          onChange={(target => {
                            if (target && target.value) {
                              setCurrentAttendeeId(target.value);
                              infoAttendee(target.value);
                            }
                          })}
                        />
                      </FormContainer>
                    </FormControl>
                  )}
                  {!edit && (
                    <FormControl>
                      <FormContainer>
                        {/* <FormikSelectField
                          onChange={(e: any) => infoCompanies(e.target.value)}
                          className="form-field"
                          name="companies"
                          label={printAll ? "Todas Empresas" : "Empresa"}
                          options={companies}
                          variant="outlined"
                        /> */}

                        <Field
                          name="companies"
                          className="form-field"
                          component={FormikAutocomplete}
                          variant="outlined"
                          label={printAll ? "Todas Empresas" : "Empresa"}
                          value={currentCompanyId}
                          options={companies}
                          //@ts-ignore
                          onChange={(target => {
                            if (target && target.value) {
                              setCurrentCompanyId(target.value);
                              infoCompanies(target.value);
                            }
                          })}
                        />
                      </FormContainer>
                    </FormControl>
                  )}
                  {edit ? (
                    <div>
                      <FormContainer>
                        <FormikTextField
                          name="name"
                          onChange={(e: any) => setName(e.target.value)}
                          value={values.name || name}
                          label="Evento"
                          fullWidth
                          variant="outlined"
                        ></FormikTextField>
                      </FormContainer>
                      <FormContainer>
                        <FormikTextField
                          name="business"
                          onChange={(e: any) => setBusiness(e.target.value)}
                          value={values.business || businessName}
                          label="Empresa"
                          fullWidth
                          variant="outlined"
                        ></FormikTextField>
                      </FormContainer>

                      <FormContainer>
                        <FormikTextField
                          onChange={(e: any) => setJob(e.target.value)}
                          name="job_title"
                          value={values.job_title || job}
                          label="Cargo"
                          fullWidth
                          variant="outlined"
                        ></FormikTextField>
                      </FormContainer>
                    </div>
                  ) : (
                    <div />
                  )}

                  {config && (
                    <div>
                      <FormControl>
                        <FormContainer>
                          <EventTitle>Configuração de espaçamento</EventTitle>
                        </FormContainer>
                      </FormControl>
                      <FormContainer>
                        <FormikTextField
                          name="offsetX"
                          onChange={(e: any) => setOffsetX(e.target.value)}
                          value={offsetX}
                          label="Deslocamento lateral"
                          fullWidth
                          variant="outlined"
                        ></FormikTextField>
                      </FormContainer>
                      <FormContainer>
                        <FormikTextField
                          name="offsetY"
                          onChange={(e: any) => setOffsetY(e.target.value)}
                          value={offsetY}
                          label="Deslocamento vertical"
                          fullWidth
                          variant="outlined"
                        ></FormikTextField>
                      </FormContainer>

                      <FormContainer>
                        <FormikTextField
                          onChange={(e: any) => setOffsetRow(e.target.value)}
                          name="offsetRow"
                          value={offsetRow}
                          label="Deslocamento entre linhas"
                          fullWidth
                          variant="outlined"
                        ></FormikTextField>
                      </FormContainer>
                    </div>
                  )}

                  {slots && (
                    <div>
                      <FormControl>
                        <FormContainer>
                          <EventTitle>Configuração de slots para impressão</EventTitle>
                        </FormContainer>
                      </FormControl>
                      <Grid container spacing={2} style={{marginLeft: "5px", marginBottom: "10px"}}>
                        <TableContainer component={Paper}>
                          <Table aria-label="attendees table">
                            <GenericTableHead>
                              <TableRow>
                                <TableCell>Coluna 1</TableCell>
                                <TableCell>Coluna 2</TableCell>
                              </TableRow>
                            </GenericTableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <Checkbox
                                    edge="start"
                                    checked={slotMatrix[0][0]}
                                    onChange={(e, checked) => {
                                      updateSlotMatrix(0,0,checked);
                                    }}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      "aria-labelledby": "slot_0_0",
                                    }}
                                  /> Slot 1
                                </TableCell>
                                <TableCell>
                                  <Checkbox
                                    edge="start"
                                    checked={slotMatrix[0][1]}
                                    onChange={(e, checked) => {
                                      updateSlotMatrix(0,1,checked);
                                    }}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      "aria-labelledby": "slot_0_1",
                                    }}
                                  /> Slot 2
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Checkbox
                                    edge="start"
                                    checked={slotMatrix[1][0]}
                                    onChange={(e, checked) => {
                                      updateSlotMatrix(1,0,checked);
                                    }}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      "aria-labelledby": "slot_1_0",
                                    }}
                                  /> Slot 3
                                </TableCell>
                                <TableCell>
                                  <Checkbox
                                    edge="start"
                                    checked={slotMatrix[1][1]}
                                    onChange={(e, checked) => {
                                      updateSlotMatrix(1,1,checked);
                                    }}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      "aria-labelledby": "slot_1_1",
                                    }}
                                  /> Slot 4
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Checkbox
                                    edge="start"
                                    checked={slotMatrix[2][0]}
                                    onChange={(e, checked) => {
                                      updateSlotMatrix(2,0,checked);
                                    }}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      "aria-labelledby": "slot_2_0",
                                    }}
                                  /> Slot 5
                                </TableCell>
                                <TableCell>
                                  <Checkbox
                                    edge="start"
                                    checked={slotMatrix[2][1]}
                                    onChange={(e, checked) => {
                                      updateSlotMatrix(2,1,checked);
                                    }}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      "aria-labelledby": "slot_2_1",
                                    }}
                                  /> Slot 6
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Checkbox
                                    edge="start"
                                    checked={slotMatrix[3][0]}
                                    onChange={(e, checked) => {
                                      updateSlotMatrix(3,0,checked);
                                    }}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      "aria-labelledby": "slot_3_0",
                                    }}
                                  /> Slot 7
                                </TableCell>
                                <TableCell>
                                  <Checkbox
                                    edge="start"
                                    checked={slotMatrix[3][1]}
                                    onChange={(e, checked) => {
                                      updateSlotMatrix(3,1,checked);
                                    }}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      "aria-labelledby": "slot_3_1",
                                    }}
                                  /> Slot 8
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Checkbox
                                    edge="start"
                                    checked={slotMatrix[4][0]}
                                    onChange={(e, checked) => {
                                      updateSlotMatrix(4,0,checked);
                                    }}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      "aria-labelledby": "slot_4_0",
                                    }}
                                  /> Slot 9
                                </TableCell>
                                <TableCell>
                                  <Checkbox
                                    edge="start"
                                    checked={slotMatrix[4][1]}
                                    onChange={(e, checked) => {
                                      updateSlotMatrix(4,1,checked);
                                    }}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      "aria-labelledby": "slot_4_1",
                                    }}
                                  /> Slot 10
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Checkbox
                                    edge="start"
                                    checked={slotMatrix[5][0]}
                                    onChange={(e, checked) => {
                                      updateSlotMatrix(5,0,checked);
                                    }}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      "aria-labelledby": "slot_5_0",
                                    }}
                                  /> Slot 11
                                </TableCell>
                                <TableCell>
                                  <Checkbox
                                    edge="start"
                                    checked={slotMatrix[5][1]}
                                    onChange={(e, checked) => {
                                      updateSlotMatrix(5,1,checked);
                                    }}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      "aria-labelledby": "slot_5_1",
                                    }}
                                  /> Slot 12
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Checkbox
                                    edge="start"
                                    checked={slotMatrix[6][0]}
                                    onChange={(e, checked) => {
                                      updateSlotMatrix(6,0,checked);
                                    }}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      "aria-labelledby": "slot_6_0",
                                    }}
                                  /> Slot 13
                                </TableCell>
                                <TableCell>
                                  <Checkbox
                                    edge="start"
                                    checked={slotMatrix[6][1]}
                                    onChange={(e, checked) => {
                                      updateSlotMatrix(6,1,checked);
                                    }}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      "aria-labelledby": "slot_6_1",
                                    }}
                                  /> Slot 14
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Checkbox
                                    edge="start"
                                    checked={slotMatrix[7][0]}
                                    onChange={(e, checked) => {
                                      updateSlotMatrix(7,0,checked);
                                    }}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      "aria-labelledby": "slot_7_0",
                                    }}
                                  /> Slot 15
                                </TableCell>
                                <TableCell>
                                  <Checkbox
                                    edge="start"
                                    checked={slotMatrix[7][1]}
                                    onChange={(e, checked) => {
                                      updateSlotMatrix(7,1,checked);
                                    }}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      "aria-labelledby": "slot_7_1",
                                    }}
                                  /> Slot 16
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </div>
                  )}

                  <EventContainer>
                    <CustomizedCheckbox onChange={() => setPrint()} />
                    <div style={{ marginTop: "18px" }}>
                      Todos
                    </div>

                    {printAll && !zebra && (
                      <>
                        <CustomizedCheckbox onChange={() => setTwoColumns(!twoColumns)} />
                        <div style={{ marginTop: "18px" }}>
                          1 Coluna
                        </div>
                      </>
                    )}

                    {printAll === false && showCompany === false && currentAttendee._id && (
                      <>
                        <CustomizedCheckbox onChange={() => setEdit(!edit)} />

                        <div style={{ marginTop: "18px" }}>Editar crachá</div>
                      </>
                    )}

                    <>
                      <CustomizedCheckbox onChange={() => {
                        setZebra(!zebra)
                      }} />
                      <div style={{ marginTop: "18px" }}>
                        Zebra
                      </div>
                    </>

                    {!zebra && (
                      <>
                        <CustomizedCheckbox onChange={() => {
                          setConfig(!config)
                        }} />
                        <div style={{ marginTop: "18px" }}>
                          Config. de espaçamento
                        </div>
                      </>
                    )}

                    {twoColumns && !zebra && (
                      <>
                        <CustomizedCheckbox onChange={() => {
                          setSlots(!slots)
                        }} />
                        <div style={{ marginTop: "18px" }}>
                          Slots
                        </div>
                      </>
                    )}
                  </EventContainer>
                </S.FormWrapper>
              )}
            </Formik>
          </S.Container>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Paper className={classes.paper}>
          <S.Container>
            <S.Title>Visualização do crachá</S.Title>
            <S.FormWrapper>
              {show && printAll === false && (
                <div>
                  <BadgeItem
                    data={{
                      title: myEvent.name,
                      participant:
                        name !== ""
                          ? name
                          : getAttendeeName(currentAttendee.user),
                      header:
                        businessName !== ""
                          ? businessName
                          : currentAttendee.user.business && currentAttendee.user.business.name ? currentAttendee.user.business.name.toUpperCase() : currentAttendee.user.business_title,
                      description:
                        job !== ""
                          ? job
                          : currentAttendee.user.job_title.toUpperCase(),
                      code: currentAttendee._id,
                    }}
                  ></BadgeItem>

                  <PdfBadge
                    data={{
                      title: myEvent.name,
                      participant:
                        name !== ""
                          ? name
                          : getAttendeeName(currentAttendee.user),
                      participant_type: getAttendeeProfileType(currentAttendee.user),
                      header:
                        businessName !== ""
                          ? businessName
                          : currentAttendee.user.business && currentAttendee.user.business.name ? currentAttendee.user.business.name.toUpperCase() : (currentAttendee.user.business_title ? currentAttendee.user.business_title.toUpperCase() : "N/D"),
                      description:
                        job !== ""
                          ? job
                          : (currentAttendee.user.job_title ? currentAttendee.user.job_title.toUpperCase() : "N/D"),
                      code: currentAttendee._id,
                      offsetX: config ? offsetX : undefined,
                      offsetY: config ? offsetY : undefined,
                      offsetRow: config ? offsetRow : undefined,
                      slotMatrix: slots && slotMatrix ? slotMatrix : undefined
                    }}
                    twoColumns={twoColumns}
                    printerType={zebra ? "etiquetasContinua" : "etiquetasA4"}
                  />
                </div>
              )}
              {showCompany && printAll === false && (
                <div>
                  <PdfBadge
                    data={{
                      title: myEvent.name,
                      userArray: companyAttendees,
                      all: true,
                      // qtdPages: qtdPages,
                      offsetX: config ? offsetX : undefined,
                      offsetY: config ? offsetY : undefined,
                      offsetRow: config ? offsetRow : undefined,
                      slotMatrix: slots && slotMatrix ? slotMatrix : undefined
                    }}
                    twoColumns={twoColumns}
                    printerType={zebra ? "etiquetasContinua" : "etiquetasA4"}
                  />
                  <BadgeItem
                    data={{
                      title: myEvent.name,
                      userArray: companyAttendees,
                      all: true,
                    }}
                  ></BadgeItem>
                </div>
              )}
              {printAll && (
                <div>
                  <PdfBadge
                    data={{
                      title: myEvent.name,
                      userArray: attendeesInfo,
                      all: printAll,
                      // qtdPages: qtdPages,
                      offsetX: config ? offsetX : undefined,
                      offsetY: config ? offsetY : undefined,
                      offsetRow: config ? offsetRow : undefined,
                      slotMatrix: slots && slotMatrix ? slotMatrix : undefined
                    }}
                    twoColumns={twoColumns}
                    printerType={zebra ? "etiquetasContinua" : "etiquetasA4"}
                  />
                  <BadgeItem
                    data={{
                      title: myEvent.name,
                      userArray: attendeesInfo.slice(0,20),
                      all: printAll,
                    }}
                  ></BadgeItem>
                </div>
              )}
            </S.FormWrapper>
          </S.Container>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Badge;

export const FormContainer = styled.div`
  width: 100%;
  margin: 10px;
`;

export const EventContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ContainerItens = styled.div`
  margin-bottom: 3px;
  width: 100%;
`;
export const EventTitle = styled.div`
  font-size: 17px;
  color: black;
  font-weight: bold;
`;

export const BadgeTitle = styled.div`
  font-size: 17px;
  color: black;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 20px;
`;

export const Text = styled.div`
  font-weight: bold;
  text-align: justify;
  margin-left: 5px;
`;

export const TextContainer = styled.div`
  /* justify-content: center; */
  display: flex;
  margin-left: 20px;
`;
