import { useContext, createContext } from "react";

import { appStore } from "./App";
import { attendeesStore } from "./Attendees";
import { userStore } from "./User";
import { eventsStore } from "./Events";
import { companyStore } from "./Companies";
import { reportStore } from "./Report";
import { subscriptionsStore } from "./Subscriptions";
import { landingpagesStore } from "./LandingPage";
import { eventsponsorsStore } from "./Sponsors";
import { contactStore } from "./Contact";
import { registerquestionsStore } from "./RegisterQuestions";
import { certificatesStore } from "./Certificate";

const rootStore = {
  appStore,
  attendeesStore,
  userStore,
  eventsStore,
  companyStore,
  reportStore,
  subscriptionsStore,
  landingpagesStore,
  eventsponsorsStore,
  contactStore,
  registerquestionsStore,
  certificatesStore
};

export const StoreContext = createContext(rootStore);

export function useStore() {
  return useContext(StoreContext);
}
