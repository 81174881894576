import React, { PureComponent } from "react";

import BaseCalendar, { ISchedule } from "tui-calendar";
import "tui-calendar/dist/tui-calendar.css";

import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
// import { formatAppointmentDate } from "../../context/Appointment/appoitments.util";

import { cx } from "./classnames";

import moment from "moment";
import styled from "styled-components";

const daynames = ["dom", "seg", "ter", "qua", "qui", "sex", "sab"];

const theme = {
  "month.dayname.borderTop": "none",
  "month.dayname.borderLeft": "none",
  "week.dayname.borderTop": "none",
  "week.dayname.overflowY": "hidden"
};

type View = "day" | "week" | "month";

interface Props {
  appointments: ISchedule[];
}

export function formatAppointmentDate(d: string | Date) {
  return moment(d).format("YYYY-MM-DD HH:mm");
}

export function formatDate(time: string | Date, _format: string) {
  moment.locale("pt-BR");
  return moment(time).to(_format);
}

interface State {
  view: View;
  date: string;
}

export default class Calendar extends PureComponent<Props, State> {
  container = React.createRef<HTMLDivElement>();
  calendar?: BaseCalendar;

  constructor(props: Props) {
    super(props);

    this.state = {
      view: "day",
      date: moment(new Date()).format("DD [de] MMMM")
    };
    console.log(props.appointments);
  }

  componentDidMount() {
    this._mountCalendar();
  }

  componentWillUnmount() {
    this.calendar!.destroy();
  }

  componentDidUpdate() {
    this.renderCalendar();
  }

  _mountCalendar = () => {
    this.calendar = new BaseCalendar(this.container.current!, {
      defaultView: "week",
      taskView: false,
      scheduleView: ["time"],
      usageStatistics: false,
      isReadOnly: true,
      // useDetailPopup: true,
      // template: templates,
      theme,
      week: {
        daynames
      },
      month: {
        daynames,
        visibleWeeksCount: 6
      }
    });

    this.renderCalendar();
  };

  renderCalendar = () => {
    this.calendar!.clear();
    this.calendar!.createSchedules(this.props.appointments);
    this.calendar!.render();
  };

  getDate = (view?: View) => {
    switch (view) {
      case "month":
      case "week":
        const start = this.calendar!.getDateRangeStart().toDate();
        const end = this.calendar!.getDateRangeEnd().toDate();
        console.log(end);
        console.log(start);
        return `${moment(start).format("DD MMMM YYYY")}-${moment(end).format(
          "DD MMMM YYYY"
        )}`;

      case "day":
        // const date = this.calendar!.getDate();
        return moment(new Date()).format("DD [de] MMMM");

      default:
        return formatDate(new Date(), "DD [de] MMMM");
    }
  };

  updateCalendarState = (view: View) => {
    if (this.calendar) {
      // @ts-ignore
      this.calendar.changeView(view);

      this.setState({
        date: this.getDate(view),
        view
      });
    }
  };

  setDayView = () => this.updateCalendarState("day");

  setWeekView = () => this.updateCalendarState("week");

  setMonthView = () => this.updateCalendarState("month");

  goToNow = () => {
    if (this.calendar) this.calendar.today();
  };

  goNext = () => {
    this.calendar!.next();
    this.setState(state => {
      return {
        ...state,
        date: this.getDate(state.view)
      };
    });
  };

  goPrev = () => {
    this.calendar!.prev();
    this.setState(state => {
      return {
        ...state,
        date: this.getDate(state.view)
      };
    });
  };

  render() {
    const { view, date } = this.state;

    return (
      <Container className="box" ref={this.container}>
        <Level>
          <div>
            <div className="buttons has-addons">
              <button
                onClick={this.setDayView}
                className={cx("button is-small", {
                  "is-selected": view === "day"
                })}
              >
                Dia
              </button>
              <button
                onClick={this.setWeekView}
                className={cx("button is-small", {
                  "is-selected": view === "week"
                })}
              >
                Semana
              </button>
              <button
                onClick={this.setMonthView}
                className={cx("button is-small", {
                  "is-selected": view === "month"
                })}
              >
                Mês
              </button>
            </div>

            <span
              style={{ margin: "0 10px", fontSize: "10pt", fontWeight: "bold" }}
            >
              {date}
            </span>
          </div>

          <div>
            <button
              className="button is-small is-rounded"
              style={{ marginRight: 10 }}
              onClick={this.goToNow}
            >
              Hoje
            </button>

            <div className="buttons has-addons">
              <button className="button is-small" onClick={this.goPrev}>
                <span className="icon is-small">
                  <FaChevronLeft />
                </span>
              </button>

              <button className="button is-small" onClick={this.goNext}>
                <span className="icon is-small">
                  <FaChevronRight />
                </span>
              </button>
            </div>
          </div>
        </Level>
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100%;
  & .tui-full-calendar-dayname-container {
    overflow-y: hidden !important;
    background-color: #188c90;
  }

  & .tui-full-calendar-month-dayname {
    border-top: none !important;
  }

  & .buttons {
    margin-bottom: 0 !important;
    display: inline-flex !important;
  }

  & .buttons button {
    font-weight: 700;
  }

  & .buttons .is-selected {
    background-color: #188c90;
    border-color: transparent;
    color: white;
  }
`;

const Level = styled.div`
  display: flex;

  justify-content: space-between;
`;

// const templates: ITemplateConfig = {
//   popupIsAllDay: function() {
//     return "All Day";
//   },
//   popupStateFree: function() {
//     return "Free";
//   },
//   popupStateBusy: function() {
//     return "Busy";
//   },
//   titlePlaceholder: function() {
//     return "Subject";
//   },
//   locationPlaceholder: function() {
//     return "Location";
//   },
//   startDatePlaceholder: function() {
//     return "Start date";
//   },
//   endDatePlaceholder: function() {
//     return "End date";
//   },
//   popupSave: function() {
//     return "Save";
//   },
//   popupUpdate: function() {
//     return "Update";
//   }
// };
