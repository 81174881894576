import { configure, observable, action, computed, runInAction } from "mobx";
import { v4 as uuidv4 } from "uuid";

import { User, IUserFinal, IUserSignupValues, IUserRegisterQuestionValues } from "Models/User";

import { eventsStore } from "Store/Events";
import { appStore } from "Store/App";
import { Get, Post, api } from "Services/Request";
import { AxiosResponse } from "axios";
import { history } from "Store/History";
import { toast } from "react-toastify";

configure({ enforceActions: "observed" });

class UserStore {
  constructor() {
    const currentUserString = localStorage.getItem("@currentUser");

    if (currentUserString)
      this.currentUser = JSON.parse(currentUserString);
      
    this.getUserDataByToken();
  }
  @observable
  users: User[] = [
    {
      company: "BitX",
      id: uuidv4(),
      position: "CEO",
      events: ["1bed0792-943d-42b7-bc4c-f5abce144787"],
      name: "Lucas",
      number: "91982345678",
      presence: false,
      tipoDeEntrada: "VIP",
      perfil: "Administrador",
    },
    {
      company: "Sony",
      id: uuidv4(),
      position: "CEO",
      events: ["123"],
      name: "Pedro",
      number: "91987761524",
      presence: false,
      tipoDeEntrada: "VIP",
      perfil: "Normal",
    },
    {
      company: "Microsoft",
      id: uuidv4(),
      position: "CEO",
      events: ["1bed0792-943d-42b7-bc4c-f5abce144787"],
      name: "Billy",
      number: "91928176219",
      presence: false,
      tipoDeEntrada: "Normal",
      perfil: "Normal",
    },
  ];

  @observable
  currentUser: IUserFinal | null = null;

  @observable
  recoveryUserId: string = "";

  @computed
  get usersByEvent() {
    return this.users.filter((user) =>
      user.events.includes(eventsStore.currentEventId)
    );
  }

  @observable
  isSignedIn: boolean = false;

  @observable
  isSigninInUnderTheHood: boolean = false;

  // @computed
  // get usersBySorteioFilter() {
  //   return this.users.filter(
  //     (user) =>
  //       (eventsStore.currentFilterSetup.perfil
  //         ? user.perfil === eventsStore.currentFilterSetup.perfil
  //         : user.perfil) &&
  //       (eventsStore.currentFilterSetup.tipoDeEntrada
  //         ? user.tipoDeEntrada === eventsStore.currentFilterSetup.tipoDeEntrada
  //         : user.tipoDeEntrada)
  //   );
  // }

  @computed
  get currentUserInitials(): string {
    if (!this.currentUser) {
      return "U"
    }

    return `${this.currentUser?.first_name
      .charAt(0)
      .toUpperCase()}${this.currentUser?.last_name.charAt(0).toUpperCase()}`;
  }

  @action
  isCompanyUser = () => {
    return this.currentUser && this.currentUser.roles[0] === "company_admin";
  };

  @action
  addParticipand = (userToAdd: User) => {
    this.users.push(userToAdd);
  };

  @action
  setIsSignedin(val: boolean) {
    console.log(val);
    this.isSignedIn = val;
  }

  @action
  async getUserDataByToken() {
    try {
      this.isSigninInUnderTheHood = true;
      const response = await Get("/api/users/me");
      // @ts-ignore
      runInAction(() => {
        if (response.data.success) {
          this.isSigninInUnderTheHood = false;
          // this.setIsSignedin(true)
          this.isSignedIn = true;
          // @ts-ignore
          this.setCurrentUser(response.data.data.user as IUserFinal);
          // @ts-ignore
          userStore.setCurrentUser(response.data.data.user as IUserFinal);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.isSigninInUnderTheHood = false;
        this.isSignedIn = false;
        this.currentUser = null;
      });
    }
  }

  @action
  setCurrentUser = (newCurrentUser: IUserFinal) => {
    console.log(newCurrentUser);
    this.currentUser = newCurrentUser;
  };

  @action
  async checkUser(token: string) {
    try {
      const response = await Get("/api/users/me");
      // @ts-ignore
      if (response.success) {
        userStore.isSignedIn = true;
        return true;
      } else {
        return false;
      }
    } catch (error) {
      throw error.response;
    }
  }

  @action
  async signin(username: string, password: string) {
    try {
      const { data }: AxiosResponse = await Post("/api/users/signin", {
        email: username,
        password: password,
      });
      if (data.data.token) {
        localStorage.setItem("@token", data.data.token);
        // @ts-ignore
        runInAction(() => {
          console.log(data.data.user);
          userStore.setCurrentUser(data.data.user);
          userStore.isSignedIn = true;
          localStorage.setItem("@currentUser", JSON.stringify(data.data.user));
        });
      }
      console.log("signing in");
      await appStore.sync();
      return true;
    } catch (error) {
      console.log(error.response);
      toast.error("Usuário ou senha incorretos");
      throw error;
    }
  }

  @action
  async forgotPass(email: string) {
    try {
      const { data }: AxiosResponse = await Post("/api/auth/forgot", {
        usernameOrEmail: email
      });
      if (data.success) {
        toast.success("Email de recuperação enviado com sucesso!");
      } else {
        toast.error("Email incorreto ou inexistente");
      }
      return true;
    } catch (error) {
      console.log(error.response);
      toast.error("Email incorreto ou inexistente");
      throw error;
    }
  }

  @action
  async validateResetToken(email: string, code: string) {
    try {
      const { data }: AxiosResponse = await Post("/api/auth/validate-reset-token", {
        email: email,
        token: code
      });
      if (data.success) {
        this.recoveryUserId = data.data.userId;
        return true;
      } else {
        toast.error("Código de recuperação inválido ou expirado");
        return false;
      }
    } catch (error) {
      console.log(error.response);
      toast.error("Código de recuperação inválido ou expirado");
      throw error;
    }
  }

  @action
  async recoveryPassword(password: string, code: string) {
    try {
      const { data }: AxiosResponse = await Post(`/api/auth/recovery-password/user/${this.recoveryUserId}/token/${code}`, {
        newPassword: password,
        verifyPassword: password
      });
      if (data.success) {
        toast.success("Senha atualizada com sucesso!");
        return true;
      } else {
        toast.error("Código de recuperação inválido ou senha fraca. Tente utilizar caracteres e números, e possuir mais de 7 digitos");
        return false;
      }
    } catch (error) {
      console.log(error.response);
      toast.error("Código de recuperação inválido ou senha fraca. Tente utilizar caracteres e números, e possuir mais de 7 digitos");
      throw error;
    }
  }

  @action
  async signup(dataValues: IUserSignupValues, registerQuestionValues: IUserRegisterQuestionValues) {
    try {
      var phone = dataValues.phone_number.replace(/\D/g,'');
      
      const { data }: AxiosResponse = await Post("/api/users/signup", {
        ...dataValues,
        area_code: phone.slice(0, 2),
        phone_number: phone.slice(2, phone.length),
        register_questions: [registerQuestionValues]
      });
      if (data.data.token) {
        localStorage.setItem("@token", data.data.token);
        // @ts-ignore
        runInAction(() => {
          console.log(data.data.user);
          userStore.setCurrentUser(data.data.user);
          userStore.isSignedIn = true;
        });
        
        toast.success("Usuário cadastrado com sucesso!");
      }
      await appStore.sync();
      return true;
    } catch (error) {
      toast.error(error.response.data.error.message);
      throw error;
    }
  }

  @action
  signout() {
    history.replace("/signin");
    localStorage.removeItem("@token");
    this.currentUser = null;
    this.isSignedIn = false;
  }
}

export const userStore = new UserStore();
