import React, { useState, useEffect } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { GenericButton } from "Components/Styled/Form";
import { useStore } from "Store";

import { IAttendee } from "Models/Attendees";

interface IProps {
  open: boolean;
  attendeeId: string;
  attendeeIds: string[];
  handleClose: Function;
}

const DeleteAttendeeDialog: React.FC<IProps> = ({
  open,
  attendeeId,
  attendeeIds,
  handleClose
}) => {
  const { attendeesStore } = useStore();
  const [currentAttendee, setCurrentAttendee] = useState<
    null | undefined | IAttendee
  >(null);

  useEffect(() => {
    setCurrentAttendee(
      attendeesStore.users.find(attendee => attendee._id === attendeeId)
    );
  }, [attendeeId, attendeesStore.users]);

  const handleDelete = async () => {
    if (attendeeIds && attendeeIds.length > 0) {
      attendeesStore.deleteAttendeesInBatch(attendeeIds);
    } else {
      attendeesStore.deleteAttendee(attendeeId);
    }
    handleClose();
  };

  return (
    <Dialog
      open={open}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {"Excluir usuário?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Você está prestes a excluir <strong>permanentemente</strong>
          {attendeeIds && attendeeIds.length > 0 ? " " + attendeeIds.length + " participante(s)" : " o participante " + currentAttendee?.first_name + ""}, deseja
          continuar?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <GenericButton aVariant="secondary" onClick={() => handleDelete()}>
          Excluir
        </GenericButton>
        <GenericButton variant="contained" onClick={() => handleClose()}>
          Cancelar
        </GenericButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAttendeeDialog;
