import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useObserver } from "mobx-react-lite";

// @ts-ignore
import { useDebounce } from "use-debounce";

import CompanyContextMenu from "./components/CompanyContextMenu";
import DeleteCompanyDialog from "./components/DeleteCompanyDialog";
import LoadingModal from "Components/LoadingModal";
import { LoadingStatusContainer } from "./styles/index";
import { NoDataContainer } from "Components/Styled/util";

import logoAdapa from "Assets/Images/adapa.png";

import * as S from "./styles";
import { CustomCircularProgress } from "Components/Styled/Loading";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  createStyles,
  Theme,
  Paper,
  TablePagination,
  Chip,
  Checkbox,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Pagination from "@material-ui/lab/Pagination";
import { Add } from "@material-ui/icons";
import {
  Container,
  GenericFAB,
  GenericTableHead,
  FlexTD,
  GenericLeftFAB,
  GenericLeft2FAB
} from "Components/Styled/table";
import { useStore } from "Store";
import { MoreVert } from "@material-ui/icons";
import SearchBar from "Components/SearchBar";
import logo from "../../Assets/Images/pp.jpeg";
import { IBusiness } from "Models/Attendees";
import { toast } from "react-toastify";
import { EventModel } from "Models/Events";

interface RouteParams {
  eventId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    status__active: {
      color: "#2cda2c",
    },
    status__inactive: {
      color: "#e72419",
    },
  })
);

const Companies = () => {
  const history = useHistory();
  const classes = useStyles();
  const { companyStore, appStore, eventsStore } = useStore();
  const { eventId } = useParams<RouteParams>();

  useEffect(() => {
    companyStore.fetchCompanies(page + 1, limit);
  }, [companyStore]);

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const isContextMenuOpen = Boolean(anchorEl);
  const [currentCompanyId, setCurrentCompanyId] = useState<string | null>("");
  const [filter, setFilter] = useState("");
  const [debounced] = useDebounce(filter, 500);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [allSelected, setAllSelected] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<EventModel | null>();

  const fetchEventBusinesses = useCallback(async () => {
    try {
      const response = await eventsStore.fetchEventById(eventId);
      const businesses: IBusiness[] = [];
      // @ts-ignore
      response.data.event.businesses.map((business: any) =>
        businesses.push(business.business)
      );

      eventsStore.setCurrentEventBusinesses(businesses);
    } catch (error) {
      console.log(error);
    }
  }, [eventId, eventsStore]);

  const fetchCurrentEvent = useCallback(async () => {
    try {
      const response = await eventsStore.fetchEventById(eventId);
      console.log(response);
      // @ts-ignore
      setCurrentEvent(response.data.event);
    } catch (error) {
      console.log(error);
    }
  }, [eventId, eventsStore]);

  useEffect(() => {
    if (eventId) {
      eventsStore.resetCurrentEventBusinesses();
      fetchEventBusinesses();
      fetchCurrentEvent();
    } else {
      eventsStore.resetCurrentEventBusinesses();
    }
  }, [eventId, eventsStore, fetchEventBusinesses]);

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    companyId: string
  ) => {
    setCurrentCompanyId(companyId);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setAnchorEl(null);
  };

  const handleEditCompany = () => {
    history.push(`/companies/form/${currentCompanyId}`);
    setAnchorEl(null);
  };

  const handleNewCompany = () => {
    setCurrentCompanyId(null);
    setOpen(true);
  };

  const handleFilterCompanies = (value: string) => {
    setFilter(value);
  };

  const handleCsvClick = () => {
    history.replace("/companies/import");
  };

  useEffect(() => {
    console.log(debounced);
    companyStore.fetchCompanies(page + 1, limit, debounced);
  }, [debounced, companyStore]);

  const getFormattedCNPJ = (cnpj: string) => {
    if (!cnpj) return "";

    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  }

  const handleLinkToEvent = async (businessId?: string, businessIds?: string[]) => {
    try {
      setLoading(true);
      if (businessId) {
        const response = await eventsStore.linkBusinessToEvent({
          business_id: businessId,
          eventId,
          user_limit: 0
        });
      } else if (businessIds) {
        const response = await eventsStore.linkBusinesInPackToEvent({
          business_ids: businessIds,
          eventId
        });
      }
      
      fetchEventBusinesses();
      history.push(`/events/${eventId}`);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error);
      setLoading(false);
    }
  };

  function updateSelectedItemsArray(itemId: string, checked: Boolean) {
		var array: string[] = selectedItems;

		if (checked) {
			const exists = array.find(
				(i) => i === itemId
			);

			if (!exists) {
				array.push(itemId);
			}
		} else {
			array = array.filter((i) => {
				return i !== itemId;
			});
		}

    if (checked)
      setCurrentCompanyId(itemId);
    else {
      setCurrentCompanyId("0");
    }
    
		setSelectedItems(array);
	}

  function handleCheckAllItems(isChecked: boolean) {
		const aux: string[] = [];
    setAllSelected(isChecked);
		if (!isChecked) {
			setSelectedItems([]);
      setCurrentCompanyId("");
		} else {
      companyStore.companies.map(company => {
        aux.push(company._id);
      })
			setSelectedItems(aux);
		}
	}

  return useObserver(() => (
    <>
      <LoadingModal open={companyStore.isDeletingCompany}>
        <LoadingStatusContainer>Removendo empresa...</LoadingStatusContainer>
      </LoadingModal>
      <LoadingModal open={loading}>
        <S.LoadingStatusContainer>
          Atualizando empresas...
        </S.LoadingStatusContainer>
      </LoadingModal>
      <SearchBar title={"Empresas" + ` (${(companyStore.currentPagination.companiesCount)})` + (eventId && currentEvent ? " | Evento: " + currentEvent.name : "")} onFilter={handleFilterCompanies} onUploadCSVClick={handleCsvClick} />
      <Container style={{ marginBottom: "60px" }}>
      {selectedItems && selectedItems.length > 0 ? (
        <>
          {eventId ? (
            <GenericLeftFAB
              variant="extended"
              color="primary"
              onClick={() => {
                handleLinkToEvent(undefined, selectedItems);
              }}
            >
              Vincular Empresa(s)
            </GenericLeftFAB>
          ) : (
            <GenericLeft2FAB
              variant="extended"
              color="secondary"
              onClick={() => {
                setDeleteDialogOpen(true);
              }}
            >
              Excluir Empresa(s)
            </GenericLeft2FAB>
          )}
        </>
      ) : (
        <GenericLeftFAB
          className={classes.fab}
          variant="extended"
          color="primary"
          onClick={() => history.push("/companies/form")}
        >
          <Add /> Nova Empresa
        </GenericLeftFAB>
      )}
        {companyStore.companies.length > 0 ? (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="empresas table">
              <GenericTableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      edge="start"
                      onChange={(e, checked) => {
                        handleCheckAllItems(checked);
                      }}
                      tabIndex={-1}
                      disableRipple
                    />
                  </TableCell>
                  <TableCell>Logo</TableCell>
                  <TableCell></TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>CNPJ</TableCell>
                  {/* <TableCell>Limite de Participantes</TableCell> */}
                  <TableCell>Tipo</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </GenericTableHead>
              <TableBody>
                {companyStore.isFetchingCompanies ? (
                  <TableRow>
                    <td colSpan={4}>
                      <S.LoadingContainer>
                        <CustomCircularProgress />
                      </S.LoadingContainer>
                    </td>
                  </TableRow>
                ) : (
                  companyStore.companies.map((row) => (
                    <TableRow key={row.cnpj}>
                      <TableCell component="th" scope="row">
                        <Checkbox
                          edge="start"
                          checked={selectedItems.indexOf(row._id) !== -1}
                          onChange={(e, checked) => {
                            updateSelectedItemsArray(row._id, checked);
                          }}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            "aria-labelledby": row._id,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ width: "66px" }}
                      >
                        {row.profile_image_urls && row.profile_image_urls?.x256 && (
                          <img
                            src={appStore.currentBaseURL + "/" + row.profile_image_urls?.x256}
                            alt="cover"
                            width="100"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src=logoAdapa;
                            }}
                          />
                        )}
                        {!(row.profile_image_urls && row.profile_image_urls?.x256) && (
                          <>
                            -
                          </>
                        )}
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell component="th" scope="row">
                        <FlexTD>
                          <span className="title">{row.trade_name.toUpperCase()}</span>
                        </FlexTD>
                      </TableCell>
                      <TableCell>{getFormattedCNPJ(row.cnpj)}</TableCell>
                      {/* <TableCell>{row.user_limit ? row.user_limit : "N/D"}</TableCell> */}
                      <TableCell>{row.profile_type === "associated_company" ? "Empresa associada" : "Empresa não associada"}</TableCell>
                      <TableCell style={{ textAlign: "right" }}>
                        {eventId ? (
                          !eventsStore.currentEventBusinessesIds.includes(
                            row._id
                          ) ? (
                            <Chip
                              label="Vincular"
                              clickable
                              color="primary"
                              onClick={() => {
                                handleLinkToEvent(row._id);
                                }
                              }
                            />
                          ) : (
                            <Chip label="Já vinculado" disabled />
                          )
                        ) : null}
                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={(e) => handleClick(e, row._id)}
                        >
                          <MoreVert color="secondary" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
            <S.PaginationContainer>
              <TablePagination
                component="div"
                page={page}
                count={companyStore.currentPagination.pages}
                rowsPerPage={limit}
                labelRowsPerPage="Quantidade"
                nextIconButtonProps={{disabled: page >= companyStore.currentPagination.pages - 1}}
                onPageChange={(event, value) => {
                  setPage(value);

                  companyStore.fetchCompanies(
                    value + 1,
                    limit,
                    debounced || ""
                  )
                }}
                onChangeRowsPerPage={(event) => {
                  setLimit(+event.target.value);
                  
                  companyStore.fetchCompanies(
                    page + 1,
                    +event.target.value,
                    debounced || ""
                  )
                }}
                color="secondary"
              />
            </S.PaginationContainer>
          </TableContainer>
        ) : (
          <NoDataContainer>
            <h3>Sem empresas aqui!</h3>
          </NoDataContainer>
        )}
      </Container>
      <CompanyContextMenu
        open={isContextMenuOpen}
        handleClose={handleClose}
        handleDelete={setDeleteDialogOpen}
        handleEdit={handleEditCompany}
        anchorEl={anchorEl}
      />
      <DeleteCompanyDialog
        open={isDeleteDialogOpen}
        companyId={currentCompanyId}
        companyIds={selectedItems}
        handleClose={handleCloseDeleteDialog}
      />
    </>
  ));
};

export default Companies;
