import styled from "styled-components";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";

export const EventDrawer = styled(Drawer)`
  .MuiPaper-root {
    margin-top: 75px;
    width: 210px;
    flex-shrink: 0;
    @media (max-width: 500px) {
      margin-top: 60px;
    }
  }
  .mr-02 {
    margin-right: 10px;
  }

  
`;

interface EventListItemProps {
  active: boolean;
}
export const EventListItem = styled(ListItem) <EventListItemProps>`
  && {
    background: ${props => (props.active ? "#F4F7FC" : "#fff")};
    color: ${props => (props.active ? "#00577C" : "#7F8181")};
    font-weight: ${props => (props.active ? "bold" : "600")};
  }
`;

export const EventStatus = styled.div`
  padding: 0 10px 0 20px;
  .status-label {
    margin: 10px 0;
    color: #7F8181;
    font-weight: 500;
    font-size: 18px;
  }
  .title {
    margin: 0 0 10px 0;
    color: #6C6C6C;
    font-weight: bold;
    font-size: 18px;
  }
  .return-button {
    cursor: pointer;
    color: #00577C;
    font-size: 14px;
    text-decoration: none;
  }
`
