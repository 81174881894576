import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Formik, Form, Field } from "formik";

import * as S from "./styles";
import InputMask from "react-input-mask";

// * Components
import LoadingModal from "Components/LoadingModal";

// * Utils
import { validatePhone } from "Utils";

// @ts-ignore
import { estados } from "estados-br";
// @ts-ignore
import { FormikTextField, FormikSelectField } from "formik-material-fields";
import { useStore } from "Store";
import { history } from "Store/History";
import { Button, makeStyles, Grid, Typography, Checkbox } from "@material-ui/core";
import { GenericButton } from "Components/Styled/Form";
import { IUserSignupValues, IUserRegisterQuestionValues } from "Models/User";
import { SignupSchema, initialValues } from "./models";

import styled from "styled-components";
import { toast } from "react-toastify";

import { Autocomplete } from 'material-ui-formik-components/Autocomplete'
import { fieldToTextField } from 'formik-material-ui'
import { TextField } from '@material-ui/core'
import { Company } from "Models/Companies";
import { LandingPageModel } from "Models/LandingPage";
import { EventModel } from "Models/Events";
import { RegisterQuestionModel } from "Models/RegisterQuestion";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    height: "auto",
    // minWidth: "700px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "10px",
  },
}));

//@ts-ignore
export const FormikAutocomplete = ({ textFieldProps, ...props }) => {
  const {
    form: { setTouched, setFieldValue }
  } = props
  //@ts-ignore
  const { error, helperText, ...field } = fieldToTextField(props)
  const { name } = field

  return (
    //@ts-ignore
    <Autocomplete
      {...props}
      {...field}
      //@ts-ignore
      onChange={(_: any, value: any) => {
        setFieldValue(name, value);
        if (props.onChange) props.onChange(value);
      }}
      //@ts-ignore
      onBlur={() => setTouched({ [name]: true })}
      getOptionSelected={(item: any, current: any) => item.value === current.value}
      renderInput={(props: any) => (
        <FormikTextField
          {...props}
          {...field}
          {...textFieldProps}
          helperText={helperText}
          error={error}
        />
      )}
    />
  )
}

const Signup = () => {
  const { userStore, subscriptionsStore, attendeesStore, companyStore, landingpagesStore, eventsStore, appStore, registerquestionsStore } = useStore();
  const classes = useStyles();
  const [currentUf, setCurrentUf] = useState("PA");
  const [zipcode, setZipcode] = useState("");
  const [zipcodeError, setZipcodeError] = useState(false);
  const [zipcodeErrorMessage, setZipcodeErrorMessage] = useState("");
  const [isFetchingCep, setIsFetchingCep] = useState(false);
  const [currentFile, setCurrentFile] = useState<FileList | null>(null);
  // const [companies, setCompanies] = useState([]  as {label: string, value: string}[]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [companiesOptions, setCompaniesOptions] = useState([]  as {label: string, value: string}[]);
  const [selectedCompany, setSelectedCompany] = useState("default" as string);
  const [landingPage, setLandingPage] = useState<LandingPageModel>({} as LandingPageModel);
  const [currentEvent, setCurrentEvent] = useState<EventModel | null>();
  const [registerQuestions, setRegisterQuestions] = useState<RegisterQuestionModel | undefined>(undefined);
  const [question1Checked, setQuestion1Checked] = useState(false);
  const [question2Checked, setQuestion2Checked] = useState(false);
  const [question3Checked, setQuestion3Checked] = useState(false);

  const getCompaniesData = useCallback(async () => {
    const response = await companyStore.fetchCompaniesForSignup(1, 999);
    // @ts-ignore
    setCompanies(response.businesses);
  }, []);

  const fetchRegisterQuestions = useCallback(async () => {
    const resp = await registerquestionsStore.fetchRegisterQuestion();

    if (resp) {
      setRegisterQuestions(resp);
    }
  }, [registerquestionsStore]);

  useEffect(() => {
    getCompaniesData();
    fetchRegisterQuestions();
  }, [getCompaniesData]);

  useEffect(() => {
    if (subscriptionsStore.eventIdForSubscribe) {
      getLandingPage();
    }

  }, [subscriptionsStore.eventIdForSubscribe]);

  const getLandingPage = useCallback(async () => {
    const eventResponse = await eventsStore.fetchEventById(subscriptionsStore.eventIdForSubscribe);
    const landingPageResponse = await landingpagesStore.fetchLandingPage(subscriptionsStore.eventIdForSubscribe);

    if (landingPageResponse && eventResponse) {
      setLandingPage(landingPageResponse);
      // @ts-ignore
      setCurrentEvent(eventResponse.data.event);
    }
  }, []);

  useEffect(() => {
    if (companies.length > 0) {
      const opt = [{ label: "Selecionar Empresa", value: "default" }];
      companies.forEach((business) => {
        opt.push({
          label: business.trade_name.toUpperCase(),
          value: business._id,
        });
      });

      setCompaniesOptions(opt);
    }

  }, [companies]);

  const getAddressDataByCep = async (
    zipcode: string,
    setFieldValue: Function,
    setFieldError: Function
  ) => {
    try {
      setIsFetchingCep(true);
      const response = await axios.get(
        `https://viacep.com.br/ws/${zipcode}/json/`
      );
      if (!response.data.erro) {
        setFieldValue("city", response.data.localidade);
        setFieldValue("district", response.data.bairro);
        setFieldValue("street", response.data.logradouro);
        setFieldValue("state", response.data.uf);
        setCurrentUf(response.data.uf);
        setFieldError("zipcode", null);
        setZipcodeError(false);
        setZipcodeErrorMessage("");
      } else {
        setZipcodeError(true);
        setZipcodeErrorMessage("CEP não encontrado");
        setFieldError("zipcode", "CEP não encontrado");
        setFieldValue("district", "");
        setFieldValue("city", "");
        setFieldValue("street", "");
        setCurrentUf("PA");
      }
      setIsFetchingCep(false);
    } catch (error) {
      setFieldValue("city", "");
      setFieldValue("street", "");
      setCurrentUf("PA");
      setIsFetchingCep(false);
    }
  };

  const handleCepChange = (
    value: string,
    setFieldValue: Function,
    setFieldError: Function
  ) => {
    try {
      if (value.charAt(8) !== "_") {
        const formattedCep = value.replace("-", "");
        getAddressDataByCep(formattedCep, setFieldValue, setFieldError);
      }
      setFieldValue("zipcode", value);
      setZipcode(value);
    } catch (error) {}
  };

  const onHandleSubmit = async (values: IUserSignupValues) => {
    try {
      console.log("submit")
      if (values.phone_number) {
        var valid = validatePhone(values.phone_number);
        if (!valid) {
          toast.error("Telefone inválido, altere e tente novamente.");
          return;
        }
      } else {
        toast.error("Telefone inválido, altere e tente novamente.");
        return;
      }

      if (!selectedCompany || selectedCompany === "default") {
        console.log(selectedCompany)
        toast.error("Selecione uma empresa para prosseguir.");
        return;
      }

      if (selectedCompany && selectedCompany !== "") values.business_id = selectedCompany;
      if (!registerQuestions) {
        toast.error("Responda as questões da LGPD.");
        return;
      }

      var register_questions = {
          register_question_id: registerQuestions._id,
          question_1: registerQuestions.question_1,
          question_2: registerQuestions.question_2,
          question_3: registerQuestions.question_3,
          answer_1: question1Checked,
          answer_2: question2Checked,
          answer_3: question3Checked
      } as IUserRegisterQuestionValues;

      const currentUf: string = "PA";
      await userStore.signup({ ...values, state: currentUf }, register_questions);

      //Registra no evento caso esteja vindo de um
      if (userStore.isSignedIn && userStore.currentUser && subscriptionsStore.eventIdForSubscribe && subscriptionsStore.eventIdForSubscribe !== "") {
        await subscriptionsStore.onSubscribeEvent(subscriptionsStore.eventIdForSubscribe, userStore.currentUser._id);
      }

      if (currentFile && userStore.currentUser) {
        await attendeesStore.updateAttendeeProfileImage(userStore.currentUser._id, currentFile[0]);
      }

      setTimeout(() => {
        history.push("/subscriptions");
      }, 2000);

    } catch (error) {
      console.log(error.response.data.error.message);
      throw error;
    }
  };

  return (
    <>
    <Formik
      initialValues={initialValues}
      validationSchema={SignupSchema}
      onSubmit={onHandleSubmit}
    >
      {({
        setFieldValue,
        setFieldError,
        values,
        handleChange,
        errors,
      }) => (
        <FormContainer>
          <Form autoComplete="off">
            <S.Container style={{ display: "flex", justifyContent: "center", background: (landingPage ? landingPage.description_register_background_color : "linear-gradient(180deg,rgba(0, 87, 123, 1) 0%,rgba(10, 52, 94, 1) 100%)") }}>
              <S.ContentContainer style={{ marginBottom: "100px" }}>
                {currentEvent && (
                  <div style={{ display: "flex", justifyContent: "center", marginTop: "25px" }}>
                    <div className={classes.paper}>
                      <img src={appStore.currentBaseURL + "/" + currentEvent?.cover_url} style={{
                        alignSelf: "center;", 
                        width: "100%",
                        // paddingLeft: "100px",
                        // paddingRight: "100px"
                        }}></img>
                    </div>
                  </div>
                )}
                {/* marginTop: "50px", */}
                <h1
                  style={{ marginTop: "25px", marginBottom: "25px", color: "#ffffff", textAlign: "center" }}
                >
                  Cadastro de Participante
                </h1>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className={classes.paper}>
                    {/* <Formik
                      initialValues={initialValues}
                      validationSchema={SignupSchema}
                      onSubmit={onHandleSubmit}
                    >
                      {({
                        setFieldValue,
                        setFieldError,
                        values,
                        handleChange,
                        errors,
                      }) => ( */}
                        <>
                          <LoadingModal open={isFetchingCep}>
                            <S.LoadingStatusContainer>
                              Buscando os dados de endereço...
                            </S.LoadingStatusContainer>
                          </LoadingModal>
                          {/* <FormContainer style={{ marginTop: "15px" }}>
                            <Form autoComplete="off"> */}
                              <Grid container spacing={2} style={{ marginTop: "15px" }}>
                                {currentEvent && (
                                  <Grid item xs={12} sm={12}>
                                    <S.FormText>
                                      Preencha os campos abaixo para concluir o registro no evento: {currentEvent.name}
                                    </S.FormText>
                                  </Grid>
                                )}
                                <Grid item xs={12} sm={6}>
                                  <FormikTextField
                                    name="first_name"
                                    label="Nome"
                                    fullWidth
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <FormikTextField
                                    name="last_name"
                                    label="Sobrenome"
                                    fullWidth
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <FormikTextField
                                    name="email"
                                    label="Email"
                                    fullWidth
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <FormikTextField
                                    name="password"
                                    type="password"
                                    label="Senha"
                                    fullWidth
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Field
                                    name="business_id_search"
                                    className="form-field"
                                    component={FormikAutocomplete}
                                    variant="outlined"
                                    label="Empresa"
                                    value={selectedCompany}
                                    options={companiesOptions}
                                    //@ts-ignore
                                    onChange={(target => {
                                      if (target && target.value) setSelectedCompany(target.value);
                                    })}
                                    // textFieldProps={{
                                    //   fullWidth: true
                                    // }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <FormikTextField
                                    name="job_title"
                                    label="Cargo"
                                    fullWidth
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <InputMask
                                    mask="999.999.999-99"
                                    name="document"
                                    value={values.document}
                                    onChange={handleChange}
                                  >
                                    {() => (
                                      <FormikTextField
                                        // mask="10.808.400/0001-81"
                                        name="document"
                                        label="CPF"
                                        fullWidth
                                        variant="outlined"
                                      />
                                    )}
                                  </InputMask>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <InputMask
                                    mask="99999-999"
                                    name="zipcode"
                                    value={zipcode}
                                    onChange={(e) =>
                                      handleCepChange(
                                        e.target.value,
                                        setFieldValue,
                                        setFieldError
                                      )
                                    }
                                  >
                                    {() => (
                                      <FormikTextField
                                        fullWidth
                                        label="CEP"
                                        variant="outlined"
                                        name="zipcode"
                                        helperText={
                                          errors.zipcode || zipcodeErrorMessage
                                        }
                                        error={
                                          Boolean(errors.zipcode) || zipcodeError
                                        }
                                      />
                                    )}
                                  </InputMask>
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                  <FormikTextField
                                    name="street"
                                    label="Rua"
                                    fullWidth
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                  <FormikTextField
                                    name="street_number"
                                    label="Número"
                                    fullWidth
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormikTextField
                                    name="complement"
                                    label="Complemento"
                                    fullWidth
                                    variant="outlined"
                                  />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <FormikTextField
                                    name="district"
                                    label="Bairro"
                                    fullWidth
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <FormikTextField
                                    name="city"
                                    label="Cidade"
                                    fullWidth
                                    variant="outlined"
                                  />
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                  <FormikSelectField
                                    style={{ width: "100%" }}
                                    className="form-field"
                                    name="state"
                                    label="UF"
                                    options={estados.map((estado: any) => ({
                                      label: estado.sigla,
                                      value: estado.sigla,
                                    }))}
                                    variant="outlined"
                                  />
                                </Grid>

                                <Grid item xs={12} sm={9}>
                                  <InputMask
                                    mask="(99) 99999-9999"
                                    name="phone_number"
                                    value={values.phone_number}
                                    onChange={handleChange}
                                  >
                                    {() => (
                                      <FormikTextField
                                        name="phone_number"
                                        label="Telefone"
                                        fullWidth
                                        variant="outlined"
                                        // validate={validatePhone}
                                        // value={values.phone_number}
                                        // onChange={handleChange}
                                      />
                                    )}
                                  </InputMask>
                                </Grid>

                                <Grid item xs={12} sm={12} style={{justifyContent: "center", display: "flex"}}>
                                  <input
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      setCurrentFile(e.target.files)
                                    }}
                                    type="file"
                                    id="file"
                                    accept="image/*"
                                  />
                                  <label htmlFor="file" style={{background: "#00577b"}}>Anexar Imagem de Perfil</label>
                                </Grid>

                                <Grid item xs={12} sm={12} style={{justifyContent: "center", display: "flex"}}>
                                  {currentFile && (
                                    <Typography variant="h6" gutterBottom>
                                      Arquivo: {currentFile[0].name}
                                    </Typography>
                                  )}
                                </Grid>

                                {/* <S.ButtonRow>
                                  <S.SubmitButton
                                    onClick={() => {
                                      history.replace("/");
                                      //history.goBack()
                                    }}
                                    variant="contained"
                                  >
                                    Login
                                  </S.SubmitButton>
                                  <GenericButton variant="contained" type="submit">
                                    Cadastrar
                                  </GenericButton>
                                </S.ButtonRow> */}
                              </Grid>
                            {/* </Form>
                          </FormContainer> */}
                        </>
                      {/* )}
                    </Formik> */}
                  </div>
                </div>

                {registerQuestions && (
                  <div style={{ display: "flex", justifyContent: "center", marginTop: "25px" }}>
                    <div className={classes.paper}>
                      <div dangerouslySetInnerHTML={{ __html: registerQuestions ? registerQuestions.description : "" }} />
                    </div>
                  </div>
                )}

                <div style={{ display: "flex", justifyContent: "center", marginTop: "25px" }}>
                  <div className={classes.paper}>
                    <Grid container spacing={2}>
                      <Grid item xs={1} sm={1}>
                        <Checkbox
                          edge="start"
                          checked={question1Checked}
                          onChange={(e, checked) => {
                            setQuestion1Checked(checked);
                          }}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            "aria-labelledby": "question_1",
                          }}
                        />
                      </Grid>
                      <Grid item xs={11} sm={11}>
                          {registerQuestions ? registerQuestions.question_1 : ""}
                      </Grid>
                    </Grid>
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center", marginTop: "25px" }}>
                  <div className={classes.paper}>
                    <Grid container spacing={2}>
                      <Grid item xs={1} sm={1}>
                        <Checkbox
                          edge="start"
                          checked={question2Checked}
                          onChange={(e, checked) => {
                            setQuestion2Checked(checked);
                          }}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            "aria-labelledby": "question_2",
                          }}
                        />
                      </Grid>
                      <Grid item xs={11} sm={11}>
                          {registerQuestions ? registerQuestions.question_2 : ""}
                      </Grid>
                    </Grid>
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center", marginTop: "25px" }}>
                  <div className={classes.paper}>
                    <Grid container spacing={2}>
                      <Grid item xs={1} sm={1}>
                        <Checkbox
                          edge="start"
                          checked={question3Checked}
                          onChange={(e, checked) => {
                            setQuestion3Checked(checked);
                          }}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            "aria-labelledby": "question_3",
                          }}
                        />
                      </Grid>
                      <Grid item xs={11} sm={11}>
                          {registerQuestions ? registerQuestions.question_3 : ""}
                      </Grid>
                    </Grid>
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center", marginTop: "25px" }}>
                  <S.ButtonRow>
                    <S.SubmitButton
                      onClick={() => {
                        history.replace("/");
                        //history.goBack()
                      }}
                      variant="contained"
                    >
                      Login
                    </S.SubmitButton>
                    <GenericButton variant="contained" type="submit">
                      Cadastrar
                    </GenericButton>
                  </S.ButtonRow>
                </div>

              </S.ContentContainer>
            </S.Container>
          </Form>
        </FormContainer>
      )}
      </Formik>
    </>
  );
};

export default Signup;

export const FormContainer = styled.div`
  width: 100%;
`;
