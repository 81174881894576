import { configure, observable, action, runInAction, toJS } from "mobx";
import { toast } from "react-toastify";
import {
  EventModel,
  Checkin,
} from "Models/Events";

import { IEventPagination, IDrawPagination } from "Models/Utils";

import { Post, Get, Delete, Put } from "Services/Request";
import { history } from "Store/History";
import { IFormValues } from "Pages/Events/ShowEvent/Page/models/indes";
import { EventSponsorModel } from "Models/Events";

configure({ enforceActions: "observed" });

class EventSponsorsStore {
  constructor() {
    // this.fetchEventSponsor();
  }

  @observable
  sponsors: EventSponsorModel[] = [];

  @observable
  isFetchingEvents: boolean = false;

  @action
  fetchEventSponsors = async (eventId: string) => {
    this.isFetchingEvents = true;
    try {
      const response = await Get(`/api/events/${eventId}/sponsors`);
      runInAction(() => {
        this.isFetchingEvents = false;
        // @ts-ignore
        this.sponsors = response.data.data.sponsors;
      });

      //@ts-ignore
      return response.data.data.sponsors;
    } catch (error) {
      runInAction(() => {
        this.isFetchingEvents = false;
      });
      console.log(error.response);
    }
  };

  @action
  onSubmitEventSponsor = async (eventId: string, cover: any) => {
    const data = new FormData();
    data.append("cover", cover);

    try {
      const response = await Post(`/api/events/${eventId}/sponsors`, data);
      toast.success("Patrocinador cadastrado com sucesso");
      console.log(response.data);
    } catch (error) {
      toast.error(error.response.data.error.message);
      console.log(error.response);
    }
  };

  @action
  onDeleteEventSponsor = async (eventId: string, sponsorId: string) => {

    try {
      const response = await Delete(`/api/events/${eventId}/sponsors/${sponsorId}`);
      toast.success("Patrocinador excluído com sucesso");
      console.log(response.data);
    } catch (error) {
      toast.error(error.response.data.error.message);
      console.log(error.response);
    }
  };

}

export const eventsponsorsStore = new EventSponsorsStore();
