import React, { useState, Reducer, useEffect, useReducer } from "react";
import { useStore } from "Store";
import { useHistory } from "react-router-dom";
import { useObserver } from "mobx-react-lite";
import { EventModel } from "Models/Events";
import moment from "moment";

// * Components

import { NoDataContainer } from "Components/Styled/util";
import SearchBar from "Components/SearchBar";

import { CustomCircularProgress } from "Components/Styled/Loading";
import { PaginationContainer } from "Components/Styled/table";

// * Material UI and styles

import * as S from "./styles";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableRow, Paper, IconButton, Chip } from "@material-ui/core";

import Pagination from "@material-ui/lab/Pagination";
import { GenericTableHead, GenericFAB } from "Components/Styled/table";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import { eventsStore } from "Store/Events";
import { MoreVert, Add } from "@material-ui/icons";

import { initialState, IAction, ActionType, IDraw, IState } from "./models";
import { reducer } from "./store";

import DrawContextMenu from "./components/DrawContextMenu";
import DeleteDrawDialog from "./components/DeleteDrawDialog";
import ReRunDrawDialog from "./components/ReRunDrawDialog";
import WinnerDialog from "./components/WinnerDialog";
import LoadingModal from "Components/LoadingModal";

interface IProps {
  currentEvent: EventModel;
  refetchEvent: Function;
}

const Draw: React.FC<IProps> = ({ currentEvent, refetchEvent }) => {
  const [isBetweenStartAndEndDate] = useState(
    moment().isBefore(currentEvent.ends_at)
  );
  const history = useHistory();
  const [
    {
      draws,
      isLoading,
      currentDrawId,
      anchorEl,
      isContextMenuOpen,
      isDeleteDialogOpen,
      isReRunDialogOpen,
      isWinnerDialogOpen,
      isFetchingDraws,
      winnerName,
    },
    dispatch,
  ] = useReducer<Reducer<IState, IAction>>(reducer, initialState);

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    drawId: string
  ) => {
    dispatch({ type: ActionType.SetIsContextMenuOpen, payload: true });
    dispatch({ type: ActionType.SetCurrentDrawId, payload: drawId });
    dispatch({ type: ActionType.SetAnchorEl, payload: event.currentTarget });
  };

  const handleClose = () => {
    dispatch({ type: ActionType.SetAnchorEl, payload: null });
    dispatch({ type: ActionType.SetIsContextMenuOpen, payload: false });
  };

  const fetchDraws = async ({
    eventId,
    page = 1,
  }: {
    eventId: string;
    page?: number;
  }) => {
    dispatch({ type: ActionType.SetIsFetchingDraws, payload: true });
    try {
      const res = await eventsStore.fetchDrawsByEventId({
        eventId,
        page,
        limit: 999
      });
      console.log(res);
      // @ts-ignore
      dispatch({ type: ActionType.SetEventDraws, payload: res.data.draws });
      dispatch({ type: ActionType.SetIsFetchingDraws, payload: false });
    } catch (error) {
      dispatch({ type: ActionType.SetIsFetchingDraws, payload: false });
      console.log(error);
    }
  };

  const handleEditDraw = () => {
    history.push(`/events/${currentEvent._id}/draw/form/${currentDrawId}`);
    handleClose();
  };

  const handleDeleteDraw = () => {
    handleClose();
    dispatch({ type: ActionType.SetIsDeleteDialogOpen, payload: true });
  };

  const onDeleteDraw = async () => {
    dispatch({ type: ActionType.SetIsDeletingDraw, payload: true });
    try {
      dispatch({ type: ActionType.SetIsDeleteDialogOpen, payload: false });
      eventsStore.handleDeleteDraw({
        eventId: currentEvent._id,
        drawId: currentDrawId,
      });
      fetchDraws({
        eventId: currentEvent._id,
      });
      dispatch({ type: ActionType.SetIsDeletingDraw, payload: false });
    } catch (error) {
      dispatch({ type: ActionType.SetIsDeletingDraw, payload: false });
      console.log(error);
    }
  };

  const handleReRunDraw = () => {
    dispatch({ type: ActionType.SetIsReRunDialogOpen, payload: false });
    handleRunDraw(currentDrawId);
  };

  const checkIfDrawHasAlreadyRan = (draw: IDraw) => {
    dispatch({ type: ActionType.SetCurrentDrawId, payload: draw._id });
    if (draw.winner) {
      dispatch({ type: ActionType.SetIsReRunDialogOpen, payload: true });
    } else {
      handleRunDraw(draw._id);
    }
  };

  const handleRunDraw = async (drawId: string) => {
    try {
      const response = await eventsStore.handleRunDraw({
        drawId,
        eventId: currentEvent._id,
      });
      fetchDraws({ eventId: currentEvent._id });
      dispatch({
        type: ActionType.SetIsWinnerDialogOpen,
        payload: true,
      });
      // @ts-ignore
      console.log(response.data);
      dispatch({
        type: ActionType.SetWinnerName,
        // @ts-ignore
        payload: response.data.draw.winner.user.first_name + " " + response.data.draw.winner.user.last_name + " (" + ( response.data.draw.winner.user.business ? response.data.draw.winner.user.business.name : "" ) + ")",
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDraws({ eventId: currentEvent._id });
  }, [currentEvent._id]);

  return useObserver(() => (
    <>
      <LoadingModal open={isFetchingDraws}>
        <S.LoadingStatusContainer>
          Buscando sorteios...
        </S.LoadingStatusContainer>
      </LoadingModal>
      <SearchBar title={"Sorteios"} onFilter={() => {}} />
      <S.Container>
        {draws.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <GenericTableHead>
                <TableRow>
                  <TableCell>Prêmio</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell>Vencedor</TableCell>
                  {/* <TableCell>Participantes</TableCell> */}
                  <TableCell></TableCell>
                </TableRow>
              </GenericTableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <td colSpan={4}>
                      <S.LoadingContainer>
                        <CustomCircularProgress />
                      </S.LoadingContainer>
                    </td>
                  </TableRow>
                ) : (
                  draws.map((draw) => (
                    <TableRow key={draw._id}>
                      <TableCell component="th" scope="row">
                        {draw.prize}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {draw.prize_description}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {draw.winner
                          ? draw.winner.user.first_name + " " + draw.winner.user.last_name + " (" + ( draw.winner.user.business ? draw.winner.user.business.name : "" ) + ")"
                          : "Sorteio por realizar"}
                      </TableCell>
                      {/* <TableCell component="th" scope="row">
                        {draw.competitors.length}
                      </TableCell> */}
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ textAlign: "right" }}
                      >
                        <Chip
                          label="Sortear"
                          clickable
                          color="secondary"
                          onClick={() => checkIfDrawHasAlreadyRan(draw)}
                        />
                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={(e) => handleClick(e, draw._id)}
                        >
                          <MoreVert color="secondary" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
            <PaginationContainer>
              <Pagination
                page={eventsStore.currentDrawsPagination.page}
                count={eventsStore.currentDrawsPagination.pages}
                onChange={(event, value) =>
                  fetchDraws({
                    eventId: currentEvent._id,
                    page: value,
                  })
                }
                color="secondary"
              />
            </PaginationContainer>
          </TableContainer>
        ) : (
          <NoDataContainer>
            <h3>Nenhum sorteio por aqui!</h3>
          </NoDataContainer>
        )}
      </S.Container>
      <DrawContextMenu
        open={isContextMenuOpen}
        handleClose={handleClose}
        handleDelete={handleDeleteDraw}
        handleEdit={handleEditDraw}
        anchorEl={anchorEl}
      />
      <DeleteDrawDialog
        open={isDeleteDialogOpen}
        handleDelete={() => onDeleteDraw()}
        handleClose={() =>
          dispatch({ type: ActionType.SetIsDeleteDialogOpen, payload: false })
        }
        itemName={draws.find((draw) => draw._id === currentDrawId)?.prize}
      />
      <GenericFAB
        variant="extended"
        color="primary"
        onClick={() => history.push(`/events/${currentEvent._id}/draw/form`)}
        disabled={!currentEvent.active || !isBetweenStartAndEndDate}
      >
        <Add /> Novo sorteio
      </GenericFAB>
      <ReRunDrawDialog
        open={isReRunDialogOpen}
        handleClose={() =>
          dispatch({ type: ActionType.SetIsReRunDialogOpen, payload: false })
        }
        onSuccess={() => handleReRunDraw()}
      />
      <WinnerDialog
        open={isWinnerDialogOpen}
        handleClose={() =>
          dispatch({ type: ActionType.SetIsWinnerDialogOpen, payload: false })
        }
        winnerName={winnerName}
      />
    </>
  ));
};

export default Draw;
