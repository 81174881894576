import React from 'react'

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

interface IProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  active: boolean;
  handleClose: Function;
  handleEdit: Function;
  handleDelete: Function;
  handleActive: Function;
}

const EventContextMenu: React.FC<IProps> = ({ active, anchorEl, open, handleClose, handleDelete, handleEdit, handleActive }) => {
  return (
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={() => handleClose()}
    >
      <MenuItem onClick={() => handleActive()}>
        {active ? 'Desativar' : 'Ativar'}
      </MenuItem>
      <MenuItem onClick={() => handleEdit(true)}>
        Editar
      </MenuItem>
      <MenuItem onClick={() => handleDelete(true)}>
        Excluir
      </MenuItem>
    </Menu>
  )
}

export default EventContextMenu
