import styled, { css } from "styled-components";

// * Assets
const LOGO_BRANCA = require("../../../Assets/Images/LOGO_BRANCA.svg");

// * Base Styles
const BaseTitle = styled.h1`
  font-size: 40px;

  @media (max-width: 500px) {
    font-size: 18px;
  }
  @media (min-height: 700px) and (max-height: 850px) {
    font-size: 24px;
  }
`;

// * Main Components

export const Container = styled.div`
  /* min-height: 100%; */
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: linear-gradient(
    180deg,
    rgba(0, 87, 123, 1) 0%,
    rgba(10, 52, 94, 1) 100%
  );
`;

export const LogoContainer = styled.div`
  height: 0;
  width: 250px;
  height: 250px;

  background: url(${LOGO_BRANCA}) no-repeat;
  background-position: center center;
  background-size: 90%;

  @media (max-width: 1400px) {
    width: 200px;
    height: 200px;
  }

  @media (max-height: 650px) {
    width: 160px;
    height: 160px;
  }
`;

export const ContentContainer = styled.div`
  width: 33%;

  position: center center;
  background-position: center center;

  @media (max-width: 1240px) {
    width: 50%;
  }
  @media (max-width: 600px) {
    width: 90%;
  }
`;

export const PageTitle = styled(BaseTitle)`
  color: #e2e2eb;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;

  position: center center;
  background-position: center center;

  padding: 2rem;

  background: #fff;
  border-radius: 10px;

  * {
    margin: 10px 0;
  }

  @media (max-width: 400px) {
    padding: 1rem;
  }
`;

export const FormTitle = styled(BaseTitle)`
  color: #656565;
  margin: 5px 0;
`;

export const FormSubtitle = styled.h2`
  font-size: 20px;
  color: #585858;
  font-weight: 300;
  margin: 0 0 40px 0;

  @media (max-width: 500px) {
    font-size: 14px;
    margin: 0 0 20px 0;
  }
  @media (max-height: 650px) {
    margin: 0 0 10px 0;
  }
`;

export const ForgotTitle = styled.h2`
  font-size: 15px;
  color: #585858;
  font-weight: 300;
  text-align: center;

  @media (max-width: 500px) {
    font-size: 10px;
  }
`;

export const InputLabel = styled.label`
  margin-top: 10px;

  color: #585858;
`;

interface LoginButtonProps {
  disabled: boolean;
}
export const LoginButton = styled.button<LoginButtonProps>`
  align-self: center;
  padding: 0 30px;
  margin: 1rem 0 0.6rem;
  min-width: 170px;
  border-radius: 50px;
  max-width: 170px;
  cursor: pointer;
  background: #00577c;
  color: #fff;

  ${(props) =>
    props.disabled &&
    css`
      background: #e3e3e3;
      cursor: not-allowed;
    `}

  @media (max-width: 420px) {
    margin: 20px 0 15px;
  }
  @media (max-height: 650px) {
    margin: 10px 0 0px;
  }
`;

export const SignupButton = styled(LoginButton)`
  background: #168669;
`;
export const SettingsButton = styled(LoginButton)`
  background: #ddd;
  color: #565656;
  white-space: nowrap;
`;

export const LoginButtonTitle = styled.div`
  font-size: 16px;
  margin: 10px 0;

  font-weight: bold;
`;

export const LoadingStatusContainer = styled.div`
  font-weight: bold;
  color: #fff;
`;
