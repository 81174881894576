import React from "react";
//@ts-ignore
import jsPDF from "jspdf";
import { GenericButton } from "Components/Styled/Form";

interface Data {
  event: string;
  background_url: string;
  participant: string;
  localization: string;
  day: string;
  hours: string;
}
interface Props {
  data: Data;
}

const PdfCertificate = ({ data }: Props) => {
  function getDataUri(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      var image = new Image();
      image.setAttribute("crossOrigin", "anonymous"); // obtendo imagens de domínio externo

      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width; // this.naturalWidth;
        canvas.height = image.height; // this.naturalHeight;

        // Próximas três linhas para fundo branco caso o PNG tenha fundo transparente
        var ctx = canvas.getContext("2d");
        if (!ctx) {
          reject(new Error("Falha ao obter o contexto do canvas"));
          return;
        }
        ctx.fillStyle = "#fff"; /// define o estilo de preenchimento como branco
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        ctx.drawImage(image, 0, 0);

        resolve(canvas.toDataURL("image/jpeg"));
      };
      image.onerror = function (err) {
        reject(err);
      };
      image.src = url;
    });
  }

  const exportPDF = async () => {
    try {
      const unit = "pt";
      const size = "A4";
      const orientation = "landscape";
      const marginLeft = 65;
      const doc = new jsPDF(orientation, unit, size);

      console.log("Entrou!");
      console.log(data);

      var imgBackground = await getDataUri(data.background_url);

      doc.addImage(
        imgBackground,
        "JPEG",
        0,
        0,
        doc.internal.pageSize.getWidth(),
        doc.internal.pageSize.getHeight()
      );

      doc.setFontSize(20);
      doc.setFont("helvetica", "bold");
      doc.setTextColor("#F7D917");
      doc.text(
        `CERTIFICADO`,
        doc.internal.pageSize.getWidth() / 2,
        170,
        { align: "center" }
      );

      doc.setFontSize(16);
      doc.setTextColor("#FFFFFF");
      doc.setFont("helvetica", "normal");
      doc.text(
        `Certificamos que o Sr. (a) ${data.participant} participou do ${data.event},` +
          `\nrealizado no dia ${data.day}, no ${data.localization},` +
          `\ncom carga horária de ${data.hours}.`,
        doc.internal.pageSize.getWidth() / 2,
        210,
        { align: "center" }
      );

      doc.save(`Certificado - ${data.participant}.pdf`);
    } catch (error) {
      console.error("Erro ao gerar o PDF:", error);
    }
  };

  return (
    <>
      <GenericButton
        onClick={exportPDF}
        style={{
          width: "max-content",
          height: "auto",
          padding: "10px",
          float: "right",
        }}
      >
        GERAR
      </GenericButton>
    </>
  );
};

export default PdfCertificate;