import React, { useState, useEffect } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { GenericButton } from "Components/Styled/Form";
import { useStore } from "Store";

import { IAttendee } from "Models/Attendees";

interface IProps {
  open: boolean;
  isAdmin: boolean;
  attendeeId: string;
  handleClose: Function;
}

const AdminDialog: React.FC<IProps> = ({
  open,
  isAdmin,
  attendeeId,
  handleClose
}) => {
  const { attendeesStore } = useStore();
  const [currentAttendee, setCurrentAttendee] = useState<
    null | undefined | IAttendee
  >(null);
  useEffect(() => {
    setCurrentAttendee(
      attendeesStore.users.find(attendee => attendee._id === attendeeId)
    );
  }, [attendeeId, attendeesStore.users]);
  const handleAdmin = async () => {
    attendeesStore.toggleAdmin(attendeeId, isAdmin);
    handleClose();
  };
  return (
    <Dialog
      open={open}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {isAdmin ? "Tornar usuário administrador?" : "Remover usuário administrador?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Você está prestes a alterar o perfil de administrador do usuário <strong>{currentAttendee?.first_name}</strong>, deseja
          continuar?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <GenericButton aVariant="secondary" onClick={() => handleAdmin()}>
          Confirmar
        </GenericButton>
        <GenericButton variant="contained" onClick={() => handleClose()}>
          Cancelar
        </GenericButton>
      </DialogActions>
    </Dialog>
  );
};

export default AdminDialog;
