import React, { useState } from "react";

import { IProps } from "./models";

import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Pagination from "@material-ui/lab/Pagination";
import {
  GenericTableHead,
  TableTitle,
  PaginationContainer,
} from "Components/Styled/table";
import { MoreVert, Add } from "@material-ui/icons";
import { IAttendee } from "Models/Attendees";
import { ListBaseContainer } from "Components/Styled/List";
import { GenericTableToolbar } from "Components/Styled/util";
import { getPagesCount, paginate } from "Pages/Events/utils";
import ChipAlert from "Components/ChipAlert";

const DrawAttendeesList: React.FC<IProps> = ({ attendees }) => {
  const [currentPage, setCurrentPage] = useState(1);
  return (
    <>
      <ListBaseContainer>
        <TableContainer component={Paper}>
          <GenericTableToolbar>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TableTitle>
                Participantes deste sorteio ({attendees.length})
              </TableTitle>
              {attendees.length < 2 && (
                <ChipAlert
                  title={"participantes insuficientes"}
                  helperText="Um sorteio precisa de no mínimo 2 participantes"
                />
              )}
            </div>
          </GenericTableToolbar>
          <Table>
            <GenericTableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Empresa</TableCell>
                {/* <TableCell></TableCell> */}
              </TableRow>
            </GenericTableHead>
            <TableBody>
              {paginate({
                array: attendees,
                page_number: currentPage,
                page_size: 5,
              }).map((attendee) => (
                <TableRow key={attendee._id}>
                  <TableCell component="th" scope="row">
                    {attendee.first_name} {attendee.last_name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {attendee.business ? attendee.business.name : (attendee.business_title ? attendee.business_title + "*" : "N/D")}
                  </TableCell>
                  {/* <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={() => console.log(attendee._id)}
                  >
                    <MoreVert color="secondary" />
                  </IconButton> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <PaginationContainer>
            <Pagination
              page={currentPage}
              count={getPagesCount({
                itemsCount: attendees.length,
                pageSize: 5,
              })}
              onChange={(_, value) => setCurrentPage(value)}
              color="secondary"
            />
          </PaginationContainer>
        </TableContainer>
      </ListBaseContainer>
    </>
  );
};

export default DrawAttendeesList;
