import React from "react";
import "./styles/index.css";
const SimpleFilePicker = (props: {
  onLoadFile: (files: FileList | null) => void;
  id: string
}) => {
  return (
    <>
      <input
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          props.onLoadFile(e.target.files)
        }
        type="file"
        id={props.id}
        accept="image/*"
      />
      <label htmlFor={props.id}>Escolha um arquivo</label>
    </>
  );
};

export default SimpleFilePicker;
