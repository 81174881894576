import styled from "styled-components";

export const Container = styled.div`
  padding: 1rem 1rem 100px 1rem;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 98%;
  margin: 10px;
`;

export const LoadingStatusContainer = styled.div`
  font-weight: bold;
  color: #fff;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 95%;
  padding: 20px;
`;

export const NoParticipantsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
