import React from "react";
import Button from '@material-ui/core/Button';
import * as Yup from 'yup'
import * as S from './styles'

import { Form, Formik } from "formik";
// @ts-ignore
import { FormikTextField, FormikSelectField } from "formik-material-fields";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

interface Props {
  open: boolean;
  handleClose: Function;
  onSubmit: Function;
}

const AddParticipantModal: React.FC<Props> = ({
  open,
  handleClose,
  onSubmit
}) => {
  const companiesArray = [
    { label: "BitX", value: "BitX" },
    { label: "Microsoft", value: "Microsoft" },
    { label: "Sony", value: "Sony" }
  ]
  interface MyValues {
    name: string,
    company: string
  }
  const initialValues: MyValues = {
    company: companiesArray[0].value,
    name: "",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nome é obrigatório"),
    company: Yup.string().required("Empresa é obrigatório")
  })
  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={() => handleClose()}>
      <DialogTitle id="form-dialog-title">Novo participante</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Adicione um participante ao evento
        </DialogContentText>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          // @ts-ignore
          onSubmit={onSubmit}
        >
          {({ isValid, handleSubmit, values, handleChange }) => (
            <>
              <Form>
                <S.FormWrapper>
                  <FormikTextField
                    name="name"
                    label="Nome do participante"
                    fullWidth
                    variant="outlined"
                  />
                  <FormikSelectField
                    name="company"
                    label="Empresa"
                    options={companiesArray}
                    variant="outlined"
                  />
                </S.FormWrapper>
                <DialogActions>
                  <Button onClick={() => handleClose()} >
                    Cancelar
                  </Button>
                  <Button variant="contained" color="primary" type="submit">
                    Adicionar
                  </Button>
                </DialogActions>
              </Form>
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddParticipantModal;
